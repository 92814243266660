import React, { useEffect, useState } from "react";
import { AED_IMG_BASE, CallGETAPI } from "../../../../common/services";
import AEDUnitsSkeleton from "../../../accounts/components/DetailsComponents/AedUnitsSkeleton";
import { Box } from "@mui/material";
import '../../css/site-details.css';

const AedSiteUnits = ({ siteId }) => {
  const [aedUnits, setaedUnits] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI("site/site-aed-unit/" + siteId);
    if (result?.data?.status) {
      setaedUnits(result?.data?.data?.aed_units);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="">
      {aedUnits && aedUnits?.length > 0 && (
        <div>
          {/* <h4 className="rounded-container-title">AED Units Owned</h4> */}

          <div className="">
            {loading ? (
              <AEDUnitsSkeleton />
            ) : (
              <div className="site-page-flex" >
                {/* aed unit container */}
                <div className="aed-unit-container">
                  {aedUnits.length > 0 ? (
                    <>
                      <div style={{ width: "100%", border: '1px solid #0C71C3', margin: '5px 0' }} />
                      {[...Array(Math.ceil(aedUnits.length / 6) * 6)].map((_, i) => {
                        const aedIndex = i < aedUnits.length ? i : null;
                        const aed = aedIndex !== null ? aedUnits[aedIndex] : null;
                        const isFirstInRow = i % 6 === 0;
                        const showLineAbove = isFirstInRow && i > 0;

                        return (
                          <>
                            {showLineAbove && (
                              <div style={{ width: "100%", border: '1px solid #0C71C3', margin: '5px 0' }} />
                            )}
                            <div
                              key={i}
                              style={{
                                flex: "0 0 16.666%",
                                maxWidth: "17.666%",
                                marginBottom: 0,
                                padding: 0,
                              }}
                            // className={`${isLastRow ? "border-b-blue" : ""
                            //   }`}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  height: 55,
                                  fontWeight: "bold",
                                }}
                              >
                                <p style={{ margin: 0 }}>{aed?.brandName}</p>
                                <p style={{ margin: 0 }}>{aed?.modalName}</p>
                              </div>

                              {aed && (
                                <>
                                  <div
                                    style={{
                                      padding: "8% 0",
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={AED_IMG_BASE + aed?.modalImage}
                                      alt={aed?.modalName}
                                      style={{
                                        width: 130,
                                        height: 130,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                </>
                              )}

                              {aed && (
                                <>
                                  <div
                                    style={{
                                      padding: "1% 0",
                                      display: "flex",
                                      justifyContent: "center",
                                      fontWeight: "600",
                                    }}
                                  >
                                    <p style={{ fontSize: 18 }}>Own: {aed?.own}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        marginBottom: 0,
                        paddingTop: "12px",
                        textAlign: "center",
                      }}
                      className={`border-l-blue border-t-blue border-r-blue border-b-blue`}
                    >
                      <p>No AED units available</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AedSiteUnits;