import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { GetProfile } from "../../../common/helper";
import { CallGETAPI, UPLOAD_URL } from "../../../common/services";
import FinalNewBreadcrumbs from "../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { printConsole } from "../../../common/helper/Common";
const BASE_API = UPLOAD_URL + "/public/";
const BASE_API2 = UPLOAD_URL + "/api/";

const AccountDocumentDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const [documentData, setDocumentData] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const getDocumentsData = async () => {
    setLoading(true);
    const response = await CallGETAPI("document/get-document-by-id/", id);

    if (response?.status) {
      setDocumentData(response.data.data.documentList[0]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getDocumentsData();
    }
  }, [id]);
  const handleEdit = (id, name) => {
    const BreadCrumbsObject = {
      title: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };
    BreadCrumbsObject.title = name + " Details Edit";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-document-edit/${id}`,
      })
    );
  };
  const documentRedirect = () => {
    handleEdit(documentData?.document_id, documentData?.parent_document_name);
    navigate(`/account-document-edit/${documentData?.document_id}`, {
      state: {
        accountId: documentData?.account_id,
        siteId: documentData?.site_id,
      },
    });
  };

  const downloadFile = async () => {
    setLoading(true);
    await axios
      .get(`${BASE_API2}download/${documentData?.document_unique_name}`, {
        responseType: "blob",
      })
      .then((responses) => {
        const anchor = document.createElement("a");
        anchor.href = window.URL.createObjectURL(responses.data);
        anchor.download = documentData?.original_name;
        document.body.appendChild(anchor);
        anchor.click();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        printConsole("error", error); // print console.log
      });
  };

  let is_user = false;
  let privileges = [];

  return (
    <>
      <div className="mb-5 mt-4" style={{ width: "100%", margin: "auto" }}>
        <FinalNewBreadcrumbs />
        <div className="d-flex mt-2" style={{ paddingLeft: "0px" }}>
          <h1 className={"newAccountH1"}>
            <span className="account-title text-capitalize">
              Document: {documentData?.parent_document_name}
            </span>
          </h1>
        </div>
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          {is_user === true ? (
            <>
              {privileges.includes("edit-document") && (
                <div className="d-flex" style={{ gap: "10px" }}>
                  <button
                    className="btn text-primary"
                    type="button"
                    onClick={() => documentRedirect()}
                  >
                    <img
                      src="assets/images/edit.svg"
                      alt="svg"
                      style={{ marginRight: "5px" }}
                    />
                    <span className="ms-2">Edit</span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="d-flex" style={{ gap: "10px" }}>
                <button
                  className="btn text-primary"
                  type="button"
                  onClick={() => documentRedirect()}
                >
                  <img
                    src="/assets/images/edit.svg"
                    alt="svg"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-2">Edit</span>
                </button>
              </div>
            </>
          )}
        </div>

        <Box className="text-left pt-3 pb-1">
          <h4 className="heading">{props?.siteName}</h4>
        </Box>
        <div className="data-table pb-3">
          <table className="w-100 border-b-blue odd-even-row">
            <thead>
              <tr className="">
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  File Name
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Comment
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Related to
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue border-r-blue">
                  Uploaded by
                </th>
                <th className=" py-1 px-2 bg-tbl-border border-t-blue">
                  Uploaded date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="py-1 px-2 tbl-border border-r-blue">
                  <div className="d-flex align-items-center gap-2">
                    {loading ? (
                      <Skeleton
                        count={5}
                        width="90%"
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    ) : (
                      <>
                        <p className="m-0 flex-grow-1">
                          {documentData?.original_name}
                        </p>
                        <a
                          className="pointer"
                          href={`${BASE_API}${documentData?.document_unique_name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/assets/images/view_document.svg"
                            alt="View Document"
                            width={25}
                            height={25}
                          />
                        </a>
                        <div className="pointer" onClick={downloadFile}>
                          <img
                            src="/assets/images/download_document.svg"
                            alt="Download Document"
                            width={25}
                            height={25}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </td>

                <td className="py-1 px-2 tbl-border border-r-blue">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width="90%"
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    <p className="m-0">{documentData?.comment}</p>
                  )}
                </td>

                <td className=" py-1 px-2 tbl-border  border-r-blue">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width="90%"
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    <p className="m-0">{documentData?.related_to}</p>
                  )}
                </td>
                <td className=" py-1 px-2 tbl-border border-r-blue">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width="90%"
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    <p className="m-0"> {documentData?.uploaded_by_name}</p>
                  )}
                </td>
                <td className=" py-1 px-2 tbl-border">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width="90%"
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    <p className="m-0">
                      {" "}
                      {moment(documentData?.createdAt).format(
                        "MM/DD/YYYY hh:mm A"
                      )}
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AccountDocumentDetails;
