import React from "react";
import { useNavigate } from "react-router-dom";
import TopAedNewMove from "../../../../Aed/NewAed/components/AedButtons/TopAedNewMove";

export default function EquipmentNoDataTbl({
  accountId,
  accountName,
  siteId,
  siteName,
  newAed = 1,
  hideAccMove = 1,
  hideAedMove = 1,
  aedAccessoryCount,
}) {
  const navigate = useNavigate();

  // Table styling from AEDStandAlone
  const thStyle = {
    backgroundColor: "#ffffff",
    padding: "8px",
    textAlign: "left",
    fontWeight: "500",
    color: "#b6b6b6",
    borderBottom: "1px solid #ddd",
    borderTop: "1.4px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    color: "#b6b6b6",
    fontWeight: 600,
    borderBottom: "1.2px solid #ddd",
    backgroundColor: "#ffffff"
  };

  return (
    <div style={{ marginBottom: "3%" }}>
      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px", marginBottom: "3%" }}>
        <thead>
          <tr>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>AED Brand / Model</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Serial Number</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>AED Placement</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Battery Expiration</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Pads Expiration</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Last Check</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Last Service</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>RMS Check</span>
                <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={8} style={{ ...tdStyle, textAlign: "center", padding: "20px" }}>
              No Data Found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}