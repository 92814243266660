import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import { useNavigate } from "react-router-dom";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { Skeleton } from "@mui/material";
import { DecryptToken } from "../../../../common/helper/index.js";

const AssignedAedTbl = ({
  tableData,
  currentPage,
  setSearch,
  showLoading,
  totalPages,
  onPageChange,
  search,
  showSearchReset,
  setShowSearchReset,
  handleReset,
  fetchLoad,
  searchFetchLoad,
  setContactDataList,
  pageSize = 50,
  // from,
}) => {
  const navigate = useNavigate();
  const user = DecryptToken();

  const renderPrimary = (data) => {
    const assignUserData = data?.assign_user
      ? JSON.parse(data?.assign_user)
      : "";
    return (
      <>
        {assignUserData
          ? assignUserData[0]?.primary != ""
            ? assignUserData[0]?.primary
            : "N/A"
          : "N/A"}
      </>
    );
  };
  const renderbackup1 = (data) => {
    const assignUserData = data?.assign_user
      ? JSON.parse(data?.assign_user)
      : "";
    return (
      <>
        {assignUserData
          ? assignUserData[1]?.backup1 != ""
            ? assignUserData[1]?.backup1
            : "N/A"
          : "N/A"}
      </>
    );
  };
  const renderbackup2 = (data) => {
    const assignUserData = data?.assign_user
      ? JSON.parse(data?.assign_user)
      : "";
    return (
      <>
        {assignUserData
          ? assignUserData[2]?.backup2 != ""
            ? assignUserData[2]?.backup2
            : "N/A"
          : "N/A"}
      </>
    );
  };
  const renderbackup3 = (data) => {
    const assignUserData = data?.assign_user
      ? JSON.parse(data?.assign_user)
      : "";
    return (
      <>
        {assignUserData
          ? assignUserData[3]?.backup3 != ""
            ? assignUserData[3]?.backup3
            : "N/A"
          : "N/A"}
      </>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "AED Brand / Model",
        accessor: "aed_brand",
        width: "20%",
        disableSortBy: false,
      },
      {
        Header: "Serial #",
        accessor: "serial_number",
        width: "10%",
        disableSortBy: false,
      },
      {
        Header: "AED Placement",
        accessor: "placement",
        width: "10%",
        disableSortBy: false,
      },
      {
        Header: "Primary",
        accessor: "",
        width: "15%",
        disableSortBy: false,
        Cell: ({ row }) => renderPrimary(row.original),
      },
      {
        Header: "Backup 1",
        accessor: "status",
        Cell: ({ row }) => renderbackup1(row.original),
        width: "15%",
        disableSortBy: false,
      },
      {
        Header: "Backup 2",
        accessor: "",
        Cell: ({ row }) => renderbackup2(row.original),
        width: "15%",
        disableSortBy: false,
      },
      {
        Header: "Backup 3",
        accessor: " ",
        Cell: ({ row }) => renderbackup3(row.original),
        width: "15%",
        disableSortBy: false,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: (tableData && tableData) || [],
      initialState: { pageIndex: 0, pageSize: pageSize },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const RenderContact = (data) => {
    return (
      <>
        <span
          onClick={
            () =>
              // .includes('contact-details') && (
              navigate(
                "/account/" +
                  user?.account_id +
                  "/contact-details/" +
                  data.contact_id
              )
            // )}
            // navigate(
            //   "/account/" + accountId + "/contact-details/" + data.contact_id,
            // {
            //   state: {
            //     // siteTitle: "Contact : " + data?.contact_name,
            //     // editUrl: "/account/contact-details-edit/" + data.contact_id,
            //     deleteUrl: "/account/contact-details-edit/" + data.contact_id,
            //   },
            // }
            // )
          }
          className={"link"}
        >
          {data?.contact_name}
        </span>
      </>
    );
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search.length > 0) {
      setShowSearchReset(true);
      searchFetchLoad();
    }
  };

  return (
    <div className="bg-light base-table" style={{ width: "100%" }}>
      <form
        className=""
        style={{ display: "flex", flexDirection: "row", gap: "20px" }}
        onSubmit={handleSearch}
      ></form>
      <div className="table-responsive">
        <table
          {...getTableProps()}
          className="table table-bordered table-hover"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ width: column.width, backgroundColor: "#999" }}
                    className="tb-td"
                  >
                    <div className="d-flex align-items-center th-d">
                      {column.render("Header")}
                      <span className="ml-1">
                        {!column.disableSortBy && // Only show icon if sorting is not disabled
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} /> // Default icon if not sorted
                          ))}
                      </span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {showLoading || !page ? (
              [...Array(10)].map((_, i) => (
                <tr key={i}>
                  {columns.map((col, j) => (
                    <td
                      key={j}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                      className="tb-td"
                    >
                      <Skeleton
                        width={"90%"}
                        height={20}
                        style={{ margin: "10px" }}
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : !showLoading && page.length < 1 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  No Data Found.
                </td>
              </tr>
            ) : (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                        }}
                        className="tb-td"
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssignedAedTbl;
