import React, { useEffect, useState } from "react";
// import "./table.css";
import New from "../../../../common/img/New.png";
import DataGrid, {
  Scrolling,
  Paging,
  Column,
} from "devextreme-react/data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CallGETAPI } from "../../../../common/services/index";
import {
  getPermission,
  prepareOptions,
} from "../../../../common/helper/Common";
import Moment from "react-moment";

import { DecryptToken } from "../../../../common/helper/BasicFn";
// import Listing from "../../../EquipmentManagement/components/Listing/Listing";
import AdminListing from "../../../Support/Pages/Admin/Listing";
// import TableSkeleton from "../../../accounts/components/skeleton/table/TableSkeleton";
import { isPermission } from "../../../../common/helper/PermissionManager.jsx";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import { useDispatch } from "react-redux";

export default function Support({
  account_id,
  setTabTbldata,
  stateData = {},
  support_api,
  newSupportUrl,
  fetch_data_key,
  isSearchable,
}) {
  const { accountId } = useParams();
  const mainAccountId = accountId || account_id;
  const navigate = useNavigate();
  const [supportList, setSupportList] = useState([]);
  const [issueTypeList, setIssueTypeList] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = DecryptToken();
  const privilege = getPermission();
  const Url = newSupportUrl;
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const hasSupportPermission = Number(isPermission({ type: "btn", name: "newSupport" }) === 1);

  const handleNewDispatch = (url1) => {
    const BreadCrumbsObject = {
      title: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
      // type: "site",
      // tab: "CONTACT_LISTING",
    };
    BreadCrumbsObject.title = "New Support Form";
    BreadCrumbsObject.type = "account";
    BreadCrumbsObject.tab = "Support";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "account/new-support",
      })
    );
  };
  return (
    <div className="relative" style={{ marginBottom: "5%" }}>
      {/* {(user?.user_type === 0 ||
        (user?.user_type === 2 && user?.sub_admin !== "" && privilege?.includes("new-support"))) && (
          <div className="d-flex justify-content-end">
            <button
              className="btn"
              type="button"
              style={{ marginTop: "10px" }}
              onClick={() => navigate(Url, { state: stateData })}
            >
              <img src={New} alt="New" style={{ marginRight: "5px" }} />
              <span style={{ color: "#0C71C3" }}>New</span>
            </button>
          </div>
        )} */}
      {/* {Number(isPermission({ type: "btn", name: "newSupport" }) === 1) ? (
        <>
          <div className="d-flex justify-content-end">
            <button
              className="btn"
              type="button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                handleNewDispatch(Url);
                navigate(Url, { state: stateData });
              }}
            >
              <img src={New} alt="New" style={{ marginRight: "5px" }} />
              <span style={{ color: "#0C71C3" }}>New</span>
            </button>
          </div>
        </>
      ) : (
        ""
      )} */}

      <AdminListing
        handleNewDispatch={handleNewDispatch(Url)}
        Url={Url}
        hasSupportPermission={hasSupportPermission}
        isSearchable={isSearchable}
        support_api={support_api}
        fetch_data_key={fetch_data_key}
        stateData={stateData}
      />
    </div>
  );
}
