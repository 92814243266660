import React, { useState, useEffect, forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import MaskedInput from 'react-text-mask';
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "../../helper";
import uuid4 from "uuid4";

const CustomInput = forwardRef(({ isRequired, value, onChange, onBlur, error, setError, ...props }, ref) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    
    if (onChange) {
      onChange(e);
    }

    // Clear error when user starts typing
    if (newValue !== value) {
      setError("");
    }
  };

  const handleBlur = (e) => {
    const newValue = e.target.value.trim();
    validateDate(newValue, e);
  };

  const validateDate = (newValue, e) => {
    // Handle empty value
    if (!newValue || newValue.replace(/[^0-9]/g, "") === "") {
      if (isRequired) {
        setError("Date is required");
        if (onChange) {
          onChange({ ...e, target: { ...e.target, value: "" } });
        }
      } else {
        setError("");
      }
      return false;
    }

    // MM/DD/YYYY validation regex
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
    if (!dateRegex.test(newValue)) {
      setError("Invalid date format (MM/DD/YYYY required)");
      if (onChange) {
        onChange({ ...e, target: { ...e.target, value: "" } });
      }
      return false;
    }

    // Extract and validate date components
    const [month, day, year] = newValue.split("/").map(Number);
    
    // Create date object and check if it's valid
    const date = new Date(year, month - 1, day);
    const isValidDate = 
      date.getFullYear() === year && 
      date.getMonth() === month - 1 && 
      date.getDate() === day &&
      year >= 1900 && 
      year <= 2100;

    if (!isValidDate) {
      setError("Invalid date");
      if (onChange) {
        onChange({ ...e, target: { ...e.target, value: "" } });
      }
      return false;
    }

    // Clear error if valid
    setError("");
    return true;
  };

  const handleKeyDown = (e) => {
    // If Tab key is pressed
    if (e.key === 'Tab') {
      if(e.target.value){
        const isValid = validateDate(e.target.value, e);
        if (!isValid) {
          e.preventDefault(); // Prevent tabbing if validation fails
        }
      }
    }
  };

  return (
    <div className="date-input-container">
      <MaskedInput
        mask={[/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        guide={true}
        {...props}
        ref={ref}
        required={isRequired}
        value={value || ""}
        onChange={handleInputChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder="MM/DD/YYYY"
        className={`date-input ${error ? "error" : ""} ${props.className || ""}`}
      />
      {error && <span className="date-error-text">{error}</span>}
    </div>
  );
});

CustomInput.displayName = "Date Input";

function CommonDatePicker({
  calName,
  CalVal,
  HandleChange,
  disabled = false,
  isRequired = false,
  minDate,
  maxDate,
}) {
  const [DateValueState, setDateValueState] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const inputRef = useRef(null);
  const [error, setError] = useState("");

  const handleCalendarChange = (value) => {
    if (!value) {
      HandleChange(calName, null);
      setDateValueState(null);
      setIsFilled(false);
    } else {
      const date = moment(value, "MM/DD/YYYY", true).isValid()
        ? moment(value).format("YYYY-MM-DD")
        : null;

      if (date) {
        HandleChange(calName, date);
        setDateValueState(value);
        setIsFilled(true);
        setError(""); // Clear error on valid date selection
      } else {
        HandleChange(calName, null);
        setDateValueState(null);
        setIsFilled(false);
        setError("Invalid date");
      }
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!CalVal || CalVal === 'unknown') {
      setDateValueState(null);
      setIsFilled(false);
    } else {
      const parsedDate = moment(CalVal);
      if (parsedDate.isValid()) {
        setDateValueState(parsedDate.toDate());
        setIsFilled(true);
      } else {
        setDateValueState(null);
        setIsFilled(false);
      }
    }
  }, [CalVal]);

  return (
    <div 
      className={`d-flex align-items-center calendar-input-btn calendar-input-btn-1012 ${disabled ? 'disabled-date':''}`}
      style={{
        border: (isRequired && !isFilled) ? '1px solid red' : '',
      }}
    >
      <DatePicker
        open={isOpen}
        selected={DateValueState}
        onChange={handleCalendarChange}
        inputRef={inputRef}
        maxDate={maxDate}
        minDate={minDate}
        disabled={disabled}
        onClickOutside={() => setIsOpen(false)}
        shouldCloseOnSelect={true}
        customInput={
          <CustomInput
            inputRef={inputRef}
            disabled={disabled}
            isRequired={isRequired}
            isFilled={isFilled}
            error={error}
            setError={setError}
            id={uuid4()}
          />
        }
      />
      <span className="cl-name" onClick={() => !disabled && setIsOpen(!isOpen)}>
        <CalendarIcon/>
      </span>
    </div>
  );
}

export default CommonDatePicker;