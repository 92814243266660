import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import { isSubAdminPermission } from "../../../../../common/helper/permission";
import check from "../../../../../common/img/Check.svg";
import cancel from "../../../../../common/img/Cancel.svg";
import NewGlobalTable from "../../../../../common/components/custom-ui/NewGlobalTable";
import { truncateText } from "../../../../../common/helper/BasicFn";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../../../common/helper/PermissionManager";
import { printConsole } from "../../../../../common/helper/Common";
import { FormatDateWithTime } from "../../../../../common/helper";

export default function AedServicing({ stateData, privileges, is_user }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [aedServiceData, setAEDServiceData] = useState([]);
  const [aedId, setAEDId] = useState();
  const [alarmBattery, setAlarmBatteryDate] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  printConsole({ stateData }); // print console.log
  const handleDispatch = (item, aedId, questions_id) => {
    BreadCrumbsObject.title = "Aed Servicing Details";
    BreadCrumbsObject.tab = item;
    BreadCrumbsObject.type = "AED Servicing ";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/AEDServiceDetails/${stateData.aedId}/${questions_id}`,
      })
    );
  };

  const currentFormatDateWithTime = (dateString) => {
    // Parse the input string directly as a date object
    const date = new Date(dateString);

    // Extract UTC components to avoid timezone issues
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const year = date.getUTCFullYear();

    // Get UTC time components
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Convert to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // convert 0 to 12

    // Format the final string
    const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    console.log({ formattedDate });
    return formattedDate;
  };

  const headerColumns = [
    {
      Header: "Aed Service Date",
      accessor: "inspection_date",
      Cell: ({ value, row }) => (
        <span
          className={
            Number(isPermission({ type: "link", name: "aedServiceDate" })) === 1
              ? "link"
              : ""
          }
          style={{
            cursor:
              Number(isPermission({ type: "link", name: "aedServiceDate" })) ===
              1
                ? "pointer"
                : "",
            color: "#0c71c3",
            textDecoration: "none",
          }}
          onClick={() => {
            if (
              Number(isPermission({ type: "link", name: "aedServiceDate" })) ===
              1
            ) {
              handleDispatch(aedId, row.original.questions_id);
              navigate(
                `/account-details/AEDServiceDetails/${stateData.aedId}/${row.original.questions_id}`
              );
            }
          }}
        >
          {currentFormatDateWithTime(value)}
        </span>
      ),
    },
    {
      Header: "Present/Ready",
      accessor: "aeds_ready_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Replaced Rescue Kit",
      accessor: "rescue_kits_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Replaced Accessories",
      accessor: "accessories_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Alarm Battery Exp",
      accessor: "alarmBattery",
      Cell: ({ row }) => 
        row.original.alarmBattery && moment(row.original.alarmBattery).isValid() 
          ? moment(row.original.alarmBattery).format("MM/DD/YYYY") 
          : "-"
    },    
    {
      Header: "Technician",
      accessor: "inspection_by",
      width: "15%",
      Cell: ({ row }) => truncateText(row.original.inspection_by, 20),
    },
  ];

  // const handleDataFetched = async (data) => {
  //   if (data) {
  //     setAEDId(data.aed_id || null);
  //     setAlarmBatteryDate(data.alarmBattery || null);
  //     return data || [];
  //   }
  // };

  return (
    <>
      {/* New Table Implementation: */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="text-left">
          <h4 className="heading">{/* Aed Service History */}</h4>
        </Box>
      </div>
      <NewGlobalTable
        isCache={true}
        pageSize={10}
        apiEndPoint={`aed/check_service_history_data_by_id/${stateData.aedId}`}
        keyAPiName={"serviceQuestion"}
        method="GET"
        headerColumns={headerColumns}
        // customRenderData={handleDataFetched}
      />
    </>
  );
}
