import React, { useState, useEffect } from "react";
import { Container, Navbar, Dropdown } from "react-bootstrap";
import { faBars, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchForm from "./SearchForm";
import { useNavigate } from "react-router-dom";
import Loading from "../Loader/Loading";
import { DecryptToken } from "../../helper";
import { getPer } from "../../helper/BasicFn";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import PermissionManagerMenu from "../../../domains/permissionManager/pages/PermissionManagerMenu";

const Header = ({ show, setShow, handleShow }) => {
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [visibleLinks, setVisibleLinks] = useState([]);
  const [dropdownLinks, setDropdownLinks] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = DecryptToken();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const [activeLink, setActiveLink] = useState(window.location.pathname);

  const navLinks = [
    { to: "/dashboard", label: "Home" },
    { to: "/accounts-listing", label: "Accounts" },
    { to: "/Admin/Sites-listing", label: "Sites" },
    { to: "/Admin/Contact-listing", label: "Contacts" },
    { to: "/Admin/accessory-listing", label: "Accessories" },
    { to: "/Admin/Aed-listing", label: "AEDs" },
    { to: "/account/support-listing", label: "Supports" },
    { to: "/Admin/Note-listing", label: "Notes" },
  ];

  // Function to handle window resize and calculate visible/dropdown links
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine which links should be visible and which should be in dropdown
  useEffect(() => {
    // These values can be adjusted based on your design requirements
    const breakpoints = [
      { width: 1200, visibleCount: navLinks?.length },
      { width: 992, visibleCount: 6 },
      { width: 768, visibleCount: 4 },
      { width: 576, visibleCount: 2 },
      { width: 0, visibleCount: 0 }
    ];

    // Find the appropriate breakpoint
    const breakpoint = breakpoints.find(bp => windowWidth >= bp.width);
    const visibleCount = breakpoint ? breakpoint.visibleCount : 0;

    setVisibleLinks(navLinks.slice(0, visibleCount));
    setDropdownLinks(navLinks.slice(visibleCount));
  }, [windowWidth]);

  const handleNavigation = (path, title) => {
    dispatch(
      addNewBreadcrumbs({
        url: path,
        type: "",
        tab: "",
        title: title,
        storageType: isUserContext ? "sessionStorage" : "localStorage",
      })
    );
    navigate(path);
  };

  // In your useEffect or in a new useEffect, update activeLink when the location changes
  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <Navbar style={{ background: "#000" }} variant="dark" expand="lg">
        <Container fluid className="">
          <div
            className="d-flex"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Navbar.Brand style={{ display: "flex", alignItems: "center" }}>
              {/* <span
                variant="outline-light"
                style={{
                  border: "none",
                  width: "20px",
                  color: "#fff",
                  cursor: "pointer",
                }}
                className="me-2"
                onClick={handleShow}
              >
                <FontAwesomeIcon icon={faBars} />
              </span> */}

              <img
                src="/assets/images/red-logo.svg"
                alt={"Ross-Logo"}
                style={{
                  width: "26rem",
                  cursor: Number(getPer()) === 1 ? "pointer" : "",
                }}
                onClick={() =>
                  Number(getPer()) === 1 ? navigate("/dashboard") : ""
                }
              />

              {/* Visible navigation links */}
              <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginLeft:"20px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                  {visibleLinks.map((link, index) => (
                    <div
                      key={index}
                      className={`custom-link ${activeLink === link.to ? 'active' : ''}`}
                      onClick={() => handleNavigation(link.to, link.label)}
                    >
                      {link.label}
                    </div>
                  ))}
                  {
                    visibleLinks.length == 8 && (
                      <div style={{ padding: 0 }}>
                        <PermissionManagerMenu
                          setOpenDropdown={setOpenDropdown}
                          show={show}
                          setShow={setShow}
                        />
                      </div>
                    )
                  }
                </div>

                {/* Dropdown for overflow menu items */}
                {dropdownLinks.length > 0 && (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id="dropdown-basic"
                      className="nav-link"
                      style={{ cursor: "pointer", padding: "0 8px" }}
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {dropdownLinks.map((link, index) => (
                        <Dropdown.Item
                          key={index}
                          style={{padding:"10px"}}
                          onClick={() => handleNavigation(link.to, link.label)}
                        >
                          {link.label}
                        </Dropdown.Item>
                      ))}
                      <button to="" className="nav-link" >
                        <PermissionManagerMenu
                          setOpenDropdown={setOpenDropdown}
                          show={show}
                          setShow={setShow}
                        />
                      </button>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

              </div>
            </Navbar.Brand>

            <div className="auto">
              <SearchForm />
            </div>
          </div>

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <div
              className="d-flex align-items-right NavbarUserIconDiv"
              style={{ position: "relative", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon="fa-solid fa-user" />
              <FontAwesomeIcon icon="fa-solid fa-caret-down" />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {loading && (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      )}
    </>
  );
};

export default Header;