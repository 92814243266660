import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Select from "react-select";
import StateField from "../components/states/StatesField";
import PaginateAutoComplete from "./custom-ui/PaginateAutoComplete";

export default function MultiTrainingFrom({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  handleInputChange,
  allDropDowns,
  noBtns,
  type,
  fieldsRequired = true,
  countriesList,
  setTrainingPhoneValidations,
  setValidateField = "",
  validated,
  sectionId = "",
  sectionName="",
}) {

  const [phone, setPhone] = useState("");
  const [phoneExt, setPhoneExt] = useState("");

  const [phoneType, setPhoneType] = useState("");
  const [main, setMain] = useState(0);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [phoneValidations, setPhoneValidations] = React.useState({});

  // const HandleSubForm = () => {
  //   let mySubFormData = [...altTrainerForm];
  //   mySubFormData[formIndex] = {
  //     phone_number: phone,
  //     ext: phoneExt,
  //     phone_type_id: phoneType,
  //     main: main,
  //   };
  // };

  const [phoneErrors, setPhoneErrors] = useState([]);

  const updateFieldChanged = (e, index, name) => {

    let newPhoneErrors = [...phoneErrors];

    if (e.target.name === 'alternative_phone') {
      e.target.value = e.target.value.replace(/[^0-9]/g, "").trim();
      e.target.value = e.target.value.slice(0, 10);
      
      if (e.target.value.trim().length < 10) {
        newPhoneErrors[index] = true;
      } else {
        newPhoneErrors[index] = false;
      }
    }

    let newArr = [...altTrainerForm];
    newArr[index][name] = e.target.value;
    
    setSubFormData(newArr);
    setPhoneErrors(newPhoneErrors);
  };

  // handle select change
  const handleSelectChange = (data, key, index) => {
    let newArr = [...altTrainerForm];

    if (data?.value != "231") {
        newArr[index] = { 
          ...newArr[index],
          [key]: data.value,
          account_alternate_traning_location_state: "",
          account_alternate_traning_location_state_abbreviation: "",
          account_alternate_traning_location_state_name: ""
        };
    } else {
      newArr[index][key] = data.value;
    }
    setSubFormData(newArr);

    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data?.label,
        value: data?.value,
      },
    }));
  };

  useEffect(() => {
    if (countriesList) {
      if (altTrainerForm[0]?.account_alternate_traning_location_country) {
        const country = countriesList.find(
          (country) =>
            country.value ==
            parseInt(
              altTrainerForm[0]?.account_alternate_traning_location_country
            )
        );
        setSelectedCountry((old) => ({
          ...old,
          account_alternate_traning_location_country: {
            label: country?.label,
            value: country?.value,
          },
        }));
      } else {
        setSelectedCountry((old) => ({
          ...old,
          account_alternate_traning_location_country: {
            label: countriesList[230]?.label,
            value: countriesList[230]?.value,
          },
        }));
      }
    }
  }, [countriesList, altTrainerForm]);

  return (
    <>
   {altTrainerForm.map((singleForm, index) => (
  <div key={index} id={`${sectionId}_${index}`}>
    <div className="form-container" style={{ display: "flex", flexDirection: "column" }}>
      {/* Buttons Row */}
      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          // alignItems: "center",  
          gap: "10px",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        {/* Empty space to align with the labels */}
        <div
          style={{
            width: "120px", // Match the label width for alignment
            margin: 0,
          }}
        ></div>

        {/* Buttons */}
        {type !== "trainingEdit" && (
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={increaseAlternative}
              className="btn btn-sm btn-primary"
              type="button"
              name={`${sectionName}_plus`}
            >
              +
            </button>
            <button
              onClick={decreaseAlternative}
              className="btn btn-sm btn-danger"
              type="button"
              name={`${sectionName}_minus`}
            >
              −
            </button>
          </div>
        )}
      </div>

      <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
  {/* Left Column */}
  <div className="col my-4 form-column" style={{ width: "45%", padding: "0" }}>
    {/* Company Name */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Company Name
      </Form.Label>
      <Form.Control
        type="text"
        name="account_alternate_traning_location_company_name"
        value={singleForm.account_alternate_traning_location_company_name}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_alternate_traning_location_company_name")
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* Phone */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Phone
      </Form.Label>
      <Form.Control
        type="text"
        name="alternative_phone"
        value={singleForm.alternative_phone}
        onChange={(e) => updateFieldChanged(e, index, "alternative_phone")}
        minLength={10}
        className={phoneErrors[index] ? "phone-invalid-input" : ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
      {phoneErrors[index] && (
        <div className="phone-invalid">Please Enter Exact 10 digits.</div>
      )}
    </Form.Group>

    {/* Ext */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Ext
      </Form.Label>
      <Form.Control
        type="number"
        name="alternative_ext"
        onChange={(e) => updateFieldChanged(e, index, "alternative_ext")}
        value={singleForm.alternative_ext}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>
  </div>

  {/* Right Column */}
  <div className="col my-4 form-column" style={{ width: "45%", padding: "0" }}>
    {/* Country */}
    <Form.Group
      className="form-group-responsive relative"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Country
      </Form.Label>
      <Select
        value={selectedCountry?.account_alternate_traning_location_country}
        options={countriesList}
        onChange={(data) => {
          handleSelectChange(data, "account_alternate_traning_location_country", index);
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0px 4px",
            fontSize: "14px",
            color: "#333",
            height: "34px",
            width: "400px",
          }),
        }}
      />
      <Form.Control.Feedback type="invalid">Please Enter Country.</Form.Control.Feedback>
    </Form.Group>

    {/* Address */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address*
      </Form.Label>
      <Form.Control
        type="text"
        required
        name="account_alternate_traning_location_address1"
        value={singleForm.account_alternate_traning_location_address1}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_alternate_traning_location_address1")
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
       isInvalid={validated && !singleForm.account_alternate_traning_location_address1} // Explicitly set isInvalid
          />
          </div>
          <Form.Control.Feedback type="invalid"
                style={{
                  marginLeft: "130px",
                  display: validated && !singleForm.account_alternate_traning_location_address1 ? "block" : "none",
                }}>Please Enter Address.</Form.Control.Feedback>
    </Form.Group>

    {/* Address 2 */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address 2
      </Form.Label>
      <Form.Control
        type="text"
        name="account_alternate_traning_location_address2"
        value={singleForm.account_alternate_traning_location_address2}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_alternate_traning_location_address2")
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* City */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        City*
      </Form.Label>
      <Form.Control
        type="text"
        required
        name="account_alternate_traning_location_city"
        value={singleForm?.account_alternate_traning_location_city}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_alternate_traning_location_city")
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
            isInvalid={validated && !singleForm?.account_alternate_traning_location_city} // Explicitly set isInvalid
            />
            </div>
            <Form.Control.Feedback type="invalid"
                  style={{
                    marginLeft: "130px",
                    display: validated && !singleForm?.account_alternate_traning_location_city ? "block" : "none",
                  }}>Please Enter City.</Form.Control.Feedback>
    </Form.Group>

    {/* State */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
        minWidth: "530px"
      }}
    >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        State*
      </Form.Label>
      {selectedCountry?.account_alternate_traning_location_country?.value == "231" ? (
        <StateField
          setFormData={setSubFormData}
          valueKey="account_alternate_traning_location_state"
          selectedCountry={selectedCountry?.account_alternate_traning_location_country?.value}
          validated={validated}
          required={fieldsRequired ? true : false}
          type="array"
          index={index}
          altTrainerForm={altTrainerForm}
          stateSelectedValue={singleForm.account_alternate_traning_location_state}
        />
      ) : (
        <>
          <PaginateAutoComplete
            dropDownName="account_alternate_traning_location_state"
            apiEndpoint={`account/get-state-by-country/${selectedCountry?.account_alternate_traning_location_country?.value}`}
            isState={true}
            onSelect={(e, val) => {
              setSubFormData((prev) => {
                const newArr = [...altTrainerForm];
                newArr[index] = {
                  ...newArr[index],
                  account_alternate_traning_location_state_name: val?.state_name,
                  account_alternate_traning_location_state: val?.state_id,
                };
                return newArr;
              });
            }}
            defaultValue={{
              state_name: singleForm.account_alternate_traning_location_state_name
                ? singleForm.account_alternate_traning_location_state_name
                : "",
              state_id: singleForm.account_alternate_traning_location_state
                ? singleForm.account_alternate_traning_location_state
                : "",
            }}
            isInvalid={validated && !singleForm.account_alternate_traning_location_state}
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              fontSize: "14px",
              color: "#333",
              height: "34px",
              width: "400px",
            }}
          />
        </>
      )}
      </div>
    </Form.Group>

    {/* Zip Code */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Zip*
      </Form.Label>
      <Form.Control
        type="number"
        name="account_alternate_traning_location_zipcode"
        defaultValue={singleForm.account_alternate_traning_location_zipcode}
        required
        onChange={(e) =>
          updateFieldChanged(e, index, "account_alternate_traning_location_zipcode")
        }
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
       isInvalid={validated && !singleForm.account_alternate_traning_location_zipcode} // Explicitly set isInvalid
            />
            </div>
            <Form.Control.Feedback type="invalid"
                  style={{
                    marginLeft: "130px",
                    display: validated && !singleForm.account_alternate_traning_location_zipcode ? "block" : "none",
                  }}>Please Enter Zip code.</Form.Control.Feedback>
    </Form.Group>
  </div>
</div>
    </div>
  </div>
))}
    </>
  );
}
