import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Snackbar, Tooltip } from "@mui/material";
import { Button as FixedButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CallPOSTAPI } from "../../../common/services/index";
import Drawer from "@mui/material/Drawer";
import SubHeading from "../components/header/SubHeading";
import Filter from "../components/filter/index";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFilterData,
  removePayloadData,
} from "../../../store/slices/AccountListFilter";
import MyTable from "../components/MyTable";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
// import { RenderEqupment } from "../../../common/helper/TblFn";
import CloseIcon from "@mui/icons-material/Close";
import { Lock } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import {
  DecryptToken,
  getPer,
  truncateText,
} from "../../../common/helper/BasicFn.js";
import { getPermission, printConsole } from "../../../common/helper/Common.js";
import {
  addNewBreadcrumbs,
  clearBreadcrumbs,
  clearHistoryStack,
} from "../../../store/slices/breadcrumbsSlice.js";
import { handleTabfunction } from "../../../store/slices/TabSlice.js";
import { AccountDetailsTab } from "../../../common/hooks/fieldsConfig.js";
import {
  isPerm,
  isPermission,
  isPermissionission,
} from "../../../common/helper/PermissionManager.jsx";
import FinalNewBreadcrumbs from "../../../common/components/breadcrumbsComp/FinalNewBreadcrumbs.jsx";

const AccountsListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [accounts, setAccounts] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSearchReset, setShowSearchReset] = useState(false);
  const [search, setSearch] = useState("");
  const [showLoading, setShowLoading] = React.useState(true);
  const [openModel, setOpenModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const payloadData = useSelector(
    (state) => state.accountlistfilter.payloadData
  );
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseModel = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenModel(false);
  };

  const handleBreadCrumbDispatch = (url) => {
    dispatch(
      addNewBreadcrumbs({
        // ...BreadCrumbsObject,
        url: url,
        title: "New Account",
        tab: "",
        type: "account",
      })
    );
  }

  const getAccountsList = async (
    page = 1,
    payloadData1 = payloadData,
    searchFn = true
  ) => {
    return "";
    setShowLoading(true);

    if (Object.keys(filterData).length !== 0) {
      const allFieldsEmpty = Object.entries(payloadData1)?.map(
        ([key, value]) => {
          if (key === "allToggle") {
            return value !== false;
          } else if (key !== "aed" && key !== "training") {
            return value?.length !== 0;
          }
          return false;
        }
      );

      let isTrue = false;
      for (const item of allFieldsEmpty) {
        if (item === true) {
          isTrue = true;
          break;
        }
      }

      if (!isTrue) {
        const accountsData = await CallPOSTAPI("account/account-list", {
          page: page,
          search: searchFn ? search.trimEnd() : "",
        });
        if (accountsData?.status) {
          setAccounts(accountsData?.data?.data?.account || []);
          setTotalPages(accountsData?.data?.data?.totalPages || 0);
        }
      } else {
        printConsole({ payloadData1 }); // print console.log
        let results = await CallPOSTAPI("account/filter-search-result", {
          ...payloadData1,
          page: page,
          search: searchFn ? search.trimEnd() : "",
        });
        if (results?.status) {
          setAccounts(results?.data?.data?.account_list || []);
          setTotalPages(results?.data?.data?.totalPages || 0);
        }
      }
    } else {
      const accountsData = await CallPOSTAPI("account/account-list", {
        page: page,
        search: searchFn ? search.trimEnd() : "",
      });

      if (accountsData?.status) {
        setAccounts(accountsData?.data?.data?.account || []);
        setTotalPages(accountsData?.data?.data?.totalPages || 0);
      }
    }

    setShowLoading(false);
  };

  const handleDetailsDispatch = (accountId, account_name) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Account Listing";
      // BreadCrumbsObject.tab = "Details";
      // BreadCrumbsObject.type = "account";
      dispatch(
        addNewBreadcrumbs({ ...BreadCrumbsObject, url: `/accounts-listing` })
      );
    };
    handleDispatch();
    BreadCrumbsObject.title = account_name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/${accountId}/Details`,
      })
    );
  };

  // useEffect(() => {
  //   // Dispatch clearBreadcrumbs action when component is mounted
  //   dispatch(clearHistoryStack());
  //   dispatch(clearBreadcrumbs());
  // }, [dispatch]);

  useEffect(() => {
    if (location?.state?.accountListings) {
      setTimeout(() => {
        setAccounts(location?.state?.accountListings);
      }, 1000);
    } else {
      // getAccountsList(currentPage, payloadData);
    }

    return () => {
      location.state = "";
    };
  }, [location, currentPage, search]);

  const handleClearFilterData = async () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
    setCurrentPage(1);
    // getAccountsList(1, {});

    // navigate("/accounts-listing", {
    //   state: {
    //     accountListings: false,
    //   },
    // });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleReset = useCallback(() => {
    setShowSearchReset(!showSearchReset);
    setSearch("");
    // getAccountsList(1, payloadData, false);
  }, [getAccountsList, search]);

  const handleSetSearch = async (value) => {
    setCurrentPage(1);
    setSearch(value);
  };

  ///Render functions:
  const user = DecryptToken();
  const privilege = getPermission();

  const RenderAccTitle = (account) => {
    return (
      <span
        id={account.account_id}
        className={
          isPermission({ type: "link", name: "accountName" }) === 1
            ? "link"
            : ""
        }
        onClick={() => {
          if (isPermission({ type: "link", name: "accountName" }) === 1) {
            // handleDispatch(account.account_id);
            handleDetailsDispatch(account.account_id, account?.account_name);
            // dispatch(
            //   handleTabfunction({
            //     value: AccountDetailsTab.DETAILS,
            //     type: "account",
            //   })
            // );
            navigate(`/account-details/${account.account_id}/Details`, {
              state: {
                siteTitle: "Account: " + account?.account_name,
                editUrl: `/account/accounts-edit/${account?.account_id}`,
                deleteUrl: `/account/accounts-delete/${account?.account_id}`,
              },
            });
          }
        }}
        title={
          account.account_name.length > 25 ? account.account_name : undefined // Tooltip only if the text is truncated
        }
      >
        {truncateText(account.account_name, 25)}
      </span>
    );
  };

  const RenderAccParent = (account) => {
    return (
      <span
        className={
          isPermission({ type: "link", name: "parentAccountListing" }) === 1
            ? "link"
            : ""
        }
        style={{
          cursor:
            isPermission({ type: "link", name: "parentAccountListing" }) === 1
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (
            isPermission({ type: "link", name: "parentAccountListing" }) === 1
          ) {
            // handleDispatch(account.account_id);
            handleDetailsDispatch(
              account.parent_account_id,
              account?.parent_name
            );
            navigate(`/account-details/${account.parent_account_id}/Details`, {
              state: {
                siteTitle: "Account: " + account?.parent_name,
                editUrl: `/account/accounts-edit/${account?.parent_account_id}`,
                deleteUrl: `/account/accounts-delete/${account?.parent_account_id}`,
              },
            });
          }
        }}
        role="button"
        title={
          account.parent_name &&
            typeof account.parent_name === "string" &&
            account.parent_name.length > 25
            ? account.parent_name
            : undefined // Tooltip only if the account name is not null and exceeds the limit
        }
      >
        {truncateText(account?.parent_name, 20)}
      </span>
    );
  };

  const RenderDistributer = (account) => {
    return (
      <span
        className={
          isPermission({ type: "link", name: "distributorAccountListing" }) ===
            1
            ? "link"
            : ""
        }
        style={{
          cursor:
            isPermission({ type: "link", name: "parentAccountListing" }) === 1
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (
            isPermission({
              type: "link",
              name: "distributorAccountListing",
            }) === 1
          ) {
            // handleDispatch(account.account_id);
            handleDetailsDispatch(
              account.distributor_id,
              account?.distributor_name
            );
            navigate(`/account-details/${account.distributor_id}/Details`, {
              state: {
                siteTitle: "Account: " + account?.distributor_name,
                editUrl: `/account/accounts-edit/${account?.distributor_id}`,
                deleteUrl: `/account/accounts-delete/${account?.distributor_id}`,
              },
            });
          }
        }}
        role="button"
        title={
          account.distributor_name &&
            typeof account.distributor_name === "string" &&
            account.distributor_name.length > 25
            ? account.distributor_name
            : undefined // Tooltip only if the account name is not null and exceeds the limit
        }
      >
        {truncateText(account?.distributor_name, 25)}
      </span>
    );
  };

  const RenderEqupment = (data) => {
    const { isAED, hasEquipment } = data || { isAED: false, hasEquipment: false };
    return (
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        {/* Left Icon - AEDs */}
        <Tooltip title={isAED ? "Has AEDs" : "No AEDs"} arrow>
          <span
            style={{
              color: isAED ? "green" : "red",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            {isAED ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
          </span>
        </Tooltip>
        {/* Right Icon - Equipment */}
        <Tooltip title={hasEquipment ? "Has Equipment" : "No Equipment"} arrow>
          <span
            style={{
              color: hasEquipment ? "green" : "red",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            {hasEquipment ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
          </span>
        </Tooltip>
      </div>
    );
  };

  const headerColumns = [
    {
      Header: "Account Name",
      accessor: "account_name",
      Cell: ({ row }) => {
        return (
          <div style={{ textDecoration: "none" }}>
            {RenderAccTitle(row.original)}
          </div>
        );
      },
      width: "18%",
      // sortType: "alphanumeric",
      sortDescFirst: false,
      disableSortBy: false,
      sortType: (rowA, rowB) => {
        // Get the values and convert to lowercase for case-insensitive comparison
        const a = rowA.values.account_name?.toLowerCase() || "";
        const b = rowB.values.account_name?.toLowerCase() || "";
        return a.localeCompare(b);
      },
    },

    {
      Header: "Equipment",
      accessor: "equipment",
      Cell: ({ row }) => RenderEqupment(row.original),
      disableSortBy: true,
    },
    {
      Header: "Training",
      accessor: "trainning",
      Cell: ({ value }) => (
        <Tooltip title={value ? "Has Training" : "No Training"} arrow>
          <span
            style={{
              color: value ? "green" : "red",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            {value ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
          </span>
        </Tooltip>
      ),
      disableSortBy: true,
    },
    {
      Header: "Customer Type",
      accessor: "customer_type_name",
    },
    {
      Header: "Parent Account",
      accessor: "parent_name",
      Cell: ({ row }) => (
        <div style={{ textDecoration: "none" }}>
          {RenderAccParent(row.original)}
        </div>
      ),
    },
    {
      Header: "Distributor Account",
      accessor: "distributon_name",
      Cell: ({ row }) => RenderDistributer(row.original),
    },
    {
      Header: "Owner",
      accessor: "owner",
      width: "10%",
      Cell: ({ row }) => (
        <span
          title={
            row.original.owner.length > 15 ? row.original.owner : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row.original.owner, 15)}
        </span>
      ),
    },
    {
      Header: "Restricted",
      accessor: "isSecure",
      width: "5%",
      Cell: ({ value }) =>
        value ? (
          <Lock size={16} className="text-dark" />
        ) : (
          <CloseIcon color="error" />
        ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      <div>
        {filterData && Object.keys(filterData).length !== 0 ? (
          <>
            <FixedButton
              className="btn-style-listing-cancel-filter"
              onClick={handleClearFilterData}
            >
              Clear Filter
            </FixedButton>
          </>
        ) : null}

        {Number(getPer()) === 1 ? (
          <>
            <FixedButton
              className="btn-style-filter"
              onClick={handleDrawerOpen}
            >
              Advanced Filters
            </FixedButton>
          </>
        ) : (
          ""
        )}
        <Drawer
          sx={{
            width: "400px",
            flexShrink: 0,
            // background: '#000',
            "& .MuiDrawer-paper": {
              width: "400px",
              boxSizing: "border-box",
              background: "#000",
            },
          }}
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
        >
          {/* filter component  */}
          <Filter
            setaccounts={setAccounts}
            accountListingPage={true}
            setOpen={setOpen}
            setShowLoading={setShowLoading}
            getAccountsList={getAccountsList}
            currentPage={currentPage}
            setTotalPages={setTotalPages}
            search={search}
            clearBtn={filterData && Object.keys(filterData).length !== 0}
          />
        </Drawer>
      </div>
      <Snackbar
        open={openModel}
        autoHideDuration={3000}
        onClose={handleCloseModel}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleCloseModel}
        >
          Record Deleted SuccessFully!!!
        </Alert>
      </Snackbar>
      <div className="mt-4">
        <SubHeading
          hideNew={
            Number(isPermission({ type: "btn", name: "accountNew" })) === 1
              ? false
              : true
          }
          hideHierarchy={true}
          title={"Account Listing"}
          newUrl="/admin-account"
          subHeading={true}
        />

        <div style={{ minHeight: "84.6vh" }}>
          <Box className="">
            <NewGlobalTable
              breadCrumbOnclick={handleBreadCrumbDispatch("/admin-account")}
              method={Number(getPer()) === 1 ? "POST" : "GET"}
              isSearchable={true}
              isCache={true}
              newButtonPath={Number(isPermission({ type: "btn", name: "accountNew" })) === 1 && "/admin-account"}
              pageSize={50}
              isPagination={true}
              payloadData={
                payloadData && Object.keys(payloadData).length > 0
                  ? payloadData
                  : null
              }
              keyAPiName={
                payloadData && Object.keys(payloadData).length > 0
                  ? "account_list"
                  : "account"
              }
              apiEndPoint={
                payloadData && Object.keys(payloadData).length > 0
                  ? "account/filter-search-result"
                  : Number(getPer()) === 1
                    ? "account/account-list"
                    : "user/user-account-list"
              }
              headerColumns={headerColumns}
            />
          </Box>
        </div>
      </div>
    </>
  );
};

export default AccountsListing;
