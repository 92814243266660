import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import "./tab.scss";
import { Box } from "@mui/material";
import { isPermission } from "../../helper/PermissionManager";

const CustomTabBar = ({
  tabs,
  onTabChange,
  from,
  activeTab: propActiveTab,
  navProps = {},
  navItemProps = {},
  navLinkProps = {},
  // type="site"
}) => {
  const activeTab =
    propActiveTab !== undefined ? propActiveTab : internalActiveTab;
  const [internalActiveTab, setInternalActiveTab] = useState(activeTab || "Details");



  const handleSelect = (selectedKey) => {
    if (propActiveTab === undefined) {
      setInternalActiveTab(selectedKey);
    }
    onTabChange(selectedKey);
  };

  return (
    // <Nav
    //   variant="tabs"
    //   className="bg-primary custom-tab"
    //   // className="text-light tab-button"
    //   activeKey={activeTab}
    //   onSelect={handleSelect}
    //   {...navProps}
    // >

    //   {tabs.map((tab) => {
    //     if (!tab) return null; // Ensure tab is defined

    //     if (Number(isPermission({ type: from, name: tab.id, })) === 1) {
    //       return (
    //         <Nav.Item
    //           key={tab.id} // Ensure tab.id is unique
    //           {...navItemProps}
    //           {...tab.itemProps}
    //           className="tab-item"
    //         >
    //           <Nav.Link
    //             eventKey={tab.id}
    //             {...navLinkProps}
    //             {...tab.linkProps}
    //             className="tab-item-a"
    //           >
    //             {tab.title}
    //           </Nav.Link>
    //         </Nav.Item>
    //       );
    //     }

    //     return null; // Explicitly return null if no permission
    //   })}
    // </Nav>

    <Box>
  <div
    className="d-flex border-secondary"
    style={{
      background: "transparent", // Set the outer container background to transparent
    }}
  >
    <div
      style={{
        display: "flex", // Keep the tabs in a flex row
        background: "white", // White background for the tab bar itself
      }}
    >
      {tabs.map((tab) => {
        if (!tab) return null; // Ensure tab is defined

        if (Number(isPermission({ type: from, name: tab.id })) === 1) {
          return (
            <div
              role="button"
              key={tab.id} // Ensure tab.id is unique
              className="tab-button"
              style={{
                background: activeTab === tab.id ? "#0C71C3" : "transparent", // Blue background for active tab, transparent for others
                color: activeTab === tab.id ? "#fff" : "#000", // White text for active tab, black for others
                padding: "10px 20px", // Adjust padding for better spacing
                cursor: "pointer",
              }}
              onClick={() => handleSelect(tab.id)} // Use handleSelect to update active tab
              // {...navItemProps}
              // {...tab.itemProps}
            >
            <strong> {tab.title} </strong> 
            </div>
          );
        }

        return null; // Explicitly return null if no permission
      })}
    </div>
  </div>
</Box>
  );
};

export default CustomTabBar;
