import React, { useState, useEffect } from "react";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  Spinner,
} from "react-bootstrap";
import "../../../global.css";
import Button from "@mui/material/Button";
import {
  ContactDropDowns,
  DecryptToken,
  fetchCustomerId,
  FetchDropDowns,
  GetCountries,
} from "../../../common/helper/BasicFn";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactModalEmail from "../../../common/components/ContactModalEmail";
import ContactModalPhone from "../../../common/components/ContactModalPhone";
import AddContactPhoneFrom from "../../../common/components/AddContactPhoneFrom";
import Select from "react-select";
import { toast } from "react-toastify";
import MessageHandler from "../../../common/components/MessageHandler";
import { CallPOSTAPI } from "../../../common/services";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import ToogleSwitch from "../../../common/components/toggleSwitch/ToogleSwitch";
import { prepareOptions } from "../../../common/helper";
import StateField from "../../../common/components/StateField";
import AddContactMultiEmailForm from "../components/common/AddContactMultiEmailForm";
import { useDispatch } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import {
  addToHistoryStack,
  removeBreadcrumb,
} from "../../../store/slices/breadcrumbsSlice";
import { useSelector } from "react-redux";
import { printConsole } from "../../../common/helper/Common";
import { isPermission } from "../../../common/helper/PermissionManager";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

const NewContact = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const user = DecryptToken();
  const dispatch = useDispatch();
  const [mainError, setMainError] = useState(false);
  const [validated, setValidated] = useState(false);
  const { accountId } = useParams();
  const [phoneValidation, setPhoneValidation] = useState({});
  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [switchValue, setSwitchValue] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [submitNew, setSubmitNew] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [ProductModalData, setProductModalData] = useState([
    { label: "Project Manager", value: "is_pm" },
    { label: "Sales Reps", value: "is_sr" },
    { label: "Technician", value: "is_tech" },
    { label: "OOS Tech", value: "00S_tech" },
  ]);
  const [formData, setFormData] = useState({
    account_id: accountId,

    account_main_contact_salutation: "",
    account_main_contact_firstname: "",
    account_main_contact_middlename: "",
    account_main_contact_lastname: "",
    account_main_contact_suffix: "",
    account_main_contact_title: "",
    account_main_contact_department: "",
    account_main_contact_status: 1,
    contact_status: 1,
    // held_positions: { is_pm: 0, is_sr: 0, is_tech: 0 },
    main_contact_phone: [],
    main_contact_email: [],
  });

  const handleProductSelect = (selected) => {
    setSelectedProducts(selected); // Update selected options state

    // Create the held_positions object with default values of 0
    const positionsObject = {
      is_pm: 0,
      is_sr: 0,
      is_tech: 0,
      oos_tech: 0,
    };

    // Update values to 1 for selected positions
    selected.forEach((item) => {
      positionsObject[item.value] = 1;
    });

    // Update form data with the new held_positions array
    setFormData((prevData) => ({
      ...prevData,
      held_positions: [positionsObject],
    }));
  };

  const [multiEmailFormCount, setMultiEmailFormCount] = useState([
    {
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: "",
    },
  ]);
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const handleDispatch = (accountId, is_active) => {
    if (is_active === 0) {
      const newFormUrl = `/account/contacts/new/${accountId}`;
      dispatch(removeBreadcrumb(newFormUrl));
    } else {
      const lastBreadcrumb =
        breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
      dispatch(addToHistoryStack(lastBreadcrumb));
      const newFormUrl = `/account/contacts/new/${accountId}`;
      dispatch(removeBreadcrumb(newFormUrl));
    }
  };

  const MultiEmailFormIncrease = () => {
    let arr = [...multiEmailFormCount];
    let obj = {
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: "",
    };
    arr.push(obj);
    setMultiEmailFormCount(arr);
  };

  const MultiEmailFormDecrease = (index1) => {
    let arr = [...multiEmailFormCount];
    if (index1 >= 0 && multiEmailFormCount.length > 1) {
      const updateArr = arr.filter((_, index) => index !== index1);
      setMultiEmailFormCount(updateArr);
    }
  };

  // const handleInputChange = (e) => {
  //   setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const [altTrainerForm1, setAltTrainerForm1] = useState([
    {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
    },
  ]);

  const increaseAlternative1 = () => {
    let arr = [...altTrainerForm1];
    let obj = {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
    };
    arr.push(obj);
    setAltTrainerForm1(arr);
  };

  const decreaseAlternative1 = (index1) => {
    let arr = [...altTrainerForm1];
    if (index1 >= 0 && altTrainerForm1.length > 1) {
      const updateArr = arr.filter((_, index) => index !== index1);
      setAltTrainerForm1(updateArr);
    }
  };

  const [allDropDowns, setAllDropDowns] = React.useState([]);

  const fetchOnload = async () => {
    // setLoading(true);
    let AllDResult = await ContactDropDowns();
    if (AllDResult) {
      setAllDropDowns(AllDResult);
    }

    // get country
    const countries = await GetCountries();
    if (countries?.status) {
      let countriesData = prepareOptions(
        countries?.data?.country,
        "id",
        "country_name"
      );
      setCountryList(countriesData);
      setSelectedCountry((old) => ({
        ...old,
        contact_country: {
          label: countriesData[230].label,
          value: countriesData[230].value,
        },
      }));
    }

    const customerIdResult = await fetchCustomerId(accountId);
    const customerTypeId = customerIdResult.customer_type_id;

    // Update ProductModalData based on customerId
    if (customerTypeId === 6) {
      setProductModalData([
        { label: "Project Manager", value: "is_pm" },
        { label: "Sales Reps", value: "is_sr" },
        { label: "Technician", value: "is_tech" },
      ]);
    } else {
      setProductModalData([{ label: "OOS Tech", value: "oos_tech" }]);
    }
    // setLoading(false);
  };

  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [loading, setLoading] = React.useState(false);
  const [openContactModal, setContactModal] = React.useState(false);
  const [listType, setListType] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // check for phone validations
    for (const [key, value] of Object.entries(phoneValidation)) {
      if (value) {
        setLoading(false);
        setValidated(true);
        return;
      }
    }

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setLoading(false);
      setValidated(true);
      return;
    }
    SaveForm();
  };

  const checkisMainContact = (arr, key) => {
    let newarr = arr.find((a) => (a[key] ? true : false));

    return newarr ? true : false;
  };

  const checkMainPhoneLengthArr = (arr) => {
    let result = 0;
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (
        element.account_main_contact_phone?.length > 0 &&
        element.account_main_contact_phone?.length !== 10
      ) {
        return 1;
      }
    }
    return result;
  };

  // Add a function to reset the form
  const resetForm = () => {
    setFormData({
      account_id: accountId,
      account_main_contact_salutation: "",
      account_main_contact_firstname: "",
      account_main_contact_middlename: "",
      account_main_contact_lastname: "",
      account_main_contact_suffix: "",
      account_main_contact_title: "",
      account_main_contact_department: "",
      account_main_contact_status: 1,
      contact_status: 1,
      main_contact_phone: [],
      main_contact_email: [],
      contact_address1: "",
      contact_address2: "",
      contact_city: "",
      contact_state: "",
      contact_zipcode: "",
      training_optout: 0,
    });
    
    setAltTrainerForm1([{
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
    }]);
    
    setMultiEmailFormCount([{
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: "",
    }]);
    
    setSelectedProducts([]);
    setPhoneValidation({});
    setValidated(false);
    setMainError(false);
    setSwitchValue({});
    
    // Reset country to default (United States, assuming 230 is US)
    setSelectedCountry({
      contact_country: {
        label: countryList[230]?.label || "",
        value: countryList[230]?.value || "",
      }
    });
    setResetState(true); // Trigger reset
    setTimeout(() => setResetState(false), 0); // Reset the flag after render
  };

  const SaveForm = async () => {
    let arr = formData;
    arr.training_optout = formData.training_optout ? 1 : 0;

    arr.main_contact_phone = altTrainerForm1.filter(
      (it) => it.account_main_contact_phone != ""
    );
    arr.main_contact_email = multiEmailFormCount;
    if (
      arr.main_contact_phone.length > 0 &&
      checkMainPhoneLengthArr(arr.main_contact_phone) === 1
    ) {
      setLoading(false);
      toast.error("Please Enter Exact 10 digits in phone.");
      return;
    }

    if (arr.main_contact_phone.length > 1) {
      arr.main_contact_phone.find((data) => {
        return data.account_main_contact_phone_main == 1;
      });
    } else {
      setMainError(false);
    }

    // check for email main
    if (arr.main_contact_email.length > 1) {
      arr.main_contact_email.find((data) => {
        return data.account_main_contact_email_main == 1;
      });
    } else {
      setMainError(false);
    }

    let checkMainPhone = checkisMainContact(
      arr.main_contact_phone,
      "account_main_contact_phone_main"
    );
    let checkMainEmail = checkisMainContact(
      arr.main_contact_email,
      "account_main_contact_email_main"
    );

    if (
      !checkMainPhone &&
      arr.main_contact_phone.some((i) => i?.account_main_contact_phone) &&
      arr.main_contact_phone?.length > 1
    ) {
      setListType("phone");
      setContactModal(true);
      return false;
    } else if (
      !checkMainPhone &&
      arr.main_contact_phone?.length === 1 &&
      arr.main_contact_phone[0]?.account_main_contact_phone?.length === 10
    ) {
      arr.main_contact_phone[0].account_main_contact_phone_main = 1;
    }

    if (!checkMainEmail && multiEmailFormCount?.length > 1) {
      setListType("email");
      setContactModal(true);
      setLoading(false);
      return false;
    } else if (!checkMainEmail && multiEmailFormCount?.length === 1) {
      arr.main_contact_email[0].account_main_contact_email_main = 1;
    }

    arr.contact_country_name = selectedCountry.contact_country.label;
    arr.contact_country = selectedCountry.contact_country.value;
    printConsole({ arr }); // print console.log

    if (!mainError) {
      let result = await CallPOSTAPI("contact/add_account_contacts", arr);
      if (result?.data?.status) {
        // setFormMsg({ type: result?.data?.status, msg: result?.data?.msg });
        toast.success(result?.data?.msg);

        if (submitNew) {
          // Reset form and stay on the page
          resetForm();
          setSubmitNew(false);
          setLoading(false);
        } else {
        let pathUrl = "/account-details/" + formData?.account_id + "/Contacts";
        handleDispatch(accountId);
        navigate(pathUrl, {
          state: {
            tab: "Contacts",
            type: result?.data?.status,
            msg: result?.data?.msg,
          },
        });
      }} else {
        toast.error(result?.data?.msg);
      }
      setLoading(false);
    }
  };

  // handle select change
  const handleSelectChange = (data, key) => {
    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data.label,
        value: data.value,
      },
    }));

    setFormData((old) => ({ ...old, [key]: data.value }));
  };

  useEffect(() => {
    fetchOnload();
  }, []);

  // switchChangeHandle
  const switchChangeHandle = (switchValue, e) => {
    setFormData((old) => ({ ...old, [switchValue?.key]: switchValue?.value }));
  };

  // check for switch value and update values
  useEffect(() => {
    switchChangeHandle(switchValue);
  }, [switchValue]);

  return (
    <>
      <div className="mt-4">
        <SubHeadingOther
          title="New Contact"
          hideNew="tab"
          subHeading={true}
          hideHierarchy={true}
          bottomLinks={false}
        />
      </div>

      <Form
        className=""
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        id="create-new-account-form"
      >
        <div className="contailer-fluid px-3">
          <div
            className="container-fluid bottom-border-blue pb-4 pt-2"
            style={{
              background: "#eee",
              border: "2px solid #00578E",
              borderRadius: "5px"
            }}
            id="contact_info_section"
          >
            <h2 className="text-left heading">Contact Information</h2>
            <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
  {/* Left Column */}
  <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px" }}>
    {/* Salutation */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Salutation
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_salutation"
        value={formData.account_main_contact_salutation || ""}
        onChange={handleInputChange}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* First Name */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        First Name *
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_firstname"
        value={formData.account_main_contact_firstname || ""}
        onChange={handleInputChange}
        required
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
        isInvalid={validated && !formData.account_main_contact_firstname} // Explicitly set isInvalid
          />
          </div>
          <Form.Control.Feedback type="invalid"
                style={{
                  marginLeft: "130px",
                  display: validated && !formData.account_main_contact_firstname ? "block" : "none",
                }}>
        Please Enter First Name.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Middle Name */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Middle Name
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_middlename"
        value={formData.account_main_contact_middlename || ""}
        onChange={handleInputChange}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Middle Name.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Last Name */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Last Name *
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_lastname"
        value={formData.account_main_contact_lastname || ""}
        onChange={handleInputChange}
        required
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      isInvalid={validated && !formData.account_main_contact_lastname} // Explicitly set isInvalid
          />
          </div>
          <Form.Control.Feedback type="invalid"
                style={{
                  marginLeft: "130px",
                  display: validated && !formData.account_main_contact_lastname ? "block" : "none",
                }}>
        Please Enter Last Name.
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Suffix
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_suffix"
        value={formData.account_main_contact_suffix || ""}
        onChange={handleInputChange}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* Title */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Title
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_title"
        value={formData.account_main_contact_title || ""}
        onChange={handleInputChange}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* Department */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Department
      </Form.Label>
      <Form.Control
        type="text"
        name="account_main_contact_department"
        value={formData.account_main_contact_department || ""}
        onChange={handleInputChange}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* Contact Status */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Contact Status
      </Form.Label>
      <Form.Select
        className={""}
        name="contact_status"
        onChange={handleInputChange}
        value={formData.contact_status || ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "0px 4px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      >
        <option value="0" disabled>
          --Select One--
        </option>
        {allDropDowns?.contactStatus &&
          allDropDowns?.contactStatus.map((CS, index) => (
            <option value={CS.dropdown_contact_status_id} key={index}>
              {CS.contact_status_type}
            </option>
          ))}
      </Form.Select>
    </Form.Group>
  </div>

  {/* Right Column */}
  <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px" }}>
    {/* Suffix */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Country
      </Form.Label>
      <Select
        value={selectedCountry?.contact_country}
        options={countryList}
        onChange={(data) => {
          handleSelectChange(data, "contact_country");
        }}
        styles={{
          control: (provided) => ({
            ...provided,
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "0px 4px",
            fontSize: "15px",
            color: "#333",
            height: "34px",
            width: "400px",
          }),
        }}
      />
    </Form.Group>

    {/* Address */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address
      </Form.Label>
      <Form.Control
        type="text"
        name="contact_address1"
        onChange={handleInputChange}
        value={formData.contact_address1 || ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Address.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Address 2 */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address 2
      </Form.Label>
      <Form.Control
        type="text"
        name="contact_address2"
        onChange={handleInputChange}
        value={formData.contact_address2 || ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        City
      </Form.Label>
      <Form.Control
        type="text"
        name="contact_city"
        onChange={handleInputChange}
        value={formData.contact_city || ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter City.
      </Form.Control.Feedback>
    </Form.Group>

    {/* State */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        maxWidth: "530px"
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        State
      </Form.Label>
      {/* <StateField
        setFormData={setFormData}
        valueKey="contact_state"
        selectedCountry={selectedCountry?.contact_country?.value}
        stateSelectedValue={formData.contact_state || ""}
        validated={false}
        required={false}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "0px 4px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      /> */}
      <StateField
        setFormData={setFormData}
        valueKey="contact_state"
        selectedCountry={selectedCountry?.contact_country?.value}
        stateSelectedValue={formData.contact_state || ""}
        validated={false} 
        required={false}
        resetState={resetState} // Pass resetState
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "0px 4px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
    </Form.Group>

    {/* Zip Code */}
    <Form.Group
      className="form-group-responsive NewContactFormField"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Zip code
      </Form.Label>
      <Form.Control
        type="text"
        name="contact_zipcode"
        onChange={handleInputChange}
        value={formData.contact_zipcode || ""}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Zip Code.
      </Form.Control.Feedback>
    </Form.Group>

    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Held Positions
      </Form.Label>
      <div style={{ width: "400px" }}>
        <MultiSelect
          options={ProductModalData}
          value={selectedProducts}
          onChange={handleProductSelect}
          labelledBy="--Select One--"
          hasSelectAll={false}
          overrideStrings={{ selectSomeItems: "--Select One--" }}
          styles={{
            control: (provided) => ({
              ...provided,
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "0px 4px",
              fontSize: "15px",
              color: "#333",
              height: "34px",
              width: "400px",
            }),
          }}
        />
      </div>
    </Form.Group>

    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Training OptOut
      </Form.Label>
      <div style={{ width: "400px" }}>
        <ToogleSwitch
          switchKeyValue={formData?.training_optout}
          setSwitchValue={setSwitchValue}
          switchValue={switchValue}
          switchKey={"training_optout"}
        />
      </div>
    </Form.Group>
  </div>
</div>

<div className="form-container mt-4" id="training_and_positions" style={{ display: "flex", justifyContent: "space-between" }}>
  {/* Left Column */}
  <div className="col form-column" style={{ width: "45%", padding: "0 0px" }}>

  </div>

  {/* Right Column */}
  <div className="col form-column" style={{ width: "45%", padding: "0 0px" }}>
  
  </div>
</div>

 {/* Horizontal Line */}
 <div style={{ border: '1px solid #0C71C3', margin: '5px 0' }} />

<div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
<div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <AddContactPhoneFrom
              altTrainerForm={altTrainerForm1}
              setSubFormData={setAltTrainerForm1}
              increaseAlternative={increaseAlternative1}
              decreaseAlternative={decreaseAlternative1}
              handleInputChange={handleInputChange}
              allDropDowns={allDropDowns}
              formData={formData.main_contact_phone}
              formName={"main_contact_phone"}
              setFormData={setFormData}
              noBtns={true}
              setPhoneValidation={setPhoneValidation}
              phoneValidation={phoneValidation}
              setLoading={setLoading}
              sectionId={"contact_phone_id"}
              sectionName={"contact_information"}
              validated={validated}
            />
            </div>

            <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <AddContactMultiEmailForm
              altTrainerForm={multiEmailFormCount}
              setSubFormData={setMultiEmailFormCount}
              increaseAlternative={MultiEmailFormIncrease}
              decreaseAlternative={MultiEmailFormDecrease}
              handleInputChange={handleInputChange}
              allDropDowns={allDropDowns}
              formData={formData.main_contact_email}
              formName={"main_contact_email"}
              setFormData={setFormData}
              disabled={false}
              updateEmail={true}
              sectionId={"contact_email_id"}
              sectionName={"contact_information"}
              validated={validated}
            />
          </div>
          </div>
          </div>

          <div className="my-4">
            <MessageHandler
              status={FormMsg.type}
              msg={FormMsg.msg}
              HandleMessage={setFormMsg}
            />
          </div>

          <div className="row pb-3 py-5">
            <div className="col-12 content-flex-right">
              <button
                className="btn btn-danger text-uppercase"
                type="button"
                onClick={() => {
                  handleDispatch(accountId);
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                disabled={loading}
                onClick={() => setSubmitNew(true)}
              >
                {loading ? "Loading..." : "Submit and New"} 
                {/* submit */}
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
                {/* submit */}
              </button>
            </div>
          </div>
        </div>
      </Form>

      <ContactModalPhone
        // open={openContactModal}
        open={listType === "phone" ? true : false}
        hanldeModal={setContactModal}
        emailDataList={altTrainerForm1}
        phoneDataList={altTrainerForm1.filter(
          (it) => it.account_main_contact_phone != ""
        )}
        dataType={listType}
        setSubFormData={setAltTrainerForm1}
        SaveForm={SaveForm}
        setDataType={setListType}
        setLoading={setLoading}
      />

      <ContactModalEmail
        open={listType === "email" ? true : false}
        hanldeModal={setContactModal}
        emailDataList={multiEmailFormCount}
        phoneDataList={multiEmailFormCount}
        dataType={listType}
        setSubFormData={setMultiEmailFormCount}
        SaveForm={SaveForm}
        setDataType={setListType}
        setLoading={setLoading}
      />

      {/* Overlay */}
      {loading && (
        <div style={overlay}>
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      )}
    </>
  );
};

export default NewContact;
