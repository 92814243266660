import React, { useState, useEffect } from "react";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  Spinner,
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CallPOSTAPI } from "../../../common/services";
import { DecryptToken, prepareOptions, sortData } from "../../../common/helper";
import { fetchCustomerId, getPer } from "../../../common/helper/BasicFn";
import EditContactMultiEmailForm from "../components/common/EditContactMultiEmailForm";
import SubHeading from "../../../common/components/SubHeading";
import AddContactPhoneFrom from "../../../common/components/AddContactPhoneFrom";
import ContactModalPhone from "../../../common/components/ContactModalPhone";
import ContactModalEmail from "../../../common/components/ContactModalEmail";
import MessageHandler from "../../../common/components/MessageHandler";
import StateField from "../../../common/components/StateField";
import ToogleSwitch from "../../../common/components/toggleSwitch/ToogleSwitch";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import {
  addToHistoryStack,
  removeBreadcrumb,
} from "../../../store/slices/breadcrumbsSlice";
import { printConsole } from "../../../common/helper/Common";
import { isPermission } from "../../../common/helper/PermissionManager";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

const EditContact = ({ setShowSidebar }) => {
  const location = useLocation();
  const [loading2, setLoading2] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    deletePhone: [],
    deleteEmail: [],
  });
  const navigate = useNavigate();
  const [listType, setListType] = React.useState("");
  const [openContactModal, setContactModal] = React.useState(false);
  const [phoneValidation, setPhoneValidation] = useState({});
  const user = DecryptToken();
  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const { contactId } = useParams();
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [ProductModalData, setProductModalData] = useState([
    { label: "Project Manager", value: "is_pm" },
    { label: "Sales Reps", value: "is_sr" },
    { label: "Technician", value: "is_tech" },
    { label: "OOS Tech", value: "oos_tech" },
  ]);
  // const contactInfoDetails = location?.state?.contactInfoDetails
  const contactInfoDetails = useSelector(
    (state) => state?.TAB_manager?.editContactDetails
  );

  printConsole("contactInfoDetails", { contactInfoDetails }); // print console.log

  const resetForm = () => {
    document.getElementById("create-new-account-form").reset();
  };

  const [multiEmailFormCount, setMultiEmailFormCount] = useState([
    {
      account_main_contact_email_id: "",
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: 0,
      account_main_contact_id: contactId,
    },
  ]);

  const MultiEmailFormIncrease = () => {
    let arr = [...multiEmailFormCount];
    let obj = {
      account_main_contact_email: "",
      email_type_id: "",
      account_main_contact_email_main: 0,
      account_main_contact_id: contactId,
    };
    arr.push(obj);
    setMultiEmailFormCount(arr);
  };

  const MultiEmailFormDecrease = (index1) => {
    let arr = [...multiEmailFormCount];
    if (multiEmailFormCount[index1]?.account_main_contact_email_main == "0") {
      let deleteObject = multiEmailFormCount[index1];

      if (!formData.deleteEmail) {
        formData.deleteEmail = [];
      }
      formData.deleteEmail.push(deleteObject);

      if (index1 >= 0 && multiEmailFormCount.length > 1) {
        if (arr[index1].account_main_contact_email_main !== 1) {
          const updateArr = arr.filter((item, index) => index !== index1);
          setMultiEmailFormCount(updateArr);
        } else {
          toast.error("Main contact cannot be removed");
        }
      }
    }
  };

  const handleProductSelect = (selected) => {
    setSelectedProducts(selected); // Update selected options state

    // Create the held_positions object with default values of 0
    const positionsObject = {
      is_pm: 0,
      is_sr: 0,
      is_tech: 0,
      oos_tech: 0,
    };

    // Update values to 1 for selected positions
    selected.forEach((item) => {
      positionsObject[item.value] = 1;
    });

    // Update form data with the new held_positions array
    setFormData((prevData) => ({
      ...prevData,
      held_positions: [positionsObject],
    }));
  };

  const handleInputChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  const [altTrainerForm1, setAltTrainerForm1] = useState([
    {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
      account_main_contact_id: contactId,
    },
  ]);

  const increaseAlternative1 = () => {
    let arr = [...altTrainerForm1];
    let obj = {
      account_main_contact_phone_id: "",
      account_main_contact_phone: "",
      account_main_contact_phone_ext: "",
      phone_type_id: "",
      account_main_contact_phone_main: 0,
      account_main_contact_id: contactId,
    };
    arr.push(obj);
    setAltTrainerForm1(arr);
  };

  const decreaseAlternative1 = (index1) => {
    let arr = [...altTrainerForm1];
    if (altTrainerForm1[index1]?.account_main_contact_phone_main == "0") {
      let deleteObject = altTrainerForm1[index1];

      if (!formData.deletePhone) {
        formData.deletePhone = [];
      }
      formData.deletePhone.push(deleteObject);

      if (index1 >= 0 && altTrainerForm1.length > 1) {
        const updateArr = arr.filter((_, index) => index !== index1);
        setAltTrainerForm1(updateArr);
      }
    }
  };

  const updatedPhone = (data) => {
    setAltTrainerForm1(data);
  };
  const [allDropDowns, setAllDropDowns] = React.useState([]);
  const [switchValue, setSwitchValue] = useState({});

  // handle select change
  const handleSelectChange = (data, key) => {
    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data.label,
        value: data.value,
      },
    }));

    setFormData((old) => ({ ...old, [key]: data.value }));
  };

  const [FormMsg, setFormMsg] = React.useState({ type: true, msg: "" });
  const [loading, setLoading] = React.useState(false);

  const checkisMainContact = (arr, key) => {
    let newarr = arr.find((a) => (a[key] ? true : false));

    return newarr ? true : false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // check for phone validations
    for (const [key, value] of Object.entries(phoneValidation)) {
      if (value) {
        setLoading(false);
        setValidated(true);
        return;
      }
    }

    // setValidated();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setLoading(false);
      setValidated(true);
      return;
    }

    SaveForm();
  };
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const handleDispatch = (contactId, is_active) => {
    if (is_active === 0) {
      const editPageUrl = "/account/contact-details-edit/" + contactId; // Replace this with your edit page's URL

      dispatch(removeBreadcrumb(editPageUrl));
    } else {
      const lastBreadcrumb =
        breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
      dispatch(addToHistoryStack(lastBreadcrumb));

      const editPageUrl = "/account/contact-details-edit/" + contactId; // Replace this with your edit page's URL
      dispatch(removeBreadcrumb(editPageUrl));
    }
  };

  const SaveForm = async () => {
    formData.contact_id = contactId;
    let arr = formData;
    arr.training_optout = formData.training_optout ? 1 : 0;

    const multiEmailCountVar = multiEmailFormCount.map((email, index) => {
      let newEmail = { ...email };
      if (
        newEmail.account_main_contact_email_id == "" ||
        newEmail.account_main_contact_email_id == null ||
        newEmail.account_main_contact_email_id == undefined
      ) {
        newEmail.account_main_contact_email_id = "";
        newEmail.email_type_id =
          email?.email_type_id == "0" ? "" : newEmail.email_type_id;
      }
      newEmail.account_main_contact_id = contactId;
      return newEmail;
    });

    const altTrainerForm1Var = altTrainerForm1.map((phone, index) => {
      let newPhone = { ...phone };
      if (
        newPhone.account_main_contact_phone_id == "" ||
        newPhone.account_main_contact_phone_id == null ||
        newPhone.account_main_contact_phone_id == undefined
      ) {
        newPhone.account_main_contact_phone_id = "";
        newPhone.phone_type_id =
          phone?.phone_type_id == "0" ? "" : newPhone.phone_type_id;
      }
      newPhone.account_main_contact_id = contactId;
      return newPhone;
    });

    arr.email = multiEmailCountVar;
    arr.phone =
      altTrainerForm1Var.filter((it) => it.account_main_contact_phone != "") ||
      []; //altTrainerForm1Var;

    const DeletedArr = altTrainerForm1Var.filter(
      (it) =>
        it.account_main_contact_phone == "" &&
        it.account_main_contact_phone_id != ""
    );
    arr.deletePhone = arr?.deletePhone
      ? [...DeletedArr, ...arr.deletePhone]
      : DeletedArr; // .push(deleteObject);

    if (arr.phone.length > 0) {
      let mainPhone = arr.phone.find((data) => {
        return data.account_main_contact_phone_main == 1;
      });
    }
    if (arr.email.length > 0) {
      let mainEmail = arr.email.find((data) => {
        return data.account_main_contact_email_main == 1;
      });
    }
    // account_main_contact_phone_main

    arr.deletePhone = !formData?.deletePhone ? [] : formData?.deletePhone;
    arr.deleteEmail = !formData?.deleteEmail ? [] : formData?.deleteEmail;

    let checkMainPhone = checkisMainContact(
      arr.phone,
      "account_main_contact_phone_main"
    );
    let checkMainEmail = checkisMainContact(
      arr.email,
      "account_main_contact_email_main"
    );

    if (
      !checkMainPhone &&
      arr.phone.some((i) => i?.account_main_contact_phone) &&
      arr.phone?.length > 1
    ) {
      setListType("phone");
      setContactModal(true);
      return false;
    } else if (
      !checkMainPhone &&
      arr.phone?.length === 1 &&
      arr.phone[0]?.account_main_contact_phone?.length === 10
    ) {
      arr.phone[0].account_main_contact_phone_main = 1;
    }

    if (!checkMainEmail && multiEmailCountVar?.length > 1) {
      setListType("email");
      setContactModal(true);
      setLoading(false);
      return false;
    } else if (!checkMainEmail && multiEmailCountVar?.length === 1) {
      arr.email[0].account_main_contact_email_main = 1;
    }

    const result = await CallPOSTAPI("contact/update-contact-details", arr);

    if (result?.data?.status) {
      toast.success(result?.data?.msg);
      // setLoading(false);
      let url = "";
      if (Number(getPer()) === 1) {
        // url = "/account-details/" + formData?.account_id;
        url = `/account/${formData?.account_id}/contact-details/${contactId}`;
      } else {
        url = `/account/${formData?.account_id}/contact-details/${contactId}`;
      }
      handleDispatch(contactId);
      navigate(url, {
        state: {
          tab: "Contacts",
          type: result?.data?.status,
          msg: result?.data?.msg,
        },
      });
    } else {
      setLoading(false);
      toast.error(result?.data?.msg);
    }
  };

  // switchChangeHandle
  const switchChangeHandle = (switchValue, e) => {
    setFormData((old) => ({ ...old, [switchValue?.key]: switchValue?.value }));
  };

  // check for switch value and update values
  useEffect(() => {
    switchChangeHandle(switchValue);
  }, [switchValue]);

  // Function to convert held_positions to selected products format
  const convertHeldPositionsToSelected = (heldPositions) => {
    if (!heldPositions || !Array.isArray(heldPositions) || !heldPositions[0])
      return [];

    const positions = heldPositions[0];
    return ProductModalData.filter((product) => {
      return positions[product.value] === 1;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch customerId using the provided contact info
        const customerIdResult = await fetchCustomerId(
          contactInfoDetails?.contact_details?.account_id
        );

        // Get the customerTypeId
        const customerTypeId = customerIdResult.customer_type_id;

        // Update ProductModalData based on customerTypeId
        if (customerTypeId === 6) {
          setProductModalData([
            { label: "Project Manager", value: "is_pm" },
            { label: "Sales Reps", value: "is_sr" },
            { label: "Technician", value: "is_tech" },
          ]);
        } else {
          setProductModalData([{ label: "OOS Tech", value: "oos_tech" }]);
        }

        // Set initial form data from contactInfoDetails
        setFormData(contactInfoDetails?.contact_details);

        // Handle held_positions if available
        if (contactInfoDetails?.held_position) {
          const initialSelected = convertHeldPositionsToSelected(
            contactInfoDetails.held_position
          );
          setSelectedProducts(initialSelected);

          // Also update formData with held_positions
          setFormData((prev) => ({
            ...prev,
            held_positions: contactInfoDetails.held_position,
          }));
        }

        // Handle emails if available
        if (contactInfoDetails?.emails) {
          setMultiEmailFormCount(
            Array.isArray(contactInfoDetails?.emails) &&
              contactInfoDetails?.emails.length > 0
              ? contactInfoDetails?.emails
              : [...multiEmailFormCount, ...(contactInfoDetails?.emails || [])]
          );
        }

        // Handle phone_numbers if available
        if (contactInfoDetails?.phone_numbers) {
          setAltTrainerForm1(
            Array.isArray(contactInfoDetails?.phone_numbers) &&
              contactInfoDetails?.phone_numbers.length > 0
              ? contactInfoDetails?.phone_numbers
              : [
                  ...altTrainerForm1,
                  ...(contactInfoDetails?.phone_numbers || []),
                ]
          );
        }

        // Set contact_state if available
        if (contactInfoDetails?.contact_details?.contact_state) {
          setFormData((prev) => ({
            ...prev,
            contact_state: contactInfoDetails?.contact_details?.contact_state,
          }));
        }

        // Set selected country based on the contact details
        setSelectedCountry((old) => ({
          ...old,
          contact_country: {
            label: contactInfoDetails?.contact_details?.contact_country_name,
            value: contactInfoDetails?.contact_details?.contact_country,
          },
        }));
      } catch (error) {
        console.error("Error fetching customer ID or contact details:", error);
      }
    };

    // Run fetchData when location changes
    fetchData();
  }, [location, contactInfoDetails]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      contact_state: contactInfoDetails?.contact_details?.contact_state || "",
      contact_state_name:
        contactInfoDetails?.contact_details?.contact_state_name || "",
    }));
  }, []);

  return (
    <>
      <div className="mt-4">
        <SubHeading
          hideNew
          hideHierarchy
          subHeading={true}
          title="Edit Contact"
        />
      </div>

      <Form
        className=""
        onSubmit={handleSubmit}
        noValidate
        validated={validated}
        id="create-new-account-form"
      >
        <div className="contailer-fluid px-2">
          <div
            className="container-fluid bottom-border-blue pb-4 pt-2"
            style={{
              background: "#eee",
              border: "2px solid #00578E",
              borderRadius: "5px"
            }}
            id="account_main_contact_section"
          >
            <h2 className="text-left heading">Account Main Contact</h2>
    

            <div className="form-container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
  {/* Left Column */}
  <div className="col-md-6 my-4 form-column" style={{ padding: "0 15px" }}>
    {/* Salutation */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="account_main_contact_salutation"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Salutation
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_salutation"
        name="account_main_contact_salutation"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_salutation}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
    </Form.Group>

    {/* First Name */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        htmlFor="account_main_contact_firstname"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        First Name <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_firstname"
        name="account_main_contact_firstname"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_firstname}
        required
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
        isInvalid={validated && !formData.account_main_contact_firstname} // Explicitly set isInvalid
        />
        </div>
        <Form.Control.Feedback type="invalid"
              style={{
                marginLeft: "130px",
                display: validated && !formData.account_main_contact_firstname ? "block" : "none",
              }}>
        Please Enter First Name.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Middle Name */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="account_main_contact_middlename"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Middle Name
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_middlename"
        name="account_main_contact_middlename"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_middlename}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Middle Name.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Last Name */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "1px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        htmlFor="account_main_contact_lastname"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Last Name <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_lastname"
        name="account_main_contact_lastname"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_lastname}
        required
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
        isInvalid={validated && !formData.account_main_contact_lastname} // Explicitly set isInvalid
        />
        </div>
        <Form.Control.Feedback type="invalid"
              style={{
                marginLeft: "130px",
                display: validated && !formData.account_main_contact_lastname ? "block" : "none",
              }}>
        Please Enter Last Name.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Suffix */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="account_main_contact_suffix"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Suffix
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_suffix"
        name="account_main_contact_suffix"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_suffix}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
    </Form.Group>

    {/* Title */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="account_main_contact_title"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Title
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_title"
        name="account_main_contact_title"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_title}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
    </Form.Group>

    {/* Department */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="account_main_contact_department"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Department
      </Form.Label>
      <Form.Control
        type="text"
        id="account_main_contact_department"
        name="account_main_contact_department"
        onInput={handleInputChange}
        defaultValue={formData.account_main_contact_department}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
    </Form.Group>

<Form.Group
  className="form-group-responsive"
  style={{
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
  }}
>
  <Form.Label
    htmlFor="contact_status_id"
    style={{
      fontWeight: "bold",
      fontSize: "15px",
      color: "#333",
      width: "120px",
      margin: 0,
      textAlign: "right",
    }}
  >
    Contact Status
  </Form.Label>
  <div style={{ width: "100%", maxWidth: "400px" }}>
    <GlobalDropdown
      id="contact_status_id"
      dropDownName="contact_status_id"
      apiEndpoint="contact/contact-dropdowns"
      idKey="dropdown_contact_status_id"
      valueKey="contact_status_type"
      parentKey="contactStatus"
      onSelect={(data, value) => {
        setFormData((prev) => ({
          ...prev,
          contact_status_id: value,
        }));
      }}
      shouldFetchOnOpen={true}
      isCache={true}
      initialSelectedValue={formData?.contact_status_id || contactInfoDetails?.contact_details?.contact_status_id}
      defaultValue={{
        id: formData?.contact_status_id || contactInfoDetails?.contact_details?.contact_status_id,
        value: contactInfoDetails?.contact_details?.contact_status_name,
      }}
      value={formData?.contact_status_id} // Yeh line add kiya hai to ensure selected value stays
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "6px 10px",
        fontSize: "15px",
        color: "#333",
        height: "34px",
        width: "100%",
      }}
    />
  </div>
</Form.Group>
  </div>

  {/* Right Column */}
  <div className="col-md-6 my-4 form-column" style={{ padding: "0 15px" }}>
    {/* Country */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_country"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Country
      </Form.Label>
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <GlobalDropdown
          id="contact_country"
          dropDownName={"contact_country"}
          apiEndpoint={"account/get-country"}
          idKey={"id"}
          valueKey={"country_name"}
          parentKey={"country"}
          onSelect={(data, value) => {
            const selectedText = data.target.options[data.target.selectedIndex].text;
            const data2 = {
              label: selectedText,
              value: value,
            };
            handleSelectChange(data2, "contact_country");
          }}
          shouldFetchOnOpen={true}
          isCache={true}
          initialSelectedValue={contactInfoDetails?.contact_details?.contact_country}
          defaultValue={{
            id: contactInfoDetails?.contact_details?.contact_country,
            value: contactInfoDetails?.contact_details?.contact_country_name,
          }}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "6px 10px",
            fontSize: "15px",
            color: "#333",
            height: "34px",
            width: "100%",
          }}
        />
      </div>
    </Form.Group>

    {/* Address */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_address1"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address
      </Form.Label>
      <Form.Control
        type="text"
        id="contact_address1"
        name="contact_address1"
        onInput={handleInputChange}
        value={formData.contact_address1}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Address.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Address 2 */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_address2"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Address 2
      </Form.Label>
      <Form.Control
        type="text"
        id="contact_address2"
        name="contact_address2"
        onInput={handleInputChange}
        value={formData.contact_address2}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
    </Form.Group>

    {/* City */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_city"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        City
      </Form.Label>
      <Form.Control
        type="text"
        id="contact_city"
        name="contact_city"
        onInput={handleInputChange}
        value={formData.contact_city}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter City.
      </Form.Control.Feedback>
    </Form.Group>

    {/* State */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_state"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        State
      </Form.Label>
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <StateField
          id="contact_state"
          setFormData={setFormData}
          valueKey="contact_state"
          selectedCountry={selectedCountry?.contact_country?.value}
          validated={false}
          required={false}
          stateSelectedValue={formData?.contact_state}
          setStateAbreaviation={setFormData}
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "6px 10px",
            fontSize: "15px",
            color: "#333",
            height: "34px",
            width: "100%",
          }}
        />
      </div>
    </Form.Group>

    {/* Zip Code */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="contact_zipcode"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Zip code
      </Form.Label>
      <Form.Control
        type="text"
        id="contact_zipcode"
        name="contact_zipcode"
        onInput={handleInputChange}
        value={formData.contact_zipcode}
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "15px",
          color: "#333",
          height: "34px",
          width: "100%",
          maxWidth: "400px",
        }}
      />
      <Form.Control.Feedback type="invalid">
        Please Enter Zip Code.
      </Form.Control.Feedback>
    </Form.Group>

    {/* Held Positions */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="held_positions"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Held Positions
      </Form.Label>
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <MultiSelect
          id="held_positions"
          options={ProductModalData}
          value={selectedProducts}
          onChange={handleProductSelect}
          labelledBy="--Select One--"
          hasSelectAll={false}
          overrideStrings={{ selectSomeItems: "--Select One--" }}
          styles={{
            control: (provided) => ({
              ...provided,
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              fontSize: "15px",
              color: "#333",
              height: "34px",
              width: "100%",
            }),
          }}
        />
      </div>
    </Form.Group>

    {/* Training OptOut */}
    <Form.Group
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <Form.Label
        htmlFor="training_optout"
        style={{
          fontWeight: "bold",
          fontSize: "15px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Training OptOut
      </Form.Label>
      <div style={{ width: "100%", maxWidth: "400px" }}>
        <ToogleSwitch
          id="training_optout"
          switchKeyValue={formData?.training_optout}
          setSwitchValue={setSwitchValue}
          switchValue={switchValue}
          switchKey={"training_optout"}
        />
      </div>
    </Form.Group>
  </div>
</div>

 {/* Horizontal Line */}
 <div style={{ border: '1px solid #0C71C3', margin: '5px 0' }} />
 
<div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
<div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <AddContactPhoneFrom
              altTrainerForm={altTrainerForm1}
              setSubFormData={setAltTrainerForm1}
              increaseAlternative={increaseAlternative1}
              decreaseAlternative={decreaseAlternative1}
              handleInputChange={handleInputChange}
              allDropDowns={allDropDowns}
              formData={formData.main_contact_phone}
              formName={"main_contact_phone"}
              setFormData={setFormData}
              noBtns={true}
              setPhoneValidation={setPhoneValidation}
              phoneValidation={phoneValidation}
              setLoading={setLoading}
              sectionId={"edit_contact_phone"}
            />
            </div>
{console.log({formData})}
            <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <EditContactMultiEmailForm
              altTrainerForm={multiEmailFormCount}
              setSubFormData={setMultiEmailFormCount}
              increaseAlternative={MultiEmailFormIncrease}
              decreaseAlternative={MultiEmailFormDecrease}
              handleInputChange={handleInputChange}
              allDropDowns={allDropDowns}
              formData={formData.main_contact_email}
              formName={"main_contact_email"}
              setFormData={setFormData}
              sectionId={"edit_contact_email"}
              validated={validated}
            />
            </div>
            </div>

          </div>

          {/* message */}
          <div className="my-4">
            <MessageHandler
              status={FormMsg.type}
              msg={FormMsg.msg}
              HandleMessage={setFormMsg}
            />
          </div>

          <div className="row pb-3 py-5">
            <div className="col-12 content-flex-right">
              <button
                className="btn btn-danger text-uppercase"
                type="button"
                onClick={() => {
                  const is_active = 0;
                  handleDispatch(contactId, is_active);
                  navigate(-1);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
                {/* submit */}
              </button>
            </div>
          </div>
        </div>
      </Form>

      {/* Overlay */}
      {loading && (
        <div style={overlay}>
          <Spinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      )}

      <ContactModalPhone
        // open={openContactModal}
        open={listType === "phone" ? true : false}
        hanldeModal={setContactModal}
        emailDataList={altTrainerForm1}
        phoneDataList={altTrainerForm1.filter(
          (it) => it.account_main_contact_phone != ""
        )}
        dataType={listType}
        setSubFormData={setAltTrainerForm1}
        SaveForm={SaveForm}
        setDataType={setListType}
        setLoading={setLoading}
      />

      <ContactModalEmail
        open={listType === "email" ? true : false}
        hanldeModal={setContactModal}
        emailDataList={multiEmailFormCount}
        phoneDataList={multiEmailFormCount}
        dataType={listType}
        setSubFormData={setMultiEmailFormCount}
        SaveForm={SaveForm}
        setDataType={setListType}
        setLoading={setLoading}
      />
    </>
  );
};

export default EditContact;
