import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form, Overlay, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { BASE_API } from '../../services';
import _ from 'lodash';

const cache = new Map();

const GlobalDropdown = ({
  apiEndpoint,
  dropDownName = "select",
  parentKey = "",
  idKey = 'id',
  valueKey = 'value',
  isSearchable = false,
  initialSelectedValue = null,
  options = null,
  isMulti = false,
  placeholder = '--Select One--',
  customRender = null,
  groupBy = null,
  isAsync = false,
  pageSize = 20,
  onSelect,
  disabled = false,
  isCache = false,
  tokenKey = 'ross_token',
  isRequired = false,
  minSelection = 0,
  maxSelection = Infinity,
  customValidation = null,
  i18nPrefix = 'dropdown',
  shouldFetchOnOpen = false,
  className = "",
  defaultValue = {},
  selectDisabled = false,
  requiredMsg = "Please Select Brand.",
  style = {}
}) => {
 
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [validationError, setValidationError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const hasFetchedOnOpen = useRef(false);

  // Initialize with defaultValue or initialSelectedValue
  useEffect(() => {
    if (initialSelectedValue) {
      setSelectedValue(initialSelectedValue);
      if (shouldFetchOnOpen && !hasFetchedOnOpen.current) {
        fetchData(searchTerm, page);
        hasFetchedOnOpen.current = true;
      }
    } else if (!_.isEmpty(defaultValue) && defaultValue?.id && defaultValue?.value) {
      setSelectedValue(defaultValue.id);
      // Do not add defaultValue to data; it will be fetched from API
      if (shouldFetchOnOpen && !hasFetchedOnOpen.current) {
        fetchData(searchTerm, page);
        hasFetchedOnOpen.current = true;
      }
    } else {
      setSelectedValue('');
      if (!options) {
        setData([]);
      }
    }
  }, [initialSelectedValue, options, shouldFetchOnOpen]);

  const fetchData = useCallback(async (search = '', page = 1) => {
    if (options) {
      setData(options);
      return;
    }

    const cacheKey = `${apiEndpoint}-${search}-${page}-${parentKey}`;
    if (isCache && cache.has(cacheKey)) {
      setData(cache.get(cacheKey));
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
        throw new Error(t(`${i18nPrefix}.noToken`));
      }

      const response = await axios.get(`${BASE_API}${apiEndpoint}`, {
        params: { search, page, pageSize },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const resultData = parentKey ? response?.data?.data?.[parentKey] : response?.data?.data;
      if (resultData) {
        setData(resultData); // Only set the fetched data, do not add defaultValue
        if (isCache) {
          cache.set(cacheKey, resultData);
        }
      }
      setError(null);
    } catch (err) {
      setError(err.message || t(`${i18nPrefix}.fetchError`));
    } finally {
      setLoading(false);
    }
  }, [apiEndpoint, options, pageSize, isCache, tokenKey, t, i18nPrefix, parentKey]);

  useEffect(() => {
    if (!shouldFetchOnOpen && !options) {
      fetchData(searchTerm, page);
    }
  }, [shouldFetchOnOpen, apiEndpoint]);

  const debouncedSearch = useCallback(
    debounce((term) => {
      setSearchTerm(term);
      setPage(1);
      fetchData(term, 1);
    }, 300),
    [fetchData]
  );

  const handleChange = (event) => {
    const value = isMulti
      ? Array.from(event.target.selectedOptions, option => option.value)
      : event.target.value;
    setSelectedValue(value);
    onSelect(event, value);
    validateSelection(value);
  };

  const handleSearch = (event) => {
    debouncedSearch(event.target.value);
  };

  const handleClear = () => {
    setSelectedValue(isMulti ? [] : '');
    onSelect(isMulti ? [] : '');
    validateSelection(isMulti ? [] : '');
    setData([]);
  };

  const validateSelection = (value) => {
    if (isRequired && (!value || (Array.isArray(value) && value.length === 0))) {
      setValidationError(t(`${i18nPrefix}.required`));
    } else if (isMulti && value.length < minSelection) {
      setValidationError(t(`${i18nPrefix}.minSelection`, { count: minSelection }));
    } else if (isMulti && value.length > maxSelection) {
      setValidationError(t(`${i18nPrefix}.maxSelection`, { count: maxSelection }));
    } else if (customValidation) {
      const customError = customValidation(value);
      setValidationError(customError);
    } else {
      setValidationError(null);
    }
  };

  const renderOptions = () => {
    let renderedOptions = Array.isArray(data) ? data : [];

    if (groupBy) {
      const groups = renderedOptions.reduce((acc, item) => {
        const groupName = item[groupBy] || 'Ungrouped';
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(item);
        return acc;
      }, {});

      return Object.entries(groups).map(([groupName, groupItems]) => (
        <optgroup key={groupName} label={groupName}>
          {groupItems.map(item => (
            customRender ? customRender(item, idKey, valueKey) :
              <option key={item[idKey]} value={item[idKey]} disabled={item.disabled}>
                {item[valueKey]}
              </option>
          ))}
        </optgroup>
      ));
    }

    return renderedOptions.map(item => customRender ? customRender(item, idKey, valueKey) :
      <option key={item[idKey]} value={item[idKey]} disabled={item.disabled}>
        {item[valueKey]}
      </option>);
  };

  const handleFocus = () => {
    setIsOpen(true);
    if (shouldFetchOnOpen && !hasFetchedOnOpen.current) {
      fetchData(searchTerm, page);
      hasFetchedOnOpen.current = true;
    }
  };

  const handleBlur = () => {
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      {isSearchable && (
        <Form.Control
          type="text"
          placeholder={t(`Search`)}
          onChange={handleSearch}
          className="mb-2"
          style={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '14px',
            color: '#333',
            height: '34px',
            width: '100%',
          }}
        />
      )}
      <div className="d-block">
        <Form.Select
          name={dropDownName}
          value={selectedValue || ''}
          onChange={handleChange}
          multiple={isMulti}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`custom-global-dropdown ${className} flex-grow-1`}
          style={{
            border: '1px solid #0275d8',
            borderRadius: '4px',
            padding: '6px 10px',
            fontSize: '14px',
            height: isMulti ? 'auto' : '34px',
            width: '100%',
            // background: "url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"12\" viewBox=\"0 0 24 24\"><path fill=\"%23000\" d=\"M7 10l5 5 5-5z\"/></svg>') no-repeat right 10px center",
            backgroundSize: '12px',
            WebkitAppearance: 'none',
            MozAppearance: 'none',
            appearance: 'none',
            boxShadow: isOpen ? '0 0 5px rgba(0, 0, 0, 0.1)' : 'none',
            transition: 'box-shadow 0.3s ease',
            ...style
          }}
          required={isRequired}
        >
          <option value="" disabled={selectDisabled}>
            {t(`${placeholder}`)}
          </option>
          {loading ? <option value="" disabled>Loading...</option> : renderOptions()}
        </Form.Select>

        <Form.Control.Feedback
          type="invalid"
          style={{
            display: validationError ? 'block' : 'none',
            color: '#dc3545',
            fontSize: '12px',
            marginTop: '4px'
          }}
        >
          {requiredMsg}
        </Form.Control.Feedback>
      </div>
      {error && (
        <div
          style={{
            color: '#dc3545',
            fontSize: '12px',
            marginTop: '4px'
          }}
        >
          {error}
        </div>
      )}
      <Overlay target={dropdownRef.current} show={!!validationError} placement="bottom">
        {(props) => (
          <Tooltip
            id="validation-tooltip"
            {...props}
            style={{
              backgroundColor: '#dc3545',
              color: '#fff',
              padding: '4px 8px',
              borderRadius: '4px',
              fontSize: '12px'
            }}
          >
            {validationError}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default GlobalDropdown;