import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";

const EditAssignUserRoleModal = ({
  show,
  setShow,
  rowData,
  fetchCreateTblData,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentSites, setCurrentSites] = useState([]);
  const [allContactSites, setAllContactSites] = useState([]);
  const [formData, setFormData] = useState({
    account_id: "",
    contact_id: "",
    role_id: [],
    site_id: [],
    role_type: "",
  });
  const [isInvalid, setIsInvalid] = useState({
    role_id: false,
    site_id: false,
  });

  useEffect(() => {
    if (rowData) {
      const preSelectedRoles = rowData.roles.map((role) => ({
        label: role.role_name,
        value: role.role_id,
      }));
      setFormData({
        account_id: rowData.account_name,
        contact_id: rowData.contactName,
        role_id: preSelectedRoles,
        site_id: [],
        role_type: rowData.roles[0]?.role_type || "",
      });
      getAllSitesByContactId(rowData.contact_id, rowData.account_id);
    }
  }, [rowData]);

  const getAllSitesByContactId = async (id, account_id) => {
    try {
      const accountSiteRes = await CallGETAPI(
        `site/account-site-list/${account_id}`
      );
      if (accountSiteRes.data.status) {
        const accountSites = accountSiteRes.data.data.site_details;
        const contactSiteRes = await CallGETAPI(
          `permission/account-site-list-by-contact/${id}?search=&page=1&pageSize=50`
        );
        if (contactSiteRes.data.status) {
          const currentSitesData = contactSiteRes.data.data.site_details || [];
          const allSites = accountSites.map((item) => ({
            label: item.account_site_name,
            value: item.account_site_info_id,
          }));
          const siteIdArray = rowData.assigned_site.split(",").map(Number);
          const selectedSites = allSites.filter((item) =>
            siteIdArray.includes(item.value)
          );
          setCurrentSites(selectedSites);
          setAllContactSites(allSites);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    if (formData.role_id.length === 0) {
      setIsInvalid({ ...isInvalid, role_id: true });
      return;
    }
    if (formData.role_type === "site_level" && currentSites.length === 0) {
      setIsInvalid({ ...isInvalid, site_id: true });
      return;
    }

    setLoading(true);
    const finalRoles = formData.role_id.map((role) => role.value).join(",");
    const finalSites = currentSites.map((site) => site.value).join(",");
    const body = { ...formData, role_id: finalRoles, site_id: finalSites };
    const res = await CallPOSTAPI(
      `permission/update-permission-user/${rowData.contact_id}`,
      body
    );
    if (res?.status) {
      toast.success("Role Updated Successfully");
      fetchCreateTblData();
      setShow(false);
      setCurrentSites([]);
    }
    setLoading(false);
  };

  const handleRoleChange = (e) => {
    const selectedRoles = e.target.formatted.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    setFormData((prev) => ({
      ...prev,
      role_id: selectedRoles,
    }));
    setIsInvalid({ ...isInvalid, role_id: false });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Assign Role to User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleModalSubmit}>
          <div className="my-4" style={{ display: "flex", gap: "2%" }}>
            <Form.Group style={{ width: "200px" }}>
              <Form.Label>Select Role*</Form.Label>
              <PaginateAutoComplete
                dropDownName="role_id"
                apiEndpoint={"permission/get-role"}
                idKey={"role_id"}
                valueKey={"role_name"}
                parentKey={"role"}
                onSelect={handleRoleChange}
                placeholder="-- Select --"
                showCountsOnly={1}
                multiple={true}
                showCheckBox={true}
                shouldFetchOnOpen={false}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
                filterDataKey={formData?.role_type}
                getResults={(res) => {
                  const newData = res?.data?.data?.role || [];
                  const result = newData.filter(
                    (role) => role.is_admin_role === 0
                  );
                  return [result, result.length];
                }}
                defaultValue={
                  formData.role_id.length > 0
                    ? formData.role_id.map((role) => ({
                        role_id: role.value,
                        role_name: role.label,
                      }))
                    : []
                }
              />
              {isInvalid.role_id && (
                <Form.Control.Feedback
                  type="invalid"
                  className="text-danger mt-1"
                >
                  Please Select Role.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group style={{ width: "180px" }}>
              <Form.Label>Select Account</Form.Label>
              <Form.Control
                type="text"
                value={formData.account_id}
                disabled={true}
              />
            </Form.Group>

            <Form.Group style={{ width: "180px" }}>
              <Form.Label>Select Contact</Form.Label>
              <Form.Control
                type="text"
                value={formData.contact_id}
                disabled={true}
              />
            </Form.Group>

            {formData.role_type === "site_level" && (
              <Form.Group style={{ width: "180px" }}>
                <Form.Label>Select Site</Form.Label>
                <MultiSelect
                  valueRenderer={() =>
                    currentSites.length >= 6
                      ? `${currentSites.length} Selected`
                      : currentSites.map((item) => item.label).join(", ")
                  }
                  options={allContactSites}
                  value={currentSites}
                  onChange={(e) => {
                    setCurrentSites(e);
                    setFormData({ ...formData, site_id: e });
                    setIsInvalid({ ...isInvalid, site_id: false });
                  }}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
                {isInvalid.site_id && (
                  <Form.Control.Feedback
                    type="invalid"
                    className="text-danger mt-1"
                  >
                    Please Select Sites.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleModalSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAssignUserRoleModal;
