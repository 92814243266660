import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { usePagination, useSortBy, useTable } from "react-table";
import { BASE_API } from "../../services";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Skeleton } from "@mui/material";
import { Button, Form } from "react-bootstrap";
import New from "../../img/New.png";
import { useNavigate } from "react-router-dom";
import { formatPhoneNumber } from "../../helper";

// Cache for API data
const cache = new Map();

// Truncate text function
const truncateText = (text, maxLength) => {
  if (typeof text !== "string") return "";
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + "...";
};

const NewGlobalTable = ({
  tableData = [],
  payloadData = null,
  apiEndPoint,
  keyAPiName = null,
  // pageSize = 50,
  tokenKey = "ross_token",
  headerColumns = [],
  method = "POST",
  isSearchable = false,
  isCache = false,
  onDataFetched,
  customRenderData = null,
  isTblUpdated = false,
  title = "",
  newButtonPath = "",
  newButtonState = {},
  isPagination = false,
  breadCrumbOnclick = null,
  siteTraningData,
}) => {
  const prevPayloadData = useRef(payloadData);
  const prevApiEndPoint = useRef(apiEndPoint);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [showReset, setShowReset] = useState(false);
  const navigate = useNavigate();

  const calculateWidth = (header) => `${header.length * 15 + 20}px`;

  const columns = useMemo(() => {
    return headerColumns.map((column) => ({
      ...column,
      width: column.width || calculateWidth(column.Header),
    }));
  }, [headerColumns]);

  useEffect(() => {
    if (isCache || apiEndPoint !== prevApiEndPoint.current) {
      cache.clear();
      prevApiEndPoint.current = apiEndPoint;
    }
  }, [isCache, apiEndPoint]);

  const fetchData = useCallback(
    async (searchTerm = "", page = 1, pageSize = recordsPerPage) => {
      if (apiEndPoint !== prevApiEndPoint.current) {
        cache.clear();
        prevApiEndPoint.current = apiEndPoint;
      }

      const cacheKey = `${apiEndPoint}-${searchTerm}-${page}-${JSON.stringify(
        prevPayloadData.current
      )}-${pageSize}`;
      if (isCache && cache.has(cacheKey)) {
        const cachedData = cache.get(cacheKey);
        setData(cachedData.data);
        setTotalPages(cachedData.totalPages);
        setTotalRecords(cachedData.totalRecords);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      setError("");
      try {
        const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
        const token = isUserContext
          ? sessionStorage.getItem(tokenKey)
          : localStorage.getItem(tokenKey);
        if (!token) throw new Error("No Token found");

        let payload = {
          ...prevPayloadData.current,
          page,
          search: searchTerm,
          pageSize: pageSize,
        };

        let options = {
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        if (method === "POST" && payload) {
          options.body = JSON.stringify(payload);
        }
        let SearchQuery = "";
        if (method === "GET" && searchTerm) {
          SearchQuery = `&search=` + searchTerm;
        }

        let response = await fetch(
          `${BASE_API}${apiEndPoint}?page=${page}&pageSize=${pageSize}${SearchQuery}`,
          options
        );
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const resultData = await response.json();

        let newData = keyAPiName
          ? resultData?.data?.[keyAPiName] || []
          : resultData?.data;
        let totalPages = resultData?.data?.totalPages || 0;
        let totalRecords = resultData?.data?.totalCount || 0;

        if (customRenderData) {
          newData = await customRenderData(newData);
        }

        if (newData || totalPages === 0) {
          setData(newData);
          setTotalPages(totalPages);
          setTotalRecords(totalRecords);
          if (isCache) {
            cache.set(cacheKey, { data: newData, totalPages, totalRecords });
          }
        }

        if (onDataFetched) {
          onDataFetched(newData);
        }
      } catch (error) {
        setError(error.message);
        setData([]);
      } finally {
        setIsLoading(false);
      }
    },
    [
      apiEndPoint,
      tokenKey,
      keyAPiName,
      method,
      isCache,
      prevPayloadData.current,
      onDataFetched,
      customRenderData,
    ]
  );

  useEffect(() => {
    if (
      JSON.stringify(prevPayloadData.current) !== JSON.stringify(payloadData)
    ) {
      prevPayloadData.current = payloadData;
      setCurrentPage(1);
    }
    fetchData(search.trimEnd(), currentPage, recordsPerPage);
  }, [payloadData, currentPage, fetchData, recordsPerPage]);

  useEffect(() => {
    if (isTblUpdated) {
      cache.clear();
      setSearch("");
      setCurrentPage(1);
      setShowReset(false);
      fetchData("", 1, recordsPerPage);
    }
  }, [isTblUpdated, fetchData, recordsPerPage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize }, // Yeh add karo
    setPageSize, // Yeh bhi add karo
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { pageIndex: 0, pageSize: recordsPerPage },
      manualPagination: true, // Yeh zaroori hai
      pageCount: totalPages, // Total pages API se
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setPageSize(recordsPerPage);
  }, [recordsPerPage, setPageSize]);

  const handleRecordsPerPageChange = (e) => {
    const newSize = Number(e.target.value);
    setRecordsPerPage(newSize);
    setCurrentPage(1);
    fetchData(search.trimEnd(), 1, newSize);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (search.trim()) {
      setShowReset(true);
    }
    setCurrentPage(1);
    fetchData(search.trimEnd(), 1, recordsPerPage);
  };

  const handleReset = () => {
    setSearch("");
    setShowReset(false);
    setCurrentPage(1);
    fetchData("", 1, recordsPerPage);
  };

  // console.log({ newButtonPath })


  return (
    <div className="base-table" style={{ borderRadius: "3px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >

        {isSearchable && (
          <Form onSubmit={handleSearch}>
            <div className="" style={{ display: "flex", gap: 5 }}>
              <input
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search by keywords..."
                className="form-control w-550"
              />
              <Button variant="primary" type="submit">
                Search
              </Button>

              {showReset && (
                <Button onClick={handleReset} variant="danger" type="button">
                  Reset
                </Button>
              )}
            </div>
          </Form>
        )}
        {
          isPagination &&
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Total Records: {totalRecords.toLocaleString()}</span>
            <select
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              style={{
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <option value={50}>50 Records Per Page</option>
              <option value={100}>100 Records Per Page</option>
              <option value={200}>200 Records Per Page</option>
              <option value={250}>250 Records Per Page</option>
              <option value={500}>500 Records Per Page</option>
            </select>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{
                padding: "5px 10px",
                border: "1px solid #ccc",
                background: "white",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
            >
              <NavigateBefore fontSize="small" />
            </button>
            <span>{currentPage}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{
                padding: "5px 10px",
                border: "1px solid #ccc",
                background: "white",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              <NavigateNext fontSize="small" />
            </button>
          </div>
        }
      </div>

      {error && (
        <div
          style={{
            color: "red",
            textAlign: "center",
            padding: "10px",
            backgroundColor: "#ffe6e6",
          }}
        >
          {error}
        </div>
      )}

      <div
        style={{
          border: "2px solid #00578E",
          borderRadius: "5px",
          padding: "15px",
          backgroundColor: "white",
          width: "100%",
          marginBottom: "30px"
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div
            style={{ display: "flex", width: "50%", justifyContent: "start" }}
          >
            {title && <h1 style={{ fontWeight: "600", color: "#0C71C3", fontSize: "28px", paddingLeft: "10px" }}>{title}</h1>}
          </div>
          <div
            style={{ display: "flex", width: "50%", justifyContent: "end" }}
          >
            {newButtonPath &&
              <button className="bg-transparent new-btn align-right"
                onClick={() => {
                  navigate(newButtonPath, {
                    state: newButtonState
                  })
                  if (breadCrumbOnclick) {
                    breadCrumbOnclick();
                  }
                }}
              >
                <img src={New} style={{ marginRight: "5px" }} />
                New</button>}
          </div>
        </div>
        <div className="table-responsive" style={{ overflowX: "auto" }}>
          <table
            {...getTableProps()}
            style={{
              width: "100%",
              border: "none",
              fontFamily: "Arial, sans-serif",
            }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  style={{
                    paddingTop: "5px",
                    marginTop: "5px",
                    borderTop: "2px solid #ddd",
                  }}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        padding: "10px",
                        backgroundColor: "#ffffff",
                        borderBottom: "1px solid #ddd",
                        textAlign: "left",
                        width: column.width,
                        color: "#b6b6b6",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{column.render("Header")}</span>
                        {!column.disableSortBy && (
                          <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                            {column.isSorted ? (
                              column.isSortedDesc ?  "▲" : "▼" 
                            ) : "▼"}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {siteTraningData &&
                <tr>
                  <td className="py-1 px-2 tbl-border ">
                    {isLoading ? (
                      <>
                        <Skeleton
                        // count={5}
                        // width={"90%"}
                        // height={20}
                        // style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      </>
                    ) : (
                      <>{siteTraningData?.account_site_name}</>
                    )}
                  </td>
                  <td className="py-1 px-2 tbl-border ">
                    {isLoading ? (
                      <>
                        <Skeleton
                        // count={5}
                        // width={"90%"}
                        // height={20}
                        // style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      </>
                    ) : (
                      <>
                        {siteTraningData?.account_site_phone && (
                          <a
                            className="link"
                            style={{ textDecoration: "none" }}
                            href={
                              "tel:" +
                              siteTraningData?.account_site_phone_ext +
                              siteTraningData?.account_site_phone
                            }
                          >
                            {siteTraningData?.account_site_phone
                              ? formatPhoneNumber(siteTraningData.account_site_phone)
                              : ""}{" "}
                            {siteTraningData?.account_site_phone_ext
                              ? " X " + siteTraningData?.account_site_phone_ext
                              : ""}
                          </a>
                        )}
                      </>
                    )}
                  </td>
                  <td className="py-1 px-2 tbl-border ">
                    {isLoading ? (
                      <>
                        <Skeleton
                        // count={5}
                        // width={"90%"}
                        // height={20}
                        // style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      </>
                    ) : (
                      <>
                        {`${siteTraningData?.building_name ?? ""} ${siteTraningData?.account_site_address1 ?? ""}, `}
                        {siteTraningData?.account_site_address2 ? `${siteTraningData?.account_site_address2}, ` : ""}
                        {siteTraningData?.account_site_city ? `${siteTraningData?.account_site_city}, ` : ""}
                        {`${siteTraningData?.account_site_state ?? ""} ${siteTraningData?.account_site_zipcode ?? ""}, `}
                        {siteTraningData?.account_site_country ?? ""}
                        {/* {siteData?.account_site_address1}{" "}
                              {siteData?.account_site_address2} {siteData?.account_site_city}{" "}
                              {siteData?.account_site_state} {siteData?.account_site_country}{" "}
                              {siteData?.account_site_zipcode} */}
                      </>
                    )}
                  </td>
                </tr>
              }
              {isLoading ? (
                [...Array(15)].map((_, i) => (
                  <tr key={i}>
                    {columns.map((col, j) => (
                      <td
                        key={j}
                        style={{
                          padding: "10px",
                          borderBottom: i === 14 ? "none" : "1px solid #ddd",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <Skeleton
                          width={"90%"}
                          height={20}
                          style={{ margin: "0 auto" }}
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : !siteTraningData && data?.length === 0 && !isLoading ? (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontStyle: "italic",
                      color: "grey",
                      backgroundColor: "#ffffff",
                      borderBottom: "none",
                    }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                page?.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            padding: "10px",
                            borderBottom:
                              i === page.length - 1 ? "none" : "1px solid #ddd",
                            backgroundColor: "#ffffff",
                            textAlign: "left", // Default to left alignment, override in headerColumns if needed
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
};

export default NewGlobalTable;