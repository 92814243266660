import React from "react";
import { Skeleton } from "@mui/material";

const AedTableSkeleton = ({ applyPadding = true }) => {
  return (
    <div
      className="mt-4 table-main-20002"
      style={{ width: "100%", paddingInline: applyPadding ? "0%" : "0%" }}
    >
      <h2
        className="heading"
        style={{ display: "flex", alignItems: "center", width: "20%" }}
      >
        Serial #:
        <Skeleton variant="text" width={150} height={43} className="mx-auto" />
      </h2>
      <table className="theme-table">
        <thead>
          <tr>
            <th className="border border-r-blue" colSpan={2}>
              Type
            </th>
            <th className="border border-r-blue" colSpan={2}>
              Brand / Model
            </th>
            <th className="border border-r-blue" colSpan={3}>
              Serial #
            </th>
            <th className="border border-r-blue" colSpan={3}>
              Placement
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={3}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={3}>
              <Skeleton variant="text" width="100%" />
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th className="border border-r-blue no-capitalization" colSpan={2}>
              Present / Ready
            </th>
            <th className="border border-r-blue no-capitalization" colSpan={2}>
              Replaced rescue kit
            </th>
            <th className="border border-r-blue no-capitalization" colSpan={2}>
              Replaced alarm battery
            </th>
            <th className="border border-r-blue no-capitalization" colSpan={2}>
              Replaced Accessories
            </th>
            <th className="border border-r-blue no-capitalization" colSpan={2}>
              Support Ticket
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
            <td colSpan={2}>
              <Skeleton variant="text" width="100%" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AedTableSkeleton;
