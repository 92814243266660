import React from "react";
import { useEffect, useState } from "react";
import { AedTabs } from "../../../../common/hooks/fieldsConfig";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AED_IMG_BASE, CallGETAPI } from "../../../../common/services/index";
import {
  AEDGroupBYCoordinatorInfo,
  DecryptToken,
  GetAedGeneralInformation,
  GetAedStorageInformation,
  GetAedBatteryInformation,
  GetAedPadInformation,
  GetAedCoordinatorInformation,
  getPer,
} from "../../../../common/helper/BasicFn";
import Box from "@mui/material/Box";
import Details from "../components/tabs/Details";
import SubHeadingOther from "../../AedDetails/components/header/SubHeadingOther";
import { useDispatch, useSelector } from "react-redux";
import AEDChecksTbl from "../components/tabs/AEDChecksTbl";
import { EquipmentIcon } from "../../../../common/helper/icons";
import AedServicing from "../components/aedService/AEDServicing";
import OutOfServiceModal from "../OutofService/model/OutOfServiceModal";
import OutOfServiceSecondModal from "../OutofService/model/OutOfServiceSecondModal";
import { setEditAedData } from "../../../../store/slices/AEDSlice";
import {
  addNewBreadcrumbs,
  handleBreadcrumbsClick,
} from "../../../../store/slices/breadcrumbsSlice";
import SupportSymbol from "../../../Support/Component/SupportSymbol";
import Support from "../../../Support/Component/SupportTab/Support";
import { isPermission } from "../../../../common/helper/PermissionManager";
import Notes from "../../../Notes/Component/NoteTab/Notes";
import Documents from "../../../Documnents/Pages/Documents";

const 
AedMain = ({ setShowSidebar }) => {
  const { tab } = useParams();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const currentTab = AedTabs?.[tab] || "Details";

  const location = useLocation();
  const navigate = useNavigate();

  const [aedData, setAEDData] = useState({});
  const [cordinatorInfo, setCordinatorInfo] = useState([]);
  const { aedId } = useParams();

  const [aedList, setAedList] = useState([]);
  const [loanerList, setLoanerList] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const [showOutOfServiceModal, setShowOutOfServiceModal] = useState(false);
  const [outofServiceSecond, setOutofServiceSecond] = useState(false);
  const [outofServiceFormData, setOutofServiceFormData] = useState({});
  const [outOfServiceLoding, setoutOfServiceLoding] = useState(false);

  const user = DecryptToken();
  const [loading, setLoading] = useState(false);
  const [aedGeneralInfo, setAedGeneralInfo] = useState();
  const [aedStorageInfo, setAedStorageInfo] = useState();
  const [aedBatteryInfo, setAedBatteryInfo] = useState();
  const [aedCoordinatorInfo, setAedCoordinatorInfo] = useState();
  const [aedPadInfo, setAedPadInfo] = useState();
  const [chargePakInfo, setChargePakInfo] = useState();
  const [assignedData, setAssignedData] = useState({});
  const [showAllLoading, setShowAllLoading] = useState({
    showAedGeneralInfo: true,
    showAedPadInfo: true,
    showAedStorageInfo: true,
    showAedBatteryInfo: true,
    showAedCoordinatorInfo: true,
  });

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI(`aed/aed-checkers-list/${aedId}`);
    const response = result?.data;

    if (response?.status)
      setAssignedData(response?.data?.resultData?.assign_user);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const getAedGeneralInformation = async () => {
    setLoading(true);

    const result = await GetAedGeneralInformation(aedId);
    if (result?.status) {
      const modelCondition = result?.data?.aedModels;
      let aedDetails = {
        account_name: result?.data?.account_name,
        site_name: result?.data?.site_name,
        is_technician: result?.data?.is_technician,
        gateway_lifespan: result?.data?.aedModels?.gateway_lifespan,
        ...result?.data?.aed,
      };
      const aedGeneralInformationSectionData = result?.data?.aed || {};
      aedDetails.modelCondition = modelCondition;
      aedDetails.pediatric_key_child =
        modelCondition?.has_ped_key ||
        modelCondition?.has_ped_pad ||
        modelCondition?.has_pedpak;
      aedDetails.pediatric_key_icon =
        aedGeneralInformationSectionData?.pediatric_key;

      aedDetails.builtin_RMS_info = JSON.parse(
        aedDetails?.builtin_RMS_info || "[]"
      );
      aedDetails.builtin_RMS_info =
        typeof aedDetails.builtin_RMS_info === "string"
          ? JSON.parse(aedDetails.builtin_RMS_info)
          : aedDetails.builtin_RMS_info;

      aedDetails.charge_pak_info = JSON.parse(
        aedDetails?.charge_pak_info || "[]"
      );

      aedDetails.rms_info = JSON.parse(aedDetails?.rms_info || "[]");
      aedDetails.rms_info = aedDetails.rms_info
        ? typeof aedDetails.rms_info === "string"
          ? JSON.parse(aedDetails.rms_info)
          : aedDetails.rms_info
        : "";

      aedDetails.out_of_service_info = JSON.parse(
        aedDetails?.out_of_service_info || "[]"
      );

      aedDetails.other = JSON.parse(aedDetails?.other || "[]");

      aedDetails.gateway_info = JSON.parse(aedDetails?.gateway_info || "[]");
      aedDetails.gateway_info =
        typeof aedDetails.gateway_info === "string"
          ? JSON.parse(aedDetails.gateway_info)
          : aedDetails.gateway_info;

      setAedGeneralInfo(aedDetails);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedGeneralInfo: false,
    }));
    setLoading(false);
    setoutOfServiceLoding(false);
  };

  const getAedStorageInformation = async () => {
    const result = await GetAedStorageInformation(aedId);

    if (result?.status) {
      setAedStorageInfo(result?.data?.storageInfo || []);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedStorageInfo: false,
    }));
  };
  const getAedBatteryInformation = async () => {
    const result = await GetAedBatteryInformation(aedId);

    if (result?.status) {
      const allBatteryData = result?.data?.batteryInfo || [];

      setAedBatteryInfo(allBatteryData);

      const ar1 = [];
      const SPar1 = [];
      for (let ci = 0; ci < allBatteryData?.length; ci++) {
        const ell = allBatteryData[ci];
        if (ell.section_name === "charge_pack") {
          const ChargePakInfoObj = {
            battery_expiration: ell?.battery_expiration,
            battery_lot: ell?.battery_lot,
            charge_pak_part: ell?.battery_type_id,
            charge_pak_uiid: ell?.charge_pak_uid,
            charge_pak_uid: ell?.charge_pak_uid,
            pad_1_expiration: "",
            pad_1_lot: "",
            pad_1_part: "",
            pad_2_expiration: "",
            pad_2_lot: "",
            pad_2_part: "",
          };
          if (ell.is_spare) {
            SPar1.push(ChargePakInfoObj);
          } else {
            ar1.push(ChargePakInfoObj);
          }
        }
      }
      setChargePakInfo({ charge_pak_info: ar1, spare_charge_pak_info: SPar1 });
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedBatteryInfo: false,
    }));
  };
  const getAedCoordinatorInformation = async () => {
    const result = await GetAedCoordinatorInformation(aedId);

    if (result?.status) {
      let CoordiData = AEDGroupBYCoordinatorInfo(
        result?.data?.cordinatorInformation
      );
      setAedCoordinatorInfo(CoordiData);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedCoordinatorInfo: false,
    }));
  };

  const getAedPadInformation = async () => {
    const result = await GetAedPadInformation(aedId);

    if (result?.status) {
      setAedPadInfo(result?.data?.allPads);
    }
    setShowAllLoading((prev) => ({
      ...prev,
      showAedPadInfo: false,
    }));
  };

  useEffect(() => {
    getAedGeneralInformation();
    getAedStorageInformation();
    getAedBatteryInformation();
    getAedCoordinatorInformation();
    getAedPadInformation();
  }, []);

  useEffect(() => {
    getAedGeneralInformation();
  }, [outOfServiceLoding]);

  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );

  const BreadCrumbsObject = {
    title: "",
    type: "",
    tab: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };
  const handleDispatch = (item, tab) => {
    BreadCrumbsObject.tab = tab;
    BreadCrumbsObject.title = tab;
    BreadCrumbsObject.type = "aed";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed-details/" + aedId + "/" + item,
      })
    );
  };
  const handleEditDispatch = (aedId, site_id) => {
    BreadCrumbsObject.title = "Aed Details Edit";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed/edit/" + aedId + "/" + site_id,
      })
    );
  };
  const handleAedCheckDispatched = (account_id) => {
    // BreadCrumbsObject.title = "Aed Check Form";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        title: "Aed Check Form",
        url:
          "/account/aed-details/check-select?aed_id=" +
          aedId +
          "&account_id=" +
          account_id,
      })
    );
  };
  const handleServiceCheckDispatch = (account_id) => {
    BreadCrumbsObject.title = "Service Check";
    BreadCrumbsObject.account_name = aedGeneralInfo?.account_name;
    BreadCrumbsObject.accountId = account_id;
    BreadCrumbsObject.type = "";
    BreadCrumbsObject.tab = "";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed/service-check/service1/${account_id}/${
          aedGeneralInfo?.site_id
        }/aedId?aedId=${encodeURIComponent(aedId)}`,
      })
    );
  };
  const handleAssignedAedDispatch = (account_id, siteId) => {
    BreadCrumbsObject.title = "Assigned Aed Listing";
    BreadCrumbsObject.account_name = aedGeneralInfo?.account_name;
    BreadCrumbsObject.accountId = account_id;
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/assign-quipment/" + account_id + "/" + siteId,
      })
    );
  };

  const handleTab = (item, tab) => {
    if (item === "Details") {
      BreadCrumbsObject.tab = item;
      const accountName = aedGeneralInfo.serial_number;
      dispatch(
        handleBreadcrumbsClick({
          accountName,
          breadcrumbsFromStore,
          tab: tab,
          type: "aed",
        })
      );
      navigate("/account/aed-details/" + aedId + "/Details");
    } else {
      BreadCrumbsObject.title = AedTabs[item];
      BreadCrumbsObject.account_name = aedGeneralInfo?.account_name;
      BreadCrumbsObject.accountId = aedGeneralInfo?.account_id;
      handleDispatch(item, tab);
      navigate("/account/aed-details/" + aedId + "/" + item);
    }
  };

  const notesRedirect = () => {
    navigate(
      `/account/new-note?account_id=${aedData?.account_id}&aed_id=${aedId}`
    );
  };
  const handleAssignEqupment = (e) => {
    let siteId = aedGeneralInfo?.site_id;
    const AEDID = aedId;
    let equipmentUrl =
      "/assign-quipment/" + aedGeneralInfo?.account_id + "/" + siteId;
    navigate(equipmentUrl, {
      state: {
        aedData: aedGeneralInfo,
        redirectPath: `account/aed-details/${aedId}/Details`,
      },
    });
  };

  const getAllAeds = async () => {
    // const result = await CallGETAPI("account/get-aed/" + aedData?.account_id);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + aedData?.account_id
    );
    if (result?.data?.status) {
      let aeds = result?.data?.data || [];
      const pendingaeds = result?.data?.pendingData;
      let newArr = [];

      if (Array.isArray(aeds) && pendingaeds.length > 0) {
        newArr = [...pendingaeds, ...aeds];
      } else {
        newArr = aeds;
      }
      let currentList = [];

      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < newArr[i].data.length; j++) {
          currentList.push(newArr[i].data[j]);
        }
      }
      // aeds = newArr;
      setAedList(currentList);
    }
  };

  console.log({ aedGeneralInfo });
  const handleServiceCheck = () => {
    navigate(
      `/account/aed/service-check/service1/${aedGeneralInfo?.account_id}/${
        aedGeneralInfo?.site_id
      }/aedId?aedId=${encodeURIComponent(aedId)}`,
      {
        state: {
          navigateAedDetails: true,
          aedData: aedGeneralInfo,
        },
      }
    );
  };

  const breadcrumbs = useSelector((state) => state.BreadCrumbs.items);
  useEffect(() => {
    const aedDetails = {
      aedGeneralInfo,
      aedStorageInfo,
      aedBatteryInfo,
      aedCoordinatorInfo,
      aedPadInfo,
      chargePakInfo,
    };
    dispatch(setEditAedData(aedDetails));
  }, [
    aedGeneralInfo,
    aedStorageInfo,
    aedBatteryInfo,
    aedCoordinatorInfo,
    aedPadInfo,
    chargePakInfo,
  ]);

  const renderOutOfServiceInfo = (aedGeneralInfo) => {
    if (!aedGeneralInfo?.out_of_service_info) {
      return null;
    }

    const serviceInfo =
      typeof aedGeneralInfo.out_of_service_info === "string"
        ? JSON.parse(aedGeneralInfo.out_of_service_info)
        : aedGeneralInfo.out_of_service_info;

    // If empty or no first item, return early
    if (!serviceInfo || !serviceInfo[0]) {
      return null;
    }

    const { replaced_serial_name, loaner_rental_serial_name } = serviceInfo[0];

    return (
      <>
        {replaced_serial_name && (
          <span style={{ color: "#dc3545" }}>
            {`"${replaced_serial_name}"`}
          </span>
        )}
        {loaner_rental_serial_name && (
          <span style={{ color: "#dc3545" }}>
            {`"${loaner_rental_serial_name}"`}
          </span>
        )}
      </>
    );
  };
  return (
    <>
      <div className="mt-4" style={{ width: "100%" }}>
        {/* top heading */}
        <SubHeadingOther
          title={
            <span>
              {"AED: " + (aedGeneralInfo?.serial_number || "") + " "}
              {/* {aedGeneralInfo?.out_of_service_info ? (
                <>
                  {aedGeneralInfo?.out_of_service_info != "" &&
                    (Array.isArray(aedGeneralInfo?.out_of_service_info)
                      ? aedGeneralInfo?.out_of_service_info?.[0]?.replaced_serial_name
                      : JSON.parse(aedGeneralInfo?.out_of_service_info)?.[0]?.replaced_serial_name) && (
                      <span style={{ color: "#dc3545" }}>
                        {'"' +
                        Array.isArray(aedGeneralInfo?.out_of_service_info)
                          ? aedGeneralInfo?.out_of_service_info?.[0]?.replaced_serial_name
                          : JSON.parse(aedGeneralInfo?.out_of_service_info)?.[0]?.replaced_serial_name + '"'}
                      </span>
                    )}
                  {aedGeneralInfo?.out_of_service_info != "" &&
                    Array.isArray(aedGeneralInfo?.out_of_service_info)
                    ? aedGeneralInfo?.out_of_service_info[0]
                      ?.loaner_rental_serial_name
                    : JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                      ?.loaner_rental_serial_name && (
                      <span style={{ color: "#dc3545" }}>
                        {'"' +
                          Array.isArray(aedGeneralInfo?.out_of_service_info)
                          ? aedGeneralInfo?.out_of_service_info[0]
                            ?.loaner_rental_serial_name
                          : JSON.parse(aedGeneralInfo?.out_of_service_info)[0]
                            ?.loaner_rental_serial_name + '"'}
                      </span>
                    )}
                </>
              ) : (
                ""
              )} */}
              {renderOutOfServiceInfo(aedGeneralInfo)}
            </span>
          }
          hideNew={true}
          hideHierarchy={true}
          hideInstructor={true}
          subHeading={true}
          bottomLinks={false}
          account={5}
          editUrl={false}
          assign_equipment={true}
          breadcrumbs={breadcrumbs}
        />

        {/* bottom buttons */}
        <div className="d-flex" style={{ justifyContent: "space-between" }}>
          <div className="d-flex">
            {Number(isPermission({ type: "btn", name: "editAed" })) === 1 ? (
              <>
                <button
                  className="btn text-primary no-border"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleEditDispatch(aedId, aedGeneralInfo?.site_id);
                    navigate(
                      "/account/aed/edit/" +
                        aedId +
                        "/" +
                        aedGeneralInfo?.site_id,
                      {
                        state: {
                          selectedAccount: {
                            accountId: aedGeneralInfo?.account_id,
                            accountName: aedGeneralInfo?.account_name,
                          },
                          selectedSite: {
                            siteId: aedGeneralInfo?.site_id,
                            siteName: aedGeneralInfo?.site_name,
                          },
                          detailsData: {
                            aedGeneralInfo: aedGeneralInfo,
                            aedStorageInfo: aedStorageInfo,
                            aedBatteryInfo: aedBatteryInfo,
                            aedCoordinatorInfo: aedCoordinatorInfo,
                            aedPadInfo: aedPadInfo,
                            chargePakInfo: chargePakInfo,
                          },
                        },
                      }
                    );
                  }}
                >
                  <img
                    src="/assets/images/edit.svg"
                    alt="Edit"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-1">Edit </span>
                </button>
              </>
            ) : (
              ""
            )}

            {Number(isPermission({ type: "btn", name: "outOfService" })) ===
            1 ? (
              <>
                <button
                  className="btn text-primary no-border"
                  disabled={loading}
                  type="button"
                  onClick={() => {
                    setShowOutOfServiceModal(true);
                  }}
                >
                  <img
                    src="/assets/images/outofservice.svg"
                    alt="outofservice"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-1">Out of Service</span>
                </button>
              </>
            ) : (
              ""
            )}

            {(Number(isPermission({ type: "btn", name: "newAedCheck" })) ===
              1 ||
              user?.assign_aed) &&
            Number(aedGeneralInfo?.site_id) !== 0 &&
            Number(aedGeneralInfo?.out_of_service_toggle) !== 1
              ? aedGeneralInfo?.ready_status != "out of service" && (
                  <button
                    className="btn text-primary no-border"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      handleAedCheckDispatched(aedGeneralInfo?.account_id);
                      navigate(
                        "/account/aed-details/check-select?aed_id=" +
                          aedId +
                          "&account_id=" +
                          aedGeneralInfo?.account_id
                      );
                    }}
                  >
                    <img
                      src="/assets/images/aedcheck.svg"
                      alt="aedcheck"
                      style={{ marginRight: "5px" }}
                    />
                    <span className="ms-1">AED Check</span>
                  </button>
                )
              : ""}

            {aedGeneralInfo?.is_technician &&
              (Number(
                isPermission({ type: "btn", name: "newServiceCheck" })
              ) === 1
                ? aedGeneralInfo?.ready_status != "out of service" && (
                    <button
                      className="btn text-primary no-border"
                      type="button"
                      disabled={loading}
                      onClick={() => {
                        handleServiceCheckDispatch(aedGeneralInfo?.account_id);
                        handleServiceCheck();
                      }}
                    >
                      <img
                        src="/assets/images/servicecheck.svg"
                        alt="servicecheck"
                        style={{ marginRight: "5px" }}
                      />
                      <span className="ms-1">Service Check</span>
                    </button>
                  )
                : "")}

            {/* <button className="btn text-primary" type="button">
                  <img
                    src="/servicecheck.svg"
                    alt="servicecheck"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-1">Service Check</span>
                </button> */}

            {Number(isPermission({ type: "btn", name: "assignedAed" })) === 1 &&
            Number(aedGeneralInfo?.site_id) !== 0 &&
            Number(aedGeneralInfo?.out_of_service_toggle) !== 1 ? (
              <button
                className="btn btn-transparent text-primary ms-2 bg-white no-border"
                id="new-tab-btn-12111"
                type="button"
                disabled={loading}
                style={{ backgroundColor: "transparent !important" }}
                onClick={(e) => {
                  handleAssignedAedDispatch(
                    aedGeneralInfo?.account_id,
                    aedGeneralInfo?.site_id
                  );
                  handleAssignEqupment(e);
                }}
              >
                <EquipmentIcon />
                <span className="ms-1">Assigned AED</span>
              </button>
            ) : (
              ""
            )}

            {/*  {exportReport &&
              (currentTab == "Sites" ||
                currentTab == "Contacts" ||
                currentTab == "Equipment" ||
                currentTab == "Notes" ||
                currentTab == "Support" ||
                currentTab == "AED Checks" ||
                currentTab == "AED Servicing") && (
                <button
                  className="btn text-primary ms-2 bg-white"
                  id="new-tab-btn-12111"
                  type="button"
                  style={{ backgroundColor: "transparent !important" }}
                  // onClick={() => navigate(equipmentUrl)}
                >
                  <img
                    src={Report}
                    alt="Report"
                    style={{ width: "25px", height: "24px" }}
                  />
                  <span className="ms-1"> Export Report</span>
                </button>
              )} */}
          </div>
        </div>

        {/* </div> */}

        {/* under bottom buttons */}
        <div className="row mt-3 align-items-center">
          <div
            className="d-flex gap-5 align-items-center"
            style={{ width: "80%", justifyContent: "space-between" }}
          >
            <div className="d-flex gap-5 align-items-center">
              {aedGeneralInfo?.ready_status == "ready" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "#5CB200",
                    borderRadius: "0px",
                  }}
                >
                  Ready
                </span>
              )}
              {aedGeneralInfo?.ready_status == "not ready" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(228, 0, 0, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Not Ready
                </span>
              )}
              {aedGeneralInfo?.ready_status == "unknown" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(228, 0, 0, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Unknown
                </span>
              )}
              {aedGeneralInfo?.ready_status == "out of service" && (
                <span
                  className="px-5 py-3 text-white"
                  style={{
                    backgroundColor: "rgba(191, 118, 9, 1)",
                    borderRadius: "0px",
                  }}
                >
                  Out of Service
                </span>
              )}
              <img
                src={AED_IMG_BASE + aedGeneralInfo?.aed_image}
                alt="aed_image"
                style={{ maxWidth: "100px" }}
              />

              {/* heading */}
              {/* <h2
                    className="text-center aed-title"
                    style={{
                      position: matches ? "" : "absolute",
                      right: matches ? "" : "35%",
                    }}
                  >
                    {aedGeneralInfo?.account_name} {aedGeneralInfo?.site_name}
                  </h2> */}
            </div>

            <div>
              <h2 className="text-center aed-title">
                {aedGeneralInfo?.account_name} {aedGeneralInfo?.site_name}
              </h2>
            </div>

            <div> </div>
          </div>
        </div>

        {/* tabs */}
        <Box className="">
        <div
    className="d-flex border-secondary"
    style={{
      background: "transparent", // Set the outer container background to transparent
    }}
    >
          <div
      style={{
        display: "flex", // Keep the tabs in a flex row
        background: "white", // White background for the tab bar itself
      }}
    >
            {Object.keys(AedTabs).map((tabItem, i) => {
              // Check if any of the permissions in the list is granted for the tabItem
              if (
                Number(
                  isPermission({
                    type: "equipment",
                    name: tabItem,
                  }) === 1 ||
                    (tabItem === "Details" && user?.assign_aed) ||
                    (tabItem === "AEDChecks" && user?.assign_aed)
                )
              ) {
                return (
                  <div
                    role="button"
                    key={i}
                    className="tab-button"
                    style={{
                      padding: "10px 20px", // Adjust padding for better spacing
                      cursor: "pointer",
                      color: tabItem === currentTab ? "#fff" : "#000",
                      backgroundColor:
                        AedTabs[tabItem] === currentTab ? "#0C71C3" : "transparent",
                    }}
                    onClick={() => handleTab(tabItem, AedTabs[tabItem])}
                  >
                    {/* JSON.stringify(Object.values(AedTabs)) */}
                    <strong>  {AedTabs[tabItem]}  </strong> 
                  </div>
                );
              }
              return null; // Return null if there is no permission
            })}
              </div>
          </div>
        </Box>

        {/* Details */}
        {currentTab === AedTabs.Details && (
          <Details
            aedDetails={aedGeneralInfo}
            loading={loading}
            assignedData={assignedData}
            storageInfo={aedStorageInfo}
            batteryInfo={aedBatteryInfo}
            cordinatorInfo={aedCoordinatorInfo}
            storageInfoList={aedStorageInfo}
            batteryTypeList={aedBatteryInfo}
            aedBattryTypeList={aedBatteryInfo}
            aedPadTypeList={aedPadInfo}
            gatewayInfo={aedGeneralInfo?.gateway_info[0]}
            OtherFiled={aedGeneralInfo?.other}
            rmsBrandList={aedGeneralInfo?.rms_info}
            outOfServiceInfo={
              aedGeneralInfo?.out_of_service_info
                ? typeof aedGeneralInfo?.out_of_service_info === "string"
                  ? JSON.parse(aedGeneralInfo?.out_of_service_info)
                  : aedGeneralInfo?.out_of_service_info
                : ""
            }
            out_of_service_toggle={aedGeneralInfo?.out_of_service_toggle}
            sphereBatteryInfo={aedBatteryInfo}
            adultPadInfo={aedPadInfo}
            spareAdultPadInfo={aedPadInfo}
            adultPadPakInfo={aedPadInfo}
            spareAdultPadPakInfo={aedPadInfo}
            pediatricPadInfo={aedPadInfo}
            sparePadricPadInfo={aedPadInfo}
            pediatricPadPakInfo={aedPadInfo}
            sparePadricPadPakInfo={aedPadInfo}
            chargePakInfo={chargePakInfo?.charge_pak_info}
            spareChargePakInfo={chargePakInfo?.spare_charge_pak_info}
            allPads={aedPadInfo}
            showAedGeneralInfo={showAllLoading.showAedGeneralInfo}
            showAedStorageInfo={showAllLoading.showAedStorageInfo}
            showAedBatteryInfo={showAllLoading.showAedBatteryInfo}
            showAedPadInfo={showAllLoading.showAedPadInfo}
            showAedCoordinatorInfo={showAllLoading.showAedCoordinatorInfo}
          />
        )}

        {/* Notes */}
        {currentTab === AedTabs.Notes && (
          <Notes
            note_api={`notes/aed-notes?account_id=${aedGeneralInfo?.account_id}&aed_id=${aedId}`}
            aed_id={aedId}
            accountId={aedGeneralInfo?.account_id}
            type="AED"
            fetch_key={"aedNotes"}
          />
        )}
        {currentTab === AedTabs.Documents && (
          <Documents
            document_api={`document/get-all-aed-documents?accountId=${aedGeneralInfo?.account_id}&aedId=${aedId}`}
            accountId={aedGeneralInfo?.account_id}
            url={`/account/aed-details/${aedId}/Documents`}
            stateData={{
              type: "aed",
              accountId: aedGeneralInfo?.account_id
                ? aedGeneralInfo?.account_id
                : 0,
              aedId: aedId,
              contactId: 0,
              siteId: 0,
              inpersonId: 0,
              accountName: "",
              support_name: aedGeneralInfo?.serial_number,
            }}
          />
        )}
        {currentTab === AedTabs.AEDChecks && (
          <AEDChecksTbl account_id={aedGeneralInfo?.account_id} />
        )}

        {currentTab === AedTabs.AEDServicing && (
          <AedServicing
            stateData={{
              type: "AEDServicing",
              accountId: aedGeneralInfo?.account_id,
              aedId: aedId,
              contactId: 0,
              siteId: 0,
              inpersonId: 0,
            }}
          />
        )}

        {currentTab === AedTabs.Support && (
          <Support
            fetch_api_key={"ticketData"}
            support_api={
              Number(getPer()) === 1
                ? "support/ticket-by-aed/" + aedId
                : "user/support-list-for-aed/" + aedId
            }
            // support_api={"support/ticket-by-aed/" + aedId}
            newSupportUrl={`/account/new-support/?accountId=${aedGeneralInfo?.account_id}&siteId=${aedGeneralInfo?.site_id}&aedId=${aedId}sectionName=aed`}
            stateData={{
              type: "aed",
              accountId: aedGeneralInfo?.account_id
                ? aedGeneralInfo?.account_id
                : 0,
              aedId: aedId,
              contactId: 0,
              siteId: 0,
              inpersonId: 0,
              accountName: "",
              support_name: aedGeneralInfo?.serial_number,
            }}
          />
        )}
        {/* 
            {currentTab === AedTabs.Documents && (
              <AedDocuments aedId={aedId} accountID={aedData?.account_id} />
            )}
*/}
        {/* out of service modal */}
        {showOutOfServiceModal && (
          <OutOfServiceModal
            ShowModal={showOutOfServiceModal}
            SetShowModal={setShowOutOfServiceModal}
            outOfServiceInfo={aedGeneralInfo?.out_of_service_info}
            outOfServiceToggle={aedGeneralInfo?.out_of_service_toggle}
            loanerList={loanerList}
            setLoanerList={setLoanerList}
            aedId={aedId}
            aedDetails={aedGeneralInfo}
            accountId={aedData?.account_id}
            outofServiceSecond={outofServiceSecond}
            setOutofServiceSecond={setOutofServiceSecond}
            setOutofServiceFormData={setOutofServiceFormData}
            cordinatorInfo={cordinatorInfo[5]}
            setoutOfServiceLoding={setoutOfServiceLoding}
          />
        )}

        <OutOfServiceSecondModal
          accountId={aedGeneralInfo?.account_id}
          ShowSecondModal={outofServiceSecond}
          setShowSecondModal={setOutofServiceSecond}
          outOfServiceInfo={aedGeneralInfo?.out_of_service_info}
          outofServiceFormData={outofServiceFormData}
          setoutOfServiceLoding={setoutOfServiceLoding}
          // setLoading={setLoading}
        />
      </div>
      <div className="floating-menu-btn d-flex flex-column gap-2">
        <SupportSymbol
          document_url={`/account/aed-details/${aedId}/Documents`}
          note_api={`/account/new-note?account_id=${aedGeneralInfo?.account_id}&aed_id=${aedId}`}
          newSupportUrl={`/account/new-support/?accountId=${aedGeneralInfo?.account_id}&siteId=${aedGeneralInfo?.site_id}&aedId=${aedId}sectionName=aed`}
          stateData={{
            type: "aed",
            accountId: aedGeneralInfo?.account_id
              ? aedGeneralInfo?.account_id
              : 0,
            aedId: aedId,
            contactId: 0,
            siteId: 0,
            inpersonId: 0,
            accountName: "",
            support_name: aedGeneralInfo?.serial_number,
          }}
        />
      </div>
    </>
  );
};

export default AedMain;
