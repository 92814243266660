import React, { useState, useEffect } from "react";
import { FormatDate } from "../../../../common/helper/Common";
import { useNavigate } from "react-router-dom";
import { RenderDate } from "../../../../common/helper/BasicFn";
import { AedTabs } from "../../../hooks";
import { handleTabfunction } from "../../../../store/slices/TabSlice";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";

function AEDOutOfServiceTbl({ SingleAED }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(SingleAED || []);
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  // Table styling from AEDStandAlone
  const thStyle = {
    backgroundColor: "#ffffff",
    padding: "8px",
    textAlign: "left",
    fontWeight: "500",
    color: "#b6b6b6",
    borderBottom: "1px solid #ddd",
    borderTop: "1.4px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    color: "#333",
    fontWeight: 600,
    borderBottom: "1.2px solid #ddd",
    backgroundColor: "#ffffff"
  };

  const linkStyle = {
    color: "#0078d4",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: 600
  };


  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      let valA = a[key] || " ";
      let valB = b[key] || " ";

      if (typeof valA === "string" && valA && valB) {
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
      }

      if (valA < valB) {
        return direction === "asc" ? -1 : 1;
      }
      if (valA > valB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const [jsonData, setJsonData] = useState(SingleAED);
  const [sortBy, setSortBy] = useState("");

  const sortJsonData = (column, dir = "") => {
    const sortedData = [...jsonData];
    if (!dir) {
      dir = sortConfig.direction;
    }
    let direction = dir === "asc" ? "desc" : "asc";
    let isSortedAsc = dir === "asc" ? 0 : 1;

    if (sortConfig.key === column && dir === "asc") {
      direction = "desc";
      isSortedAsc = false;
    }

    sortedData.sort((a, b) => {
      const valueA = getValueToCompare(a, column);
      const valueB = getValueToCompare(b, column);

      if (typeof valueA === "string" && typeof valueB === "string") {
        return valueA.localeCompare(valueB) * (isSortedAsc ? 1 : -1);
      } else if (typeof valueA === "string" && typeof valueB === "object") {
        return -1 * (isSortedAsc ? 1 : -1);
      } else if (typeof valueA === "object" && typeof valueB === "string") {
        return 1 * (isSortedAsc ? 1 : -1);
      } else if (valueA instanceof Date && valueB instanceof Date) {
        return (valueA - valueB) * (isSortedAsc ? 1 : -1);
      } else {
        return 0;
      }
    });

    setJsonData(sortedData);
    setSortBy(column);
    setSortConfig({ key: column, direction: direction });
  };

  const getValueToCompare = (object, column) => {
    if (column === "aed_id") {
      return object.aed_id;
    } else if (column === "site_id") {
      return object.site_id;
    } else if (column === "serial_number") {
      return object.serial_number;
    } else if (column === "placement") {
      return object.placement;
    } else if (column === "brand_name") {
      return object.brand_name;
    } else if (column === "battery_expiration") {
      return object.battery_expiration[0].data[0];
    } else if (column === "pads_expiration") {
      return object.pads_expiration[0].data[0];
    } else if (column === "last_check") {
      return object.last_check;
    } else if (column === "last_service") {
      return object.last_service;
    } else if (column === "rms_check") {
      return object.rms_check;
    } else if (column === "pediatric_key") {
      return object.pediatric_key;
    } else if (column === "site_name") {
      return object.site_name;
    } else {
      return null;
    }
  };

  useEffect(() => {
    sortJsonData("brand_name", "desc");
  }, []);

  const handleDispatch = (aed_id, name) => {
    const BreadCrumbsObject = {
      title: "",
      type: "aed",
      tab: "Details",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };
    BreadCrumbsObject.title = name + " Details";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed-details/" + aed_id + "?tab=Details",
      })
    );
  };

  useEffect(() => {
    setJsonData(SingleAED || []);
  }, [SingleAED]);

  return (
    <div style={{ marginBottom: "3%" }}>
      {SingleAED && (
        <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px", marginBottom: "3%" }}>
          <thead>
            <tr>
              <th style={thStyle} onClick={() => sortJsonData("brand_name")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>AED Brand / Model</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "brand_name" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
              <th style={thStyle} onClick={() => sortJsonData("serial_number")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Serial Number</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "serial_number" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
              <th style={thStyle} onClick={() => sortJsonData("placement")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>AED Placement</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "placement" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
              <th style={thStyle} onClick={() => sortJsonData("battery_expiration")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Battery Expiration</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "battery_expiration" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
              <th style={thStyle} onClick={() => sortJsonData("pads_expiration")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Pads Expiration</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "pads_expiration" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
              <th style={thStyle} onClick={() => sortJsonData("site_name")}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Site Name</span>
                  <span style={{ fontSize: "12px", color: "#b6b6b6" }}>
                    {sortConfig.key === "site_name" && sortConfig.direction === "asc" ? "▲" : "▼"}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {jsonData?.length > 0 ? (
              jsonData.map((item, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f9f9f9" }}>
                  <td style={tdStyle}>
                    <span
                      onClick={() => {
                        handleDispatch(item?.aed_id, item?.serial_number);
                        dispatch(
                          handleTabfunction({
                            value: AedTabs.Details,
                            type: "aed",
                          })
                        );
                        navigate(`/account/aed-details/` + item?.aed_id);
                      }}
                      style={linkStyle}
                    >
                      {item?.brand_name}
                    </span>
                  </td>
                  <td style={{ ...tdStyle, maxWidth: "300px", overflowWrap: "break-word" }}>
                    {item?.serial_number}
                  </td>
                  <td style={tdStyle}>
                    {item?.placement}
                  </td>
                  <td style={tdStyle}>
                    {item?.battery_expiration?.map((it, i) => (
                      <React.Fragment key={i}>
                        {it?.data?.map((it2, j) =>
                          it2 === "unknown" ? (
                            <p key={j}>
                              <img src={it?.img} width={15} alt="Battery" />
                              {it2}
                            </p>
                          ) : (
                            it2 !== "" &&
                            FormatDate(it2) !== "" && (
                              <p key={j}>
                                <img src={it?.img} width={15} alt="Battery" /> &nbsp;
                                {RenderDate(
                                  FormatDate(it2),
                                  it.title === "manufetchDate" ? 0 : 1
                                )}
                              </p>
                            )
                          )
                        )}
                      </React.Fragment>
                    ))}
                  </td>
                  <td style={tdStyle}>
                    {item?.pads_expiration?.map((it, i) => (
                      <React.Fragment key={i}>
                        {it?.data?.map((it2, j) =>
                          it2 === "unknown" ? (
                            <p key={j}>
                              <img src={it?.img} width={15} alt="Pad" /> &nbsp;
                              {it2}
                            </p>
                          ) : FormatDate(it2) ? (
                            <p key={j}>
                              <img src={it?.img} width={15} alt="Pad" /> &nbsp;
                              {RenderDate(
                                FormatDate(it2),
                                it.title === "manufetchDate" ? 0 : 1
                              )}
                            </p>
                          ) : it2 !== "" && FormatDate(it2) ? (
                            <p key={j}>
                              <img src={it?.img} width={15} alt="Pad" /> &nbsp;
                              {RenderDate(
                                FormatDate(it2),
                                it.title === "manufetchDate" ? 0 : 1
                              )}
                            </p>
                          ) : (
                            ""
                          )
                        )}
                      </React.Fragment>
                    ))}
                    {item?.pediatric_key ? (
                      <p>
                        <img
                          src={"/assets/images/child-Vector.png"}
                          width={15}
                          alt="Child"
                        />{" "}
                        &nbsp;
                        <img
                          src="/assets/images/key-Vector.png"
                          width={15}
                          alt="Key"
                        />
                      </p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td style={tdStyle}>
                    {item?.site_name || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} style={{ ...tdStyle, textAlign: "center", padding: "20px" }}>
                  No equipment at this site
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AEDOutOfServiceTbl;