import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStorageType,
  addNewBreadcrumbs,
  clearBreadcrumbs,
} from "../../store/slices/breadcrumbsSlice";

export const useBreadcrumbPersistence = () => {
  const dispatch = useDispatch();

  const breadcrumbs = useSelector((state) => state.BreadCrumbs.breadcrumbs);
  const storageType = useSelector((state) => state.BreadCrumbs.storageType);

  function getStorageTypeFromBreadcrumbs(breadcrumbs) {
    // Agar breadcrumbs array empty ho, to empty string return karo
    if (breadcrumbs.length === 0) {
      return "";
    }

    // Breadcrumbs ke last element ki storageType ko return karna
    const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];
    return lastBreadcrumb.storageType || ""; // Agar storageType nahi hai to empty return kare
  }

  function filterBreadcrumbs(breadcrumbs, emptyStorageType) {
    // Empty array check
    if (!breadcrumbs || breadcrumbs.length === 0) {
      return [];
    }

    // Preserve first index
    const firstIndex = breadcrumbs[0];

    // Filter breadcrumbs based on storageType
    const filteredBreadcrumbs = breadcrumbs.filter((breadcrumb, index) => {
      // Always keep first index
      if (index === 0) {
        return true;
      }

      // Keep items that match the emptyStorageType
      return breadcrumb.storageType === emptyStorageType;
    });

    return filteredBreadcrumbs;
  }

  useEffect(() => {
    // Check session storage for user context
    const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
    const type = isUserContext ? "sessionStorage" : "localStorage";
    let emptyStorageType = getStorageTypeFromBreadcrumbs(breadcrumbs);

    // Persist breadcrumbs based on storage type
    if (
      type === "localStorage" &&
      (emptyStorageType === "localStorage" || emptyStorageType === "")
    ) {
      localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
    }
    if (type === "sessionStorage" && emptyStorageType === "sessionStorage") {
      const filteredBreadcrumbs = filterBreadcrumbs(
        breadcrumbs,
        emptyStorageType
      );

      sessionStorage.setItem(
        "breadcrumbs",
        JSON.stringify(filteredBreadcrumbs)
      );
    }
  }, [breadcrumbs, storageType]);

  // Load breadcrumbs on app initialization
  useEffect(() => {
    const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
    const storedBreadcrumbs = isUserContext
      ? JSON.parse(sessionStorage.getItem("breadcrumbs") || "[]")
      : JSON.parse(localStorage.getItem("breadcrumbs") || "[]");

    storedBreadcrumbs.forEach((breadcrumb) => {
      dispatch(
        addNewBreadcrumbs({
          ...breadcrumb,
          storageType: isUserContext ? "sessionStorage" : "localStorage",
        })
      );
    });
  }, []);
};
