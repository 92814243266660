
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControlLabel, Radio } from "@mui/material";
import { Form } from "react-bootstrap";
import "../../global.css";
import { toast } from "react-toastify";
import GlobalDropdown from "./custom-ui/GlobalDropdown";
import { useLocation } from "react-router-dom";
import { printConsole } from "../helper/Common";

export default function AddContactPhoneFrom({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  allDropDowns,
  noBtns,
  sectionId = "",
  sectionName = "",
  validated
}) {
  const [phoneErrors, setPhoneErrors] = useState([]);
  const location = useLocation();

  const updatePhoneFields = (e, name, index) => {
    printConsole({ checking_data: e, name, index });
    const newArr = altTrainerForm.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: e.target.value };
      }
      return item;
    });
    setSubFormData(newArr);
    validatePhoneNumbers(newArr);
  };

  const validatePhoneNumbers = (forms) => {
    const newPhoneErrors = forms.map((form) => {
      const phone = form.account_main_contact_phone || "";
      return phone.trim() !== "" && phone.trim() !=="-" && phone.trim().length < 10;
    });
    setPhoneErrors(newPhoneErrors);
  };

  const updateFieldChanged = (e, index, name) => {
    const newArr = altTrainerForm.map((item, i) => {
      if (i === index) {
        let value = e.target.value;

        if (name === "account_main_contact_phone") {
          value = value
            .replace(/[^0-9]/g, "")
            .trim()
            .slice(0, 10);
        }

        if (name === "account_main_contact_phone_main") {
          return {
            ...item,
            [name]: "1",
          };
        }

        return {
          ...item,
          [name]: value,
        };
      } else if (name === "account_main_contact_phone_main") {
        return {
          ...item,
          [name]: "0",
        };
      }
      return item;
    });

    const newPhoneErrors = [...phoneErrors];
    if (name === "account_main_contact_phone") {
      if (newArr[index].account_main_contact_phone.length < 10) {
        newPhoneErrors[index] = true;
      } else {
        newPhoneErrors[index] = false;
      }
    }

    setPhoneErrors(newPhoneErrors);
    setSubFormData(newArr);
  };

  const handleDecreaseAlternative = (index) => {
    decreaseAlternative(index);
    validatePhoneNumbers(altTrainerForm);
  };

  useEffect(() => {
    validatePhoneNumbers(altTrainerForm);
  }, [altTrainerForm]);

  // Handle dropdown selection for phone_type_id
  // const handleDropdownChange = (value, index) => {
  //   const newArr = [...altTrainerForm];
  //   newArr[index].phone_type_id = value;
  //   setSubFormData(newArr);
  // };

  const handleDropdownChange = (value, index) => {
    const newArr = altTrainerForm.map((item, i) => {
      if (i === index) {
        return { ...item, phone_type_id: value }; // Deep copy banake phone_type_id update karo
      }
      return item; // Baaki items same rakhenge
    });
    setSubFormData(newArr); // Naya array set karo
  };

  return (
    <>
     {altTrainerForm.map((singleForm, index) => {
  const phoneOptions =
    location?.state?.contactInfoDetails?.phone_numbers?.map((phone) => ({
      id: phone.phone_type_id,
      value: phone.phone_type_name,
    }));

  const selectedPhoneTypeId = phoneOptions && phoneOptions[index]?.id;
  const selectedPhoneTypeValue = phoneOptions && phoneOptions[index]?.value;

  const finalSelectedValue = selectedPhoneTypeId ? selectedPhoneTypeId : null;
  const finalDefaultValue =
    selectedPhoneTypeId && selectedPhoneTypeValue
      ? { id: selectedPhoneTypeId, value: selectedPhoneTypeValue }
      : {};

  return (
    <div
      className="row mb-4"
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      key={index}
      id={`${sectionId}_${index}`}
    >
      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <div style={{ width: "120px", margin: 0 }}></div>
        {noBtns && (
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={increaseAlternative}
              type="button"
              style={{
                padding: "5px 10px",
                fontSize: "14px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                backgroundColor: "#007bff",
                color: "white",
              }}
              name={`${sectionName}_plus`}
            >
              +
            </button>
            <button
              onClick={() => handleDecreaseAlternative(index)}
              type="button"
              style={{
                padding: "5px 10px",
                fontSize: "14px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
                backgroundColor: "#dc3545",
                color: "white",
              }}
              name={`${sectionName}_minus`}
            >
              −
            </button>
          </div>
        )}
      </div>

    
         <Form.Group
                      className="form-group-responsive"
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px",
                      }}
                    >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <label
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            color: "#333",
            width: "120px",
            margin: 0,
            textAlign: "right",
          }}
        >
          Phone
        </label>
       <Form.Control
          type="text"
          name="account_main_contact_phone"
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "6px 10px",
            fontSize: "14px",
            color: "#333",
            height: "34px",
            width: "400px",
          }}
          value={singleForm.account_main_contact_phone}
          onChange={(e) =>
            updateFieldChanged(e, index, "account_main_contact_phone")
          }
          pattern="[0-9]*"
          minLength={10}
          className={phoneErrors[index] ? "phone-invalid-input" : ""}
          isInvalid={validated && !singleForm.account_main_contact_phone} // Explicitly set isInvalid
        />
     
         </div>
           {phoneErrors[index] && (
          <div className="phone-invalid"     
          style={{
            marginLeft: "130px",
          }}>
            Please Enter Exact 10 digits.
            </div>
        )}
         </Form.Group>
     

      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <label
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            color: "#333",
            width: "120px",
            margin: 0,
            textAlign: "right",
          }}
        >
          Ext
        </label>
        <input
          type="number"
          name="account_main_contact_phone_ext"
          style={{
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "6px 10px",
            fontSize: "14px",
            color: "#333",
            height: "34px",
            width: "400px",
          }}
          onChange={(e) =>
            updateFieldChanged(e, index, "account_main_contact_phone_ext")
          }
          value={singleForm.account_main_contact_phone_ext}
        />
      </div>

      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <label
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            color: "#333",
            width: "120px",
            margin: 0,
            textAlign: "right",
          }}
        >
          Phone Type
        </label>
        <div style={{ width: "400px" }}>
          {/* <GlobalDropdown
            dropDownName="phone_type_id"
            apiEndpoint="contact/contact-dropdowns"
            idKey="dropdown_phone_type_id"
            valueKey="dropdown_phone_type_name"
            parentKey="phoneType"
            onSelect={(e) => {
              updatePhoneFields(e, "phone_type_id", index);
            }}
            shouldFetchOnOpen={true}
            isCache={true}
            initialSelectedValue={finalSelectedValue || ""}
            defaultValue={finalDefaultValue || ""}
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "6px 10px",
              fontSize: "14px",
              color: "#333",
              height: "34px",
              width: "100%",
            }}
          /> */}
          <GlobalDropdown
                dropDownName={`phone_type_id_${index}`}  // Unique name for each instance
                apiEndpoint="contact/contact-dropdowns"
                idKey="dropdown_phone_type_id"
                valueKey="dropdown_phone_type_name"
                parentKey="phoneType"
                onSelect={(event, value) => handleDropdownChange(value, index)}
                shouldFetchOnOpen={true}
                isCache={true}
                initialSelectedValue={singleForm.phone_type_id || ""}  // Controlled by parent state
                defaultValue={{
                  id: singleForm.phone_type_id || "",
                  value: allDropDowns?.phoneType?.find(opt => opt.dropdown_phone_type_id === singleForm.phone_type_id)?.dropdown_phone_type_name || "",
                }}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "6px 10px",
                  fontSize: "14px",
                  color: "#333",
                  height: "34px",
                  width: "100%",
                }}
              />
        </div>
      </div>

      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          width: "100%",
        }}
      >
        <label
          style={{
            fontWeight: "bold",
            fontSize: "14px",
            color: "#333",
            width: "120px",
            margin: 0,
            textAlign: "right",
          }}
        >
          Main
        </label>
        <div>
          <FormControlLabel
            label=""
            name="account_main_contact_phone_main"
            onClick={(e) => {
              const phoneNumber = altTrainerForm[index].account_main_contact_phone;
              if (!phoneNumber || phoneNumber.trim() === "") {
                toast.error("Please first fill Phone number");
              } else {
                e.preventDefault();
                updateFieldChanged(e, index, "account_main_contact_phone_main");
              }
            }}
            control={
              <Radio
                color="primary"
                size="medium"
                value={singleForm?.account_main_contact_phone_main}
                checked={singleForm?.account_main_contact_phone_main === "1"}
              />
            }
          />
        </div>
      </div>
    </div>
  );
})}
    </>
  );
}
