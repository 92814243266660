import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CallGETAPI } from "../../../../common/services";
import SubHeadingOther from "../../../../common/components/SubHeadingOther";
import EditAssignAedTbl from "../components/EditAssignAedTbl";

const EditAllAssignAed = () => {
  const { state } = useLocation();
  const { accountId } = state || {};
  const [equipment_data, set_equipment_data] = useState(null);
  const [contact_list, set_contact_list] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const assignedAedData = location?.state?.assignAedData;
  const redirectPath = location?.state ? location?.state?.redirectPath : "";
  const accountName = location?.state ? location?.state?.accountName : "";
  const Title = (assignedAedData?.[0]?.account_name || accountName) ?? "";

  function processAssignedAedData(assignedAedData) {
    // Initialize empty result array
    let assignedData = [];

    // Loop through each site in assignedAedData
    assignedAedData.forEach((site) => {
      // Check if site has data array
      if (site.data && Array.isArray(site.data)) {
        // Add each AED from site's data to the result array
        site.data.forEach((aed) => {
          assignedData.push({
            account_id: aed.account_id,
            aed_brand: aed.aed_brand,
            aed_id: aed.aed_id,
            assign_user: aed.assign_user || null,
            placement: aed.placement || "unknown",
            serial_number: aed.serial_number,
            site_id: aed.site_id,
          });
        });
      }
    });

    return assignedData;
  }

  const assignedData = processAssignedAedData(assignedAedData);

  const getContactsData = useCallback(async () => {
    setLoading(true);
    const response = await CallGETAPI(
      "account/account-contacts-list/",
      accountId
    );

    if (response?.status) {
      set_contact_list(response.data.data.contact_list);
      setLoading(false);
    }
    setLoading(false);
  }, [accountId]);

  useEffect(() => {
    if (accountId) {
      getContactsData();
    }
  }, [accountId, getContactsData]);

  return (
    <>
      <div
        className="mt-4 mb-5"
        style={{ width: "100%", paddingInline: "0px" }}
      >
        <SubHeadingOther
          title={Boolean(Title) ? `Account: ${Title}` : ""}
          hideNew={true}
          hideHierarchy={true}
          hideInstructor={true}
          subHeading={true}
          bottomLinks={false}
        />

        <div className="mb-5">
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: "30%",
              justifyContent: "space-between",
              marginBottom: "2%",
            }}
          >
            <h1
              style={{
                color: "#000",
                textAlign: "center",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "normal",
                width: "520px",
                margin: "0px",
              }}
            >
              {equipment_data?.site_name}
            </h1>
          </div>

          <EditAssignAedTbl
            redirectPath={redirectPath}
            accountId={accountId}
            showLoading={loading}
            data={assignedData}
            fromAed={location?.state?.fromAed}
          />
        </div>
      </div>
    </>
  );
};

export default EditAllAssignAed;
