import React, { useState, useEffect } from "react";
import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import { useNavigate } from "react-router-dom";
import {
  DecryptToken,
  getPermission,
  RenderDate,
  FormatDate,
  GetProfile,
} from "../../../../common/helper";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { AedTabs } from "../../../hooks";
import { handleTabfunction } from "../../../../store/slices/TabSlice";
import { isPermission } from "../../../helper/PermissionManager";
import { RenderDiffDate } from "../../../helper/BasicFn";
import { CallGETAPI } from "../../../services";

function AEDTable({
  SingleAED,
  setShowAedTbl,
  tabTbldata,
  setTabTbldata,
  hideAccMove,
  hideAedMove,
  isTechnician = false,
  accountId = "",
  aedCheckLength,
}) {
  console.log({ SingleAED });
  const navigate = useNavigate();
  const [data, setData] = useState(SingleAED || []);
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const user = DecryptToken();
  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      let valA = a[key] || " ";
      let valB = b[key] || " ";

      if (typeof valA === "string" && valA && valB) {
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
      }

      if (valA < valB) {
        return direction === "asc" ? -1 : 1;
      }
      if (valA > valB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const [jsonData, setJsonData] = useState(SingleAED);
  const [sortBy, setSortBy] = useState("");
  // Fix the sortJsonData function to correctly handle sort direction
  const sortJsonData = (column, dir = "") => {
    const sortedData = [...jsonData];

    // Determine direction based on current state
    let direction;
    if (sortConfig.key === column) {
      // If clicking same column, toggle direction
      direction = sortConfig.direction === "asc" ? "desc" : "asc";
    } else {
      // If clicking new column, start with ascending
      direction = "asc";
    }

    // If direction was explicitly provided, use it
    if (dir === "asc" || dir === "desc") {
      direction = dir;
    }

    // Sort the data
    sortedData.sort((a, b) => {
      const valueA = getValueToCompare(a, column);
      const valueB = getValueToCompare(b, column);

      // Handle different data types for comparison
      if (valueA === valueB) return 0;

      // For dates in string format (YYYY-MM-DD)
      if (column === "battery_expiration" || column === "pads_expiration" ||
        column === "last_check" || column === "last_service") {
        // For dates, we can compare the strings directly if they're in YYYY-MM-DD format
        const result = valueA < valueB ? -1 : 1;
        return direction === "asc" ? result : -result;
      }

      // For strings
      if (typeof valueA === "string" && typeof valueB === "string") {
        const result = valueA.localeCompare(valueB);
        return direction === "asc" ? result : -result;
      }

      // For numbers
      const result = valueA < valueB ? -1 : 1;
      return direction === "asc" ? result : -result;
    });

    // Update state
    if (sortedData?.length > 0) {
      setTabTbldata({
        ...tabTbldata,
        equipment: {
          ...tabTbldata.equipment,
          aed: true,
        },
      });
    }

    setJsonData(sortedData);
    setSortBy(column);
    setSortConfig({ key: column, direction });
  };

  // Fix the getValueToCompare function to properly handle complex data structures
  const getValueToCompare = (object, column) => {
    if (!object) return null;

    switch (column) {
      case "aed_id":
      case "site_id":
      case "serial_number":
      case "placement":
      case "aed_checker":
      case "brand_name":
      case "assign_user":
        return object[column] || "";

      case "battery_expiration":
        // Handle array data structure safely
        if (Array.isArray(object.battery_expiration) &&
          object.battery_expiration.length > 0 &&
          object.battery_expiration[0].data &&
          Array.isArray(object.battery_expiration[0].data)) {
          const dateStr = object.battery_expiration[0].data[0];
          if (dateStr === "unknown" || dateStr === null) return "9999-12-31"; // Put unknowns at the end
          return FormatDate(dateStr) || "9999-12-31"; // Format dates consistently
        }
        return "9999-12-31"; // Default value for sorting when data is missing

      case "pads_expiration":
        // Handle array data structure safely
        if (Array.isArray(object.pads_expiration) &&
          object.pads_expiration.length > 0 &&
          object.pads_expiration[0].data &&
          Array.isArray(object.pads_expiration[0].data)) {
          const dateStr = object.pads_expiration[0].data[0];
          if (dateStr === "unknown" || dateStr === null) return "9999-12-31";
          return FormatDate(dateStr) || "9999-12-31";
        }
        return "9999-12-31";

      case "last_check":
        // Convert to consistent date format for sorting
        return object.last_check ? FormatDate(object.last_check) || "9999-12-31" : "9999-12-31";

      case "last_service":
        return object.last_service ? FormatDate(object.last_service) || "9999-12-31" : "9999-12-31";

      case "rms_check":
      case "pediatric_key":
        return object[column] || "";

      default:
        return "";
    }
  };
  useEffect(() => {
    sortJsonData("brand_name", "desc");
    if (typeof setShowAedTbl === "function") {
      setShowAedTbl(false);
    }
  }, []);

  useEffect(() => {
    setJsonData(SingleAED || []);
  }, [SingleAED]);

  let profile = GetProfile(); //JSON.parse(localStorage.getItem("ross-profile"));
  let user_type = profile?.user_type;

  let is_user = false;
  let is_aed_details = false;
  const BreadCrumbsObject = {
    title: "",
    type: "aed",
    tab: "Details",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };
  const handleDispatch = (aed_id, name) => {
    BreadCrumbsObject.title = name + " Details";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/aed-details/" + aed_id + "/Details",
      })
    );
  };
  const handleAedClick = (item) => {
    if (user_type > 1) {
      is_user = true;
      let permissions = localStorage.getItem("permissions");
      let permissionsArr = permissions.split(",");
      if (permissionsArr.includes("aed-details")) {
        is_aed_details = true;
        handleDispatch(item?.aed_id, item?.serial_number);
        // dispatch(handleTabfunction({ value: AedTabs.Details, type: "aed" }));
        navigate("/user/aed-details/" + item?.aed_id + "/Details", {
          state: { is_user: true },
        });
      }
    } else {
      handleDispatch(item?.aed_id, item?.serial_number);
      // dispatch(handleTabfunction({ value: AedTabs.Details, type: "aed" }));
      navigate("/account/aed-details/" + item?.aed_id + "/Details");
    }
  };

  // Update the thStyle object
  const thStyle = {
    backgroundColor: "#ffffff", // Changed from #f5f5f5 to white
    padding: "8px", // Matching NewGlobalTable's padding
    textAlign: "left",
    fontWeight: "500",
    color: "#b6b6b6", // Changed to match NewGlobalTable's header color
    borderBottom: "1px solid #ddd", // Matching NewGlobalTable's border
    borderTop: "1.4px solid #ddd"
  };

  // Update the tdStyle object
  const tdStyle = {
    padding: "8px", // Matching NewGlobalTable's padding
    textAlign: "left",
    color: "#333",
    fontWeight: 600,
    borderBottom: "1.2px solid #ddd", // Changed to match NewGlobalTable's row borders
    backgroundColor: "#ffffff" // Explicitly setting white background
  };

  // Styles for link text
  const linkStyle = {
    color: "#0C71C3",
    cursor: "pointer",
    textDecoration: "none",
    fontWeight: 600
  };

  return (
    <>
      <div className="table">
        {SingleAED && (
          <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px", marginBottom: "3%" }}>
            <thead>
              <tr>
                <th style={thStyle} onClick={() => sortJsonData("brand_name")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>AED Brand / Model</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "brand_name" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("serial_number")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Serial Number</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "serial_number" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("assign_user")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>AED Checker</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "assign_user" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("placement")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>AED Placement</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "placement" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("battery_expiration")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Battery Expiration</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "battery_expiration" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("pads_expiration")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Pads Expiration</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "pads_expiration" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("last_check")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Last Check</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "last_check" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>

                <th style={thStyle} onClick={() => sortJsonData("last_service")}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Last Service</span>
                    <span style={{
                      fontSize: "12px",
                    }}>
                      {sortConfig.key === "last_service" && sortConfig.direction === "asc" ? "▲" : "▼"}
                    </span>
                  </div>
                </th>
              </tr>
            </thead>

            {jsonData?.length > 0 ? (
              <tbody>
                {jsonData.map((item, index) => (
                  <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f9f9f9" }}>
                    <td style={tdStyle}>
                      <span
                        style={
                          Number(isPermission({ type: "link", name: "aedBrand" })) === 1 || user?.assign_aed
                            ? linkStyle
                            : {}
                        }
                        onClick={() => {
                          if (
                            Number(isPermission({ type: "link", name: "aedBrand" })) === 1 ||
                            user?.assign_aed
                          ) {
                            handleDispatch(item?.aed_id, item?.serial_number);
                            navigate("/account/aed-details/" + item?.aed_id + "/Details");
                          }
                        }}
                      >
                        {item?.brand_name}
                      </span>
                    </td>

                    <td style={tdStyle}>
                      <span
                        style={
                          Number(isPermission({ type: "link", name: "serialNumber" })) === 1 || user?.assign_aed
                            ? linkStyle
                            : {}
                        }
                        onClick={() => {
                          if (
                            Number(isPermission({ type: "link", name: "serialNumber" })) === 1 ||
                            user?.assign_aed
                          ) {
                            handleDispatch(item?.aed_id, item?.serial_number);
                            navigate("/account/aed-details/" + item?.aed_id + "/Details");
                          }
                        }}
                      >
                        {item?.serial_number}
                      </span>
                    </td>

                    <td style={tdStyle}>{item?.assign_user}</td>
                    <td style={tdStyle}>{item?.placement}</td>

                    <td style={tdStyle}>
                      {item?.battery_expiration?.map((it, idx) => (
                        <React.Fragment key={idx}>
                          {it?.data?.map((it2, idx2) =>
                            it2 === "unknown" || it2 === null ? (
                              <p key={idx2} style={{ margin: "2px 0" }}>
                                <img src={it?.img} width={15} alt="Battery" />
                                {it2 === "unknown" || it2 === null ? " unknown" : it2}
                              </p>
                            ) : (
                              it2 !== "" &&
                              FormatDate(it2) !== "" && (
                                <p key={idx2} style={{ margin: "2px 0" }}>
                                  <img src={it?.img} width={15} alt="Battery" /> &nbsp;
                                  {RenderDate(FormatDate(it2), it.title === "manufetchDate" ? 0 : 1)}
                                </p>
                              )
                            )
                          )}
                        </React.Fragment>
                      ))}
                    </td>

                    <td style={tdStyle}>
                      {item?.pads_expiration?.map((it, idx) => (
                        <React.Fragment key={idx}>
                          {it?.data?.map((it2, idx2) =>
                            it2 === "unknown" ? (
                              <p key={idx2} style={{ margin: "2px 0" }}>
                                <img src={it?.img} width={15} alt="Pads" /> &nbsp;
                                {it2}
                              </p>
                            ) : FormatDate(it2) ? (
                              <p key={idx2} style={{ margin: "2px 0" }}>
                                <img src={it?.img} width={15} alt="Pads" /> &nbsp;
                                {RenderDate(FormatDate(it2))}
                              </p>
                            ) : it2 !== "" && FormatDate(it2) ? (
                              <p key={idx2} style={{ margin: "2px 0" }}>
                                <img src={it?.img} width={15} alt="Pads" /> &nbsp;
                                {RenderDate(FormatDate(it2), it.title === "manufetchDate" ? 0 : 1)}
                              </p>
                            ) : (
                              ""
                            )
                          )}
                        </React.Fragment>
                      ))}
                      {item?.pediatric_key ? (
                        <p style={{ margin: "2px 0" }}>
                          <img src={"/assets/images/child-Vector.png"} width={15} alt="Child" /> &nbsp;
                          <img src="/assets/images/key-Vector.png" width={15} alt="Key" />
                        </p>
                      ) : (
                        ""
                      )}
                    </td>

                    <td style={tdStyle}>{RenderDiffDate(item?.last_check, aedCheckLength) || "-"}</td>
                    <td style={tdStyle}>{FormatDate(item?.last_service) || "-"}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={8} style={{ ...tdStyle, textAlign: "center", padding: "20px" }}>
                    No equipment at this site.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
      </div>
    </>
  );
}

export default AEDTable;