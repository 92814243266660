import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControlLabel, Icon, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Select from "react-select";
import StateField from "../../../common/components/StateField";
import { validatePhone } from "../../../common/helper";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";

export default function MultiTrainingFrom({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  noBtns,
  type,
  fieldsRequired = true,
  countriesList,
  setTrainingPhoneValidations,
  setValidateField = "",
}) {
  const [selectedCountry, setSelectedCountry] = React.useState({});
  const [phoneValidations, setPhoneValidations] = React.useState({});

  const updateFieldChanged = (index) => (e) => {
    if (e.target.value !== " ") {
      let newArr = [...altTrainerForm]; // copying the old datas array
      if (e.target.name == "alternative_phone") {
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "").trim();
        e.target.value = e.target.value.slice(0, 10);

        const phoneValidate = validatePhone(e.target.value);
        setPhoneValidations((old) => ({
          ...old,
          [index]: phoneValidate ? false : true,
        }));
        setTrainingPhoneValidations(phoneValidate ? false : true);
        setValidateField(phoneValidate ? false : true);
      }

      if (e.target.type == "checkbox") {
        let value = e.target.checked ? 1 : 0;
        newArr[index][e.target.name] = value;
      } else {
        newArr[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      }
      setSubFormData(newArr);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Update the specific field in the form data
    setSubFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // handle select change
  const handleSelectChange = (data, key, index) => {
    let newArr = [...altTrainerForm];

    if (data?.value != "231") {
      newArr[index] = { 
        ...newArr[index],
        [key]: data.value,
        account_alternate_traning_location_state: "",
        account_alternate_traning_location_state_abbreviation: "",
        account_alternate_traning_location_state_name: ""
      };
    } else {
      newArr[index][key] = data.value;
    }
    
    setSubFormData(newArr);

    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data?.label,
        value: data?.value,
      },
    }));
  };

  useEffect(() => {
    if (countriesList) {
      if (altTrainerForm[0]?.account_alternate_traning_location_country) {
        const country = countriesList.find(
          (country) =>
            country.value ==
            parseInt(
              altTrainerForm[0]?.account_alternate_traning_location_country
            )
        );
        setSelectedCountry((old) => ({
          ...old,
          account_alternate_traning_location_country: {
            label:
              altTrainerForm[0]?.account_alternate_traning_location_country,
            value:
              altTrainerForm[0]?.account_alternate_traning_location_country_id,
          },
        }));
      } else {
        setSelectedCountry((old) => ({
          ...old,
          account_alternate_traning_location_country: {
            label: countriesList[230]?.label,
            value: countriesList[230]?.value,
          },
        }));
      }
    }
  }, [countriesList, altTrainerForm]);

  useEffect(() => {
    setSelectedCountry((old) => ({
      ...old,
      ["account_alternate_traning_location_country"]: {
        label: altTrainerForm[0]?.account_alternate_traning_location_country,
        value: altTrainerForm[0]?.account_alternate_traning_location_country_id,
      },
    }));
  }, []);

  return (
    <>
      {altTrainerForm &&
        altTrainerForm.map((singleForm, index) => (
          <div key={index}>
            <div
              className="row mb-4 "
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="col-4">
                <div className="d-flex mb-2" style={{ alignItems: "center" }}>
                  <Form.Label>Company Name</Form.Label>
                  {type != "trainingEdit" && (
                    <>
                      <button
                        onClick={increaseAlternative}
                        className="btn mx-2 btn-sm btn-primary "
                        type="button"
                      >
                        +
                      </button>
                      <button
                        onClick={decreaseAlternative}
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button>
                    </>
                  )}
                </div>

                <Form.Group className={"col"}>
                  <Form.Control
                    type="text"
                    name="account_alternate_traning_location_company_name"
                    value={
                      singleForm.account_alternate_traning_location_company_name
                    }
                    onChange={updateFieldChanged(index)}
                  />
                </Form.Group>
              </div>

              <div className="col-8 row">
                <Form.Group className={"col-md-4"}>
                  <div className="d-flex mb-2" style={{ alignItems: "center" }}>
                    <Form.Label>Phone </Form.Label>
                    {noBtns && (
                      <>
                        <button
                          onClick={increaseAlternative}
                          type="button"
                          className="btn mx-2 btn-sm btn-primary "
                        >
                          +
                        </button>
                        <button
                          onClick={decreaseAlternative}
                          type="button"
                          className="btn mx-2 btn-sm btn-danger "
                        >
                          -
                        </button>
                      </>
                    )}
                  </div>

                  <Form.Control
                    type="text"
                    name="alternative_phone"
                    value={singleForm.alternative_phone}
                    onChange={updateFieldChanged(index)}
                    // required
                    minLength="10"
                    className={
                      phoneValidations[index]
                        ? "phone-invalid-input col-md-4"
                        : "col-md-4"
                    }
                  />

                  {phoneValidations[index] ? (
                    <>
                      <div className="phone-invalid">
                        Please Enter Exact 10 digits.
                      </div>
                    </>
                  ) : (
                    <>
                      <Form.Control.Feedback type="invalid">
                        Please Enter Exact 10 digits.
                      </Form.Control.Feedback>
                    </>
                  )}
                </Form.Group>

                <Form.Group className={"col-md-4"}>
                  <div className="mb-2">
                    <Form.Label>Ext</Form.Label>
                  </div>

                  <Form.Control
                    type="number"
                    name="alternative_ext"
                    onChange={updateFieldChanged(index)}
                    value={singleForm.alternative_ext}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row my-4">
              <Form.Group className={"col relative"}>
                <Form.Label>Country {fieldsRequired ? "*" : ""}</Form.Label>
                {/* <Select
                value={
                  selectedCountry?.account_alternate_traning_location_country
                }
                options={countriesList}
                onChange={(data) => {
                  handleSelectChange(
                    data,
                    "account_alternate_traning_location_country",
                    index
                  );
                }}
              /> */}

                <GlobalDropdown
                  dropDownName={"account_alternate_traning_location_country"}
                  apiEndpoint={"account/get-country"}
                  idKey={"id"}
                  valueKey={"country_name"}
                  parentKey={"country"}
                  // onSelect={(e) => {
                  //   handleInputChange(e);
                  // }}
                  onSelect={(data, value) => {
                    const selectedText =
                      data.target.options[data.target.selectedIndex].text;
                    const data2 = {
                      label: selectedText,
                      value: value,
                    };

                    handleSelectChange(
                      data2,
                      "account_alternate_traning_location_country",
                      index
                    );
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={
                    altTrainerForm[index]
                      ?.account_alternate_traning_location_country
                  }
                  // defaultValue={{
                  //   id: altTrainerForm[index]
                  //     ?.account_alternate_traning_location_country,
                  //   value:
                  //     altTrainerForm[index]
                  //       ?.account_alternate_traning_location_country_name,
                  // }}
                  defaultValue={{
                    id: singleForm?.account_alternate_traning_location_country ?? "231",
                    value: singleForm?.account_alternate_traning_location_country_name ?? "United States",
                  }}
                />

                <Form.Control.Feedback type="invalid">
                  Please Enter Country.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Address </Form.Label>
                <Form.Control
                  type="text"
                  name="account_alternate_traning_location_address1"
                  value={singleForm.account_alternate_traning_location_address1}
                  onChange={updateFieldChanged(index)}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>Address 2</Form.Label>
                <Form.Control
                  type="text"
                  name="account_alternate_traning_location_address2"
                  value={singleForm.account_alternate_traning_location_address2}
                  onChange={updateFieldChanged(index)}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>City </Form.Label>
                <Form.Control
                  type="text"
                  name="account_alternate_traning_location_city"
                  value={singleForm.account_alternate_traning_location_city}
                  onChange={updateFieldChanged(index)}
                />
              </Form.Group>

              <Form.Group className={"col"}>
                <Form.Label>State </Form.Label>
                {/* <StateField
                  setFormData={setSubFormData}
                  valueKey="account_alternate_traning_location_state"
                  // selectedCountry={
                  //   selectedCountry?.account_alternate_traning_location_country
                  //     ?.value
                  // }

                  handleParentChange={(data)=>{
                    if(data){
                      let newArr = [...altTrainerForm];

                      newArr[index]['account_alternate_traning_location_state'] = data['id'];
                      newArr[index]['account_alternate_traning_location_state_name'] = data['name'];
                      setSubFormData(newArr);
                    }else{
                      let newArr = [...altTrainerForm];
                      
                      newArr[index]['account_alternate_traning_location_state'] = '';
                      newArr[index]['account_alternate_traning_location_state_name'] = '';
                        setSubFormData(newArr);

                    }
                  }}
                  selectedCountry={
                    altTrainerForm?.[index]
                      ?.account_alternate_traning_location_country
                  }
                  validated={false}
                  required={fieldsRequired ? true : false}
                  type="array"
                  index={index}
                  altTrainerForm={altTrainerForm}
                  stateSelectedValue={
                    altTrainerForm?.[index]
                      .account_alternate_traning_location_state
                  }

                  // stateSelectedValue={
                  //   altTrainerForm[0].account_alternate_traning_location_state
                  // }
                /> */}
              {altTrainerForm?.[index].account_alternate_traning_location_country == "231" ?
                <StateField
                  setFormData={setSubFormData}
                  valueKey="account_alternate_traning_location_state"
                  handleParentChange={(data)=>{
                    if(data){
                      let newArr = [...altTrainerForm];

                      newArr[index]['account_alternate_traning_location_state'] = data['id'];
                      newArr[index]['account_alternate_traning_location_state_name'] = data['name'];
                      setSubFormData(newArr);
                    } else {
                      let newArr = [...altTrainerForm];
                    
                      newArr[index]['account_alternate_traning_location_state'] = '';
                      newArr[index]['account_alternate_traning_location_state_name'] = '';
                      setSubFormData(newArr);
                    }
                  }}
                  selectedCountry={
                    altTrainerForm?.[index]
                      ?.account_alternate_traning_location_country
                  }
                  validated={false}
                  required={fieldsRequired ? true : false}
                  type="array"
                  index={index}
                  altTrainerForm={altTrainerForm}
                  stateSelectedValue={
                    altTrainerForm?.[index]
                      .account_alternate_traning_location_state
                  }
                /> :
                <>
                <PaginateAutoComplete
                  dropDownName="account_site_alternate_traning_location_state"
                  apiEndpoint={`account/get-state-by-country/${altTrainerForm?.[index].account_alternate_traning_location_country}`}
                  isState={true}
                  onSelect={(e, val) => {
                    setSubFormData((prev) => {
                      const newArr = [...altTrainerForm];
                      newArr[index] = { ...newArr[index], 
                        account_alternate_traning_location_state_name: val?.state_name, 
                        account_alternate_traning_location_state: val?.state_id };
                      return newArr;
                    });
                  }}
                  defaultValue={{
                    state_name: altTrainerForm?.[index]
                      .account_alternate_traning_location_state_name ?? "",
                    state_id: altTrainerForm?.[index]
                      .account_alternate_traning_location_state ?? "" 
                  }}
                  />
               </>
              }
              </Form.Group>
             
              <Form.Group className={"col"}>
                <Form.Label>Zip code </Form.Label>
                <Form.Control
                  type="number"
                  name="account_alternate_traning_location_zipcode"
                  defaultValue={
                    singleForm.account_alternate_traning_location_zipcode
                  }
                  onChange={updateFieldChanged(index)}
                />
              </Form.Group>
            </div>
          </div>
        ))}
    </>
  );
}
