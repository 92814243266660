import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Checkbox, FormControlLabel, Icon, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import { toast } from "react-toastify";

export default function SubFormAdmin({
    altTrainerForm,
    setSubFormData,
    increaseAlternative,
    decreaseAlternative,
    handleInputChange,
    allDropDowns,
    formData,
    formName,
    setFormData,
    noBtns,
    noMain,
    setSubFormPhoneValidated,
    sectionId="",
    sectionName="",
}) {

    const [allPhoneData, setAllPhoneData] = useState([]);
    const [phone, setPhone] = useState("");
    const [phoneExt, setPhoneExt] = useState("");
    const [phoneType, setPhoneType] = useState(1);
    const [main, setMain] = useState(false);
    const [phoneValidations, setPhoneValidations] = useState({});
    const [phoneErrors, setPhoneErrors] = useState([]);

    const validatePhoneNumbers = (forms) => {
        const newPhoneErrors = forms.map((form) => {
            const phone = form.account_main_contact_phone || '';
            return phone.trim() !== '' && phone.trim().length < 10;
        });
        setPhoneErrors(newPhoneErrors);
    };

    const updateFieldChanged = (e, index, name) => {
        const newArr = [...altTrainerForm];
        let newPhoneErrors = [...phoneErrors];

        if (e.target.name === 'account_main_contact_phone') {
            e.target.value = e.target.value.replace(/[^0-9]/g, "").trim();
            e.target.value = e.target.value.slice(0, 10);

            newArr[index][name] = e.target.value;

            if (e.target.value.trim().length < 10) {
                newPhoneErrors[index] = true;
            } else {
                newPhoneErrors[index] = false;
            }
        }

        if (name === `account_main_contact_phone_main`) {
            newArr.forEach((form, i) => {
                newArr[i].account_main_contact_phone_main = i === index ? (form.account_main_contact_phone_main ? 0 : 1) : 0;
            });
        } else {
            newArr[index][name] = e.target.value;
        }
        setPhoneErrors(newPhoneErrors);
        setSubFormData(newArr);
    };

    const handleDecreaseAlternative = (index) => {
        decreaseAlternative(index);
        validatePhoneNumbers(altTrainerForm);
    };

    useEffect(() => {
        validatePhoneNumbers(altTrainerForm);
    }, [altTrainerForm]);

    return (
        <>
            {altTrainerForm.map((singleForm, index) => (
        <div
  className="row mb-4"
  style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
  key={index}
  id={`${sectionId}_${index}`}
>
  <div
    className="form-group-responsive"
    style={{
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      width: "100%", // Ensure the container takes full width
      justifyContent: "flex-start", // Align content to the start
    }}
  >
    {/* Empty space to align with the labels */}
    <div
      style={{
        width: "120px", // Match the label width for alignment
        margin: 0,
      }}
    ></div>

    {/* Buttons */}
    {noBtns && (
      <div style={{ display: "flex", gap: "10px" }}>
        <button
          onClick={increaseAlternative}
          type="button"
          className="btn btn-sm btn-primary"
          name={`${sectionName}_plus`}
        >
          +
        </button>
        <button
          onClick={() => handleDecreaseAlternative(index)}
          type="button"
          className="btn btn-sm btn-danger"
          name={`${sectionName}_minus`}
        >
          −
        </button>
      </div>
    )}
  </div>

  {/* Rest of the form fields */}
         <Form.Group
                      className="form-group-responsive"
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1px",
                      }}
                    >
   <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Form.Label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Phone
      </Form.Label>
    <Form.Control
      type="text"
      name="account_main_contact_phone"
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "6px 10px",
        fontSize: "14px",
        color: "#333",
        height: "34px",
        width: "400px",
      }}
      value={singleForm.account_main_contact_phone}
      onChange={(e) => updateFieldChanged(e, index, "account_main_contact_phone")}
      pattern="[0-9]*"
      minLength={10}
      className={phoneErrors[index] ? "phone-invalid-input" : ""}
    />
       </div>
    {phoneErrors[index] && (
      <div className="phone-invalid"
      style={{
        marginLeft: "130px",
      }}>Please Enter Exact 10 digits.</div>
    )}
  </Form.Group>

  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <Form.Label
      style={{
        fontWeight: "bold",
        fontSize: "14px",
        color: "#333",
        width: "120px",
        margin: 0,
        textAlign: "right",
      }}
    >
      Ext
    </Form.Label>
    <Form.Control
      type="number"
      name="account_main_contact_phone_ext"
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "6px 10px",
        fontSize: "14px",
        color: "#333",
        height: "34px",
        width: "400px",
      }}
      onChange={(e) =>
        updateFieldChanged(e, index, "account_main_contact_phone_ext")
      }
      value={singleForm.account_main_contact_phone_ext}
    />
  </Form.Group>

  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <Form.Label
      style={{
        fontWeight: "bold",
        fontSize: "14px",
        color: "#333",
        width: "120px",
        margin: 0,
        textAlign: "right",
      }}
    >
      Phone Type
    </Form.Label>
    <Form.Select
      className={""}
      name="phone_type_id"
      style={{
        border: "1px solid #ccc",
        borderRadius: "4px",
        padding: "6px 10px",
        fontSize: "14px",
        color: "#333",
        height: "34px",
        width: "400px",
      }}
      onChange={(e) => updateFieldChanged(e, index, "phone_type_id")}
      value={singleForm.phone_type_id}
    >
      <option value="" selected>
        --Select One--
      </option>
      {allDropDowns?.phoneType &&
        allDropDowns?.phoneType.map((PT, index) => (
          <option value={PT.dropdown_phone_type_id} key={index}>
            {PT.dropdown_phone_type_name}
          </option>
        ))}
    </Form.Select>
  </Form.Group>

  <Form.Group
    className="form-group-responsive"
    style={{
      marginBottom: "10px",
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
  >
    <Form.Label
      style={{
        fontWeight: "bold",
        fontSize: "14px",
        color: "#333",
        width: "120px",
        margin: 0,
        textAlign: "right",
      }}
    >
      Main
    </Form.Label>
    <div className="">
      <FormControlLabel
        className={""}
        label=""
        name="account_main_contact_phone_main"
        onClick={(e) => {
          const phoneNumber = altTrainerForm[index].account_main_contact_phone;
          if (!phoneNumber || phoneNumber.trim() === "") {
            toast.error("Please first fill Phone number");
          } else {
            e.preventDefault();
            updateFieldChanged(e, index, "account_main_contact_phone_main");
          }
        }}
        control={
          <Radio
            color="primary"
            size="medium"
            checked={singleForm?.account_main_contact_phone_main}
          />
        }
      />
    </div>
  </Form.Group>
</div>
            ))}
        </>
    );
}
