import React from 'react'
import CommonDatePicker from '../../../../common/components/DatePickers/CommonDatePicker';
import BatteryComp from './sub-comp/BatteryComp';
import { calculate9vExpiryDate, CheckADTable, conditonalCheckingTypeOfBattery, HandleUnknow } from '../../../../common/helper/BasicFn';
import { FormatDate } from '../../../../common/helper/Common';
import moment from 'moment';
import ActionsComp from './sub-comp/ActionsComp';


const MenufectureDate = ({ is_unknowntrue, crrFormData, handleDateChange }) => {
    return (
        <>

            <div className='col-3 form-group' >
                <label htmlFor="">Manufactured Date</label>
                <CommonDatePicker
                    disabled={is_unknowntrue}
                    calName={'manufactured_date'}
                    CalVal={crrFormData?.manufactured_date}
                    HandleChange={handleDateChange}
                />
            </div>
        </>
    )
}


const InstallBeforeDateComp = ({ is_unknowntrue, crrFormData, handleDateChange }) => {
    return (
        <>

            <div className='col form-group' style={{ maxWidth: "300px" }} >
                <label htmlFor="">Installed Before Date</label>
                <CommonDatePicker
                    disabled={is_unknowntrue}
                    calName={'install_before_date'}
                    CalVal={crrFormData?.install_before_date}
                    HandleChange={handleDateChange}
                />
            </div>
        </>
    )
}

const BatteryExpiery = ({ is_unknowntrue, crrFormData, handleDateChange }) => {
    return (
        <>
            <div className='col form-group' >
                <label htmlFor="">Battery Expiration</label>

                <CommonDatePicker
                    disabled={is_unknowntrue}
                    calName={'battery_expiration'}
                    CalVal={crrFormData?.battery_expiration}
                    HandleChange={handleDateChange}
                />

            </div>
        </>
    )
}

const InstalledDate = ({ is_unknowntrue, crrFormData, handleDateChange }) => {
    return (<>

        <div className='col-3 form-group' >
            <label htmlFor="">Installed Date</label>
            <CommonDatePicker
                disabled={is_unknowntrue}
                calName={'date_installed'}
                CalVal={crrFormData?.install_date}
                HandleChange={handleDateChange}
            />
        </div>
    </>)
}

function SpareBatteryinformation({
    keyName,
    crrIndex,
    formData,
    setFormData,
    crrFormData,
    Permissins,
    bettery_type,
    BatteryList,
    is_unknowntrue,
    batteryInfo,
    batteryIndex,
    RenderDate,
    print_battery_part,
    is_spare_manu = 0,
    DataValue,
    unKnownToggleKey
}) {
    const handleInputChange = (e) => {
        let index = crrIndex;
        let name = e.target.name;
        let val = e.target.value;
        const oldData = { ...formData };
        oldData[keyName][index][name] = val;
        setFormData(oldData);
    }

    const handleDateChange = (name, val) => {
        const oldData = { ...formData };
        oldData[keyName][crrIndex][name] = val;
        if (name === 'date_installed' && (bettery_type !== 1 || bettery_type !== 2)) {
            let add_year = BatteryList.find((item) => parseInt(item.battery_type_id) === parseInt(oldData[keyName][crrIndex]['battery_type_id']));
            // .calendar()
            let battery_expiration = FormatDate(moment(val).add(add_year?.lifespan, 'years'));
            oldData[keyName][crrIndex]['battery_expiration'] = battery_expiration;
        }
        setFormData(oldData);;
    }



    if (bettery_type === 5) {
        return <>
            <hr />
            <div className='row' >
                <BatteryComp is_readonly={is_unknowntrue} crrFormData={crrFormData} BatteryList={BatteryList} handleInputChange={handleInputChange} crrIndex={crrIndex} />

                {/* <InstalledDate
                 is_unknowntrue={is_unknowntrue}  crrFormData={crrFormData}  handleDateChange={handleDateChange}
                /> */}
                <InstallBeforeDateComp
                    is_unknowntrue={is_unknowntrue} crrFormData={crrFormData} handleDateChange={handleDateChange}
                />

                <ActionsComp
                    BatteryList={BatteryList}
                    index={0}
                    crrIndex={crrIndex}
                    formData={formData}
                    setFormData={setFormData}
                    section_name={keyName}
                    crrFormData={crrFormData}
                    unKnownToggleKey={unKnownToggleKey}
                />
            </div>

        </>
    }

    if (bettery_type === 4) {
        return (<>
            <div className='row' key={crrIndex} >
                <div className='col-12' >
                    <hr />
                </div>
                <BatteryComp is_readonly={is_unknowntrue} crrFormData={crrFormData} BatteryList={BatteryList} handleInputChange={handleInputChange} crrIndex={crrIndex} />


                <MenufectureDate is_unknowntrue={is_unknowntrue} crrFormData={crrFormData} handleDateChange={handleDateChange} />
                {(is_spare_manu === 1 ?
                    "" :
                    <InstalledDate
                        is_unknowntrue={is_unknowntrue} crrFormData={crrFormData} handleDateChange={handleDateChange}

                    />)}

                <div className='col form-group' >
                    <label htmlFor="">Battery lot</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_lot)} name="battery_lot" disabled={is_unknowntrue} />
                </div>


                <div className='col form-group' >
                    <label htmlFor="">Battery UDI</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_udi)} name="battery_udi" disabled={is_unknowntrue} />
                </div>

                <ActionsComp
                    BatteryList={BatteryList}
                    index={0}
                    crrIndex={crrIndex}
                    formData={formData}
                    setFormData={setFormData}
                    section_name={keyName}
                    crrFormData={crrFormData}
                    unKnownToggleKey={unKnownToggleKey}
                />
            </div>
        </>);
    }


    if (bettery_type === 3) {
        return (<>
            <div className='col' key={crrIndex} >
                <div className='col-12' >
                    <hr />
                </div>

                <div className='row'>
                    <BatteryComp is_readonly={is_unknowntrue} crrFormData={crrFormData} BatteryList={BatteryList} handleInputChange={handleInputChange} crrIndex={crrIndex} />

                    <InstallBeforeDateComp
                        is_unknowntrue={is_unknowntrue} crrFormData={crrFormData} handleDateChange={handleDateChange}

                    />

                    {/* <InstalledDate
                 is_unknowntrue={is_unknowntrue}  crrFormData={crrFormData}  handleDateChange={handleDateChange}
                
                /> */}

                    <div className='col form-group' >
                        <label htmlFor="">Battery lot</label>
                        <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_lot)} name="battery_lot" disabled={is_unknowntrue} />
                    </div>

                    <div className='col form-group' >
                        <label htmlFor="">Battery UDI</label>
                        <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_udi)} name="battery_udi" disabled={is_unknowntrue} />
                    </div>

                    <div className='col form-group' >
                        <label htmlFor="">Serial #</label>
                        <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_serial)} name="battery_serial" disabled={is_unknowntrue} />
                    </div>

                    <ActionsComp
                        BatteryList={BatteryList}
                        index={0}
                        crrIndex={crrIndex}
                        formData={formData}
                        setFormData={setFormData}
                        section_name={keyName}
                        crrFormData={crrFormData}
                        unKnownToggleKey={unKnownToggleKey}
                    />
                </div>

            </div>
        </>);
    }

    if (bettery_type === 2) {
        return (<>
            <div className='row' key={crrIndex} >
                <div className='col-12' >
                    <hr />
                </div>

                <BatteryComp is_readonly={is_unknowntrue} crrFormData={crrFormData} BatteryList={BatteryList} handleInputChange={handleInputChange} crrIndex={crrIndex} />

                <BatteryExpiery


                    is_unknowntrue={is_unknowntrue}
                    crrFormData={crrFormData}
                    handleDateChange={handleDateChange}
                />

                <div className='col form-group' >
                    <label htmlFor="">Battery lot</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_lot)} name="battery_lot" disabled={is_unknowntrue} />
                </div>


                <div className='col form-group' >
                    <label htmlFor="">Battery UDI</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_udi)} name="battery_udi" disabled={is_unknowntrue} />
                </div>

                <div className='col form-group' >
                    <label htmlFor="">9v Install</label>

                    <CommonDatePicker
                        disabled={is_unknowntrue}
                        calName={'v9_install'}
                        CalVal={crrFormData?.install_9v_date}
                        HandleChange={handleDateChange}
                    />
                </div>

                <ActionsComp
                    BatteryList={BatteryList}
                    index={0}
                    crrIndex={crrIndex}
                    formData={formData}
                    setFormData={setFormData}
                    section_name={keyName}
                    crrFormData={crrFormData}
                    unKnownToggleKey={unKnownToggleKey}
                />
            </div>
        </>);
    }


    if (bettery_type === 1) {
        return (<>
            <div className='row' key={crrIndex} >
                <div className='col-12' >
                    <hr />
                </div>


                <BatteryComp is_readonly={is_unknowntrue} crrFormData={crrFormData} BatteryList={BatteryList} handleInputChange={handleInputChange} crrIndex={crrIndex} />

                <BatteryExpiery


                    is_unknowntrue={is_unknowntrue}
                    crrFormData={crrFormData}
                    handleDateChange={handleDateChange}
                />

                <div className='col form-group' >
                    <label htmlFor="">Battery lot</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_lot)} name="battery_lot" disabled={is_unknowntrue} />
                </div>


                <div className='col form-group' >
                    <label htmlFor="">Battery UDI</label>
                    <input onChange={handleInputChange} className='form-control' type="text" id="" value={HandleUnknow(crrFormData?.battery_udi)} name="battery_udi" disabled={is_unknowntrue} />
                </div>

                <ActionsComp
                    BatteryList={BatteryList}
                    index={0}
                    crrIndex={crrIndex}
                    formData={formData}
                    setFormData={setFormData}
                    section_name={keyName}
                    crrFormData={crrFormData}
                    unKnownToggleKey={unKnownToggleKey}
                />

            </div>
        </>);
    }

    const typeofBattery = conditonalCheckingTypeOfBattery(DataValue)

    const displayColumns = (typeOfBat) => {
        let cols = [];
        if (typeOfBat == "has_man") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                { key: "manufactured_date", title: "Manufactured Date" },
                { key: "battery_lot", title: "Battery Lot" },
                { key: "battery_udi", title: "Battery UDI" },
            );
        }
        else if (typeOfBat == "has_9v") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                { key: "battery_expiration", title: "Battery Expiration" },
                { key: "install_9v_date", title: "9v Install Date" },
                { key: "9v_expiry_date", title: "9v Expiry Date" },
                { key: "battery_lot", title: "Battery Lot" },
                { key: "battery_udi", title: "Battery UDI" },
            );
        }
        else if (typeOfBat == "has_battery") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                { key: "battery_expiration", title: "Battery Expiration" },
                { key: "battery_lot", title: "Battery Lot" },
                { key: "battery_udi", title: "Battery UDI" },
            );
        }
        else if (typeOfBat == "has_installby") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                // { key: "battery_expiration", title: "Battery Expiration" },
                { key: "battery_serial", title: "Serial" },
                // { key: "install_date", title: "Install Date" },
                { key: "battery_lot", title: "Battery Lot" },
                { key: "battery_udi", title: "Battery UDI" },
                { key: "install_before_date", title: "Install Before Date" },
            );
        }
        else if (typeOfBat == "has_10pk") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                // { key: "battery_expiration", title: "Battery Expiration" },
                // { key: "install_date", title: "Install Date" },
                { key: "install_before_date", title: "Install Before Date" },
            );
        }
        else if (typeOfBat == "charge_pack") {
            cols.push(
                { key: "battery_type_id", title: "Battery Part" },
                { key: "battery_expiration", title: "Battery Expiration" },
                { key: "battery_lot", title: "Battery Lot" },
                { key: "charge_pak_uid", title: "Battery UDI" },
            );
        }
        return cols;
    }

    const renderCellValue = (key, value) => {

        // Special handling for calculated expiry date
        if (key === "9v_expiry_date") {
            const expiryDate = calculate9vExpiryDate(DataValue?.install_9v_date);
            return expiryDate === "unknown" ? "unknown" : RenderDate(expiryDate, 0);
        }

        if (value === "unknown") return "unknown";
        if (!value) return "";

        switch (key) {
            case "battery_type_id":
                return print_battery_part(value);
            case "battery_expiration":
                return RenderDate(FormatDate(value), 1);
            case "install_9v_date":
                return RenderDate(FormatDate(value), 0);
            case "manufactured_date":
            case "install_date":
            case "install_before_date":
                return RenderDate(value, 0);
            default:
                return value;
        }
    };

    const columns = displayColumns(typeofBattery);

    return (
        // <>
        //     <tr className="" key={batteryIndex}>
        //         {/* <td>Main</td> */}
        //         {CheckADTable(batteryInfo, 'battery_type_id') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {print_battery_part(DataValue?.battery_type_id)}
        //             </td> : ""}

        //         {CheckADTable(batteryInfo, 'battery_expiration') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(FormatDate(DataValue?.battery_expiration), 1)} */}
        //                 {
        //                     DataValue?.battery_expiration === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(FormatDate(DataValue?.battery_expiration), 1)
        //                 }
        //             </td> : ""}

        //         {CheckADTable(batteryInfo, 'battery_serial') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {DataValue?.battery_serial}
        //             </td> : ""}
        //         {CheckADTable(batteryInfo, 'install_9v_date') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(DataValue?.install_9v_date, 0)} */}
        //                 {
        //                     DataValue?.install_9v_date === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(DataValue?.install_9v_date, 0)
        //                 }
        //             </td> : ""}
        //         {CheckADTable(batteryInfo, 'install_9v_date') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(moment(DataValue?.install_9v_date).add(1, 'year').format('YYYY-MM-DD'))} */}
        //                 {
        //                     DataValue?.install_9v_date === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(moment(DataValue?.install_9v_date).add(1, 'year').format('YYYY-MM-DD'))
        //                 }
        //             </td> : ""}
        //         {CheckADTable(batteryInfo, 'manufactured_date') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(DataValue?.manufactured_date, 0)} */}
        //                 {
        //                     DataValue?.manufactured_date === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(DataValue?.manufactured_date, 0)
        //                 }
        //             </td> : ""}
        //         {CheckADTable(batteryInfo, 'installed_date') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(DataValue?.installed_date, 0)} */}
        //                 {
        //                     DataValue?.installed_date === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(DataValue?.installed_date, 0)
        //                 }
        //             </td>
        //             : ""}

        //         {CheckADTable(batteryInfo, 'battery_lot') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {DataValue?.battery_lot}
        //             </td>
        //             : ""}

        //         {CheckADTable(batteryInfo, 'battery_udi') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {DataValue?.battery_udi}
        //             </td>
        //             : (
        //                 <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"></td>
        //             )}
        //         {CheckADTable(batteryInfo, 'install_before_date') === 1 ?
        //             <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
        //                 {/* {RenderDate(DataValue?.install_before_date, 0)} */}
        //                 {
        //                     DataValue?.install_before_date === 'unknown' ?
        //                         'unknown' :
        //                         RenderDate(DataValue?.install_before_date, 0)
        //                 }
        //             </td>
        //             : ""}
        //     </tr>

        // </>
        <tr className="" key={batteryIndex}>
            {columns.map((column) => (
                <td key={column.key} className="border bg-tbl-border py-1 px-2 border-r-blue">
                    {renderCellValue(column.key, DataValue[column.key])}
                </td>
            ))}
        </tr>
    )
}

export default SpareBatteryinformation