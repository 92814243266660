import { Box, Skeleton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { truncateText } from "../../../common/helper/BasicFn";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { isPermission } from "../../helper/PermissionManager";

const CoorrInfo = ({ CoordiDataList, loading, accountId = "" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleContactDetailsDispatch = (
    accountId,
    account_name,
    contact_id
  ) => {
    BreadCrumbsObject.title = account_name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "contact";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/" + accountId + "/contact-details/" + contact_id,
      })
    );
  };
  return (
    <>
       <div
              className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
              style={{
                background: "white",
                border: "2px solid #00578E",
                borderRadius: "5px"
              }}
              id="main_account_section"
            >
  <Box>
  <h4 className="heading mt-3">Coordinator Information</h4>
  <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
    {/* First Column */}
    <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
      {CoordiDataList?.map(
        (CoorD, index) =>
          CoorD.title !== "Shipping Contact" &&
          CoorD.title !== "Billing Coordinator" && (
            index % 2 === 0 ? (
              <div key={index} style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                <strong style={{ width: "200px", textAlign: "right" }}>{CoorD.title}</strong>
                <div style={{ width: "200px", textAlign: "left" }}>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : (
                    <>
                      P:
                      <span
                        onClick={() => {
                          if (
                            Number(
                              isPermission({
                                type: "link",
                                name: "cordiIPrimary",
                              })
                            ) === 1
                          ) {
                            handleContactDetailsDispatch(
                              accountId,
                              CoorD?.arr?.[0]?.primary ||
                                CoorD?.arr?.[1]?.primary ||
                                "",
                              CoorD?.arr?.[0]?.contact_id
                            );
                            navigate(
                              "/account/" +
                                accountId +
                                "/contact-details/" +
                                CoorD?.arr?.[0]?.contact_id,
                              {
                                state: {
                                  siteTitle:
                                    "Contact : " + CoorD?.arr?.[0]?.primary,
                                  editUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                  deleteUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                },
                              }
                            );
                          }
                        }}
                        className={
                          Number(
                            isPermission({
                              type: "link",
                              name: "cordiIPrimary",
                            })
                          ) === 1
                            ? "link"
                            : ""
                        }
                        title={
                          CoorD?.arr?.[0]?.primary ||
                          CoorD?.arr?.[1]?.primary ||
                          "".length > 15
                            ? CoorD?.arr?.[0]?.primary ||
                              CoorD?.arr?.[1]?.primary ||
                              ""
                            : undefined
                        }
                      >
                        {" "}
                        {truncateText(
                          CoorD?.arr?.[0]?.primary ||
                            CoorD?.arr?.[1]?.primary ||
                            "",
                          15
                        )}
                      </span>
                      <br />
                      B:
                      <span
                        onClick={() => {
                          if (
                            Number(
                              isPermission({
                                type: "link",
                                name: "cordiIBackup",
                              })
                            ) === 1
                          ) {
                            handleContactDetailsDispatch(
                              accountId,
                              CoorD?.arr?.[0]?.backup ||
                                CoorD?.arr?.[1]?.backup ||
                                "",
                              CoorD?.arr?.[1]?.contact_id
                            );
                            navigate(
                              "/account/" +
                                accountId +
                                "/contact-details/" +
                                CoorD?.arr?.[1]?.contact_id,
                              {
                                state: {
                                  siteTitle:
                                    "Contact : " + CoorD?.arr?.[1]?.backup,
                                  editUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                  deleteUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                },
                              }
                            );
                          }
                        }}
                        className={
                          Number(
                            isPermission({
                              type: "link",
                              name: "cordiIBackup",
                            })
                          ) === 1
                            ? "link"
                            : ""
                        }
                        title={
                          CoorD?.arr?.[0]?.backup ||
                          CoorD?.arr?.[1]?.backup ||
                          "".length > 15
                            ? CoorD?.arr?.[0]?.backup ||
                              CoorD?.arr?.[1]?.backup ||
                              ""
                            : undefined
                        }
                      >
                        {" "}
                        {truncateText(
                          CoorD?.arr?.[0]?.backup ||
                            CoorD?.arr?.[1]?.backup ||
                            "",
                          18
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : null
          )
      )}
    </div>

    {/* Second Column */}
    <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
      {CoordiDataList?.map(
        (CoorD, index) =>
          CoorD.title !== "Shipping Contact" &&
          CoorD.title !== "Billing Coordinator" && (
            index % 2 !== 0 ? (
              <div key={index} style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                <strong style={{ width: "200px", textAlign: "right" }}>{CoorD.title}</strong>
                <div style={{ width: "200px", textAlign: "left" }}>
                  {loading ? (
                    <>
                      <Skeleton
                        count={5}
                        width={"90%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    </>
                  ) : (
                    <>
                      P:
                      <span
                        onClick={() => {
                          if (
                            Number(
                              isPermission({
                                type: "link",
                                name: "cordiIPrimary",
                              })
                            ) === 1
                          ) {
                            handleContactDetailsDispatch(
                              accountId,
                              CoorD?.arr?.[0]?.primary ||
                                CoorD?.arr?.[1]?.primary ||
                                "",
                              CoorD?.arr?.[0]?.contact_id
                            );
                            navigate(
                              "/account/" +
                                accountId +
                                "/contact-details/" +
                                CoorD?.arr?.[0]?.contact_id,
                              {
                                state: {
                                  siteTitle:
                                    "Contact : " + CoorD?.arr?.[0]?.primary,
                                  editUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                  deleteUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                },
                              }
                            );
                          }
                        }}
                        className={
                          Number(
                            isPermission({
                              type: "link",
                              name: "cordiIPrimary",
                            })
                          ) === 1
                            ? "link"
                            : ""
                        }
                        title={
                          CoorD?.arr?.[0]?.primary ||
                          CoorD?.arr?.[1]?.primary ||
                          "".length > 15
                            ? CoorD?.arr?.[0]?.primary ||
                              CoorD?.arr?.[1]?.primary ||
                              ""
                            : undefined
                        }
                      >
                        {" "}
                        {truncateText(
                          CoorD?.arr?.[0]?.primary ||
                            CoorD?.arr?.[1]?.primary ||
                            "",
                          15
                        )}
                      </span>
                      <br />
                      B:
                      <span
                        onClick={() => {
                          if (
                            Number(
                              isPermission({
                                type: "link",
                                name: "cordiIBackup",
                              })
                            ) === 1
                          ) {
                            handleContactDetailsDispatch(
                              accountId,
                              CoorD?.arr?.[0]?.backup ||
                                CoorD?.arr?.[1]?.backup ||
                                "",
                              CoorD?.arr?.[1]?.contact_id
                            );
                            navigate(
                              "/account/" +
                                accountId +
                                "/contact-details/" +
                                CoorD?.arr?.[1]?.contact_id,
                              {
                                state: {
                                  siteTitle:
                                    "Contact : " + CoorD?.arr?.[1]?.backup,
                                  editUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                  deleteUrl:
                                    "/account/contact-details-edit/" +
                                    (CoorD?.arr?.[0]?.contact_id ||
                                      CoorD?.arr?.[1]?.contact_id),
                                },
                              }
                            );
                          }
                        }}
                        className={
                          Number(
                            isPermission({
                              type: "link",
                              name: "cordiIBackup",
                            })
                          ) === 1
                            ? "link"
                            : ""
                        }
                        title={
                          CoorD?.arr?.[0]?.backup ||
                          CoorD?.arr?.[1]?.backup ||
                          "".length > 15
                            ? CoorD?.arr?.[0]?.backup ||
                              CoorD?.arr?.[1]?.backup ||
                              ""
                            : undefined
                        }
                      >
                        {" "}
                        {truncateText(
                          CoorD?.arr?.[0]?.backup ||
                            CoorD?.arr?.[1]?.backup ||
                            "",
                          18
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ) : null
          )
      )}
    </div>
  </div>
</Box>
</div>
    </>
  );
};

export default CoorrInfo;
