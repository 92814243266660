import "./common/css/table.css";

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { fetchPermissions } from "./store/slices/permissionsSlice";
import PublicRoutes from "./routes/PublicRoutes";
import UnauthorizedPage from "./domains/auth/pages/UnauthorizedPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotFound404 from "./NotFound404";
import { CallGETAPI, CallPOSTAPI } from "./common/services";
import { DecryptToken } from "./common/helper";
import PrivateRoutes from "./routes/PrivateRoutes";
import { Spinner } from "react-bootstrap";
import TableSkeleton from "./common/components/Loader/TableSkeleton";
import {
  compareAndUpdateToken,
  pathChangeDecryptToken,
} from "./common/helper/BasicFn";
import { handleClearEquipmentFilter } from "./domains/accounts/components/filter/helperFunction.js/AEDFilterFn";
import {
  removeFilterData,
  removePayloadData,
} from "./store/slices/AccountDetailsEquipmentFilter";
import { useBreadcrumbPersistence } from "./common/components/BreadCrumbPersitance";

const queryClient = new QueryClient();

// Create a separate component for the main app content
const AppContent = () => {
  const dispatch = useDispatch();
  const user = DecryptToken();
  const location = useLocation();
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check authentication status
    const token = localStorage.getItem("ross_token");
    setIsAuthenticated(!!token);
  }, []);

  // Conditional hook initialization
  useBreadcrumbPersistence(isAuthenticated);

  const fetchPermissionAPI = async () => {
    try {
      const user = await DecryptToken();
      const result = await CallGETAPI("auth/priviledge");
      const permission = JSON.stringify(result?.data?.data?.data);
      const oldToken = await DecryptToken();

      const newToken = await pathChangeDecryptToken(
        result?.data?.data?.refreshtoken
      );
      if (Number(sessionStorage.getItem("is_user")) === 1) {
      } else {
        const wow = compareAndUpdateToken({
          oldToken: oldToken,
          newToken: newToken,
          refreshtoken: result?.data?.data?.refreshtoken,
        });
      }

      if (permission) {
        const is_user = Number(sessionStorage.getItem("is_user") || 0);
        if (is_user && is_user === 1) {
          sessionStorage.setItem("Permission", permission);
        } else {
          localStorage.setItem("Permission", permission);
        }
        setPermissionsLoaded(true);
      } else {
        console.error("Permission is undefined or null.");
        setPermissionsLoaded(false);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
      setPermissionsLoaded(false);
    }
  };

  const handleClearEquipmentFilter = () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
  };

  // Refresh Token Function
  const refreshTokenApi = async () => {
    const is_user = Number(sessionStorage.getItem("is_user") || 0);
    const oldRefreshToken =
      is_user === 1
        ? sessionStorage.getItem("ross_rtoken")
        : localStorage.getItem("ross_rtoken");
    if (!oldRefreshToken) {
      console.log("No refresh token found, please login again.");
      return;
    }
    try {
      const response = await CallPOSTAPI("auth/refresh-token", {
        email: user.email,
        refresh_token: oldRefreshToken,
      });
      console.log({ response });
      const { token, refreshtoken } = response.data.data;
      is_user === 1
        ? sessionStorage.setItem("ross_token", token)
        : localStorage.setItem("ross_token", token); // Update access token
      is_user === 1
        ? sessionStorage.setItem("ross_rtoken", refreshtoken)
        : localStorage.setItem("ross_rtoken", refreshtoken); // Update refresh token
      console.log("Token refreshed successfully!");
    } catch (error) {
      console.error("Refresh token failed:", error);
    }
  };

  // Timer for Refresh Token (2 minutes for now)
  // useEffect(() => {
  //   // const intervalTime = 2 * 60 * 1000; // 2 minutes in milliseconds
  //   const intervalTime =
  //     Number(user?.user_type) === 0 ? 358 * 60 * 1000 : 118 * 60 * 1000; // 119 minutes for production

  //   // Only run refresh if permissions are loaded and user is authenticated
  //   let interval;
  //   console.log("Token refreshed successfully!");
  //   if (permissionsLoaded) {
  //     interval = setInterval(() => {
  //       refreshTokenApi();
  //     }, intervalTime);
  //   }

  //   // Cleanup interval on unmount or when condition changes
  //   return () => {
  //     if (interval) clearInterval(interval);
  //   };
  // }, [permissionsLoaded]); // Dependencies added

  useEffect(() => {
    if (
      location.pathname === "/accounts-listing" ||
      location.pathname === "/Admin/Sites-listing" ||
      location.pathname === "/Admin/Contact-listing" ||
      location.pathname === "/Admin/accessory-listing" ||
      location.pathname === "/Admin/Aed-listing" ||
      location.pathname === "/account/support-listing" ||
      location.pathname === "/Admin/Note-listing" ||
      location.pathname === "/account/assign-admin" ||
      location.pathname === "/account/multiple-account-permission" ||
      location.pathname === "/account/assign-create-permission" ||
      location.pathname === "/account/assign-permission-user" ||
      location.pathname === "/account/assign-permission-position" ||
      location.pathname === "/import-csv/Accounts" ||
      location.pathname === "/dashboard"
    ) {
      handleClearEquipmentFilter();
    }
    const is_user = Number(sessionStorage.getItem("is_user") || 0);
    let token = "";
    if (is_user && is_user === 1) {
      token = sessionStorage.getItem("ross_token");
    }
    token = localStorage.getItem("ross_token");

    if (token && Number(user?.user_type) !== 0) {
      fetchPermissionAPI();
    } else {
      setPermissionsLoaded(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (Number(sessionStorage.getItem("is_user")) === 1) {
      fetchPermissionAPI();
    }
  }, [sessionStorage.getItem("is_user")]);

  // Render loading state if permissions are not loaded
  if (!permissionsLoaded) {
    return (
      <div
        style={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
        }}
      >
        <TableSkeleton />
      </div>
    );
  }

  return (
    <Routes>
      {/* Public Routes */}
      {PublicRoutes.map((item, i) => (
        <Route key={i} exact path={item.path} element={item.component} />
      ))}

      <Route element={<PrivateRoutes />}>
        {ProtectedRoute.map((item, i) => (
          <Route key={i} exact path={item.path} element={item.component} />
        ))}
      </Route>

      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<NotFound404 />} />
    </Routes>
  );
};

// Wrap the entire app with Router
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
};

export default App;
