import { Skeleton } from '@mui/material';
import React from 'react';
import { Box } from '@mui/material';

const DataTableSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, padding: 2 }}>
      {/* First Column */}
      <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
      </div>

      {/* Second Column */}
      <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
          <Skeleton width={200} height={20} style={{ marginRight: "10px" }} />
          <Skeleton width={200} height={20} />
        </div>
      </div>
    </Box>
  );
};

export default DataTableSkeleton;