import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";
import { useNavigate } from "react-router-dom";
import {
  DecryptToken,
  formatPhoneNumber,
  getPermission,
} from "../../../../common/helper";
import Edit from "../../../../common/img/Edit.png";
import New from "../../../../common/img/New.png";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  addNewBreadcrumbs,
} from "../../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../../common/helper/PermissionManager";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable";
import { Skeleton } from "@mui/material";

const SiteTranningAddress = ({ siteId, siteData }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const privileges = getPermission();
  const user = DecryptToken();
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const [trainingData, setTraningData] = useState([]);

  const handleDelete = async (id) => {
    const res = await CallGETAPI(
      `site/delete-training-address/${id}?site-traningaddress`
    );
    if (res?.status) {
      toast.success(res?.data?.msg);
      fetchData();
    } else {
      toast.error(res?.data?.msg);
    }
  };

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "site",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleDispatch = (accountId, siteId) => {
    BreadCrumbsObject.title = "New Trainning site Form";
    BreadCrumbsObject.tab = "SITE_DETAILS";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/" + accountId + "/site/" + siteId + "/training/new",
      })
    );
  };

  const handleEditDispatch = (
    account_id,
    account_site_info_id,
    account_alternate_traning_location_id
  ) => {
    BreadCrumbsObject.title = "Edit Trainning Address";
    BreadCrumbsObject.tab = "SITE_DETAILS";
    BreadCrumbsObject.type = "sites";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url:
          "/account/" +
          account_id +
          "/site/" +
          account_site_info_id +
          `/${account_alternate_traning_location_id}/training/edit`,
      })
    );
  };

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI("site/site-trainning-data/" + siteId);
    if (result?.data?.status) {
      const resultData = result?.data?.data?.trainingLocations;
      setTraningData(resultData);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Prepare header columns for NewGlobalTable
  const headerColumns = [
    {
      Header: 'Company Name',
      accessor: 'account_alternate_traning_location_company_name',
      Cell: ({ row }) => {
        if (loading) {
          return <div style={{ width: '90%', height: '20px', background: '#e0e0e0' }} />;
        }
        return row.original.account_alternate_traning_location_company_name || siteData?.account_site_name;
      }
    },
    {
      Header: 'Phone Number',
      accessor: 'alternative_phone',
      Cell: ({ row }) => {
        if (loading) {
          return <div style={{ width: '90%', height: '20px', background: '#e0e0e0' }} />;
        }

        const phoneNumber = row.original.alternative_phone || siteData?.account_site_phone;
        const phoneExt = row.original.alternative_ext || siteData?.account_site_phone_ext;

        if (!phoneNumber) return '';

        const formattedPhone = formatPhoneNumber(phoneNumber);
        const phoneLink = `tel:${phoneExt}${phoneNumber}`;

        return (
          <a
            href={phoneLink}
            className="link"
            style={{ textDecoration: 'none' }}
          >
            {formattedPhone} {phoneExt ? ` X ${phoneExt}` : ''}
          </a>
        );
      }
    },
    {
      Header: 'Address',
      accessor: 'address',
      Cell: ({ row }) => {
        if (loading) {
          return <div style={{ width: '90%', height: '20px', background: '#e0e0e0' }} />;
        }

        // Handle site data first, then training data
        const addressData = row.original.account_alternate_traning_location_address1
          ? {
            buildingName: row.original.account_alternate_traning_location_company_name,
            address1: row.original.account_alternate_traning_location_address1,
            address2: row.original.account_alternate_traning_location_address2,
            city: row.original.account_alternate_traning_location_city,
            state: row.original.account_alternate_traning_location_state_name,
            zipcode: row.original.account_alternate_traning_location_zipcode,
            country: row.original.account_alternate_traning_location_country_name
          }
          : {
            buildingName: siteData?.building_name,
            address1: siteData?.account_site_address1,
            address2: siteData?.account_site_address2,
            city: siteData?.account_site_city,
            state: siteData?.account_site_state,
            zipcode: siteData?.account_site_zipcode,
            country: siteData?.account_site_country
          };

        return `${addressData.buildingName || ''} ${addressData.address1 || ''}, 
                ${addressData.address2 ? `${addressData.address2}, ` : ''}
                ${addressData.city ? `${addressData.city}, ` : ''}
                ${addressData.state || ''} ${addressData.zipcode || ''}, 
                ${addressData.country || ''}`.replace(/\s+/g, ' ').trim();
      }
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      disableSortBy: true,
      Cell: ({ row }) => {
        if (loading) return null;

        const trainingLocationId = row.original.account_alternate_traning_location_id;

        return (
          <div className="d-flex">
            <button
              className="new-btn btn d-flex edit"
              onClick={() => {
                handleEditDispatch(
                  siteData.account_id,
                  siteData.account_site_info_id,
                  trainingLocationId
                );
                navigate(
                  `/account/${siteData.account_id}/site/${siteData.account_site_info_id}/${trainingLocationId}/training/edit`,
                  {
                    state: {
                      trainingData: row.original,
                    },
                  }
                );
              }}
            >
              <img src={Edit} style={{ width: "20px", height: "20px", marginRight: "5px" }} />
              Edit
            </button>
            <button
              className="new-btn btn d-flex delete"
              onClick={() => handleDelete(trainingLocationId)}
            >
              <img
                src="/assets/images/trashcan_icon.svg"
                alt="TrashIcon"
                style={{ width: "20px", height: "20px", marginRight: "5px" }}
              />
              Delete
            </button>
          </div>
        );
      }
    }
  ];

  // Combine site data and training data
  const combinedData = [siteData, ...(trainingData || [])];

  return (
    // <div style={{ border: "2px solid #0C71C3", borderRadius: "8px", padding: "14px", backgroundColor: "white" }}>
    //   <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
    //     <div
    //       className=""
    //       style={{ fontWeight: "600", color: "#0C71C3", fontSize: "28px", paddingLeft: "10px" }}
    //     >
    //       Training Address
    //     </div>
    //     <div
    //       className="no-border"
    //       style={{ textAlign: "end" }}
    //     >
    //       {Number(isPermission({ type: "btn", name: "trainingNew" })) === 1 && (
    //         <button
    //           className="bg-transparent new-btn align-right"
    //           onClick={() => {
    //             handleDispatch(
    //               siteData.account_id,
    //               siteData.account_site_info_id
    //             );
    //             navigate(
    //               "/account/" +
    //               siteData.account_id +
    //               "/site/" +
    //               siteData.account_site_info_id +
    //               "/training/new"
    //             );
    //           }}
    //         >
    //           <img src={New} style={{ marginRight: "5px" }} />
    //           New
    //         </button>
    //       )}
    //     </div>
    //   </div>
    <>
      <NewGlobalTable
        siteTraningData={siteData}
        title="Training Address"
        newButtonPath={Number(isPermission({ type: "btn", name: "trainingNew" })) === 1 ? `/account/${siteData.account_id}/site/${siteData.account_site_info_id}/training/new` : ``}
        tableData={combinedData}
        headerColumns={headerColumns}
        isTblUpdated={loading}
        apiEndPoint={"site/site-trainning-data/" + siteId}
        method="GET"
        keyAPiName={"trainingLocations"}
      />
    </>
    // </div>
  );
};

export default SiteTranningAddress;