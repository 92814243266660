import React from 'react';
import { Skeleton } from '@mui/material';

export default function TableSkeleton() {
  // Table styling from AEDOutOfServiceTbl
  const thStyle = {
    backgroundColor: "#ffffff",
    padding: "8px",
    textAlign: "left",
    fontWeight: "500",
    color: "#b6b6b6",
    borderBottom: "1px solid #ddd",
    borderTop: "1.4px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    color: "#333",
    fontWeight: 600,
    borderBottom: "1.2px solid #ddd",
    backgroundColor: "#ffffff"
  };

  return (
    <div style={{ marginBottom: "3%" }}>
      <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px", marginBottom: "3%" }}>
        <thead>
          <tr>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={120} height={20} />
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={100} height={20} />
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={110} height={20} />
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={130} height={20} />
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={120} height={20} />
              </div>
            </th>
            <th style={thStyle}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Skeleton width={90} height={20} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(5).fill().map((_, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f9f9f9" }}>
              <td style={tdStyle}>
                <Skeleton width={120} height={20} />
              </td>
              <td style={{ ...tdStyle, maxWidth: "300px", overflowWrap: "break-word" }}>
                <Skeleton width={150} height={20} />
              </td>
              <td style={tdStyle}>
                <Skeleton width={100} height={20} />
              </td>
              <td style={tdStyle}>
                <Skeleton width={130} height={20} />
                <Skeleton width={110} height={20} style={{ marginTop: "4px" }} />
              </td>
              <td style={tdStyle}>
                <Skeleton width={120} height={20} />
                <Skeleton width={100} height={20} style={{ marginTop: "4px" }} />
              </td>
              <td style={tdStyle}>
                <Skeleton width={140} height={20} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}