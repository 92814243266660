import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormControlLabel, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import { toast } from "react-toastify";

export default function SubFormAdmin({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  allDropDowns,
  noBtns,
  section,
  sectionId="",
  sectionName="",
}) {
  const [phoneErrors, setPhoneErrors] = useState([]);

  const validatePhoneNumbers = (forms) => {
    const newPhoneErrors = forms.map((form) => {
      const phone = form.account_main_contact_phone || "";
      return phone.trim() !== "" && phone.trim() !== "-" && phone.trim().length < 10;
    });
    setPhoneErrors(newPhoneErrors);
  };

  const updateFieldChanged = (e, index, name) => {
    const newArr = [...altTrainerForm];
    let newPhoneErrors = [...phoneErrors];

    if (e.target.name === "account_main_contact_phone") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "").trim();
      e.target.value = e.target.value.slice(0, 10);

      newArr[index][name] = e.target.value;

      if (e.target.value.trim().length < 10) {
        newPhoneErrors[index] = true;
      } else {
        newPhoneErrors[index] = false;
      }
    }

    if (name === `account_main_contact_phone_main`) {
      newArr.forEach((form, i) => {
        newArr[i].account_main_contact_phone_main =
          i === index ? (form.account_main_contact_phone_main ? 0 : 1) : 0;
      });
    } else {
      newArr[index][name] = e.target.value;
    }
    setPhoneErrors(newPhoneErrors);
    setSubFormData(newArr);
  };

  const handleDecreaseAlternative = (index, section) => {
    decreaseAlternative(section, index);
    validatePhoneNumbers(altTrainerForm);
  };

  useEffect(() => {
    validatePhoneNumbers(altTrainerForm);
  }, [altTrainerForm]);

  return (
    <>
   {altTrainerForm.map((singleForm, index) => (
  <div
    className="row mb-4"
    style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    key={index}
    id={`${sectionId}_${index}`}
  >
    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ width: "120px", margin: 0 }}></div>
      {noBtns && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={increaseAlternative}
            type="button"
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#007bff",
              color: "white",
            }}
            name={`${sectionName}_plus`}
          >
            +
          </button>
          <button
            onClick={() => handleDecreaseAlternative(index, section)}
            type="button"
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#dc3545",
              color: "white",
            }}
            name={`${sectionName}_minus`}
          >
            −
          </button>
        </div>
      )}
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Phone
      </label>
      <input
        type="text"
        name="account_main_contact_phone"
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
        value={singleForm.account_main_contact_phone}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_main_contact_phone")
        }
        pattern="[0-9]*"
        minLength={10}
        className={phoneErrors[index] ? "phone-invalid-input" : ""}
      />
      {phoneErrors[index] && (
        <div className="phone-invalid">Please Enter Exact 10 digits.</div>
      )}
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Ext
      </label>
      <input
        type="number"
        name="account_main_contact_phone_ext"
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_main_contact_phone_ext")
        }
        value={singleForm.account_main_contact_phone_ext}
      />
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Phone Type
      </label>
      <select
        name="phone_type_id"
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
        onChange={(e) => updateFieldChanged(e, index, "phone_type_id")}
        value={singleForm.phone_type_id}
      >
        <option value="" selected>
          --Select One--
        </option>
        {allDropDowns?.phoneType &&
          allDropDowns?.phoneType.map((PT, index) => (
            <option value={PT.dropdown_phone_type_id} key={index}>
              {PT.dropdown_phone_type_name}
            </option>
          ))}
      </select>
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Main
      </label>
      <div>
        <FormControlLabel
          label=""
          name="account_main_contact_phone_main"
          onClick={(e) => {
            const phoneNumber = altTrainerForm[index].account_main_contact_phone;
            if (!phoneNumber || phoneNumber.trim() === "") {
              toast.error("Please first fill Phone number");
            } else {
              e.preventDefault();
              updateFieldChanged(e, index, "account_main_contact_phone_main");
            }
          }}
          control={
            <Radio
              color="primary"
              size="medium"
              checked={singleForm?.account_main_contact_phone_main}
            />
          }
        />
      </div>
    </div>
  </div>
))}
    </>
  );
}
