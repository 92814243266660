import React, { useEffect, useState } from "react";
import SubHeadingOther from "../../../common/components/SubHeadingOther";
import { Box, Skeleton } from "@mui/material";
import { AccountPopTab } from "../../../common/hooks";
// import New from "../../../common/img/New.png";
// import Edit from "../../../img/Edit.png";
// import Clone from "../../../img/Clone.svg";
import { CallGETAPI } from "../../../common/services/index";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import AEDCabinet from "../../../common/img/AEDCabinet.svg";
import Adult from "../../../common/img/Adult.svg";
import Battery from "../../../common/img/Battery.svg";
import RMSBattery from "../../../common/img/RMSBattery.svg";
import pediatricPad from "../../../common/img/pediatricPad.svg";
import Cancel from "../../../common/img/Cancel.svg";
import Check from "../../../common/img/Check.svg";

// import Equipment from "../../../img/Equipment.svg";
// import Accessories from "../../../img/Accessories.svg";
import MoneyBag from "../../../common/img/MoneyBag.svg";
import { useDispatch } from "react-redux";
import {
  FormatDate,
  printConsole,
  RenderWithOutZero,
} from "../../../common/helper/Common";
import moment from "moment";
import { FetchAccountDetails } from "../../../common/helper/BasicFn";
// import Loading from "../../../common/components/Loader/Loading";
// import Documents from "../../accounts/components/tabs/Documents";
import {
  setEditPopDetails,
  setPopActiveTab,
} from "../../../store/slices/TabSlice";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../common/helper/PermissionManager";
import Documents from "../../Documnents/Pages/Documents";

const Details = () => {
  const { popId, accountId } = useParams();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(AccountPopTab.Details);
  const [popDetails, setPopDetails] = useState("");
  const [planDetails, setPlanDetails] = useState("");
  const [sitesDetails, setSitesDetails] = useState([]);
  const [accessories, setAccessories] = useState([]);
  const [purchaseEquipment, setPurchaseEquipment] = useState([]);
  const [contractEquipment, setContractEquipment] = useState([]);
  const [contractAccessories, setContractAccessories] = useState([]);
  const [contractCourse, setContractCourse] = useState([]);
  const [contractOfficer, setContractOfficer] = useState("");
  const [contractOfficerRep, setContractOfficerRep] = useState("");
  const [otherReps, setOtherReps] = useState("");
  const [invoicePaid, setInvoicePaid] = useState("");
  const [activated, setActivated] = useState("");
  const [planType, setPlanType] = useState("");
  const [accessoryPrice, setAccessoryPrice] = useState("");
  const [accessoryQuantity, setAccessoryQuantity] = useState("");
  const [equipmentPrice, setequipmentPrice] = useState("");
  const [equipmentQuantity, setequipmentQuantity] = useState("");
  const [loading, setLoading] = useState();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleContactDispatch = (accountId, contact_id, contact_name) => {
    BreadCrumbsObject.title = contact_name + " Details";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${accountId}/contact-details/${contact_id}`,
      })
    );
  };
  const PrintContact = ({ data, accountId }) => {
    // console.log({ myaccount: accountId })
    if (!Array.isArray(data)) {
      return "";
    }

    // console.log({ data })

    return (
      <div>
        {data?.map((it, i) => (
          <div
            key={i}
            className={
              Number(
                isPermission({ type: "link", name: "popContactContracts" })
              ) === 1
                ? "link"
                : "default"
            }
            style={{
              cursor:
                Number(
                  isPermission({ type: "link", name: "popContactContracts" })
                ) === 1
                  ? "pointer"
                  : "default",
              // color: "#0c71c3",
              marginBottom: "8px",
            }}
            onClick={() => {
              if (
                Number(
                  isPermission({ type: "link", name: "popContactContracts" })
                ) === 1
              ) {
                printConsole("my choice data", data); // print console.log
                handleContactDispatch(
                  accountId,
                  it?.contact_id,
                  it?.contact_name
                );
                navigate(
                  `/account/${accountId}/contact-details/${it?.contact_id}`
                );
              }
            }}
          >
            {it?.contact_name}
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    // Fetch data and update invoicePaid
    const fetchInvoicePaid = async () => {
      try {
        setLoading(true);
        const response = await CallGETAPI(`pop/get-pop-performance/${popId}`);
        // console.log("LLLLLLLL", response)
        const data = response?.data?.data || [];
        // console.log({ APIDATA: popId ? popId : "N/A" });

        if (data) {
          const invoicePaidDate = data.invoice_paid_date;
          const activatedDate = "08/09/2024";
          if (invoicePaidDate) {
            const date = new Date(invoicePaidDate);
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const year = date.getFullYear();
            const formattedDate = `${month}/${day}/${year}`;
            setInvoicePaid(formattedDate); // Update invoicePaid
          }
          if (activatedDate) {
            const activateDate = new Date(activatedDate);
            const activateMonth = String(activateDate.getMonth() + 1).padStart(
              2,
              "0"
            ); // Adding 1 to month since January is 0
            const activateDay = String(activateDate.getDate()).padStart(2, "0");
            const activateYear = activateDate.getFullYear();
            const formattedDate1 = `${activateMonth}/${activateDay}/${activateYear}`;
            setActivated(formattedDate1);
          }
        }
      } catch (error) {
        console.error("Error fetching invoicePaid:", error);
      }
    };

    fetchInvoicePaid(); // Fetch and update invoicePaid
  }, [popId]);
  const [accountDetails, setAccountDetails] = useState({});

  const fetchLoad = async () => {
    const response = await CallGETAPI(`pop/get-pop-site-details/${popId}`);
    const data = response?.data?.data || [];

    // console.log("zxzxzxxzxzx", data)
    if (data) {
      const siteDetails = data;
      setSitesDetails(siteDetails);
    }
  };

  const fetchOnLoad = async () => {
    const response = await CallGETAPI(`pop/get-pop-performance/${popId}`);
    const data = response?.data?.data || [];

    if (accountId) {
      const AccDetails = await FetchAccountDetails(accountId);
      setAccountDetails(AccDetails);
    }
    const res = await CallGETAPI(`pop/get-plan-details/${popId}`);
    const result = res?.data?.data || [];
    // console.log("ooooooooo", result)
    if (data) {
      const contactData = JSON.parse(data?.contact);
      const contractOfficer = contactData.contract_officer;
      // planType,setPlanType

      setPlanType(result.plan_name);
      const contractOfficerRep = contactData.contracting_officer_rep;
      const otherReps = contactData.other_reps;
      setContractOfficer(contractOfficer);
      setContractOfficerRep(contractOfficerRep);
      setOtherReps(otherReps);
    }

    if (data) {
      const PopDetails = data;
      setPopDetails(PopDetails);
    }

    if (result) {
      const planDetails = result;
      setPlanDetails(planDetails);
    }

    if (data.siteNames) {
      const sitesDetails = data.siteNames;
      setSitesDetails(sitesDetails);
    }

    if (data.purchase_accessories) {
      // Assuming 'purchase_accessories' is an array
      const accessoriesArray = data.purchase_accessories;
      setAccessories(accessoriesArray);
    }
    if (data.purchase_equipment) {
      const PurchaseEquipmentArray = data.purchase_equipment;
      setPurchaseEquipment(PurchaseEquipmentArray);
    }
    if (data.contract_equipment) {
      const ContractEquipmentArray = data.contract_equipment;
      setContractEquipment(ContractEquipmentArray);
    }
    if (data.contract_accessories) {
      const ContractAccessoriesArray = data.contract_accessories;
      setContractAccessories(ContractAccessoriesArray);
    }
    if (data.contract_course) {
      const ContractCourseArray = data.contract_course;
      setContractCourse(ContractCourseArray);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchOnLoad();
    fetchLoad();
  }, []);
  // console.log("sssssssssssqqqq", planDetails.rms_billable)

  const dispatch = useDispatch();
  const handleTab = (item) => {
    // console.log({ item })
    dispatch(setPopActiveTab(item));
    setCurrentTab(item);
  };
  const [yearlyCost, setYearlyCost] = useState("");
  const calculateYearlyPrice = (qty, price, rmsPrice) => {
    price = price ? price : 0;
    rmsPrice = rmsPrice ? rmsPrice : 0;
    qty = qty ? qty : 0;

    let TTPrice = parseInt(price) + parseInt(rmsPrice);
    qty = parseInt(qty);
    const yearlycost = qty * TTPrice;
    setYearlyCost(yearlycost);
  };
  useEffect(() => {
    calculateYearlyPrice(
      popDetails?.purchased_products_qty,
      popDetails?.purchased_products_price,
      popDetails?.purchased_products_rms_yearly_cost
    );
  }, [
    popDetails?.purchased_products_qty,
    popDetails?.purchased_products_price,
    popDetails?.purchased_products_rms_yearly_cost,
  ]);

  // Calculate and set the accessory prices in the effect
  useEffect(() => {
    const accessoryPricesvalue = accessories.map(
      (accessory) => accessory.accessories.price
    );
    const accessoryQuantityvalue = accessories.map(
      (accessory) => accessory.accessories.quantity
    );
    setAccessoryPrice(accessoryPricesvalue);
    setAccessoryQuantity(accessoryQuantityvalue);
  }, [accessories]);

  const [accessoryTPrice, setAccessoryTPrice] = useState("");
  const accessoryTotalPrice = (qty, price) => {
    qty = qty ? qty : 0;
    price = price ? price : 0;
    const accessoryCalucalateprice = parseInt(qty) * parseInt(price);
    setAccessoryTPrice(accessoryCalucalateprice);
  };

  useEffect(() => {
    accessoryTotalPrice(accessoryQuantity, accessoryPrice);
  }, [accessoryQuantity, accessoryPrice]);

  // Calculate and set the Equipment prices in the effect
  useEffect(() => {
    const equipmentPricesvalue = purchaseEquipment.map(
      (equipment) => equipment.equipment.price
    );
    const equipmentQuantityvalue = purchaseEquipment.map(
      (equipment) => equipment.equipment.quantity
    );
    setequipmentPrice(equipmentPricesvalue);
    setequipmentQuantity(equipmentQuantityvalue);
  }, [purchaseEquipment]);

  const [equipmentTPrice, setequipmentTPrice] = useState("");
  const equipmentTotalPrice = (qty, price) => {
    printConsole(qty); // print console.log
    qty = qty ? qty : 0;
    price = price ? price : 0;
    const equipmentCalculateprice = parseInt(qty) * parseInt(price);
    setequipmentTPrice(equipmentCalculateprice);
  };

  useEffect(() => {
    equipmentTotalPrice(equipmentQuantity, equipmentPrice);
  }, [equipmentQuantity, equipmentPrice]);

  const params = useParams();
  // console.log("hello world", { params });
  // console.log("1234567890", sitesDetails)

  useEffect(() => {
    //  console.log(">>>PD", {popDetails});
    //  console.log(">>>PLD", {planDetails});
    //  console.log(">>>CO", {contractOfficer});
    //  console.log(">>>COR", {contractOfficerRep});
    //  console.log(">>>OR", {otherReps})
    if (
      popDetails &&
      planDetails &&
      contractOfficer &&
      contractOfficerRep &&
      otherReps &&
      sitesDetails
    ) {
      const editPopDetails = {
        popDetails,
        planDetails,
        contractOfficer,
        contractOfficerRep,
        otherReps,
        sitesDetails,
      };
      dispatch(setEditPopDetails(editPopDetails));
      // console.log(">>>", {editPopDetails});
    }
  }, [
    popDetails,
    planDetails,
    contractOfficer,
    contractOfficerRep,
    otherReps,
    sitesDetails,
  ]);

  return (
    <>
      <div className="mt-4" style={{ width: "100%", paddingInline: "5px" }}>
        {/* <SubHeadingOther title={accountDetails?.account_name} hideNew={true} hideHierarchy={true} hideInstructor={true} subHeading={true} bottomLinks={false} />

          <div className="d-flex" style={{ gap: "10px" }}>
            <button
              className="btn text-primary"
              type="button"
              onClick={() => navigate('/account/new-pop/' + accountId)}
            >
              <img
                src={New}
                alt="New"
                style={{ marginRight: "5px" }}
              />
              <span className="ms-1">New</span>
            </button>

            <button
              className="btn text-primary"
              type="button"
              onClick={() => navigate(`/account/pop-edit/${popId}/${accountId}`)}
            >
              <img
                src={Edit}
                alt="Edit"
                style={{ marginRight: "5px" }}
              />
              <span className="ms-1">Edit</span>
            </button>

            <button
              className="btn text-primary"
              type="button"
            >
              <img
                src={Clone}
                alt="Clone"
                style={{ marginRight: "5px" }}
              />
              <span className="ms-1">Clone</span>
            </button>
          </div> */}

        {/* tabs */}
        {/* <Box className="bg-primary my-3 ">
            <div className="d-flex border-bottom border-secondary">
              {Object.values(AccountPopTab).map((tabItem, i) => (
                <div
                  role="button"
                  key={i}
                  className={"text-light py-2 px-3"}
                  style={{
                    backgroundColor: `${tabItem == currentTab ? "#26AEE0" : "#0C71C3"}`,
                  }}
                  onClick={() => handleTab(tabItem)}
                >
                  {tabItem}
                </div>
              ))}
            </div>
          </Box> */}

        {/* { currentTab === AccountPopTab.Details && (
							<>
								<PopDetails accountDetails={ accountDetails }
                //  CoordiDataList={ CoordiDataList } 
                //  programDetails={ programDetails }
                //   httpsWeb={ httpsWeb } 
                  />
							</>
						) } */}

        {currentTab === AccountPopTab.Documents && (
          <Documents
            accountId={accountId}
            //  contact_id={data[0].contact_id}
            type={"Documents"}
          />
        )}
        {/* {console.log({currentTab})}
        {/* { currentTab === AccountPopTab.Notes && (
							<>
								<PopDetails accountId={accountId}   type="ACCOUNT"  />
							</>
						) } */}

        <div className="text-left pt-3 pb-1">
          <Box className="text-left pt-1 pb-1">
            <h4 className="heading">Period of Performance Information</h4>
          </Box>

          <table className="theme-table">
            <thead>
              <tr>
                <td style={{ fontWeight: "bold" }}>Contract #</td>
                <td style={{ fontWeight: "bold" }}>Req #</td>
                <td style={{ fontWeight: "bold" }}>Order #</td>
                <td style={{ fontWeight: "bold" }}>Modification #</td>
                <td style={{ fontWeight: "bold" }}># of Years</td>
                <td style={{ fontWeight: "bold" }}>Contract Start</td>
                <td style={{ fontWeight: "bold" }}>Contract Year</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.contract
                  )}
                </td>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.req
                  )}
                </td>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.order
                  )}
                </td>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.modification
                  )}
                </td>
                <td>{popDetails.of_year === 0 ? "" : popDetails.of_year}</td>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    moment(popDetails.contract_start).format("MM/DD/YYYY")
                  )}
                </td>
                <td>
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.contract_year
                  )}
                </td>
              </tr>
            </tbody>

            <thead>
              <tr>
                <td colSpan="2" style={{ fontWeight: "bold" }}>
                  Pop Type
                </td>
                <td colSpan="1" style={{ fontWeight: "bold" }}>
                  QB Invoice #
                </td>
                <td colSpan="1" style={{ fontWeight: "bold" }}>
                  Grace Period
                </td>
                <td colSpan="2" style={{ fontWeight: "bold" }}>
                  Invoice Paid
                </td>
                <td colSpan="2" style={{ fontWeight: "bold" }}>
                  Invoicing Instructions
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.pop_type
                  )}
                </td>
                <td colSpan="1">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.qb_invoice
                  )}
                </td>
                <td colSpan="1">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    popDetails.grace_period
                  )}
                </td>
                <td colSpan="2">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : (
                    invoicePaid
                  )}
                </td>
                <td colSpan="2">
                  {loading ? (
                    <Skeleton
                      count={5}
                      width={"90%"}
                      height={20}
                      style={{ marginLeft: "2px", marginRight: "2px" }}
                    />
                  ) : popDetails.invoicing_instructions ? (
                    <div>{popDetails.invoicing_instructions}</div>
                  ) : (
                    <div></div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {popDetails?.pop_type === "Equipment" && (
          <div className="text-left pt-3 pb-1">
            <Box className="text-left pt-1 pb-1">
              <h4 className="heading">Program Management Plan Information</h4>
            </Box>

            <table className="theme-table">
              <thead>
                <tr>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    Type
                  </td>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    Activated
                  </td>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    Rental
                  </td>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    Visits
                  </td>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    RMS
                  </td>
                  <td colSpan="1" style={{ fontWeight: "bold" }}>
                    Accessories Included
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      planDetails.plan_name
                    )}
                  </td>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      FormatDate(popDetails?.activated_plan_date)
                    )}
                  </td>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <div className="row">
                        {planDetails.rental === 0 ? (
                          <img
                            className="col"
                            style={{ maxWidth: "25%", height: "20px" }}
                            src={Cancel}
                            alt="No Rental"
                          />
                        ) : (
                          <img
                            className="col"
                            style={{ maxWidth: "25%", height: "25px" }}
                            src={Check}
                            alt="Rental Available"
                          />
                        )}
                        <h1
                          className="col"
                          style={{
                            fontSize: "15px",
                            marginTop: "0px",
                            fontWeight: "400",
                          }}
                        >
                          {planDetails.rental_duration}
                        </h1>
                      </div>
                    )}
                  </td>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <>
                        {planDetails.visits === "0" ||
                        planDetails.visits === null ? (
                          <img
                            className="col"
                            style={{ maxWidth: "20%", height: "20px" }}
                            src={Cancel}
                            alt="No Visits"
                          />
                        ) : (
                          <img
                            className="col"
                            style={{ maxWidth: "20%", height: "25px" }}
                            src={Check}
                            alt="Visits Available"
                          />
                        )}
                        <span>{planDetails.visits}</span>
                      </>
                    )}
                  </td>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <>
                        {planDetails.rms_billable === 1 ? (
                          <img
                            className="col"
                            style={{
                              marginLeft: "0px",
                              maxWidth: "40px",
                              height: "20px",
                            }}
                            src={MoneyBag}
                            alt="RMS Billable"
                          />
                        ) : null}
                        {planDetails.rms === 0 ? (
                          <img
                            className="col"
                            style={{ maxWidth: "20%", height: "20px" }}
                            src={Cancel}
                            alt="No RMS"
                          />
                        ) : (
                          <img
                            className="col"
                            style={{ maxWidth: "20%", height: "25px" }}
                            src={Check}
                            alt="RMS Available"
                          />
                        )}
                      </>
                    )}
                  </td>
                  <td colSpan="1">
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={30}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <>
                        {planDetails.accessory_billable === 1 ? (
                          <img
                            className="col"
                            style={{
                              marginRight: "4px",
                              maxWidth: "40px",
                              height: "25px",
                            }}
                            src={MoneyBag}
                            alt="Accessory Billable"
                          />
                        ) : null}
                        {planDetails.battery === 1 ? (
                          <img
                            src={Battery}
                            alt="Battery"
                            style={{ marginRight: "4px" }}
                          />
                        ) : null}
                        {planDetails.spare_battery === 1 ? (
                          <img
                            src={Battery}
                            alt="Spare Battery"
                            style={{ marginRight: "4px" }}
                          />
                        ) : null}
                        {planDetails.adult_pad === 1 ? (
                          <img
                            src={Adult}
                            alt="Adult Pad"
                            style={{ marginRight: "4px" }}
                          />
                        ) : null}
                        {planDetails.spare_adult === 1 ? (
                          <img
                            src={Adult}
                            alt="Spare Adult Pad"
                            style={{ marginRight: "4px" }}
                          />
                        ) : null}
                        {planDetails.aed_cabinet_9v === 1 ? (
                          <img
                            src={AEDCabinet}
                            alt="AED Cabinet"
                            style={{ marginRight: "4px" }}
                          />
                        ) : null}
                        {planDetails.rms_battery === 1 ? (
                          <img src={RMSBattery} alt="RMS Battery" />
                        ) : null}
                        {planDetails.pediatric_pad === 1 ? (
                          <img
                            src={pediatricPad}
                            alt="Pediatric Pad"
                            style={{ height: "26px" }}
                          />
                        ) : null}
                        {planDetails.pediatric_spare_pad === 1 ? (
                          <img
                            src={pediatricPad}
                            alt="Pediatric Spare Pad"
                            style={{ height: "26px" }}
                          />
                        ) : null}
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className="row pt-3 pb-5">
          {/* Contract Contacts Section */}
          <div className="col" style={{ maxWidth: "700px" }}>
            <Box className="text-left pt-1 pb-1">
              <h4 className="heading">Contract Contacts</h4>
            </Box>

            <table className="theme-table">
              <thead>
                <tr>
                  <th>Contract Officer</th>
                  <th>Contract Officer Rep</th>
                  <th>Other Contacts</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    ) : (
                      <PrintContact
                        accountId={accountId}
                        data={contractOfficer || []}
                      />
                    )}
                  </td>
                  <td>
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    ) : (
                      <PrintContact
                        accountId={accountId}
                        data={contractOfficerRep || []}
                      />
                    )}
                  </td>
                  <td>
                    {loading ? (
                      <Skeleton
                        count={1}
                        width={"100%"}
                        height={20}
                        style={{ marginLeft: "2px", marginRight: "2px" }}
                      />
                    ) : (
                      <PrintContact
                        accountId={accountId}
                        data={otherReps || []}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Sites Section */}
          <div className="col" style={{ maxWidth: "750px" }}>
            <Box className="text-left pt-1 pb-1">
              <h4 className="heading">Sites</h4>
            </Box>

            <table
              className="theme-table"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <tbody style={{ height: "60px", maxWidth: "750px" }}>
                {sitesDetails.length > 0 ? (
                  sitesDetails.map((site, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid blue", padding: "8px" }}>
                        {site?.account_site_name}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td style={{ textAlign: "center", padding: "8px" }}>
                      No sites available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Details;
