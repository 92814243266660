import React, { useState, useEffect } from "react";
import { CallGETAPI } from "../../../../common/services/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import TableSkeleton from "../skeleton/table/TableSkeleton";
import Moment from "react-moment";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable";
import { printConsole } from "../../../../common/helper/Common";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

export default function Emails({ accountId, emailApi }) {
  const [emailList, setEmailList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const accountid = accountId;
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  // fetch email
  const fetchEmail = async () => {
    try {
      const result = await CallGETAPI("account/emails-by-account/" + accountid);

      if (result?.status) {
        const emailDataList = result?.data?.emailList;
        setEmailList(emailDataList);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };

  // use effect
  useEffect(() => {
    // fetchEmail();
  }, []);

  const handleReceipentDispatch = (url, data) => {
    const BreadCrumbsObject = {
      title: data?.contact_name,
      storageType: isUserContext ? "sessionStorage" : "localStorage",
      // type: "site",
      // tab: "CONTACT_LISTING",
    };
    BreadCrumbsObject.title = data?.contact_name;
    BreadCrumbsObject.type = "contact";
    BreadCrumbsObject.tab = "Details";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: url,
        title: data?.contact_name,
      })
    );
  };

  // getRecipient
  const getRecipient = (data) => {
    const stateData = {
      type: "Email",
    };

    return (
      <Link
        to={{
          pathname:
            isPermission({ type: "link", name: "contactName" }) === 1
              ? `/account/${accountid}/contact-details/${data?.contact_id}`
              : "",
          state: stateData,
        }}
        style={{
          textDecoration: "none",
          color:
            isPermission({ type: "link", name: "contactName" }) != 1 && "black",
          cursor:
            isPermission({ type: "link", name: "contactName" }) === 1
              ? "pointer"
              : "default",
        }}
        className={
          isPermission({ type: "link", name: "contactName" }) === 1 && "link"
        }
        onClick={() => {
          if (isPermission({ type: "link", name: "contactName" }) === 1) {
            handleReceipentDispatch(
              `/account/${accountid}/contact-details/${data?.contact_id}`,
              data
            );
          }
        }}
      >
        {data?.email}
      </Link>
    );
  };

  // getEmailDetails
  const handleEmailDetailsDispatch = (url, data) => {
    const BreadCrumbsObject = {
      title: data?.subject,
      // type: "site",
      // tab: "CONTACT_LISTING",
    };
    BreadCrumbsObject.title = data?.subject;
    BreadCrumbsObject.type = "email";
    BreadCrumbsObject.tab = "Details";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: url,
        title: data?.subject,
      })
    );
  };
  const getEmailDetails = (data) => {
    printConsole({ getEmailDetails: data }); // print console.log
    const hasPermission =
      Number(isPermission({ type: "link", name: "emailDetails" })) === 1;
    const url = `/account/${accountid}/email/${data?.id}`;
    return (
      <>
        <Link
          className={hasPermission && "link"}
          style={{
            textDecoration: "none",
            color: hasPermission ? "link" : "black",
            cursor: hasPermission ? "pointer" : "default",
          }}
          to={hasPermission ? `/account/${accountid}/email/${data?.id}` : ""}
          onClick={() => hasPermission && handleEmailDetailsDispatch(url, data)}
        >
          {data?.subject}
        </Link>
      </>
    );
  };

  const headerColumns = [
    {
      Header: "Recipient",
      accessor: "email",
      width: "12%",
      Cell: ({ row }) => {
        return getRecipient(row.original); // Assuming `getRecipient` is defined
      },
      sortDescFirst: false,
      disableSortBy: false,
    },
    {
      Header: "Subject",
      accessor: "subject",
      width: "12%",
      Cell: ({ row }) => {
        return getEmailDetails(row.original); // Assuming `getEmailDetails` is defined
      },
      sortDescFirst: false,
      disableSortBy: false,
    },
    {
      Header: "Email Date",
      accessor: "created_date",
      width: "12%",
      Cell: ({ row }) => (
        <span>
          <Moment date={row?.original.created_date} format={"MM/DD/YYYY"} />
        </span>
      ),
      sortDescFirst: false,
      disableSortBy: false,
    },
  ];

  return (
    <div
      className="relative"
      style={{
        width: "100%",
        paddingInline: "0px",
        marginBottom: "400px",
      }}
    >
      {/* data grid table */}

      <div className="data-table py-4">
        <h3 className="heading">{/* Emails Information */}</h3>
        {!loading ? (
          // <DataGrid
          //   dataSource={emailList}
          //   keyExpr="id"
          //   showColumnLines={true}
          //   showRowLines={true}
          //   showBorders={false}
          //   rowAlternationEnabled={true}
          // >
          //   <Column
          //     dataField="email"
          //     cellRender={(e) => getRecipient(e.data)}
          //     caption={"Recipient"}
          //     cssClass="column-header"
          //   />
          //   <Column
          //     dataField="subject"
          //     cellRender={(e) => getEmailDetails(e.data)}
          //     caption={"Subject"}
          //     cssClass="column-header"
          //   />
          //   <Column
          //     dataField="created_date"
          //     caption={"Email Date"}
          //     dataType={"date"}
          //     cssClass="column-header"
          //   />

          //   <Scrolling columnRenderingMode="virtual" />
          //   <Paging enabled={false} />
          // </DataGrid>

          <NewGlobalTable
            method="GET"
            title="Emails"
            isPagination={true}
            // newButtonPath={""}
            isSearchable={true}
            isCache={true}
            pageSize={20}
            // customRenderData={handleSorting}
            apiEndPoint={emailApi}
            // apiEndPoint={`email/emails-by-account/${accountid}`}
            keyAPiName={"emailList"}
            headerColumns={headerColumns}
          />
        ) : (
          <>
            {loading && (
              <div className="" style={{ width: "100%", marginTop: "2%" }}>
                <TableSkeleton />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
