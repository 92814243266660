// SiteListModel.jsx
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { CallPOSTAPI } from "../../../../common/services";
import { FormatDate } from "../../../../common/helper";
import moment from "moment";
import SiteSelector from "./SiteSelector";
import InventoryDisplay from "./InventoryDisplay";
import ModalFooter from "./ModalFooter";

const SiteListModel = ({
  openSitelistModel,
  setOpenSitelistModel,
  secName,
  Bindex,
  AedFormData,
  contact_id,
  BatteryList,
  setNewFormData,
}) => {
  const [selectSite, setSelectedSite] = useState("");
  const [batteryInventory, setBatteryInventory] = useState([]);
  const [padInventory, setPadInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBatteryRows, setSelectedBatteryRows] = useState([]);
  const [selectedPadRows, setSelectedPadRows] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");
  const aed_brand_id = AedFormData.brand;
  const aed_model_id = AedFormData.model_name;

  useEffect(() => {
    if (selectSite) {
      setBatteryInventory([]);
      setPadInventory([]);
      fetchInventoryData();
    }
  }, [selectSite]);

  const fetchInventoryData = async () => {
    setLoading(true);
    await fetchBatteryInventoryData();
    await fetchPadInventoryData();
    setLoading(false);
  };

  const fetchBatteryInventoryData = async () => {
    if (
      [
        "has_battery",
        "has_battery_spare",
        "has_9v",
        "has_9v_spare",
        "has_installby",
        "has_installby_spare",
        "has_man",
        "has_man_spare",
        "has_10pk",
        "has_10pk_spare",
        "ChargePakInformation",
        "SpareChargePakInformation",
      ].includes(secName) &&
      selectSite !== ""
    ) {
      const payload = {
        section_name: secName.includes("_spare")
          ? secName.replace("_spare", "")
          : secName === "ChargePakInformation" ||
            secName === "SpareChargePakInformation"
          ? "charge_pack"
          : secName,
        site_id: selectSite,
        brand_id: AedFormData?.brand,
        model_id: AedFormData?.model_name,
      };

      const response = await CallPOSTAPI(
        "acc/get-site-battery-inventory",
        payload
      );
      if (response.status === true) {
        let data = response?.data?.data;

        if (data?.batteryInfo) {
          data.batteryInfo = data.batteryInfo
            .map((battery) => {
              // Update quantity based on existing selections
              AedFormData[secName.replace(/'/g, "")].forEach((item) => {
                if (battery.sbid === item?.inventory_id) {
                  battery.quantity--;
                }
              });

              // Remove battery if quantity is 0
              return battery.quantity > 0 ? battery : null;
            })
            .filter((battery) => battery !== null);

          setBatteryInventory(data.batteryInfo);
        }
      }
    }
  };

  const fetchPadInventoryData = async () => {
    if (
      [
        "AdultPadInformation",
        "SpareAdultPadInfo",
        "AdultPadPakInfo",
        "SpareAdultPadPakInfo",
        "PediatricPadInformation",
        "sparePadricPadInfo",
        "PediatricPakPadInformation",
        "sparePadricPakPad",
      ].includes(secName) &&
      selectSite !== ""
    ) {
      const payload = {
        section_name:
          secName === "AdultPadInformation" || secName === "SpareAdultPadInfo"
            ? "adult_pad_info"
            : secName === "AdultPadPakInfo" ||
              secName === "SpareAdultPadPakInfo"
            ? "adult_pad_pak_info"
            : secName === "PediatricPadInformation" ||
              secName === "sparePadricPadInfo"
            ? "pediatric_pad_info"
            : secName === "PediatricPakPadInformation" ||
              secName === "sparePadricPakPad"
            ? "pediatric_pak_pad_info"
            : "",
        site_id: selectSite,
        brand_id: AedFormData?.brand,
        model_id: AedFormData?.model_name,
      };

      const response = await CallPOSTAPI("acc/get-site-pad-inventory", payload);
      if (response.status === true) {
        let data = response?.data?.data;

        if (data?.padInfo) {
          data.padInfo = data.padInfo
            .map((pad) => {
              // Update quantity based on existing selections
              AedFormData[secName.replace(/'/g, "")].forEach((item) => {
                if (pad.spid === item?.inventory_id) {
                  pad.quantity--;
                }
              });

              // Remove pad if quantity is 0
              return pad.quantity > 0 ? pad : null;
            })
            .filter((pad) => pad !== null);

          setPadInventory(data.padInfo);
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = { ...AedFormData };
    const currentDate = moment().format("YYYY-MM-DD");

    if (
      [
        "has_battery",
        "has_battery_spare",
        "has_9v",
        "has_9v_spare",
        "has_installby",
        "has_installby_spare",
        "has_man",
        "has_man_spare",
        "has_10pk",
        "has_10pk_spare",
        "ChargePakInformation",
        "SpareChargePakInformation",
      ].includes(secName)
    ) {
      const battery = secName.replace(/'/g, "");
      const selectedBattery = selectedBatteryRows[0];

      fd[battery][Bindex] = {
        ...fd[battery][Bindex],
        battery_udi: selectedBattery?.battery_udi,
        battery_lot: selectedBattery?.battery_lot,
        battery_expiration: selectedBattery?.battery_expiration,
        battery_type_id: selectedBattery?.battery_type_id,
        battery_part: selectedBattery?.battery_part,
        battery_serial: selectedBattery?.battery_serial,
        inventory_id: selectedBattery?.sbid,
        sbid: selectedBattery?.sbid,
        install_date: currentDate,
      };
      if (battery === "has_man" || battery === "has_man_spare") {
        fd[battery][Bindex].manufactured_date =
          selectedBattery?.manufactured_date;
        fd[battery][Bindex].date_installed = currentDate;

        // const batteryType = BatteryList.find(item => parseInt(item.battery_type_id) === parseInt(fd[battery][Bindex].battery_type_id));
        // if (batteryType) {

        //   // const expirationDate = moment().add(batteryType.lifespan, 'years');
        //   fd[battery][Bindex].date_installed = currentDate;
        //   // fd[battery][Bindex].v9_install = expirationDate.format("YYYY-MM-DD");

        // }
      }

      if (battery === "has_10pk" || battery === "has_10pk_spare") {
        fd[battery][Bindex].install_date = currentDate;
      }

      if (battery === "has_9v" || battery === "has_9v_spare") {
        fd[battery][Bindex].install_9v_date =
          secName === "has_9v" ? currentDate : "N/A";
        fd[battery][Bindex].v9_install =
          secName === "has_9v" ? currentDate : "N/A";
      }

      if (
        [
          "has_10pk",
          "has_10pk_spare",
          "has_installby",
          "has_installby_spare",
        ].includes(battery)
      ) {
        fd[battery][Bindex].install_before_date =
          secName === "has_10pk" || secName === "has_installby_spare"
            ? currentDate
            : "N/A";
      }

      if (battery === "has_installby" || battery === "has_installby_spare") {
        fd[battery][Bindex].install_before_date =
          selectedBattery?.install_date || "N/A";
        fd[battery][Bindex].date_installed = currentDate;
        if (BatteryList) {
          const batteryType = BatteryList.find(
            (item) =>
              parseInt(item.battery_type_id) ===
              parseInt(fd[battery][Bindex].battery_type_id)
          );
          if (batteryType) {
            const expirationDate = moment().add(batteryType.lifespan, "years");
            fd[battery][Bindex].date_installed = currentDate;
            fd[battery][Bindex].battery_expiration =
              expirationDate.format("YYYY-MM-DD");
          }
        }
      }
      if (
        battery === "ChargePakInformation" ||
        battery === "SpareChargePakInformation"
      ) {
        fd[battery][Bindex] = {
          ...fd[battery][Bindex],
          charge_pak_part: selectedBattery?.battery_type_id,
          battery_part_number: selectedBattery?.battery_part,
          charge_pak_uid: selectedBattery?.charge_pak_uid,
          pad_1_expiration: selectedBattery?.pad1?.pad_expiration,
          pad_1_lot: selectedBattery?.pad1?.pad_lot,
          pad_1_part: selectedBattery?.pad1?.pad_type_id,
          charge_pak_pad_1_id: selectedBattery?.pad1?.pad_type_id,
          pad_1_part_number: selectedBattery?.pad1?.pad_part_number,
          pad_2_expiration: selectedBattery?.pad2?.pad_expiration,
          pad_2_lot: selectedBattery?.pad2?.pad_lot,
          pad_2_part: selectedBattery?.pad2?.pad_type_id,
          charge_pak_pad_2_id: selectedBattery?.pad2?.pad_type_id,
          pad_2_part_number: selectedBattery?.pad2?.pad_part_number,
        };
      }

      if (!fd[battery][Bindex].battery_expiration && BatteryList) {
        const batteryType = BatteryList.find(
          (item) =>
            parseInt(item.battery_type_id) ===
            parseInt(fd[battery][Bindex].battery_type_id)
        );
        if (batteryType) {
          const expirationDate = moment().add(batteryType.lifespan, "years");
          fd[battery][Bindex].date_installed = currentDate;
          fd[battery][Bindex].battery_expiration =
            expirationDate.format("YYYY-MM-DD");
        }
      }

      fd.useSbidInventory.push(selectedBattery?.sbid);
    } else {
      const pad = secName.replace(/'/g, "");
      const selectedPad = selectedPadRows[0];

      fd[pad][Bindex] = {
        ...fd[pad][Bindex],
        pad_udi: selectedPad?.pad_udi,
        pad_lot: selectedPad?.pad_lot,
        pad_expiration: selectedPad?.pad_expiration,
        pad_type_id: selectedPad?.pad_type_id,
        pad_part: selectedPad?.pad_part,
        inventory_id: selectedPad?.spid,
        spid: selectedPad?.spid,
      };

      if (!Array.isArray(fd.useSpidInventory)) {
        fd.useSpidInventory = [];
      }
      fd.useSpidInventory.push(selectedPad?.spid);
    }

    setNewFormData(fd);
    setSelectedSite("");
    setSelectedBatteryRows([]);
    setSelectedPadRows([]);
    handleClose();
  };

  const handleClose = () => setOpenSitelistModel(false);

  useEffect(() => {
    // if (selectSite) {
    setSelectedBatteryRows([]);
    setSelectedPadRows([]);
    // }
  }, [selectSite]);

  return (
    <Modal
      show={openSitelistModel}
      onHide={handleClose}
      size="lg"
      style={{
        maxWidth: "900px",
        marginLeft: "22%",
        marginTop: "8%",
        height: modalHeight,
        minHeight: "90vh",
        overflowY: "auto",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Standalone Inventory</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-container" id="outofservice-modal-content">
          <div className="my-modal-section" style={{ position: "relative" }}>
            <div className="row">
              <div className="col-md-12 mx-auto">
                <form onSubmit={handleSubmit}>
                  <div
                    className="bottom-border-blue py-4 px-2"
                    style={{ background: "#eee" }}
                  >
                    <SiteSelector
                      selectSite={selectSite}
                      setSelectedSite={setSelectedSite}
                    />
                    <InventoryDisplay
                      loading={loading}
                      selectSite={selectSite}
                      batteryInventory={batteryInventory}
                      padInventory={padInventory}
                      secName={secName}
                      AedFormData={AedFormData}
                      aed_brand_id={aed_brand_id}
                      aed_model_id={aed_model_id}
                      contact_id={contact_id}
                      selectedBatteryRows={selectedBatteryRows}
                      setSelectedBatteryRows={setSelectedBatteryRows}
                      setSelectedPadRows={setSelectedPadRows}
                      setSelectedRowIndex={setSelectedRowIndex}
                      selectedPadRows={selectedPadRows}
                      setModalHeight={setModalHeight}
                    />
                    <ModalFooter
                      handleClose={handleClose}
                      handleSubmit={handleSubmit}
                      selectSite={selectSite}
                      batteryInventory={batteryInventory}
                      padInventory={padInventory}
                      selectedBatteryRows={selectedBatteryRows}
                      selectedPadRows={selectedPadRows}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SiteListModel;
