import React, { useState, useEffect } from "react";
// import "../../../../src/global.css"
// import { Form, Button as BButton, Spinner } from "react-bootstrap";
import { Form, Button as BButton } from "react-bootstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updatePermission } from "../../../../store/slices/StandloneAEDSlice";
import { AED_IMG_BASE } from "../../../../common/services";
import CustomToggleButton from "../../../../common/components/CustomToggleButton";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";
import {
  AccountSiteList,
  GetAedModelsByBrandId,
  GetAedSumModelsById,
  HandleUnknow,
} from "../../../../common/helper/BasicFn";
import PaginateAutoComplete from "../../../../common/components/custom-ui/PaginateAutoComplete";
import { useLocation } from "react-router-dom";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";
// import QuestionMark from "../../../../common/img/QuestionMark.png";
import { isPermission } from "../../../../common/helper/PermissionManager";

function EditGeneralInfo({
  updateModal,
  setUpdateModal,
  spareUpdate,
  setSpareUpdate,
  AccountList,
  formData,
  BrandList,
  setFormData,
  setPermission,
  Permissins,
  is_edit = false,
  DefaultValue,
  setAccId,
  AccId,
  addRemoveBtn,
  setModelChange,
}) {
  const PermissionRedux = useSelector(
    (state) => state?.AED_manager?.permissions
  );

  // const [edit, setedit] = useState(is_edit);

  const accountObj = {
    accountId: formData?.account_id,
    accountName: formData?.account_name,
  };

  const location = useLocation();
  const [SelectedAccount, setSelectedAccount] = useState(accountObj || {});
  // const [isChecked, setIsChecked] = useState(false);

  const modelResetValues = {
    has_battery: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],
    has_battery_spare: [],

    has_9v: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],

    has_9v_spare: [],

    has_installby: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],
    has_installby_spare: [],

    has_man: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],

    has_man_spare: [],

    has_10pk: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],
    has_10pk_spare: [],

    battery_info: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],

    spare_battery_info: [],

    battery_info1: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_udi: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
        battery_part_number: "",
      },
    ],

    ChargePakInformation: [
      {
        charge_pak_part: "",
        charge_pak_uid: "",
        battery_expiration: "",
        battery_lot: "",
        pad_1_part: "",
        pad_1_expiration: "",
        pad_1_lot: "",
        pad_2_part: "",
        pad_2_expiration: "",
        pad_2_lot: "",
      },
    ],

    SpareChargePakInformation: [],

    AdultPadInformation: [
      {
        // pad_part: "",
        pad_expiration: "",
        pad_lot: "",
        pad_udi: "",
        pad_part_number: "",
        pad_type_id: "",
      },
    ],

    SpareAdultPadInfo: [],

    AdultPadPakInfo: [
      {
        // pad_part: "",
        pad_expiration: "",
        pad_lot: "",
        pad_udi: "",
        pad_part_number: "",
        pad_type_id: "",
      },
    ],

    SpareAdultPadPakInfo: [],

    PediatricPadInformation: [
      {
        // pad_part: "",
        pad_expiration: "",
        pad_lot: "",
        pad_udi: "",
        pad_part_number: "",
        pad_type_id: "",
      },
    ],
    sparePadricPadInfo: [],
    PediatricPakPadInformation: [
      {
        // pad_part: "",
        pad_expiration: "",
        pad_lot: "",
        pad_udi: "",
        pad_part_number: "",
        pad_type_id: "",
      },
    ],
    sparePadricPakPad: [],

    GatewayInformation: [
      {
        installed: "",
        connected: "",
        gateway_serial: "",
        gateway_Mmac_address: "",
        battery_install_date: "",
        expiry_date: "",
      },
    ],
    BuiltInRMSInformation: [
      {
        connected: false,
        mac_address: "",
      },
    ],
    pediatric_key: false,
    useSbidInventory: [],
    useSpidInventory: [],
  };

  const default_conditoions = {
    auto: 0,
    brand_id: 0,
    created_by_id: null,
    created_date: "2023-05-04T19:51:56.000Z",
    discontinued: 0,
    display: 0,
    has_9v: 0,
    has_10pk: 0,
    has_battery: 0,
    has_builtin_rms: 0,
    has_chargepak: 0,
    has_gateway: 0,
    has_installby: 0,
    has_man: 0,
    has_pad: 0,
    has_padpak: 0,
    has_ped_key: 0,
    has_ped_pad: 0,
    has_pedpak: 0,
    id: 0,
    image_file_name: "",
    model_name: "",
    model_partnumber: null,
    modified_by_id: null,
    modified_date: null,
    semi: 0,
  };
  const [siteData, setSiteData] = useState([]);
  const [models, setModels] = useState([]);
  const [subModels, setSubModels] = useState([]);
  const [defaultFromData, setDefaultfromData] = useState(DefaultValue);
  // const [partName,setPartName] = useState()
  const [warentyYear, setWarentyYear] = useState(0);
  const [partDetails, setPartDetails] = useState({});
  const selected_model = useSelector(
    (state) => state.AED_manager.selected_model
  );
  const dispatch = useDispatch();
  const [modelId, setModelId] = useState(formData?.model_name);

  const GetSite = async (value, name) => {
    // setAccId(e.target.value);
    let val = value;
    setFormData((old) => ({ ...old, [name]: parseInt(val) }));
    setDefaultfromData((old) => ({ ...old, [name]: parseInt(val) }));
    FetChFetchInfo(val);
  };

  const FetChFetchInfo = async (id) => {
    if (!id) {
      return "";
    }
    let fetchAccData = await AccountSiteList(id);
    let resSiteData = fetchAccData?.site_details || [];
    setSiteData(resSiteData);
  };

  const handleBrandChange = async (name, bId) => {
    if (!bId) {
      return "";
    }
    setModelChange(true);
    const fd = { ...formData };
    fd[name] = bId;
    fd.model_name = "";
    fd.file_name = "";
    fd.part_number = "";
    fd.sub_model_id = "";
    setFormData(fd);
    setDefaultfromData(fd);
    let ModelRes = await GetAedModelsByBrandId(bId);
    let modelResult = ModelRes?.data || [];
    setPermission(default_conditoions);
    dispatch(updatePermission(default_conditoions));
    setModels(modelResult);
  };

  const handleModelChange = async (name, ModelId) => {
    if (!ModelId) {
      return "";
    }
    setModelChange(true);
    const fd = { ...formData, ...modelResetValues }; //{...defaultFromData};//DefaultValue;//{...formData};
    let partDetails = models.find(
      (item) => parseInt(item.id) === parseInt(ModelId)
    );
    setWarentyYear(partDetails?.warranty);
    fd[name] = ModelId;
    fd.file_name = partDetails?.image_file_name;
    fd.part_number = partDetails?.model_partnumber;
    fd.sub_model_id = "";
    setFormData(fd);
    setDefaultfromData(fd);
    let ModelRes = await GetAedSumModelsById(ModelId);
    let modelResult = ModelRes?.data || [];
    setPermission(partDetails);
    dispatch(updatePermission(partDetails));
    setSubModels(modelResult);
    setModelId(ModelId);
  };

  const handlesubModel = (e) => {
    let ModelId = e.target.value;
    let name = e.target.name;
    const fd = { ...formData };

    if (!ModelId) {
      fd[name] = ModelId;
      setFormData(fd);
      setDefaultfromData(fd);
      return "";
    }
    let list = subModels?.find((item) => item.id === parseInt(ModelId));
    if (list?.sub_model_part_number) {
      fd.part_number = list?.sub_model_part_number;
    }
    fd[name] = ModelId;
    setFormData(fd);
    setDefaultfromData(fd);
    setPartDetails(list);
  };

  const handleInput = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    setFormData((old) => ({ ...old, [name]: val }));
    setDefaultfromData((old) => ({ ...old, [name]: val }));
  };

  const handleCheckBox = (e, ToggleName) => {
    if (e.target.type == "checkbox") {
      setFormData((old) => ({
        ...old,
        [e.target.name]: e.target.checked ? 1 : 0,
      }));
      setDefaultfromData((old) => ({
        ...old,
        [e.target.name]: e.target.checked ? 1 : 0,
      }));
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
      setDefaultfromData((old) => ({
        ...old,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleDateChange = async (name, val) => {
    const fd = { ...formData };

    let ModelRes = await GetAedModelsByBrandId(formData?.brand);
    let modelResult = ModelRes?.data || [];
    setModels(modelResult);

    let partDetails = modelResult.find(
      (item) => parseInt(item.id) === parseInt(formData?.model_name)
    );

    let warrentyYear = partDetails?.warranty;
    if (name === "purchase_date") {
      let warenty_date = moment(val)
        .add(warrentyYear, "years")
        .format("YYYY-MM-DD");
      fd.warenty_date = warenty_date;
    } else if (name === "installed") {
      fd.installed = moment(val).format("YYYY-MM-DD");
    }
    fd[name] = val;
    setFormData(fd);
    setDefaultfromData(fd);
  };

  const handleSelectChange = (data, name) => {
    setFormData((old) => ({ ...old, [name]: data.value }));
  };

  // handle For Edits
  const handleEditBrandGetModal = async (id) => {
    let ModelRes = await GetAedModelsByBrandId(id);
    let modelResult = ModelRes?.data || [];
    setModels(modelResult);
    handleEditModalGetSubModal(formData?.model_name, modelResult);
  };

  const handleEditModalGetSubModal = async (ModelId, modelData) => {
    let partDetails = modelData.find(
      (item) => parseInt(item.id) === parseInt(ModelId)
    );
    setWarentyYear(partDetails?.warranty);
    let ModelRes = await GetAedSumModelsById(ModelId);
    let modelResult = ModelRes?.data || [];
    // setPermission(partDetails);
    setPermission((old) => ({ ...old, ...partDetails }));
    dispatch(updatePermission(partDetails));
    setSubModels(modelResult);
  };

  useEffect(() => {
    if (is_edit) {
      // handleEditBrandGetModal(formData?.brand);
    }
    FetChFetchInfo(formData?.account_id);
  }, []);

  useEffect(() => {
    if (formData?.brand != "") {
      handleEditBrandGetModal(formData?.brand);
      handleDateChange("purchase_date", formData?.purchase_date);
    }
  }, [formData?.brand, formData?.model_name]);

  // useEffect(() => {
  //   if (formData?.model_name != "" && models) {
  //     handleEditModalGetSubModal(formData?.model_name, models);
  //   }
  // }, [formData?.model_name, models]);

  // const handleCheckboxClick = () => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     placement: prevData.placement === "unknown" ? "" : "unknown", // Toggle value
  //   }));
  // };

  const handleModelPopUp = (name, value) => {
    setUpdateModal(true);
  };

  return (
    <>
      <div className=" bg-gray py-4 px-4 my-2" id="edit_aed_infomation_section">
        <h2 className="heading">General Information</h2>

        <div className="row mt-4">
          <div className="col-md-9 row">
            {formData?.account_id && formData?.account_name ? (
              <div className="col-md-6 form-group">
                <label className="my-2" htmlFor="account_list">
                  Account*{" "}
                </label>
                <Form.Control
                  type="text"
                  placeholder="Account Name..."
                  required
                  name="account_name"
                  value={formData?.account_name}
                  disabled={true}
                />
                <Form.Control.Feedback type="invalid">
                  {" "}
                  Please Select Site.
                </Form.Control.Feedback>
              </div>
            ) : (
              ""
            )}

            {formData?.site ? (
              <div className="col-md-6 form-group">
                <label className="my-2" htmlFor="account_site_list">
                  Site*
                </label>
                <Form.Control
                  type="text"
                  placeholder="Site Name..."
                  required
                  name="site"
                  value={formData?.site}
                  disabled={true}
                />
                <Form.Control.Feedback type="invalid">
                  {" "}
                  Please Select Site.
                </Form.Control.Feedback>
              </div>
            ) : (
              ""
            )}

            <div className="cleafix"></div>
            <div className="col-md-3 my-2">
              <label className="my-2" htmlFor="brandDropdown">
                Brand*
              </label>
              {formData?.brand ? (
                <GlobalDropdown
                  dropDownName={"brand"}
                  apiEndpoint={"equipment/brand-dropdown"}
                  idKey={"id"}
                  valueKey={"AED_brands"}
                  parentKey={""}
                  onSelect={(e) => {
                    handleBrandChange("brand", e.target.value);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  disabled={is_edit}
                  initialSelectedValue={formData?.brand}
                  defaultValue={{
                    id: formData?.brand,
                    value: formData?.brandName,
                  }}
                />
              ) : (
                ""
              )}
              <Form.Control.Feedback type="invalid">
                {" "}
                Please Select Brand.
              </Form.Control.Feedback>
            </div>

            <div className="col-md-3 my-2">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label className="my-2" htmlFor="modelDropdown">
                  Model*
                </label>
                {isPermission({ type: "btn", name: "editBrandModel" }) ? (
                  <label
                    className="my-2"
                    htmlFor="modelDropdown"
                    style={{ color: "#0C71C3", cursor: "pointer" }}
                    onClick={() =>
                      handleModelPopUp("model_name", formData?.model_name)
                    }
                  >
                    Edit
                  </label>
                ) : (
                  ""
                )}
              </div>
              <select
                id="modelDropdown"
                className="form-control"
                onChange={(e) => {
                  handleModelChange("model_name", e.target.value);
                }}
                name="model_name"
                value={formData?.model_name}
                defaultValue={formData?.modelName}
                disabled={is_edit}
                required
              >
                <option value="" key={0} selected>
                  --Select One--
                </option>
                {models.map((model, index) => (
                  <option key={index + 1} value={model?.id}>
                    {model?.model_name}
                  </option>
                ))}
              </select>
              <Form.Control.Feedback type="invalid">
                {" "}
                Please Select Model.
              </Form.Control.Feedback>
            </div>

            {subModels?.length > 0 ? (
              <div className="col-md-2 my-2">
                <label className="my-2" htmlFor="subModelDropdown">
                  Sub-Model
                </label>

                <GlobalDropdown
                  dropDownName={"sub_model_id"}
                  apiEndpoint={
                    "equipment/aed-submodels-by-models?model_id=" +
                    formData?.model_name
                  }
                  idKey={"id"}
                  valueKey={"sub_model_name"}
                  parentKey={""}
                  onSelect={(e) => {
                    handlesubModel(e);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={false}
                  initialSelectedValue={formData?.sub_model_id}
                  defaultValue={{
                    id: formData?.sub_model_id,
                    value: formData?.sub_model_name,
                  }}
                />
              </div>
            ) : (
              <div className="col-md-3 my-2"></div>
            )}

            <div className="col-md-2 my-2">
              <label className="my-2" htmlFor="subModelDropdown">
                Part #
              </label>
              <br />
              <b>{formData?.part_number ? formData?.part_number : <></>}</b>
            </div>

            <div className="col-md-3 my-2">
              <label htmlFor="">Serial #*</label>
              <input
                type="text"
                onChange={handleInput}
                value={formData?.serial_number}
                className="form-control"
                required
                name="serial_number"
                defaultValue={formData?.serial_number}
              />
              <Form.Control.Feedback type="invalid">
                {" "}
                Please Enter Serial.
              </Form.Control.Feedback>
            </div>

            <div className="col-md-3 my-2">
              <label htmlFor="">Asset #</label>
              <input
                type="text"
                onChange={handleInput}
                value={formData?.asset}
                className="form-control"
                name="asset"
                defaultValue={formData?.asset}
              />
            </div>
            {formData?.other1_lable ? (
              <div className="col-md-3 my-2">
                <label htmlFor="">{formData?.other1_lable}</label>
                <input
                  type="text"
                  onChange={handleInput}
                  value={formData?.other1}
                  className="form-control"
                  name="other1"
                />
              </div>
            ) : (
              ""
            )}
            {formData?.other2_lable ? (
              <div className="col-md-3 my-2">
                <label htmlFor="">{formData?.other2_lable}</label>
                <input
                  type="text"
                  onChange={handleInput}
                  value={formData?.other2}
                  className="form-control"
                  name="other2"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-3 text-right d-flex justify-content-end">
            <input
              type="hidden"
              name="file_name"
              value={formData?.file_name}
              onChange={handleInput}
            />
            {formData?.file_name ? (
              <img
                src={AED_IMG_BASE + formData?.file_name}
                className="file-image"
                style={{ width: 150 }}
              />
            ) : (
              <img
                src="/photo-image.svg"
                className="file-image"
                style={{ width: 150 }}
              />
            )}
          </div>

          <div className="AedToggleRow my-2">
            <div className="col-mt-3 w-50">
              <label htmlFor="">Installed</label>
              <CommonDatePicker
                calName={"installed"}
                CalVal={formData?.installed}
                HandleChange={(e, value) =>
                  handleDateChange("installed", value)
                }
                defaultValue={formData?.installed}
              />
            </div>

            <div className="col-mt-3 w-50">
              <label htmlFor="">Purchase Type</label>
              <select
                className="form-control"
                name="purchase_type"
                value={formData?.purchase_type}
                onChange={handleInput}
                defaultValue={formData?.purchase_type}
              >
                <option value="" selected>
                  --Select One--
                </option>
                <option value="Own">Own</option>
                <option value="Rental">Rental</option>
                <option value="Leased">Leased</option>
              </select>
            </div>

            <div className="form-group col-mt-3 w-50">
              <label htmlFor="">Purchase Date</label>
              <CommonDatePicker
                calName={"purchase_date"}
                CalVal={formData?.purchase_date}
                HandleChange={handleDateChange}
                defaultValue={formData?.purchase_date}
              />
            </div>

            <div className="col-mt-3 w-50">
              <label htmlFor="">Invoice #</label>
              <input
                type="text"
                onChange={handleInput}
                value={formData?.invoice}
                className="form-control"
                name="invoice"
                defaultValue={formData?.invoice}
              />
            </div>
          </div>

          <div className="AedToggleRow">
            {/* <div className="d-flex w-100"> */}
            <div className="col-mt-3 w-100">
              <label htmlFor="">Placement</label>
              <input
                type="text"
                onChange={handleInput}
                value={formData?.placement}
                // value={HandleUnknow(formData?.placement)}
                className="form-control"
                name="placement"
                defaultValue={formData?.placement}
                // disabled={formData?.placement === "unknown" ? 1 : 0}
              />
            </div>
            {/* <span>
                <img
                  src={QuestionMark}
                  alt=""
                  onClick={handleCheckboxClick}
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5px",
                    cursor: "pointer",
                    marginTop: "40px",
                  }}
                />
              </span>
            </div> */}

            <div className="AedGeneralToggle">
              <Form.Group>
                <b className={""}>Spares</b>
                <div className="mt-2">
                  <CustomToggleButton
                    ToggleName="no_spares_toggle"
                    ToggleValue={formData?.no_spares_toggle}
                    changeHandler={(e) => {
                      handleCheckBox(e, "no_spares_toggle");
                    }}
                    defaultValue={formData?.no_spares_toggle}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <b className={""}>Pediatric</b>
                <div className="mt-2">
                  <CustomToggleButton
                    ToggleName="no_pediatric_toggle"
                    ToggleValue={formData?.no_pediatric_toggle}
                    changeHandler={handleCheckBox}
                    is_read_only={
                      !(
                        Permissins?.has_pedpak ||
                        Permissins?.has_ped_pad ||
                        Permissins?.has_ped_key
                      )
                    }
                    defaultValue={formData?.no_pediatric_toggle}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <b className={""}>RMS</b>
                <div className="mt-2">
                  <CustomToggleButton
                    ToggleName="RMS_toggle"
                    ToggleValue={formData?.RMS_toggle}
                    changeHandler={handleCheckBox}
                    defaultValue={formData?.RMS_toggle}
                  />
                </div>
              </Form.Group>

              <Form.Group style={{ minWidth: "150px" }}>
                <b className={"w-100 "}>Out Of Service</b>
                <div className="mt-2">
                  <CustomToggleButton
                    ToggleName="out_of_service_toggle"
                    ToggleValue={formData?.out_of_service_toggle}
                    changeHandler={handleCheckBox}
                    defaultValue={formData?.out_of_service_toggle}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditGeneralInfo;
