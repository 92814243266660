import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../../../common/services';
import { Box } from '@mui/material';
import AEDUnitsSkeleton from './AedUnitsSkeleton';
import { AED_IMG_BASE } from "../../../../common/services/index";

const AEDUnits = ({accountId, setAEDUnitData})=> {
    const [AEDUnits,setAEDUnits] = useState([]);
    const [loading,setLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await CallGETAPI('account/acc-details-aedunit/' + accountId);
            if (result?.data?.status) {
                const aedUnitsArray = result?.data?.data;
                setAEDUnits(aedUnitsArray);
                setAEDUnitData(aedUnitsArray);
            }
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        fetchData();
    },[accountId])

    return (
        <>
            <Box className="text-left pt-3 ">
                {/* <h4 className="heading">AED Units Owned</h4> */}
            </Box>

            <div>
                {loading ? <AEDUnitsSkeleton/> :
                    <div style={{ display: "flex", flexWrap: "wrap", gap: 0 }}>
                        {AEDUnits.length > 0 ? (
                            <>
                              <div style={{ width: "100%", border: '1px solid #0C71C3', margin: '5px 0' }} />
                                {[...Array(Math.ceil(AEDUnits.length / 6) * 6)].map((_, i) => {
                                    const aedIndex = i < AEDUnits.length ? i : null;
                                    const aed = aedIndex !== null ? AEDUnits[aedIndex] : null;
                                    const isFirstInRow = i % 6 === 0;
                                    const showLineAbove = isFirstInRow && i > 0;

                                    return (
                                        <>
                                            {showLineAbove && (
                                                <div style={{ width: "100%", border: '1px solid #0C71C3', margin: '5px 0' }} />
                                            )}
                                            <div
                                                key={i}
                                                style={{
                                                    flex: "0 0 16.666%",
                                                    maxWidth: "17.666%",
                                                    marginBottom: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        height: 55,
                                                    }}
                                                >
                                                    <strong style={{ margin: 0 }}>{aed?.brandName}</strong>
                                                    <strong style={{ margin: 0 }}>{aed?.modalName}</strong>
                                                </div>

                                                {aed && (
                                                    <div
                                                        style={{
                                                            padding: "8% 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <img
                                                            src={AED_IMG_BASE + aed?.modalImage}
                                                            alt={aed?.modalName}
                                                            style={{
                                                                width: 130,
                                                                height: 130,
                                                                objectFit: "contain",
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                {aed && (
                                                    <div
                                                        style={{
                                                            padding: "1% 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <p style={{ fontSize: 18 }}>Own: {aed?.own}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        ) : ( <></>
                            // <div
                            //     style={{
                            //         width: "100%",
                            //         marginBottom: 0,
                            //         paddingTop: "12px",
                            //         textAlign: "center",
                            //     }}
                            //     className={`border-l-blue border-t-blue border-r-blue border-b-blue`}
                            // >
                            //     <p>No AED units available</p>
                            // </div>
                        )}
                    </div>
                }
            </div>
        </>
    )
}

export default AEDUnits