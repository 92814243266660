import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faHome,
  faPhoneFlip,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "react-moment";
import { GetAccountContactList } from "../../../../common/helper/BasicFn";
import { ContactStatus, formatPhoneNumber } from "../../../../common/helper";
import { CallGETAPI } from "../../../../common/services";
import { convertToUserTime, printConsole } from "../../../../common/helper/Common";
import home from "../../../../common/img/home.svg";
import mobile from "../../../../common/img/mobile.svg";
import work from "../../../../common/img/work-phone.svg";
import fax from "../../../../common/img/fax.svg";
import personalEmail from "../../../../common/img/personal-email.svg";
import workEmail from "../../../../common/img/work-email.svg";

export default function ContactDetails() {
  const { contactId } = useParams();
  printConsole("qwqwqqw", contactId); // print console.log
  const [MainEmail, setMainEmail] = useState("");
  const [MainPhone, setMainPhone] = useState("");
  const [AccountRoles, setAccountRoles] = useState([]);
  const [SiteRoles, setSiteRoles] = useState([]);
  const [primaryAed, setPrimaryAed] = useState([]);
  const [backupAed, setBackupAed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contact_details: {},
    isUser: "",
    phone_numbers: [],
    emails: [],
    account_information: {},
    site_information: {},
  });
  const navigate = useNavigate();
  
  // fetch country
  const fetchCountryAndState = async (id, stateId) => {
    setLoading(true);
    const results = await CallGETAPI("account/get-state-by-country/" + id);
    if (results?.status) {
      let states = results?.data?.data?.state;
      return states.find((state) => state.state_id == stateId);
    }
    setLoading(false);
  };

  const fetch = async () => {
    setLoading(true);
    let data = await GetAccountContactList(contactId);

    printConsole("yuyuyu", { data }); // print console.log
    if (data) {
      let allData = data?.data?.data;
      let contactDetails = allData?.contact_details;
      if (contactDetails?.contact_state && contactDetails?.contact_country) {
        let state = await fetchCountryAndState(
          contactDetails?.contact_country,
          contactDetails?.contact_state
        );
        contactDetails.contact_state_name = state?.state_name;
      }

      setFormData(allData);
      setSiteRoles(data?.data?.data?.site_information);
      setAccountRoles(data?.data?.data?.account_information?.account_roles);
      setMainEmailData(data?.data?.data?.emails);
      setMainPhoneData(data?.data?.data?.phone_numbers);
      setBackupAed(data?.data?.data?.assigned_info?.backup)
      setPrimaryAed(data?.data?.data?.assigned_info?.primary)
    }
    setLoading(false);
  };

  function setMainEmailData(data) {
    data?.map((item, index) => {
      if (item.account_main_contact_email_main == 1) {
        setMainEmail({
          email: item.account_main_contact_email,
          email_type_id: item.email_type_id,
        });
      }
    });
  }

  function setMainPhoneData(data) {
    printConsole("uuuuuuuuu", data); // print console.log
    data?.map((item, index) => {
      if (item.account_main_contact_phone_main == 1) {
        setMainPhone({
          phone: item.account_main_contact_phone,
          ext: item.account_main_contact_phone_ext,
          phone_type_id: item.phone_type_id,
        });
      }
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div
      className="relative"
      style={{
        width: "100%",
        paddingInline: "0px",
        marginLeft: "0px",
        paddingLeft: "0px",
        marginBottom: "5%",
      }}
    >
      {/* general information */}
      <div
        className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
        style={{
          background: "white",
          border: "2px solid #00578E",
          borderRadius: "5px",
        }}
        id="main_account_section"
      >
        {/* heading */}
        <Box className="text-left pt-3">
          <h4 className="heading">General Information</h4>
        </Box>

        {/* Two-column layout */}
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          {/* Left Column */}
          <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px", marginLeft: "100px" }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Title</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <span>{formData?.contact_details?.account_main_contact_title || "--"}</span>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Department</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <span>{formData?.contact_details?.account_main_contact_department || "--"}</span>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Personal Address</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <span>
                    {formData?.contact_details?.contact_address1 ?? ""}{" "}
                    {formData?.contact_details?.contact_address2 ?? ""}{" "}
                    {formData?.contact_details?.contact_city ?? ""}{" "}
                    {formData?.contact_details?.contact_state_name ?? ""}{" "}
                    {formData?.contact_details?.contact_country_name ?? ""}{" "}
                    {formData?.contact_details?.contact_zipcode ?? ""}
                  </span>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>User</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : formData.isUser ? (
                  <CheckIcon color={"success"} />
                ) : (
                  <CloseIcon color={"error"} />
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Training OptOut</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : formData?.contact_details?.training_optout == 1 ? (
                  <>
                    <CheckIcon color={"success"} />
                  </>
                ) : (
                  <CloseIcon color={"error"} />
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Created by</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <> <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{formData?.contact_details?.created_by || "--"} </div>
                    {formData?.contact_details?.created_date ? (
                      <Moment
                        date={convertToUserTime(formData?.contact_details?.created_date)}
                        format={"MM/DD/YYYY h:mm A"}
                      />
                    ) : (
                      "--"
                    )}
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px" }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Contact Status</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <span
                    className={
                      formData?.contact_details?.contact_status_id == 1 ? "" : "text-danger"
                    }
                  >
                    {ContactStatus[formData?.contact_details?.contact_status_id] || "--"}
                  </span>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Phone</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  MainPhone?.phone && (
                    <a
                      className="link"
                      style={{ textDecoration: "none" }}
                      href={"tel:" + MainPhone?.phone + "p" + MainPhone?.ext}
                    >
                      {Number(MainPhone.phone_type_id) === 1 && (
                        <img
                          src={mobile}
                          alt="mobile"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainPhone.phone_type_id) === 3 && (
                        <img
                          src={home}
                          alt="home"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainPhone.phone_type_id) === 4 && (
                        <img
                          src={work}
                          alt="work"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainPhone.phone_type_id) === 5 && (
                        <img
                          src={fax}
                          alt="fax"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainPhone.phone_type_id) === 2 && (
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainPhone.phone_type_id) === 0 && (
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {MainPhone?.phone ? formatPhoneNumber(MainPhone.phone) : ""}{" "}
                      {MainPhone?.ext ? " X " + MainPhone?.ext : ""}
                    </a>
                  )
                )}
                {formData?.phone_numbers &&
                  formData?.phone_numbers.map((phone, index) => (
                    phone?.account_main_contact_phone_main != 1 && (
                      <div key={index} style={{ marginTop: "5px", minWidth: "300px" }}>
                        <a
                          className="link"
                          style={{ textDecoration: "none" }}
                          href={
                            "tel:" +
                            phone?.account_main_contact_phone +
                            "p" +
                            phone?.account_main_contact_phone_ext
                          }
                        >
                          {Number(phone?.phone_type_id) === 1 && (
                            <img
                              src={mobile}
                              alt="mobile"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(phone?.phone_type_id) === 2 && (
                            <FontAwesomeIcon
                              icon={faPhone}
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(phone?.phone_type_id) === 3 && (
                            <img
                              src={home}
                              alt="home"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(phone?.phone_type_id) === 4 && (
                            <img
                              src={work}
                              alt="work"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(phone?.phone_type_id) === 5 && (
                            <img
                              src={fax}
                              alt="fax"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(phone?.phone_type_id) === 0 && (
                            <FontAwesomeIcon
                              icon={faPhone}
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {phone?.account_main_contact_phone
                            ? formatPhoneNumber(phone?.account_main_contact_phone)
                            : ""}
                          {phone?.account_main_contact_phone_ext
                            ? " X " + phone?.account_main_contact_phone_ext
                            : ""}
                        </a>
                      </div>
                    )
                  ))}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Email</strong>
              <div style={{ width: "200px", textAlign: "left", minWidth: "300px" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  MainEmail?.email && (
                    <a
                      className="link"
                      style={{ textDecoration: "none" }}
                      href={"mailto:" + MainEmail?.email}
                    >
                      {Number(MainEmail?.email_type_id) === 1 && (
                        <img
                          src={personalEmail}
                          alt="personalEmail"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainEmail?.email_type_id) === 2 && (
                        <img
                          src={workEmail}
                          alt="workEmail"
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {Number(MainEmail?.email_type_id) === 0 && (
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ maxWidth: "20px", marginRight: "6px" }}
                        />
                      )}
                      {MainEmail?.email}
                    </a>
                  )
                )}
                {formData?.emails &&
                  formData?.emails.map((email, index) => (
                    email?.account_main_contact_email_main != 1 && (
                      <div key={index} style={{ marginTop: "5px" }}>
                        <a
                          className="link"
                          style={{ textDecoration: "none" }}
                          href={"mailto:" + email?.account_main_contact_email}
                        >
                          {Number(email?.email_type_id) === 1 && (
                            <img
                              src={personalEmail}
                              alt="personalEmail"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(email?.email_type_id) === 2 && (
                            <img
                              src={workEmail}
                              alt="workEmail"
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {Number(email?.email_type_id) === 0 && (
                            <FontAwesomeIcon
                              icon={faEnvelope}
                              style={{ maxWidth: "20px", marginRight: "6px" }}
                            />
                          )}
                          {email?.account_main_contact_email}
                        </a>
                      </div>
                    )
                  ))}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Modified by</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <> <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{formData?.contact_details?.modified_by || "--"} </div>
                    {formData?.contact_details?.modified_date ? (
                      <Moment
                        date={convertToUserTime(formData?.contact_details?.modified_date)}
                        format={"MM/DD/YYYY h:mm A"}
                      />
                    ) : (
                      "--"
                    )}
                  </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assignment Information */}
      <div
        className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
        style={{
          background: "white",
          border: "2px solid #00578E",
          borderRadius: "5px",
        }}
        id="main_account_section"
      >
        <Box className="text-left pt-3">
          <h4 className="heading">
            Assignment Information
          </h4>
        </Box>

        {/* Two-column layout */}
        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          {/* Left Column */}
          <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px", marginLeft: "100px" }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Account</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <div style={{ minWidth: "200px" }}>
                      {formData?.account_information?.account_name || "--"}
                    </div>
                    {console.log({ AccountRoles })}
                    {AccountRoles?.map((acc, index) => (<>
                      <div key={index} style={{ minWidth: "300px" }}>
                        {acc.role || "--"}
                      </div>
                    </>))}
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Sites</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <div style={{ minWidth: "200px", }}>
                      {formData?.site_information?.[0]?.account_site_name || "--"}
                    </div>
                    {SiteRoles?.map((site, index) => (
                      <div key={index} style={{ minWidth: "300px" }}>
                        {site?.position_names || "--"}
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </div>
          </div>

          {/* Right Column */}
          <div className="col my-4 form-column" style={{ width: "45%", padding: "0 0px" }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Primary AED Assigned
              </strong>
              <div style={{ width: "100px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    {primaryAed?.map((aed, index) => (
                      <div key={index} style={{ width: "180px", minWidth: "180px", color:"#0C71C3", cursor:"pointer" }} onClick={()=>{
                        navigate("/account/aed-details/" + aed?.aed_id + "/Details");
                      }}>
                        {`${aed?.brand} ${aed?.model_name}` || "--"}{" "}
                        {aed?.serial_number || "--"}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Backup AED Assigned</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {loading ? (
                  <Skeleton width={"90%"} height={20} />
                ) : (
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                    {backupAed?.map((aed, index) => (
                      <div key={index} style={{ width: "180px", minWidth: "180px", color:"#0C71C3", cursor:"pointer" }} onClick={()=>{
                        navigate("/account/aed-details/" + aed?.aed_id + "/Details");
                      }}>
                        {`${aed?.brand} ${aed?.model_name}` || "--"}
                        {aed?.serial_number || "--"}
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>


      {/* <div className="pb-0" style={{ marginTop: "20px", marginBottom: "30px" }}>
        <div className="d-flex Created-Modified">
          <div className="CreatedDiv">
            <span>
              Created : {formData?.contact_details?.created_by}{" "}
              {formData?.contact_details?.created_date ? (
                <Moment
                  date={convertToUserTime(formData?.contact_details?.created_date)}
                  format={"MM/DD/YYYY h:mm A"}
                />
              ) : (
                ""
              )}
            </span>
          </div>

          <div className="ModifiedDiv">
            <span>
              Modified : {formData?.contact_details?.modified_by}{" "}
              {formData?.contact_details?.modified_date ? (
                <Moment
                  date={formData?.contact_details?.modified_date}
                  format={"MM/DD/YYYY h:mm A"}
                />
              ) : (
                ""
              )}{" "}
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
}
