import { useState } from "react";
import { Box } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getPermission } from "../../../../common/helper/Common";
import { DecryptToken } from "../../../../common/helper/BasicFn";
import New from "../../../../common/img/New.png";
import NoteListing from "../Note-Listing";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

export default function Notes({
  accountId,
  site_id = 0,
  contact_id = 0,
  aed_id = 0,
  type,
  note_api,
  inperson_id = 0,
  fetch_key,
}) {
  const [noteApi, setNoteApi] = useState();

  let redirectUrl = ``;
  if (type === "ACCOUNT" || type === "account") {
    redirectUrl = `/account/new-note?account_id=${accountId}`;
  }

  if (type === "CONTACT" || type === "contact") {
    redirectUrl = `/account/new-note?account_id=${accountId}&contact_id=${contact_id}`;
  }
  if (type === "SITE" || type === "site") {
    redirectUrl = `/account/new-note?account_id=${accountId}&site_id=${site_id}`;
  }
  if (type === "AED" || type === "aed") {
    redirectUrl = `/account/new-note?account_id=${accountId}&aed_id=${aed_id}`;
  }

  // if (type === 'INPERSON') {
  //   redirectUrl = `/account/new-note?account_id=${accountId}&inperson_id=${inperson_id}`;
  // }
  const user = DecryptToken();
  const privilege = getPermission();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const handleNewNoteDispatch = ({ url }) => {
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };
    BreadCrumbsObject.title = "New Note Form";
    BreadCrumbsObject.tab = "note";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: url,
      })
    );
  };

  return (
    <div className="relative" style={{ marginBottom: "5%" }}>
      {/* heading */}


      {/* data grid table*/}
      <div className="data-table pb-3">
        <NoteListing
          handleNewNoteDispatch={handleNewNoteDispatch(redirectUrl)}
          redirectUrl={redirectUrl}
          note_api={note_api}
          type={type}
          accountId={accountId}
          site_id={site_id}
          contact_id={contact_id}
          aed_id={aed_id}
          fetch_key={fetch_key}
        />
      </div>
    </div>
  );
}
