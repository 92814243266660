import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import Select from "react-select";
import { validatePhone } from "../../../common/helper";
import StateField from "../../../common/components/StateField";
import GlobalDropdown from "../../../common/components/custom-ui/GlobalDropdown";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import { printConsole } from "../../../common/helper/Common";

export default function AdminMultiTrainingFrom({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  handleInputChange,
  allDropDowns,
  noBtns,
  type,
  fieldsRequired = true,
  countriesList,
  setTrainingPhoneValidations,
  setValidateField = "",
  validated,
  sectionId = "",
  sectionName = "",
}) {
  const [main, setMain] = useState(0);
  const [selectedCountry, setSelectedCountry] = React.useState({
    account_site_country: { label: "United States", value: "231" },
    account_alternate_traning_location_country: { label: "United States", value: "231" },
    account_billing_info_country: { label: "United States", value: "231" },
    account_shipping_info_country: { label: "United States", value: "231" },
  });

  console.log({ selectedCountry })
  const [phoneValidations, setPhoneValidations] = React.useState({});

  const updateFieldChanged = (index) => (e) => {
    if (e.target.value !== " ") {
      let newArr = [...altTrainerForm]; // copying the old datas array
      if (e.target.name == "alternative_phone") {
        e.target.value = e.target.value.replace(/[^0-9 ]/g, "").trim();
        e.target.value = e.target.value.slice(0, 10);

        const phoneValidate = validatePhone(e.target.value);
        setPhoneValidations((old) => ({
          ...old,
          [index]: phoneValidate ? false : true,
        }));
        setTrainingPhoneValidations(phoneValidate ? false : true);
      }

      if (e.target.type == "checkbox") {
        let value = e.target.checked ? 1 : 0;
        newArr[index][e.target.name] = value;
      } else {
        newArr[index][e.target.name] = e.target.value; // replace e.target.value with whatever you want to change it to
      }
      setSubFormData(newArr);
    }
  };

  // handle select change
  const handleSelectChange = (data, key, index) => {
    let newArr = [...altTrainerForm];

    if (data?.value != "231") {
      newArr[index] = { 
        ...newArr[index],
        [key]: data.value,
        account_alternate_traning_location_state: "",
        account_alternate_traning_location_state_abbreviation: "",
        account_alternate_traning_location_state_name: ""
      };
    } else {
      newArr[index][key] = data.value;
    }

    setSubFormData(newArr);

    setSelectedCountry((old) => ({
      ...old,
      [key]: {
        label: data?.label,
        value: data?.value,
      },
    }));
  };

  printConsole({ altTrainerForm })

  // useEffect(() => {
  //   if (countriesList) {
  //     if (altTrainerForm[0]?.account_alternate_traning_location_country) {
  //       const country = countriesList.find(
  //         (country) =>
  //           country.value ==
  //           parseInt(
  //             altTrainerForm[0]?.account_alternate_traning_location_country
  //           )
  //       );
  //       setSelectedCountry((old) => ({
  //         ...old,
  //         account_alternate_traning_location_country: {
  //           label: country?.label,
  //           value: country?.value,
  //         },
  //       }));
  //     } else {
  //       setSelectedCountry((old) => ({
  //         ...old,
  //         account_alternate_traning_location_country: {
  //           label: countriesList[0]?.label,
  //           value: countriesList[0]?.value,
  //         },
  //       }));
  //     }
  //   }
  // }, [countriesList]);


  return (
    <>
   {altTrainerForm.map((singleForm, index) => (
  <div key={index} id={`${sectionId}_${index}`}>
    <div className="form-container" style={{ display: "flex", flexDirection: "column" }}>
      {/* Buttons Row */}
      <div
        className="form-group-responsive"
        style={{
          marginBottom: "10px",
          display: "flex",
          gap: "10px",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        {/* Empty space to align with the labels */}
        <div
          style={{
            width: "120px", // Match the label width for alignment
            margin: 0,
          }}
        ></div>

        {/* Buttons */}
        {(type !== "trainingEdit" || noBtns) && (
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              onClick={increaseAlternative}
              className="btn btn-sm btn-primary"
              type="button"
              name={`${sectionName}_plus`}
            >
              +
            </button>
            <button
              onClick={decreaseAlternative}
              className="btn btn-sm btn-danger"
              type="button"
              name={`${sectionName}_minus`}
            >
              −
            </button>
          </div>
        )}
      </div>

      <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Left Column */}
        <div className="col my-4 form-column" style={{ width: "45%", padding: "0" }}>
          {/* Company Name */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Company Name
            </Form.Label>
            <Form.Control
              type="text"
              name="account_alternate_traning_location_company_name"
              value={singleForm.account_alternate_traning_location_company_name}
              onChange={updateFieldChanged(index)}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
            />
          </Form.Group>

          {/* Phone */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Phone
            </Form.Label>
            <Form.Control
              type="text"
              name="alternative_phone"
              value={singleForm.alternative_phone}
              onChange={updateFieldChanged(index)}
              minLength="10"
              className={phoneValidations[index] ? "phone-invalid-input" : ""}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
            />
            {phoneValidations[index] ? (
              <div className="phone-invalid">Please Enter Exact 10 digits.</div>
            ) : (
              <Form.Control.Feedback type="invalid">
                Please Enter Exact 10 digits.
              </Form.Control.Feedback>
            )}
          </Form.Group>

          {/* Ext */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Ext
            </Form.Label>
            <Form.Control
              type="number"
              name="alternative_ext"
              onChange={updateFieldChanged(index)}
              value={singleForm.alternative_ext}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
            />
          </Form.Group>
        </div>

        {/* Right Column */}
        <div className="col my-4 form-column" style={{ width: "45%", padding: "0" }}>
          {/* Country */}
          <Form.Group
            className="form-group-responsive relative"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Country
            </Form.Label>
            <GlobalDropdown
              dropDownName={"account_alternate_traning_location_country"}
              apiEndpoint={"account/get-country"}
              idKey={"id"}
              valueKey={"country_name"}
              parentKey={"country"}
              onSelect={(data, value) => {
                const selectedText = data.target.options[data.target.selectedIndex].text;
                const data2 = {
                  label: selectedText,
                  value: value,
                };
                handleSelectChange(data2, "account_alternate_traning_location_country", index);
              }}
              shouldFetchOnOpen={true}
              isCache={true}
              initialSelectedValue={"231"}
              defaultValue={{
                id: "231",
                value: "United States",
              }}
              selectDisabled={true}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "0px 4px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please Enter Country.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Address */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Address *
            </Form.Label>
            <Form.Control
              type="text"
              name="account_alternate_traning_location_address1"
              value={singleForm.account_alternate_traning_location_address1}
              onChange={updateFieldChanged(index)}
              required
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
              isInvalid={validated && !singleForm?.account_alternate_traning_location_address1} // Explicitly set isInvalid
                  />
                  </div>
                  <Form.Control.Feedback type="invalid"
                        style={{
                          marginLeft: "130px",
                          display: validated && !singleForm?.account_alternate_traning_location_address1 ? "block" : "none",
                        }}>
              Please Enter Address.
            </Form.Control.Feedback>
          </Form.Group>

          {/* Address 2 */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Address 2
            </Form.Label>
            <Form.Control
              type="text"
              name="account_alternate_traning_location_address2"
              value={singleForm.account_alternate_traning_location_address2}
              onChange={updateFieldChanged(index)}
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
            />
          </Form.Group>

          {/* City */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              City *
            </Form.Label>
            <Form.Control
              type="text"
              name="account_alternate_traning_location_city"
              value={singleForm.account_alternate_traning_location_city}
              onChange={updateFieldChanged(index)}
              required
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
              isInvalid={validated && !singleForm?.account_alternate_traning_location_city} // Explicitly set isInvalid
                 />
                 </div>
                 <Form.Control.Feedback type="invalid"
                       style={{
                         marginLeft: "130px",
                         display: validated && !singleForm?.account_alternate_traning_location_city ? "block" : "none",
                       }}>
              Please Enter City.
            </Form.Control.Feedback>
          </Form.Group>

          {/* State */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              maxWidth: "530px"
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              State *
            </Form.Label>
            {selectedCountry?.account_alternate_traning_location_country?.value == "231" ? (
              <StateField
                setFormData={setSubFormData}
                valueKey="account_alternate_traning_location_state"
                selectedCountry={selectedCountry?.account_alternate_traning_location_country?.value}
                validated={validated}
                required={fieldsRequired ? true : false}
                type="array"
                index={index}
                altTrainerForm={altTrainerForm}
                stateSelectedValue={singleForm.account_alternate_traning_location_state}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "0px 4px",
                  fontSize: "14px",
                  color: "#333",
                  height: "34px",
                  width: "400px",
                }}
              />
            ) : (
              <>
                <PaginateAutoComplete
                  dropDownName="account_site_alternate_traning_location_state"
                  apiEndpoint={`account/get-state-by-country/${selectedCountry?.account_alternate_traning_location_country?.value}`}
                  isState={true}
                  onSelect={(e, val) => {
                    setSubFormData((prev) => {
                      const newArr = [...altTrainerForm];
                      newArr[index] = {
                        ...newArr[index],
                        account_alternate_traning_location_state_name: val?.state_name,
                        account_alternate_traning_location_state: val?.state_id,
                      };
                      return newArr;
                    });
                  }}
                  defaultValue={{
                    state_name: singleForm.account_alternate_traning_location_state_name ?? "",
                    state_id: singleForm.account_alternate_traning_location_state ?? "",
                  }}
                  isInvalid={validated && !singleForm.account_alternate_traning_location_state}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "0px 4px",
                    fontSize: "14px",
                    color: "#333",
                    height: "34px",
                    width: "400px",
                  }}
                />
              </>
            )}
            </div>
            {/* <Form.Control.Feedback
                  type="invalid"
                  style={{
                    display: validated && !singleForm.account_alternate_traning_location_state ? "block" : "none",
                  }}
                >
                  Please enter a valid state.
                </Form.Control.Feedback> */}
          </Form.Group>

          {/* Zip Code */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Zip code *
            </Form.Label>
            <Form.Control
              type="number"
              name="account_alternate_traning_location_zipcode"
              defaultValue={singleForm.account_alternate_traning_location_zipcode}
              onChange={updateFieldChanged(index)}
              required
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
              isInvalid={validated && !singleForm?.account_alternate_traning_location_zipcode} // Explicitly set isInvalid
                  />
                  </div>
                  <Form.Control.Feedback type="invalid"
                        style={{
                          marginLeft: "130px",
                          display: validated && !singleForm?.account_alternate_traning_location_zipcode ? "block" : "none",
                        }}>
              Please Enter Zip Code.
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    </div>
  </div>
))}
    </>
  );
}
