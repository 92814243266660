import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallGETAPI } from "../../../../common/services";
import {
  GroupBYAdminSiteCoordinatorInfo,
  truncateText,
} from "../../../../common/helper/BasicFn";
import { Box, Skeleton } from "@mui/material";
import { isPermission } from "../../../../common/helper/PermissionManager";
import '../../css/site-details.css';

const BillingShipping = ({ siteId, accountId, setEditSiteAdmin }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [billingData, setbillingData] = useState({});
  const [shippingData, setshippingData] = useState({});
  const [coordinatorData, setCoordinatorData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI(
      "site/site-billing-shipping-data/" + siteId
    );
    if (result?.data?.status) {
      const resultData = result?.data?.data;
      setbillingData(resultData?.billingData);
      setshippingData(resultData?.shippingData);
      const CoordiData = GroupBYAdminSiteCoordinatorInfo(
        resultData?.contactInformation || []
      );
      setCoordinatorData(CoordiData);

      setEditSiteAdmin((prev) => ({
        ...prev,
        billingInfo: resultData?.billingData,
        shippingInfo: resultData?.shippingData,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const hasPermisssion =
    Number(
      isPermission({
        type: "link",
        name: "contactName",
      })
    ) === 1;

  return (
    <div
      className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
      style={{
        background: "white",
        border: "2px solid #00578E",
        borderRadius: "5px",
      }}
    >
      {/* Headers section */}
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
        <h4 style={{ fontWeight: "600", color: "#0C71C3", fontSize: "22px", fontWeight: 700 }}>Billing Information</h4>
        <h4 style={{ fontWeight: "600", color: "#0C71C3", fontSize: "22px", fontWeight: 700, marginRight: "660px" }}>Shipping Information</h4>
      </div>

      {loading ? (
        <div style={{ padding: "20px", paddingLeft:"140px" ,display:"flex", flexDirection:"row", justifyContent:"space-around", width:"90%" }}>
          <div style={{display:"flex", flexDirection:"column", gap:"10px", width:"70%" }}>
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
          </div>
          <div style={{display:"flex", flexDirection:"column", gap:"10px" , width:"70%"}}>
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
            <Skeleton count={4} width={"90%"} height={20} />
          </div>
        </div>
      ) : (
        <div className="form-container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
          {/* Billing Information content */}
          <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Billing Contact</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
              <div style={{display: "flex", flexDirection: "column", columnGap: "0px"}}>
              <div >
                {loading ? (
                  <Skeleton
                    count={2}
                    width={"90%"}
                    height={20}
                  />
                ) : (
                  <div style={{ marginTop: "1px" }}>
                    {coordinatorData
                      ?.filter((CoorD) => CoorD.title === "Billing Coordinator")
                      ?.map((CoorD, i1) => (
                        <div
                          key={i1}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",

                          }}
                        >
                          P: {CoorD?.arr?.[0]?.primary || CoorD?.arr?.[1]?.primary || "N/A"}
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div >
                {loading ? (
                  <Skeleton
                    count={2}
                    width={"90%"}
                    height={20}
                  // style={{ marginTop: "4px" }}
                  />
                ) : (
                  <div style={{ marginTop: "1px" }}>
                    {coordinatorData
                      ?.filter((CoorD) => CoorD.title === "Billing Coordinator")
                      ?.map((CoorD, i1) => (
                        <div
                          key={i1}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            // marginTop: "4px"

                          }}
                        >
                          B: {CoorD?.arr?.[0]?.backup || CoorD?.arr?.[1]?.backup || "N/A"}
                        </div>
                      ))}
                  </div>
                )}
              </div>
              </div>
              </div>
            </div>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Billing Address</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {billingData?.account_billing_info_address1 && (
                  <div>{billingData.account_billing_info_address1},</div>
                )}
                {billingData?.account_billing_info_city && (
                  <div>
                    {billingData.account_billing_info_city},{" "}
                    {billingData?.account_billing_info_state}{" "}
                    {billingData?.account_billing_info_zipcode}
                  </div>
                )}
                <div>{billingData?.account_billing_info_country}</div>
              </div>
            </div>
          </div>

          {/* Shipping Information content */}
          <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Shipping Contact</strong>
              <div style={{display: "flex", flexDirection: "column", columnGap: "0px"}}>
            
            <div>
              {loading ? (
                <Skeleton
                  count={2}
                  width={"90%"}
                  height={20}
                // style={{ marginTop: "4px" }}
                />
              ) : (
                <div style={{ marginTop: "1px" }}>
                  {coordinatorData
                    ?.filter((CoorD) => CoorD.title === "Shipping Contact")
                    ?.map((CoorD, i1) => (
                      <div
                        key={i1}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        P: {CoorD?.arr?.[0]?.primary || CoorD?.arr?.[1]?.primary || "N/A"}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div>
              {loading ? (
                <Skeleton
                  count={2}
                  width={"90%"}
                  height={20}
                // style={{ marginTop: "4px" }}
                />
              ) : (
                <div style={{ marginTop: "1px" }}>
                  {coordinatorData
                    ?.filter((CoorD) => CoorD.title === "Shipping Contact")
                    ?.map((CoorD, i1) => (
                      <div
                        key={i1}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        B: {CoorD?.arr?.[0]?.backup || CoorD?.arr?.[1]?.backup || "N/A"}
                      </div>
                    ))}
                </div>
              )}
            </div>
            </div>
            </div>
            <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
              <strong style={{ width: "200px", textAlign: "right" }}>Shipping Address</strong>
              <div style={{ width: "200px", textAlign: "left" }}>
                {shippingData?.account_shipping_info_address1 && (
                  <div>{shippingData.account_shipping_info_address1},</div>
                )}
                {shippingData?.account_shipping_info_city && (
                  <div>
                    {shippingData.account_shipping_info_city},{" "}
                    {shippingData?.account_shipping_info_state}{" "}
                    {shippingData?.account_shipping_info_zipcode}
                  </div>
                )}
                <div>{shippingData?.account_shipping_info_country}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingShipping;