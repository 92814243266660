import React, { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import { CallGETAPI } from "../services";
import { toast } from "react-toastify";

const StateField = forwardRef(
  (
    {
      selectedCountry,
      setFormData,
      valueKey,
      validated,
      required,
      type,
      index,
      altTrainerForm,
      stateSelectedValue,
      resetState = false, // Ensure this is used to reset
      setValueEmpty,
      handleParentChange,
      style, // Add style prop
    },
    ref
  ) => {
    const [value, setValue] = useState("");
    const [valueValidated, setValueValidated] = useState(false);
    const [states, setStates] = useState([]);
    const [touched, setTouched] = useState(false);

    // Sync value with stateSelectedValue and resetState
    useEffect(() => {
      if (resetState || !stateSelectedValue) {
        setValue("");
        setValueValidated(false);
      } else if (stateSelectedValue && states.length > 0) {
        const findState = states.find(
          (state) => state.state_id === parseInt(stateSelectedValue)
        );
        setValue(findState ? findState.state_name : "");
      }
    }, [stateSelectedValue, resetState, states]);

    const handleChange = (event) => {
      setTouched(true);
      const newValue = event.target.value;
      if (!newValue) {
        setValue("");
        formDataValueUpdate("");
        if (handleParentChange) {
          handleParentChange(false);
        }
      } else {
        setValue(newValue);
        formDataValueUpdate(newValue);
      }
    };

    const getState = (value) => {
      if (!value) return null;
      if (Number(selectedCountry) === 231) {
        const state = states.find(
          (state) => state.abbreviation === value.toUpperCase()
        );
        if (value.trim().length === 2 && !state) {
          toast.error("Invalid state abbreviation");
        }
        return state;
      } else {
        return states.find(
          (state) => state.state_name.toLowerCase() === value.toLowerCase()
        );
      }
    };

    const formDataValueUpdate = (value) => {
      if (type !== "array") {
        let state = getState(value);
        if (state) {
          setFormData((old) => ({
            ...old,
            [valueKey]: state.state_id,
            [valueKey + "_name"]: state.state_name,
            [valueKey + "_abbreviation"]: state.abbreviation,
          }));
          setValueValidated(false);
        } else {
          setFormData((old) => ({
            ...old,
            [valueKey]: "",
            [valueKey + "_name"]: "",
            [valueKey + "_abbreviation"]: "",
          }));
          setValueValidated(true);
        }
      } else {
        let state = getState(value);
        if (state) {
          let newArr = [...altTrainerForm];
          newArr[index] = {
            ...newArr[index],
            [valueKey]: state.state_id,
            [valueKey + "_name"]: state.state_name,
            [valueKey + "_abbreviation"]: state.abbreviation,
          };
          if (handleParentChange) {
            handleParentChange({
              id: state.state_id,
              name: state.state_name,
            });
          } else {
            setFormData(newArr);
          }
          setValueValidated(false);
        } else {
          setValueValidated(true);
        }
      }
    };

    const fetchCountry = async (id) => {
      if (!id) return [];
      const results = await CallGETAPI("account/get-state-by-country/" + id);
      return results?.status ? results?.data?.data?.state : [];
    };

    useEffect(() => {
      if (selectedCountry) {
        fetchCountry(selectedCountry).then((states) => {
          setStates(states || []);
          if (stateSelectedValue && !resetState) {
            const findState = states.find(
              (state) => state.state_id === parseInt(stateSelectedValue)
            );
            if (findState) {
              setValue(findState.state_name);
            } else {
              setValue("");
            }
          } else {
            setValue(""); // Ensure reset when country changes
          }
        });
      } else {
        setStates([]);
        setValue("");
      }
    }, [selectedCountry]);

    const isInvalid = validated && required && valueValidated && touched;

    return (
      <Form.Group className="col">
        <Form.Control
          name={valueKey}
          type="text"
          value={value} // Fully controlled by internal value state
          onChange={handleChange}
          className={isInvalid ? "invalid-input" : ""}
          ref={ref}
          required={required}
          isInvalid={isInvalid}
          onBlur={() => setTouched(true)}
          style={style} // Apply passed styles
        />
        <Form.Control.Feedback type="invalid">
          Please enter a valid state.
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
);

export default StateField;