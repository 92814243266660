import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Icon, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import { toast } from "react-toastify";

MultiEmailFormAdmin.defaultProps = {
  emailRefs: { current: {} },
};

export default function MultiEmailFormAdmin({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  allDropDowns,
  noBtns,
  section,
  sectionId = "",
  sectionName = "",
  emailRefs,
}) {
  const [emailErrors, setEmailErrors] = useState(
    Array(altTrainerForm.length).fill(false)
  );

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateEmailAddresses = (forms) => {
    const newEmailErrors = forms.map((form) => {
      const email = form.account_main_contact_email || "";
      return email.trim() !== "" && !validateEmail(email);
    });
    setEmailErrors(newEmailErrors);
  };

  const updateFieldChanged = (e, index, name) => {
    const newArr = [...altTrainerForm];
    let newEmailErrors = [...emailErrors];

    if (name === `account_main_contact_email_main`) {
      newArr.forEach((form, i) => {
        newArr[i].account_main_contact_email_main =
          i === index ? (form.account_main_contact_email_main ? 0 : 1) : 0;
      });
    } else {
      newArr[index][name] = e.target.value;
    }

    if (name === "account_main_contact_email") {
      if (!validateEmail(e.target.value)) {
        newEmailErrors[index] = true;
      } else {
        newEmailErrors[index] = false;
      }
    }

    setEmailErrors(newEmailErrors);
    setSubFormData(newArr);
  };

  const handleDecreaseAlternative = (section, index) => {
    decreaseAlternative(section, index);
    validateEmailAddresses(altTrainerForm);
  };

  useEffect(() => {
    validateEmailAddresses(altTrainerForm);
  }, [altTrainerForm]);

  return (
    <>
   {altTrainerForm.map((singleForm, index) => (
  <div
    className="row mb-4"
    style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    key={index}
    id={`${sectionId}_${index}`}
  >
    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        justifyContent: "flex-start",
      }}
    >
      <div style={{ width: "120px", margin: 0 }}></div>
      {!noBtns && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={increaseAlternative}
            type="button"
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#007bff",
              color: "white",
            }}
            name={`${sectionName}_plus`}
          >
            +
          </button>
          <button
            onClick={() => handleDecreaseAlternative(section, index)}
            type="button"
            style={{
              padding: "5px 10px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "none",
              cursor: "pointer",
              backgroundColor: "#dc3545",
              color: "white",
            }}
            name={`${sectionName}_minus`}
          >
            −
          </button>
        </div>
      )}
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Email*
      </label>
      <input
        type="email"
        required
        name="account_main_contact_email"
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
          ...(emailErrors[index] && {
            borderColor: "#dc3545",
          }),
        }}
        value={singleForm.account_main_contact_email}
        onChange={(e) =>
          updateFieldChanged(e, index, "account_main_contact_email")
        }
        ref={(el) => {
          if (emailRefs && emailRefs.current) {
            emailRefs.current.account_main_contact_email = el;
          }
        }}
      />
      {emailErrors[index] && (
        <div style={{ color: "#dc3545", fontSize: "12px", marginTop: "5px" }}>
          Please enter a valid email address.
        </div>
      )}
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Email Type
      </label>
      <select
        name="email_type_id"
        style={{
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "6px 10px",
          fontSize: "14px",
          color: "#333",
          height: "34px",
          width: "400px",
        }}
        onChange={(e) => updateFieldChanged(e, index, "email_type_id")}
        value={singleForm.email_type_id}
      >
        <option value="0" selected>
          --Select One--
        </option>
        {allDropDowns?.emailType &&
          allDropDowns?.emailType.map((ET, index) => (
            <option value={ET.dropdown_email_type_id} key={index}>
              {ET.dropdown_email_type_name}
            </option>
          ))}
      </select>
    </div>

    <div
      className="form-group-responsive"
      style={{
        marginBottom: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <label
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
          width: "120px",
          margin: 0,
          textAlign: "right",
        }}
      >
        Main
      </label>
      <div>
        <FormControlLabel
          label=""
          name="account_main_contact_email_main"
          onClick={(e) => {
            const email = altTrainerForm[index].account_main_contact_email;
            if (!email || email.trim() === "") {
              toast.error("Please first fill Email");
            } else {
              e.preventDefault();
              updateFieldChanged(e, index, "account_main_contact_email_main");
            }
          }}
          control={
            <Radio
              color="primary"
              size="medium"
              checked={singleForm?.account_main_contact_email_main}
              value={
                singleForm?.account_main_contact_email_main == true ? 1 : 0
              }
            />
          }
        />
      </div>
    </div>
  </div>
))}
    </>
  );
}
