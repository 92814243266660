import React, { useState } from "react";
import QuestionMark from "../../../../../common/img/QuestionMark.png";
import Activitycheck from "../../../../../common/img/Activity Symbols.png";
import Minus from "../../../../../common/img/Minus.svg";
import EditAedInventoryModel from "./EditAedInventoryModel";
import EditSiteListModel from "./EditSiteListModel";
import { NewModiFicationGroupIcon } from "../../../../../common/helper/icons";

const EditActionsComp = ({
  index,
  crrIndex,
  formData,
  setFormData,
  section_name,
  BatteryList,
  handleInputChange,
  is_readonly = 0,
  crrFormData,
  unKnownToggleKey,
  type,
  showAddRow,
  setShowAddRow,
  is_unknowntrue,
  modelChange,
}) => {
  const [openSitelistModel, setOpenSitelistModel] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);
  const [selectSite, setSelectedSite] = useState("");
  const [api, setApi] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  let key = "";

  if (type == "battery") {
    key = crrFormData?.battery_type_id;
  } else if (type == "pad") {
    key = crrFormData?.pad_type_id;
  }

  function areAllKeysUnknown(obj) {
    for (const key in obj) {
      if (
        obj[section_name] !== "unknown" &&
        obj["no_9v_spare_toggle"] !== "unkown" &&
        obj[key] == "unknown"
      ) {
        return false;
      }
    }
    return true;
  }

  function findObjectBySectionAndIndex(sectionName, index, unkownArr) {
    let checkKey = formData[`${sectionName.replace(/'/g, "")}`][crrIndex];
    for (let obj of unkownArr) {
      sectionName =
        sectionName === "ChargePakInformation"
          ? "charge_pack"
          : sectionName === "AdultPadInformation"
          ? "adult_pad_info"
          : sectionName === "AdultPadPakInfo"
          ? "adult_pad_pak_info"
          : sectionName === "PediatricPadInformation"
          ? "pediatric_pad_info"
          : sectionName === "PediatricPakPadInformation"
          ? "pediatric_pak_pad_info"
          : sectionName;
      if (
        obj.section_name === sectionName &&
        obj.index === index &&
        (obj.is_spare === 0 || obj.is_spare === 1)
      ) {
        return obj;
      }
    }
    return replaceUnknownWithEmptyString(checkKey);
  }

  function replaceUnknownWithEmptyString(obj) {
    for (let key in obj) {
      if (obj[key] === "unknown") {
        obj[key] = "";
      }
    }
    return obj;
  }

  const removeUnknown = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === "unknown") {
          obj[key] = "";
        }
      }
    }
    return obj;
  };

  const fillUnknown = (checkKey) => {
    // Create a new object to store the result
    let result = {};

    // Iterate over each key in the input object
    for (let key in checkKey) {
      // Check if the current key is "section_name"
      if (key === "section_name" || key === "index" || key === "is_spare") {
        // Preserve the original value for "section_name"
        result[key] = checkKey[key];
      } else {
        // Set the value to "unknown" for all other keys
        result[key] = "unknown";
      }
    }
    return result;
  };

  const handle_unknow_checkbox = (e, crrIndex, keyname, toogleKeyName) => {
    const oldData = { ...formData };
    const oldestData = { ...formData };

    const removeUnknowns = (obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] === "unknown") {
            obj[key] = "";
          }
        }
      }
      return obj;
    };

    const checkKey = formData[`${keyname.replace(/'/g, "")}`][crrIndex];

    let save_obj = {};
    if (
      keyname === "has_battery" ||
      keyname === "has_battery_spare" ||
      keyname === "has_9v" ||
      keyname === "has_9v_spare" ||
      keyname === "has_installby" ||
      keyname === "has_installby_spare" ||
      keyname === "has_man" ||
      keyname === "has_man_spare" ||
      keyname === "has_10pk" ||
      keyname === "has_10pk_spare"
    ) {
      const allKeysUnkown = areAllKeysUnknown(checkKey);

      if (formData[keyname][crrIndex].no_has_man_toggle == true) {
        save_obj = removeUnknown(checkKey);
      } else if (allKeysUnkown === true) {
        var unknownRow = oldestData[keyname][crrIndex];
        unknownRow.index = crrIndex;

        formData.unKnownArr.push(unknownRow);
        save_obj = fillUnknown(checkKey);
      } else {
        const arrObject = formData?.unKnownArr || [];
        const sectionName = `${section_name.replace(/'/g, "")}`;
        const unknownArr = formData[keyname][crrIndex];

        // const removeUnknowns = (obj) => {
        //   for (let key in obj) {
        //     if (obj.hasOwnProperty(key)) {
        //       if (obj[key] === "unknown") {
        //         obj[key] = "";
        //       }
        //     }
        //   }
        //   return obj;
        // };

        const removeUnknown = removeUnknowns(unknownArr);

        const oldRow = findObjectBySectionAndIndex(
          sectionName,
          crrIndex,
          arrObject
        );
        save_obj = modelChange ? removeUnknown : oldRow;
      }
    }

    if (
      keyname === "ChargePakInformation" ||
      keyname === "SpareChargePakInformation"
    ) {
      const allKeysUnkown = areAllKeysUnknown(checkKey);

      if (allKeysUnkown === true) {
        let unknownArr = [];
        var unknownRow = oldestData[keyname][crrIndex];
        unknownRow.index = crrIndex;

        formData.unKnownArr.push(unknownRow);
        save_obj = fillUnknown(checkKey);
      } else {
        const arrObject = formData?.unKnownArr || [];
        const sectionName = `${section_name.replace(/'/g, "")}`;
        const unknownArr = formData[keyname][crrIndex];

        // const removeUnknowns = (obj) => {
        //   for (let key in obj) {
        //     if (obj.hasOwnProperty(key)) {
        //       if (obj[key] === "unknown") {
        //         obj[key] = "";
        //       }
        //     }
        //   }
        //   return obj;
        // };

        const removeUnknown = removeUnknowns(unknownArr);
        const oldRow = findObjectBySectionAndIndex(
          sectionName,
          crrIndex,
          arrObject
        );
        save_obj = modelChange ? removeUnknown : oldRow;
      }
    }

    if (
      keyname === "AdultPadInformation" ||
      keyname === "SpareAdultPadInfo" ||
      keyname === "AdultPadPakInfo" ||
      keyname === "SpareAdultPadPakInfo" ||
      keyname === "PediatricPadInformation" ||
      keyname === "sparePadricPadInfo" ||
      keyname === "PediatricPakPadInformation" ||
      keyname === "sparePadricPakPad"
    ) {
      const allKeysUnkown = areAllKeysUnknown(checkKey);
      if (allKeysUnkown === true) {
        let unknownArr = [];
        var unknownRow = oldestData[keyname][crrIndex];
        unknownRow.index = crrIndex;

        formData.unKnownArr.push(unknownRow);
        save_obj = fillUnknown(checkKey);
      } else {
        const arrObject = formData?.unKnownArr || [];
        const unknownArr = formData[keyname][crrIndex];
        const sectionName = `${section_name.replace(/'/g, "")}`;

        // const removeUnknowns = (obj) => {
        //   for (let key in obj) {
        //     if (obj.hasOwnProperty(key)) {
        //       if (obj[key] === "unknown") {
        //         obj[key] = "";
        //       }
        //     }
        //   }
        //   return obj;
        // };

        const removeUnknown = removeUnknowns(unknownArr);
        // console.log({
        //   sectionName,
        //   crrIndex,
        //   arrObject,
        //   unknownArr,
        //   removeUnknown,
        //   modelChange,
        // });
        const oldRow = findObjectBySectionAndIndex(
          sectionName,
          crrIndex,
          arrObject
        );

        save_obj = modelChange ? removeUnknown : oldRow;
      }
    }

    setFormData((prev) => ({
      ...prev,
      [keyname]: prev[keyname].map((item, index) =>
        index === crrIndex ? { ...item, ...save_obj } : item
      ),
    }));
  };

  const handleCheckboxClick = () => {
    setIsChecked((prevState) => !prevState);
    const e = { target: { checked: !isChecked } };
    handle_unknow_checkbox(e, crrIndex, section_name, unKnownToggleKey);
  };

  const handleDNDButton = (index, Bindex, row, section_name, type) => {
    const Fd = { ...formData };
    let is_found = 0;
    for (let i2 = 0; i2 < Fd?.dni_array_list.length; i2++) {
      let element = Fd?.dni_array_list[i2];
      if (type === "battery") {
        if (element.bid === row.bid) {
          is_found = 1;
        }
      } else {
        if (element.pid === row.pid) {
          is_found = 1;
        }
      }
    }
    const checkArr = Fd?.dni_array_list?.find(
      (it) =>
        it.aed_id === row.aed_id &&
        (it?.bid === row?.bid || it?.pid === row?.pid)
    );
    if (!is_found) {
      //toast.success('DNI Addedd Successfully');
      Fd.dni_array_list.push(row);
      if (type === "battery") {
        row.accessory_type = "Battery";
        const biArr = Fd[`${section_name.replace(/'/g, "")}`];
        let dniArr = Fd.dni_array_list;
        const deletedBatteryArr = Fd.deletedBatteried;
        const removedBattery = biArr[Bindex];
        Fd.deletedBatteried = [...deletedBatteryArr, removedBattery];
        Fd[`${section_name.replace(/'/g, "")}`] = biArr.filter(
          (_, i) => i !== Bindex
        );
        dniArr = [removedBattery];
        setFormData(Fd);
      } else {
        if (
          row?.section_name === "pediatric_pad_info" ||
          row?.section_name === "spare_padric_pad_info"
        ) {
          row.accessory_type = "Pediatric Pad";
        }
        if (
          row?.section_name === "adult_pad_info" ||
          row?.section_name === "spare_adult_pad_info"
        ) {
          row.accessory_type = "Pad";
        }
        if (
          row?.section_name === "adult_pad_pak_info" ||
          row?.section_name === "spare_adult_pad_pak_info"
        ) {
          row.accessory_type = "Pad Pak";
        }

        const piArr = Fd[`${section_name.replace(/'/g, "")}`];
        let dniArr = Fd.dni_array_list;
        const deletedPadsArr = Fd.deletedBatteried;

        // const piArr = Fd[index].all_pads;
        // let dniArr = Fd[index].dni_array_list;
        // const deletedPadsArr = Fd[index].deletedPads;
        const removedPad = piArr[Bindex];
        Fd.deletedPads = [...deletedPadsArr, removedPad];
        Fd[`${section_name.replace(/'/g, "")}`] = piArr.filter(
          (_, i) => i !== Bindex
        );

        // Fd[index].deletedPads = [...deletedPadsArr, removedPad];
        // Fd[index].all_pads = piArr.filter((_, i) => i !== Bindex);
        dniArr = [removedPad];
        setFormData(Fd);
      }
      setFormData(Fd);
    }
  };

  const handleRemoveMainBatteryRow = (
    index,
    Bindex,
    ret = 0,
    type,
    section_name
  ) => {
    const Fd = { ...formData };
    const biArr = Fd[`${section_name.replace(/'/g, "")}`];
    const deletedArr = Fd.deletedBatteried;
    const removedBattery = biArr[Bindex];
    Fd[`${section_name.replace(/'/g, "")}`] = biArr.filter(
      (_, i) => i !== Bindex
    );
    Fd.deletedBatteried = [...deletedArr, removedBattery];
    setFormData(Fd);
    return ret ? Fd : undefined;
  };

  const handleRemoveMainPadRow = (
    index,
    Bindex,
    ret = 0,
    type,
    section_name
  ) => {
    const Fd = { ...formData };
    const biArr = Fd[`${section_name.replace(/'/g, "")}`];
    const deletedArr = Fd.deletedBatteried;
    const removedBattery = biArr[Bindex];

    const padName = `${section_name.replace(/'/g, "")}`;

    Fd[`${section_name.replace(/'/g, "")}`] = biArr.filter(
      (_, i) => i !== Bindex
    );
    Fd.deletedBatteried = [...deletedArr, removedBattery];
    setFormData(Fd);
    return ret ? Fd : undefined;
  };
  let showActionBtn = 0;
  if (type === "battery") {
    if (!crrFormData?.bid) {
      showActionBtn = 1;
    }
  } else if (type === "Pad") {
    if (!crrFormData?.pid) {
      showActionBtn = 1;
    }
  }

  return (
    <>
      <div className="col form-group" style={{ maxWidth: "300px" }}>
        <label htmlFor="">Actions</label>
        <div>
          {type === "battery" && !crrFormData?.bid}
          {showActionBtn ? (
            <>
              <span
                onClick={() => {
                  if (
                    areAllKeysUnknown(
                      formData[`${section_name.replace(/'/g, "")}`][crrIndex]
                    ) === true
                  ) {
                    if (!(isChecked && is_unknowntrue)) {
                      setOpenSitelistModel(true);
                    }
                  }
                }}
                //  style={{ cursor: "pointer", marginRight: "2px" }}
                style={{
                  cursor:
                    areAllKeysUnknown(
                      formData[`${section_name.replace(/'/g, "")}`][crrIndex]
                    ) === true
                      ? "pointer"
                      : "default",
                }}
                disabled={isChecked == true}
              >
                {" "}
                {type == "pad" ||
                key == "" ||
                key == "unknown" ||
                type == "battery" ? (
                  <NewModiFicationGroupIcon />
                ) : null}
              </span>
            </>
          ) : (
            ""
          )}

          {/* <span>
          <img
                  src={Activitycheck}
                  alt="Activitycheck"
                  style={{ height: "30px", width: "30px", marginLeft: "5px",
                    cursor: areAllKeysUnknown(formData[`${section_name.replace(/'/g, '')}`][crrIndex]) === true ? "pointer" : "default" }}
                  onClick={() => areAllKeysUnknown(formData[`${section_name.replace(/'/g, '')}`][crrIndex]) === true &&
                    handleDNDButton(index, crrIndex, crrFormData, section_name, type)
                  }
                />
          </span> */}
          <span>
            <img
              src={Minus}
              alt="Minus"
              height={20}
              width={30}
              style={{
                cursor:
                  areAllKeysUnknown(
                    formData[`${section_name.replace(/'/g, "")}`][crrIndex]
                  ) === true
                    ? "pointer"
                    : "default",
              }}
              onClick={() => {
                if (
                  areAllKeysUnknown(
                    formData[`${section_name.replace(/'/g, "")}`][crrIndex]
                  ) === true
                ) {
                  if (crrFormData?.pid) {
                    handleRemoveMainPadRow(
                      index,
                      crrIndex,
                      false,
                      false,
                      section_name
                    );
                    setShowAddRow((prev) => ({ ...prev, [section_name]: 1 }));
                  } else {
                    handleRemoveMainBatteryRow(
                      index,
                      crrIndex,
                      false,
                      false,
                      section_name
                    );
                    setShowAddRow((prev) => ({ ...prev, [section_name]: 1 }));
                  }
                }
              }}
            />
          </span>

          <span>
            <img
              src={QuestionMark}
              alt=""
              onClick={() => handleCheckboxClick(crrIndex)}
              style={{ height: "20px", width: "20px", marginLeft: "5px", cursor: "pointer" }}
            />
          </span>

          <img src="" />
        </div>
      </div>

      {/* {openSitelistModel && (
        <EditSiteListModel
          openSitelistModel={openSitelistModel}
          setOpenSitelistModel={setOpenSitelistModel}
          setInventoryModal={setInventoryModal}
          section_name={section_name}
          setApi={setApi}
          selectSite={selectSite}
          setSelectedSite={setSelectedSite}
        />
      )}

      {inventoryModal ? (
        <>
          <EditAedInventoryModel
            setNewFormData={setFormData}
            Bindex={crrIndex}
            // Pindex={}
            index={index}
            AedFormData={formData}
            inventoryModal={inventoryModal}
            setInventoryModal={setInventoryModal}
            contact_id={"1"}
            secName={section_name}
            inspection_by={"2"}
            api={api}
            selectSite={selectSite}
            setSelectedSite={setSelectedSite}
            crrFormData={crrFormData}
          />
        </>
      ) : (
        <></>
      )} */}

      {openSitelistModel && (
        <EditSiteListModel
          openSitelistModel={openSitelistModel}
          setOpenSitelistModel={setOpenSitelistModel}
          setInventoryModal={setInventoryModal}
          section_name={section_name}
          Bindex={crrIndex}
          index={index}
          AedFormData={formData}
          setNewFormData={setFormData}
          contact_id={"1"}
          secName={section_name}
          inspection_by={"2"}
          crrFormData={crrFormData}
        />
      )}

      {/* {inventoryModal ? (
        <>
          <EditAedInventoryModel
            setNewFormData={setFormData}
            Bindex={crrIndex}
            // Pindex={}
            index={index}
            AedFormData={formData}
            inventoryModal={inventoryModal}
            setInventoryModal={setInventoryModal}
            contact_id={"1"}
            secName={section_name}
            inspection_by={"2"}
            api={api}
            selectSite={selectSite}
            setSelectedSite={setSelectedSite}
            crrFormData={crrFormData}
          />
        </>
      ) : (
        <></>
      )} */}
    </>
  );
};

export default EditActionsComp;
