import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Icon, Radio, Switch } from "@mui/material";
import { Form, Button as BButton, Button as BsButton } from "react-bootstrap";
import { toast } from "react-toastify";

export default function MultiEmailFormAdmin({
  isRequired = "",
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  handleInputChange,
  allDropDowns,
  noBtns,
  noMain,
  setFormData,
  emailFormData,
  sectionId = "",
  sectionName = "",
  validated // Add validated prop to control when validation should show
}) {
  const updateFieldChanged = (e, index, name) => {
    const newArr = [...altTrainerForm];

    if (name === "account_main_contact_email_main") {
      newArr.forEach((form, i) => {
        newArr[i].account_main_contact_email_main = i === index ? (form.account_main_contact_email_main ? 0 : 1) : 0;
      });
    } else {
      newArr[index][name] = e.target.value;
    }

    setSubFormData(newArr);
  };

  const handleDecreaseAlternative = (index) => {
    decreaseAlternative(index);
  };

  return (
    <>
      {altTrainerForm.map((singleForm, index) => (
        <div
          className="row mb-4"
          key={index}
          id={`${sectionId}_${index}`}
          style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
        >
          {/* Separate row for the buttons */}
          <div
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {/* Empty space to align with the labels */}
            <div
              style={{
                width: "120px",
                margin: 0,
              }}
            ></div>
            {/* Buttons */}
            {!noBtns && (
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  type="button"
                  onClick={increaseAlternative}
                  className="btn py-1 btn-sm btn-primary"
                  name={`${sectionName}_plus`}
                >
                  +
                </button>
                <button
                  type="button"
                  onClick={() => handleDecreaseAlternative(index)}
                  className="btn py-1 btn-sm btn-danger"
                  name={`${sectionName}_minus`}
                >
                  −
                </button>
              </div>
            )}
          </div>
          {/* Email Field */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "1px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className="d-flex">
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#333",
                    width: "120px",
                    margin: 0,
                    textAlign: "right",
                  }}
                >
                  Email*
                </Form.Label>
              </div>
              <Form.Control
                type="email"
                required={isRequired === true || 1 ? true : false}
                name="account_main_contact_email"
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "6px 10px",
                  fontSize: "14px",
                  color: "#333",
                  height: "34px",
                  width: "400px",
                }}
                value={singleForm.account_main_contact_email}
                onChange={(e) => updateFieldChanged(e, index, "account_main_contact_email")}
                isInvalid={
                  validated &&
                  (!singleForm.account_main_contact_email ||
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(singleForm.account_main_contact_email))
                }
              />
            </div>
            <Form.Control.Feedback
              type="invalid"
              style={{
                marginLeft: "130px",
                display:
                  validated &&
                  (!singleForm.account_main_contact_email ||
                    !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(singleForm.account_main_contact_email))
                    ? "block"
                    : "none",
              }}
            >
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          {/* Email Type Field */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Form.Label
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333",
                width: "120px",
                margin: 0,
                textAlign: "right",
              }}
            >
              Email Type
            </Form.Label>
            <Form.Select
              className={""}
              name="email_type_id"
              style={{
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "6px 10px",
                fontSize: "14px",
                color: "#333",
                height: "34px",
                width: "400px",
              }}
              onChange={(e) => updateFieldChanged(e, index, "email_type_id")}
              value={singleForm.email_type_id}
            >
              <option value="0" selected>
                --Select One--
              </option>
              {allDropDowns?.emailType &&
                allDropDowns?.emailType.map((ET, index) => (
                  <option value={ET.dropdown_email_type_id} key={index}>
                    {ET.dropdown_email_type_name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          {/* Main Checkbox */}
          <Form.Group
            className="form-group-responsive"
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {!noMain && (
              <>
                <Form.Label
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#333",
                    width: "120px",
                    margin: 0,
                    textAlign: "right",
                  }}
                >
                  Main
                </Form.Label>
                <div className="">
                  <FormControlLabel
                    className={""}
                    label=""
                    control={
                      <Radio
                        color="primary"
                        size="medium"
                        checked={singleForm?.account_main_contact_email_main}
                        name={`account_main_contact_email_main-${index}`}
                        value={singleForm?.account_main_contact_email_main}
                        onClick={(e) => {
                          const email = altTrainerForm[index].account_main_contact_email;
                          if (!email || email.trim() === "") {
                            toast.error("Please first fill Email");
                          } else {
                            e.preventDefault();
                            updateFieldChanged(e, index, "account_main_contact_email_main");
                          }
                        }}
                      />
                    }
                  />
                </div>
              </>
            )}
          </Form.Group>
        </div>
      ))}
    </>
  );
}