import React from 'react'
import SupportListing from '../../Component/Support-Listing'

function AdminListing({ handleNewDispatch, hasSupportPermission, Url, support_api, fetch_data_key, isSearchable, method, stateData }) {
    return (
        <div>
            <SupportListing
                handleNewDispatch={handleNewDispatch}
                hasSupportPermission={hasSupportPermission}
                Url={Url}
                method={method}
                isSearchable={isSearchable}
                fetch_data_key={fetch_data_key}
                support_api={support_api}
                stateData={stateData}
            />
        </div>
    )
}

export default AdminListing
