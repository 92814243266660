import { Box, Skeleton } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TableSkeleton from "../skeleton/table/TableSkeleton";
import Moment from "react-moment";
import { AED_IMG_BASE } from "../../../../common/services/index";
import { convertToUserTime, FormatDateWithTime } from "../../../../common/helper/Common";
import "../../../../global.css";
import ProgramDetails from "../DetailsComponents/ProgramDetails";
import CoordinatorInformation from "../DetailsComponents/CoordinatorInformation";
import DataTableSkeleton from "../DetailsComponents/ProgramDetailsSkeleton";
import AedUnits from "../DetailsComponents/AedUnits";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { getPer } from "../../../../common/helper/BasicFn";

export default function Details({
  accountDetails,
  CoordiDataList,
  programDetails,
  httpsWeb,
  tabTbldata,
  setProgramData,
  setAedUnitData = () => { },
  setCoordinatorData,
  showLoading,
}) {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleDetailsDispatch = (accountId, account_name) => {
    BreadCrumbsObject.title = account_name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/${accountId}/Details`,
      })
    );
  };

  return (
    <>
      <div
        className="relative"
        style={{
          width: "100%",
          paddingInline: "0px",
          marginLeft: "0px",
          paddingLeft: "0px",
          marginBottom: "5%",
        }}
      >

        <div
          className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
          style={{
            background: "white",
            border: "2px solid #00578E",
            borderRadius: "5px",
          }}
          id="main_account_section"
        >
          {Number(getPer()) === 1 ? (
            <>
              <Box className="text-left pt-3">
                <h4 className="heading">General Information</h4>
              </Box>

              {showLoading ? (
                <div style={{ padding: "20px", paddingLeft: "140px", display: "flex", flexDirection: "row", justifyContent: "space-around", width: "90%" }}>
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "70%" }}>
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "70%" }}>
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                    <Skeleton count={4} width={"90%"} height={20} />
                  </div>
                </div>
              ) : (
                <div className="form-container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                  {/* Left Column */}
                  <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Account Name</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.account_name || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Main Site</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.main_site || "AL, City - Address1"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Parent Account</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.parent_account || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Distributor Account</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.distributor_account || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Customer Type</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.customer_type || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Website</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>
                        {httpsWeb ? (
                          <a
                            href={accountDetails?.website}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#0C71C3" }}
                          >
                            {accountDetails?.website}
                          </a>
                        ) : (
                          accountDetails?.website || "--"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Industry</strong>
                      <div style={{ width: "200px", textAlign: "left", minWidth: "300px" }}>{accountDetails?.industry || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Lead Source</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.lead_source || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Account Status</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.account_status || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Terms</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.terms || "--"}</div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Product Interest</strong>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {accountDetails?.product_interest?.map((PI, i) => (
                          <div style={{ width: "200px", textAlign: "left" }}>
                            {PI}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                      <strong style={{ width: "200px", textAlign: "right" }}>Important Note</strong>
                      <div style={{ width: "200px", textAlign: "left" }}>{accountDetails?.inportant_notes || "--"}</div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>


        <div
          className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
          style={{
            background: "white",
            border: "2px solid #00578E",
            borderRadius: "5px"
          }}
          id="main_account_section"
        >
          {/* Program Details */}
          <ProgramDetails accountId={accountId} setProgramData={setProgramData} />

          {/* AED Units Owned */}
          <AedUnits accountId={accountId} setAEDUnitData={setAedUnitData} />

        </div>

        <CoordinatorInformation
          accountId={accountId}
          setCoordinatorData={setCoordinatorData}
        />

        <div className="pb-0" style={{ marginTop: "40px" }}>
          <div className="d-flex Created-Modified">
            <div className="CreatedDiv">
              <span>
                Created: {accountDetails?.created_by}{" "}
                {accountDetails?.created_date
                  ? FormatDateWithTime(convertToUserTime(accountDetails?.created_date))
                  : ""}
              </span>
              {/* <span>Created By: {accountDetails?.created_by}</span> */}
            </div>

            <div className="ModifiedDiv">
              <span>
                Modified :{" "}
                {accountDetails?.modifiedBy?.account_name
                  ? accountDetails?.modifiedBy?.account_name
                  : accountDetails?.modifiedBy}{" "}
                {accountDetails?.modified_date ? (
                  <Moment
                    date={accountDetails?.modified_date}
                    format={"MM/DD/YYYY h:mm A"}
                  />
                ) : (
                  ""
                )}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
