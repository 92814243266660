// import { useNavigate } from "react-router-dom";
import { getToken } from "../helper";

// const BASE = "https://api.rossdev.xyz";
// const BASE = "http://192.155.106.105:6005";
// const BASE = "https://opt-api.rossdev.xyz";
// const BASE = "https://docker.be.v1.rossdev.xyz";
// export const BASE = "http://192.168.1.6:6005";
export const BASE = window.BASE_URL; //"https://ross-op.techcarrel.in";
export const UPLOAD_URL = window.UPLOAD_URL;
// export const BASE_API = BASE + "/api/";
export const BASE_API = BASE + "/api/w2/";   // for wave 2
export const BASE_API_NEW = BASE + "/api/w2/";
export const BASE_API_TECH = BASE + "/api/w2/";
export const AED_IMG_BASE = BASE + "/uploads/aedImages/";

const moveLogout = () => {
  localStorage.removeItem("ross_token");
  localStorage.removeItem("ross_rtoken");
  localStorage.removeItem("ross-profile");
  localStorage.removeItem("Permission");
  window.location.href = "/";
};

export async function CallGETAPI(url, params = "", headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });
    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallGETPreveledge(url, params = "", headers = {}) {
  try {
    let token = localStorage.getItem("ross_token");
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallGETAPI2(url, params = "", headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export const CallPOSTAPI = async (url, params = [], headers = {}) => {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: resultData?.msg, data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
};
export const CallPOSTAPILOGIN = async (url, params = [], headers = {}) => {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    let res = await fetch(BASE_API + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: resultData?.msg, data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
};

export async function CallDetails(params = "", headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API + "account/account_info/" + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTData(params = [], data, headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    let res = await fetch(BASE_API + "account/update-account-info/" + params, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(data),
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTAPINEW(url, params = [], headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    let res = await fetch(BASE_API_NEW + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallGETAPINEW(url, params = "", headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API_NEW + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTAPINEWTech(url, params = [], headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }

    let res = await fetch(BASE_API_TECH + url, {
      method: "POST",
      headers: { "Content-Type": "application/json", ...AllHeaders },
      body: JSON.stringify(params),
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallGETAPINEWTech(url, params = "", headers = {}) {
  try {
    let token = getToken();
    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    let res = await fetch(BASE_API_TECH + url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...AllHeaders,
      },
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}

export async function CallPOSTAPINEWFileUpload(url, params, headers = {}) {
  try {
    let token = getToken();

    let AllHeaders = { ...headers };
    if (token) {
      AllHeaders = { Authorization: "Bearer " + token, ...headers };
    }
    //             // headers:{'Content-Type':'application/json',...AllHeaders},

    let res = await fetch(BASE_API_NEW + url, {
      method: "POST",
      headers: AllHeaders,
      body: params,
    });

    if (!res.ok && res.status === 403) {
      moveLogout();
    } else if (!res.ok) {
      return { status: false, msg: "No Data Found", data: [] };
    }
    let resultData = await res.json();
    return { status: true, msg: "", data: resultData };
  } catch (e) {
    return { status: false, msg: e.message, data: [] };
  }
}
