import React, { useEffect, useState } from 'react'
import { CallGETAPI } from '../../../../common/services';
import { Box } from '@mui/material';
import DataTableSkeleton from '../../../accounts/components/DetailsComponents/ProgramDetailsSkeleton';

const SiteProgramDetails = ({ siteId }) => {
    const [programDetails, setProgramDetails] = useState({});
    const [loading, setLoading] = useState(false);

    console.log({programDetails})
    const fetchData = async () => {
        setLoading(true);
        if (!siteId) {
            return "";
        }
        const result = await CallGETAPI('site/site-details-aed-programs/' + siteId);
        if (result?.data?.status) {
            setProgramDetails(result?.data?.data);

        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [siteId]);
    return (
        <>
            <Box className="text-left pt-3">
                <h4 className="heading">AED Program</h4>
            </Box>

            {loading ? (
                <DataTableSkeleton />
            ) : (
                <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* First Column */}
                    {/* Left Column */}
                    <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
                        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                            <strong style={{ width: "200px", textAlign: "right" }}>AEDS / Status </strong>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.not_ready || "0"} Not Ready</div>
                                <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.ready || "0"} Ready </div>
                                <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.unknown || "0"} Unknown</div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                            <strong style={{ width: "200px", textAlign: "right" }}>AED Check Length</strong>
                            <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aed_check_length || "0"}</div>
                        </div>
                    </div>

                    {/* Second Column */}
                    {/* Right Column */}
                    <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
                        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                            <strong style={{ width: "200px", textAlign: "right" }}>Expiration Alerts</strong>
                            <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.expiration_alert_toggle == 1 ? "Yes" : "No" || "No"}</div>
                        </div>
                        <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                            <strong style={{ width: "200px", textAlign: "right" }}>Extra Field Names</strong>
                            <div style={{ width: "200px", textAlign: "left" }}>
                                <div style={{ width: "200px", textAlign: "left" }}>
                                    {programDetails?.extra_fields ? (
                                        <>
                                            <div>{programDetails?.extra_field1}</div>
                                            <div>{programDetails?.extra_field2}</div>
                                        </>
                                    ) : (
                                        " "
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default SiteProgramDetails