import React, { useEffect, useState } from "react";
// import "./table.css";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AEDTable from "../../../../common/components/tables/AEDs/AEDTable";
import { getPermission } from "../../../../common/helper/Common";
import TableSkeleton from "../skeleton/table/AedsSkeleton";
import '../../../sitesManagments/css/site-details.css'
import {
  CalculateAEDList,
  DecryptToken,
  FetchAEDListData,
  FetchAedListFromDashboard,
  FetchAEDListPending,
  FetchAEDOFS,
  getPer,
} from "../../../../common/helper/BasicFn";
import AEDOutOfServiceTbl from "../../../../common/components/tables/AEDs/AEDOutOfServiceTbl";
import servicecheck from "../../../../common/img/ServiceCheck.svg";
import { Dropdown } from "react-bootstrap";
import AEDStandAlone from "../../../../common/components/tables/AEDs/AEDStandAlone";
import { Button as FixedButton } from "@mui/material";
import Filter from "../filter/equipmentIndex";
import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch } from "react-redux";
import "../../../../global.css";
import {
  removeFilterData,
  removePayloadData,
} from "../../../../store/slices/AccountDetailsEquipmentFilter";
import SingleSiteName from "../SingleSiteName";
import { isSubAdminPermission } from "../../../../common/helper/permission";
import TopAedNewMove from "../../../Aed/NewAed/components/AedButtons/TopAedNewMove";
import EquipmentNoDataTbl from "../skeleton/table/EquipmentNoDataTbl";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { sortData } from "../../../../common/helper";
import {
  filterAccessoryData,
  filterAedDatas,
  filterOutOfServiceDatas,
  filterPendingAccessoryDatas,
  filterPendingAedDatas,
  hasAccessoryData,
} from "../filter/helperFunction.js/AEDFilterFn";
import AccessoryNoDataTbl from "../skeleton/table/AccessoryNoDataTbl";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable";

export default function Aeds({
  tabTbldata,
  setTabTbldata,
  accountName = "",
  aedLength,
}) {
  const privilege = getPermission();
  const navigate = useNavigate();
  const user = DecryptToken();
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [showAedTbl, setShowAedTbl] = useState(true);
  const [showAccTbl, setShowAccTbl] = useState(true);
  const [aedList, setAedList] = useState([]);
  const [originalAedList, setOriginalAedList] = useState([]);
  const [originalOutofServiceList, setOriginalOutofServiceList] = useState([]);
  const [originalPendingAedList, setOriginalPendingAedList] = useState([]);
  const [pendingaeds, setPendingaeds] = useState([]);
  const [outofServiceData, setOutofServiceData] = useState([]);
  const [aedCheckLength, setAedCheckLength] = useState("");
  const [data, setData] = useState();
  const [sortedDataList, setSortedDataList] = useState([]);
  const location = useLocation();
  const from = location?.state?.from;
  const [formData, setFormData] = useState({
    aed: true,
    // equipment: true,
    accessories: true,
    accessory_brand_model: [],
    accessory_type: [],
    aed_checkers: [],
    aed_brand_model: [],
    equipment_brand_model: [],
    equipment_type: [],
    expiration_range_type: [],
    expiration_date_range1: "",
    expiration_date_range2: "",
    sites: [],
    state: [],
  });
  const equipmentFilterData = useSelector(
    (state) => state.accountdetailsequipmentfilter.equipmentFilterData
  );
  const equipmentPayloadData = useSelector(
    (state) => state.accountdetailsequipmentfilter.equipmentPayloadData
  );

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const handleAedPendingList = async () => {
  //   const result = await FetchAEDListPending(accountId);

  //   if (result?.status) {
  //     const pendingaeds = result?.data;
  //     const resultArr = CalculateAEDList(pendingaeds);
  //     setPendingaeds(resultArr);
  //     let deepCopy = JSON.parse(JSON.stringify(resultArr));

  //     setOriginalPendingAedList(deepCopy);
  //   }
  // };

  const handleAedPendingList = async () => {
    try {
      const result = await FetchAEDListPending(accountId);
      if (result?.status) {
        const resultArr = CalculateAEDList(result?.data);

        let deepCopy = JSON.parse(JSON.stringify(resultArr));
        setOriginalPendingAedList(deepCopy);

        if (!equipmentFilterData.aed || !equipmentFilterData.accessories) {
          setPendingaeds(originalPendingAedList);
        }
        setPendingaeds(resultArr);
        return resultArr;
      }
      return [];
    } catch (error) {
      console.error("Error in handleAedPendingList:", error);
      return [];
    }
  };

  const handleAedList = async () => {
    try {
      let result;
      if (from === "dashboard") {
        result = await FetchAedListFromDashboard(accountId);
      } else {
        result = await FetchAEDListData(accountId);
      }

      if (result?.status) {
        const resultArr = CalculateAEDList(result?.data);
        // Create a deep copy using JSON parse/stringify
        const deepCopy = JSON.parse(JSON.stringify(resultArr));
        setOriginalAedList(deepCopy);
        if (!equipmentFilterData.aed || !equipmentFilterData.accessories) {
          setAedList(originalAedList);
        }
        setAedList(resultArr); // Still set the state
        return resultArr; // Also return the data
      }
      return [];
    } catch (error) {
      console.error("Error in handleAedList:", error);
      return [];
    }
  };

  useEffect(() => {
    const filterData = (aedList) => {
      // First filter AED data
      const aedFiltered = filterAedDatas(aedList, equipmentFilterData);

      // Then filter accessories on the AED filtered result
      const accessoryFiltered = filterAccessoryData(
        aedFiltered,
        equipmentFilterData
      );
      // Set the final filtered result
      setAedList(accessoryFiltered);

      // OutofService Filter

      const outofServiceFiltered = filterOutOfServiceDatas(
        outofServiceData,
        equipmentFilterData
      );

      setOutofServiceData(outofServiceFiltered);

      // Pending AED Filter
      const pendingAedFiltered = filterPendingAedDatas(
        pendingaeds,
        equipmentFilterData
      );

      setPendingaeds(pendingAedFiltered);
    };

    filterData(originalAedList);
  }, [
    equipmentFilterData.aed,
    equipmentFilterData.accessories,
    // showAedTbl,
    equipmentFilterData.aed_brand_model,
  ]);

  useEffect(() => {
    RenderAedData(originalAedList);
  }, [aedList, pendingaeds]);

  useEffect(() => { }, [outofServiceData]);

  const handleOutOfService = async () => {
    try {
      const result = await FetchAEDOFS(accountId);
      if (result?.status) {
        const OFDArr = CalculateAEDList(result?.data);
        const OFD = OFDArr.reduce((acc, el) => {
          const siteData = el.data.map((element) => ({
            site_name: el?.site_name,
            site_id: el?.site_id,
            standalone_data: el?.standalone_data || [],
            ...element,
          }));
          return [...acc, ...siteData];
        }, []);

        const deepCopy = JSON.parse(JSON.stringify(OFD));
        setOriginalOutofServiceList(deepCopy);

        if (
          equipmentFilterData &&
          Object.keys(equipmentFilterData).length !== 0
        ) {
          const filtered = filterOutOfServiceDatas(OFD, equipmentFilterData);
          setOutofServiceData(filtered);
        } else {
          setOutofServiceData(OFD);
        }
        return OFD;
      }
      return [];
    } catch (error) {
      console.error("Error in handleOutOfService:", error);
      return [];
    }
  };

  const setTblsData = (result) => {
    let aeds = result?.data || [];
    const pendingaeds = result?.pendingData;
    const OFSData = result?.outOfData;

    let newArr = [];

    if (Array.isArray(aeds) && pendingaeds?.length > 0) {
      newArr = [...pendingaeds, ...aeds];
    } else {
      newArr = aeds;
    }
    aeds = newArr;
    const resultArr = CalculateAEDList(aeds);
    const OFDArr = CalculateAEDList(OFSData);
    const OFD = [];
    for (let OFi = 0; OFi < OFDArr?.length; OFi++) {
      const el = OFDArr[OFi];
      for (let OF2 = 0; OF2 < el.data.length; OF2++) {
        const element = el.data[OF2];
        const obj = {
          site_name: el?.site_name,
          site_id: el?.site_id,
          standalone_data: el?.standalone_data || [],
          ...element,
        };
        OFD.push(obj);
      }
    }
    // setOutofServiceData(OFD);
    setAedList(resultArr);

    setShowLoading(false);
  };

  // get aeds by account
  // const getAeds = async () => {
  //   setShowLoading(true);

  //   if (
  //     Object.keys(equipmentFilterData).length !== 0 &&
  //     Object.keys(equipmentPayloadData).length !== 0
  //   ) {
  //     const result = await CallPOSTAPI(
  //       "account/equipment-filter-search-result",
  //       equipmentPayloadData
  //     );
  //     if (result?.data?.status) {
  //       setTblsData(result);
  //     }
  //   } else {
  //     const result = await CallGETAPI(
  //       "account/get-aed-with-standalon/" + accountId
  //     );
  //     if (result?.data?.status) {
  //       setTblsData(result);
  //     }
  //   }
  //   setShowLoading(false);
  // };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      let valA = a[key];
      let valB = b[key];

      if (typeof valA === "string") {
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
      }

      if (valA < valB) {
        return direction === "asc" ? -1 : 1;
      }
      if (valA > valB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const handleClearFilterData = () => {
    setFormData({
      aed: true,
      accessories: true,
      accessory_brand_model: [],
      accessory_type: [],
      aed_checkers: [],
      aed_brand_model: [],
      equipment_brand_model: [],
      equipment_type: [],
      expiration_range_type: [],
      expiration_date_range1: "",
      expiration_date_range2: "",
      sites: [],
      state: [],
    });

    // Reset to original data
    setAedList(originalAedList);
    setPendingaeds(originalPendingAedList);
    setOutofServiceData(originalOutofServiceList);

    // Clear Redux filter state
    dispatch(removeFilterData());
    dispatch(removePayloadData());

    handleDrawerClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!accountId) {
        return "";
      }
      const result = await CallGETAPI(
        "account/acc-details-programs/" + accountId
      );

      if (result?.data?.status) {
        const newData = result?.data?.data?.aed_check_length ?? "0";
        const aed_check_length = typeof newData === "string" ? newData.match(/\d+/)?.[0] || "0" : "0";
        setAedCheckLength(aed_check_length);
      }
    };

    fetchData();
  }, [accountId]);

  // Helper function to process out of service data
  const processOutOfServiceData = (data) => {
    const OFDArr = CalculateAEDList(data);
    return OFDArr.reduce((acc, el) => {
      const siteData = el.data.map((element) => ({
        site_name: el?.site_name,
        site_id: el?.site_id,
        standalone_data: el?.standalone_data || [],
        ...element,
      }));
      return [...acc, ...siteData];
    }, []);
  };

  // Initial data fetch on component mount
  useEffect(() => {
    const fetchInitialData = async () => {
      setShowLoading(true);
      try {
        // Fetch all data in parallel
        const [aedData, pendingData, outOfServiceResult] = await Promise.all([
          handleAedList(),
          Number(getPer()) === 1 ? handleAedPendingList() : Promise.resolve([]),
          Number(getPer()) === 1
            ? FetchAEDOFS(accountId)
            : Promise.resolve({ status: false, data: [] }),
        ]);

        // Store original data
        setOriginalAedList(aedData);
        setOriginalPendingAedList(pendingData);

        if (outOfServiceResult?.status) {
          const processedOFSData = processOutOfServiceData(
            outOfServiceResult.data
          );
          setOriginalOutofServiceList(processedOFSData);
        }

        // Initialize filtered data with original data
        setAedList(aedData);
        setPendingaeds(pendingData);
        // setOutofServiceData(outOfServiceResult?.status ? processedOFSData : []);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setShowLoading(false);
      }
    };

    fetchInitialData();
  }, [accountId]);

  // Apply filters when filter data changes
  useEffect(() => {
    if (!equipmentFilterData || Object.keys(equipmentFilterData).length === 0) {
      // Reset to original data if no filters
      setAedList(originalAedList);
      setPendingaeds(originalPendingAedList);
      setOutofServiceData(originalOutofServiceList);
      return;
    }

    // Apply filters to AEDs
    const filteredAeds = filterAedDatas(originalAedList, equipmentFilterData);
    equipmentFilterData.aed && setAedList(filteredAeds);

    if (
      equipmentFilterData.accessories &&
      (equipmentFilterData.accessory_brand_model.length > 0 ||
        equipmentFilterData.accessory_type != "")
    ) {
      const filteredWithAccessories = filterAccessoryData(
        filteredAeds,
        equipmentFilterData
      );
      console.log({ filteredWithAccessories });
      setAedList(filteredWithAccessories);
    }

    // Apply filters to pending AEDs
    const filteredPending = filterPendingAedDatas(
      originalPendingAedList,
      equipmentFilterData
    );
    equipmentFilterData.aed && setPendingaeds(filteredPending);

    if (
      equipmentFilterData.accessories &&
      (equipmentFilterData.accessory_brand_model.length > 0 ||
        equipmentFilterData.accessory_type != "")
    ) {
      const filteredPendingAccessory = filterPendingAccessoryDatas(
        filteredPending,
        equipmentFilterData
      );
      setPendingaeds(filteredPendingAccessory);
    }

    // Apply filters to out of service
    const filteredOFS = filterOutOfServiceDatas(
      originalOutofServiceList,
      equipmentFilterData
    );
    setOutofServiceData(filteredOFS);
  }, [
    equipmentFilterData,
    originalAedList,
    originalPendingAedList,
    originalOutofServiceList,
  ]);

  // Sorting function
  const sortSitesList = (dataList) => {
    if (!dataList) return [];

    // Check if dataList is array
    if (!Array.isArray(dataList)) {
      console.warn("dataList is not an array:", dataList);
      return [];
    }

    return [...dataList].sort((a, b) => {
      // Add null checks for site_name
      const siteNameA = (a?.site_name || "").toLowerCase();
      const siteNameB = (b?.site_name || "").toLowerCase();

      if (siteNameA < siteNameB) return -1;
      if (siteNameA > siteNameB) return 1;
      return 0;
    });
  };

  const RenderAedData = (dataList, pending = false, ...props) => {
    // Sort the dataList before rendering
    const sortedDataList = sortSitesList(dataList);
    // setSortedDataList((prev) => [...prev, ...sortedDataList]);

    return (
      <div>
        {console.log({ sortedDataList })}
        {sortedDataList?.map((item, index) => (
          <div
            style={{ alignItems: "center", borderRadius: "5px", border: "2px solid #00578E", padding: "14px", marginBottom: "20px", marginTop: "20px", background: "white", width:"100%" }}
            className="row px-0"
            key={index}
          >
            <div
              className="row w-100 EquipmentTab"
              style={{ alignItems: "center" }}
            >
              <div className="px-3 site-title-btn EquipmentHeaderDiv"

              >
                <SingleSiteName
                  equipmentFilterData={equipmentFilterData}
                  item={item}
                />
                {equipmentFilterData && equipmentFilterData?.aed === false
                  ? null
                  : true && (
                    <div className="right-btns d-flex align-items-center absolute right-0 btn-section btnsDiv mt-2">
                      <TopAedNewMove
                        accountId={accountId}
                        accountName={accountName}
                        siteId={item?.site_id}
                        siteName={item?.site_name}
                        isTechnician={item?.is_technician}
                        moveAed={1}
                        isserviceCheck={
                          item?.site_name === "Pending" ||
                            item?.data.length === 0
                            ? 0
                            : 1
                        }
                        aedAccessoryCount={aedLength}
                        hideAedMove={item?.data?.length > 0 ? 1 : 0}
                        hideAccMove={
                          item?.standalone_data?.length > 0 ? 1 : 0
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="row">
              {equipmentFilterData &&
                equipmentFilterData?.aed === false ? null : item?.data.length ===
                  0 ? (
                <EquipmentNoDataTbl
                  accountId={accountId}
                  accountName={accountName}
                  siteId={item?.site_id}
                  siteName={item?.site_name}
                  aedAccessoryCount={aedLength}
                  hideAedMove={item?.data?.length > 0 ? 1 : 0}
                  hideAccMove={item?.standalone_data?.length > 0 ? 1 : 0}
                />
              ) : (
                <>
                  {/* <NewGlobalTable/> */}
                  <AEDTable
                    accountId={accountId}
                    SingleAED={item?.data}
                    setShowAedTbl={setShowAedTbl}
                    tabTbldata={tabTbldata}
                    setTabTbldata={setTabTbldata}
                    hideAccMove={item?.standalone_data?.length > 0 ? 0 : 1}
                    hideAedMove={item?.data?.length > 0 ? 0 : 1}
                    isTechnician={item?.is_technician}
                    aedCheckLength={aedCheckLength}
                  />
                </>
              )}

              {
                // Number(isPermission({ type: "tbl", name: "accessoryTbl" })) ===
                // 1 &&
                equipmentFilterData &&
                  equipmentFilterData?.accessories === false ? null : item
                    ?.standalone_data.length === 0 ? null : (
                  // (
                  // <>
                  //   <div className="row mt-2 ">
                  //     <EquipmentNoDataTbl
                  //       aedAccessoryCount={aedLength}
                  //       hideAccMove={0}
                  //       hideAedMove={0}
                  //     />
                  //   </div>
                  // </>
                  // )
                  <AEDStandAlone
                    siteId={item?.site_id}
                    siteName={item?.site_name}
                    accountId={accountId}
                    standaloneData={item?.standalone_data}
                    setShowAccTbl={setShowAccTbl}
                    tabTbldata={tabTbldata}
                    setTabTbldata={setTabTbldata}
                    accountName={accountName}
                  />
                )
              }
            </div>
          </div>
        ))}
      </div>
    );
  };

  function NoSiteFound(data) {
    if (data.length === 0 && pendingaeds.length === 0) {
      return (
        <div
          className="row px-0"
          // key={index}
          style={{ alignItems: "center" }}
        >
          <div
            className="row w-100 EquipmentTab"
            style={{ alignItems: "center" }}
          >
            <div className="px-3 site-title-btn EquipmentHeaderDiv">

              <div className="right-btns d-flex align-items-center absolute right-0 btn-section btnsDiv mt-2">
                <TopAedNewMove
                  accountId={accountId}
                  accountName={accountName}
                  siteId={"0"}
                  siteName={"Pending"}
                  // isTechnician={item?.is_technician}
                  moveAed={1}
                  // isserviceCheck={
                  //   item?.site_name === "Pending" ||
                  //     item?.data.length === 0
                  //     ? 0
                  //     : 1
                  // }
                  aedAccessoryCount={aedLength}
                // hideAedMove={item?.data?.length > 0 ? 1 : 0}
                // hideAccMove={
                //   item?.standalone_data?.length > 0 ? 1 : 0
                // }
                />
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "40px" }}>

            <EquipmentNoDataTbl
              accountId={accountId}
              accountName={accountName}
            // siteId={item?.site_id}
            // siteName={item?.site_name}
            // aedAccessoryCount={aedLength}
            // hideAedMove={item?.data?.length > 0 ? 1 : 0}
            // hideAccMove={item?.standalone_data?.length > 0 ? 1 : 0}
            />
          </div>

        </div>
      );
    }
  }

  console.log({ aedList });

  return (
    <div
      className="relative mx-0"
      style={{ width: "100%", marginBottom: "5%" }}
    >
      <>
        <div>
          {equipmentFilterData &&
            Object.keys(equipmentFilterData).length !== 0 ? (
            <div style={{ position: "relative !important" }}>
              <FixedButton
                className="btn-style-cancel-filter"
                onClick={handleClearFilterData}
              >
                Clear Filter
              </FixedButton>
            </div>
          ) : null}
          <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
            Equipment Filters
          </FixedButton>
          <Drawer
            sx={{
              width: "400px",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "400px",
                boxSizing: "border-box",
                background: "#000",
              },
            }}
            anchor="right"
            open={open}
            onClose={handleDrawerClose}
          >
            {/* filter component  */}
            <Filter
              setOpen={setOpen}
              setShowLoading={setShowLoading}
              accountId={accountId}
              aedList={aedList}
              setAedList={setAedList}
              // accountListingPage={true}
              setTblsData={setTblsData}
              showAedTbl={showAedTbl}
              setShowAedTbl={setShowAedTbl}
              setShowAccTbl={setShowAccTbl}
              tabTbldata={tabTbldata}
              setTabTbldata={setTabTbldata}
              handleAedList={handleAedList}
              formData={formData}
              setFormData={setFormData}
              handleClearFilter={handleClearFilterData}
            />
          </Drawer>
        </div>

        <>
          {user.user_type === 3
            ? ""
            : pendingaeds?.length > 0 && RenderAedData(pendingaeds, true)}
          {/* {aedList?.length > 0 && RenderAedData(aedList)} */}
          {console.log({ length: Number(hasAccessoryData(aedList)) })}
          {console.log({
            check: aedList?.every((item) => !item.standalone_data?.length),
          })}
          {console.log({ equipmentFilterData })}
          {/* {aedList?.length > 0 ? (
            RenderAedData(aedList)
          ) : !showLoading &&
            // Case 1: When both AED and Accessories are being filtered
            ((!equipmentFilterData.aed && !equipmentFilterData.accessories) ||
              // Case 2: When only AED is being filtered and no AEDs exist
              (equipmentFilterData.aed &&
                !equipmentFilterData.accessories &&
                aedList?.length === 0) ||
              // Case 3: When only Accessories are being filtered and no accessories exist
              (!equipmentFilterData.aed &&
                equipmentFilterData.accessories &&
                // Number(hasAccessoryData(aedList)) === 1)) ? (
                aedList?.every((item) => !item.standalone_data?.length))) ? (
            <div className="row mt-2 ">
              <EquipmentNoDataTbl
                aedAccessoryCount={aedLength}
                hideAccMove={0}
                hideAedMove={0}
              />
            </div>
          ) : (
            ""
          )} */}

          {/* {aedList?.length > 0 ? (
            // First check if all items have empty standalone_data when only accessories are being filtered
            (!equipmentFilterData.aed || equipmentFilterData.aed) &&
            equipmentFilterData.accessories &&
            aedList.every((item) => !item.standalone_data?.length) ? (
              <div className="row mt-2 ">
                <AccessoryNoDataTbl
                  aedAccessoryCount={aedLength}
                  hideAccMove={0}
                  hideAedMove={0}
                />
              </div>
            ) : (
              RenderAedData(aedList)
            )
          ) : !showLoading &&
            // Other existing conditions for when aedList is empty
            ((!equipmentFilterData.aed && !equipmentFilterData.accessories) ||
              (equipmentFilterData.aed &&
                !equipmentFilterData.accessories &&
                aedList?.length === 0)) ? (
            <div className="row mt-2 ">
              <EquipmentNoDataTbl
                aedAccessoryCount={aedLength}
                hideAccMove={0}
                hideAedMove={0}
              />
            </div>
          ) : null} */}

          {/* Replace the existing rendering logic with this */}
          {!showLoading && (

            <>
              {/* First check all no-data conditions */}

              {/* Case 1: Both filters are ON */}
              {aedList &&
                equipmentFilterData.aed &&
                equipmentFilterData.accessories && (

                  <>
                    {/* Both AED and Accessory data are empty */}
                    {console.log({
                      aedListChceck: aedList?.every(
                        (item) =>
                          item.data.length === 0 &&
                          !item.standalone_data?.length
                      ),
                    })}
                    {console.log({ aedList })}
                    {aedList?.every(
                      (item) =>
                        item.data.length === 0 && !item.standalone_data?.length
                    ) && (
                        <>
                          <div className="row mt-2">
                            <EquipmentNoDataTbl
                              aedAccessoryCount={aedLength}
                              hideAccMove={0}
                              hideAedMove={0}
                            />
                          </div>
                          <div className="row mt-2">
                            <AccessoryNoDataTbl
                              aedAccessoryCount={aedLength}
                              hideAccMove={0}
                              hideAedMove={0}
                            />
                          </div>
                        </>
                      )}

                    {/* Only AED data is empty */}
                    {aedList.every((item) => item.data.length === 0) &&
                      aedList.some(
                        (item) => item.standalone_data?.length > 0
                      ) && (
                        <div className="row mt-2">
                          <EquipmentNoDataTbl
                            aedAccessoryCount={aedLength}
                            hideAccMove={0}
                            hideAedMove={0}
                          />
                        </div>
                      )}

                    {/* Only Accessory data is empty */}
                    {aedList.some((item) => item.data.length > 0) &&
                      aedList.every(
                        (item) => !item.standalone_data?.length
                      ) && (
                        <div className="row mt-2">
                          <AccessoryNoDataTbl
                            aedAccessoryCount={aedLength}
                            hideAccMove={0}
                            hideAedMove={0}
                          />
                        </div>
                      )}
                  </>
                )}

              {/* Case 2: Only AED filter is ON */}
              {equipmentFilterData.aed &&
                !equipmentFilterData.accessories &&
                aedList.every((item) => item.data.length === 0) && (
                  <div className="row mt-2">
                    <EquipmentNoDataTbl
                      aedAccessoryCount={aedLength}
                      hideAccMove={0}
                      hideAedMove={0}
                    />
                  </div>
                )}

              {/* Case 3: Only Accessory filter is ON */}
              {!equipmentFilterData.aed &&
                equipmentFilterData.accessories &&
                aedList.every((item) => !item.standalone_data?.length) && (
                  <div className="row mt-2">
                    <AccessoryNoDataTbl
                      aedAccessoryCount={aedLength}
                      hideAccMove={0}
                      hideAedMove={0}
                    />
                  </div>
                )}

              {/* If none of the above no-data conditions are met, render the data */}

              {/* // Check if ANY of the no-data conditions are true */}
              {/* (
                  (equipmentFilterData.aed &&
                    equipmentFilterData.accessories &&
                    aedList.every(
                      (item) =>
                        item.data.length === 0 && !item.standalone_data?.length
                    )) ||
                  (equipmentFilterData.aed &&
                    !equipmentFilterData.accessories &&
                    aedList.every((item) => item.data.length === 0)) ||
                  (!equipmentFilterData.aed &&
                    equipmentFilterData.accessories &&
                    aedList.every((item) => !item.standalone_data?.length))
                )
              ) && */}
              {aedList?.length > 0 ? RenderAedData(aedList) : NoSiteFound(aedList)}
            </>
          )}

          {Number(isPermission({ type: "tbl", name: "outOfServiceTbl" })) ===
            1 &&
            (pendingaeds?.length > 0 || aedList?.length > 0) &&
            outofServiceData?.length > 0 &&
            ((equipmentFilterData && equipmentFilterData?.aed === false) ||
              equipmentFilterData.aed_checkers?.length >
              0 ? null : outofServiceData.length === 0 ? null : ( //     "")) //     equipmentFilterData?.expiration_range_type !== //     equipmentFilterData?.expiration_date_range2 !== "" || //   (equipmentFilterData?.expiration_date_range1 !== "" || //   equipmentFilterData?.aed === true && // (equipmentFilterData && //   ||
                <div className="" style={{ borderRadius: "10px", border: "2px solid #0C71C3", padding: "14px", marginBottom: "20px", marginTop: "20px", background: "white",  width: "100%", marginLeft:"-12px"  }}>
                  <div className="row w-100">
                    <div className="col-md-12 px-3 site-title-btn">
                      <h2 className="aed-title OutOfServiceTitle" style={{ fontSize: "28px", fontWeight: "600", color: "#0C71C3", paddingLeft: "10px" }}>
                        Out of Service
                      </h2>
                    </div>
                  </div>
                  <div className="">
                    <AEDOutOfServiceTbl SingleAED={outofServiceData} />
                  </div>
                </div>
              ))}
        </>

        {showLoading ? <TableSkeleton /> : null}
      </>
    </div>
  );
}
