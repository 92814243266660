import React, { useEffect, useState } from 'react';
import { CallGETAPI } from '../../../../common/services';
import { Box } from '@mui/material';
import DataTableSkeleton from './ProgramDetailsSkeleton';

const ProgramDetails = ({ accountId, setProgramData }) => {
  const [programDetails, setProgramDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    if (!accountId) {
      return "";
    }
    const result = await CallGETAPI('account/acc-details-programs/' + accountId);
    if (result?.data?.status) {
      setProgramDetails(result?.data?.data);
      setProgramData(result?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [accountId]);

  return (
    <>
   <Box className="text-left pt-3">
  <h4 className="heading">AED Program</h4>
</Box>

{loading ? (
  <DataTableSkeleton />
) : (
  <div className="form-container" style={{ display: "flex", justifyContent: "space-between" }}>
    {/* First Column */}
           {/* Left Column */}
           <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
      <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>AEDS / Status </strong>
        <div style={{display:"flex", flexDirection: "column"}}> 
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.not_ready || "0"} Not Ready</div>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.ready || "0"} Ready </div>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds_status?.unknown || "0"} Unknown</div>
        </div>
      </div>
      <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>Active Sites</strong>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.activeSites || "0"}</div>
      </div>
      {/* <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>AEDS</strong>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aeds || "0"}</div>
      </div> */}
      {/* <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>Equipment</strong>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.equipment || "0"}</div>
      </div> */}
</div>

    {/* Second Column */}
      {/* Right Column */}
      <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
      {/* <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>CPR Trained</strong>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.crp_trained || "0"}</div>
      </div> */}
      <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>AED Check Length</strong>
        <div style={{ width: "200px", textAlign: "left" }}>{programDetails?.aed_check_length || "30 Days"}</div>
      </div>
      <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
        <strong style={{ width: "200px", textAlign: "right" }}>Extra Fields</strong>
        <div style={{ width: "200px", textAlign: "left" }}>
        <div style={{ width: "200px", textAlign: "left" }}>
  {programDetails?.extra_field1 ? (
    <>
      <div>{programDetails?.extra_field1}</div>
      <div>{programDetails?.extra_field2}</div>
    </>
  ) : (
    " "
  )}
</div>
        </div>
      </div>
    </div>
  </div>
)}
    </>
  );
};

export default ProgramDetails;

   // `${programDetails?.extra_field1}${programDetails?.extra_field2 ? ", " + programDetails?.extra_field2 : ""}`
          