import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import New from "../../../../common/img/New.png";
import Cancel from "../../../../common/img/Cancel.svg";
import Check from "../../../../common/img/Check.svg";
import { toast } from "react-toastify";
import Edit from "../../../../common/img/Edit.png";
import {
  DecryptToken,
  formatPhoneNumber,
  getPermission,
} from "../../../../common/helper";
import ContactName from "../../../../common/components/ContactName";
import { AED_IMG_BASE, CallGETAPI } from "../../../../common/services";
import Moment from "react-moment";
import { Box, Skeleton } from "@mui/material";
import SiteCoordinatorInformation from "../common/SiteCoordinatorInformation";
import SiteTranningAddress from "../common/SiteTranningAddress";
import SiteBottom from "../../../../common/components/custom-ui/SiteBottom";
import BillingShipping from "../common/BillingShipping";
import SiteHours from "../common/SiteHours";
import AedSiteUnits from "../common/AedSiteUnits";
import DynamicTblSkeleton from "../../../accounts/components/skeleton/table/DynamicTblSkeleton";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { printConsole } from "../../../../common/helper/Common";
import SiteProgramDetails from "../common/SiteProgramDetails";
import '../../css/site-details.css'
import TableSkeleton from "../../../accounts/components/skeleton/table/TableSkeleton";

export default function SiteDetails({
  siteData,
  setSiteData,
  siteHoursData,
  billingData,
  shippingData,
  trainingData,
  aedUnits,
  coordinatorData,
  fetch,
  setEditSiteAdmin,
}) {
  const { siteId } = useParams();
  const [siteInfo, setSiteInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  printConsole("accountName", location.state); // print console.log

  const fetchData = async () => {
    setLoading(true);
    const result = await CallGETAPI("site/site-information/" + siteId);
    if (result?.data?.status) {
      const resultData = result?.data?.data?.siteData || {};
      console.log({ resultData })
      setSiteInfo(resultData);
      setSiteData(resultData);
      setEditSiteAdmin((prev) => ({
        ...prev,
        accountName: location?.state?.accountName,
        siteInfo: resultData,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    // /site/site-information/
    fetchData();
  }, []);

  return (
    <div className="containerr site-details-table-section">
      <div
        className="relative"
        style={{
          width: "100%",
          paddingInline: "0px",
          marginLeft: "0px",
          paddingLeft: "0px",
          marginBottom: "5%",
        }}
      >
        <div
          className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
          style={{
            background: "white",
            border: "2px solid #00578E",
            borderRadius: "5px",
          }}
          id="main_account_section"
        >
          <Box className="text-left pt-3">
            <h4 className="heading">General Information</h4>
          </Box>

          {loading ? (
            <div style={{ padding: "20px", paddingLeft: "140px", display: "flex", flexDirection: "row", justifyContent: "space-around", width: "90%" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "70%" }}>
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "70%" }}>
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
                <Skeleton count={4} width={"90%"} height={20} />
              </div>
            </div>
          ) : (
            <div className="form-container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
              {/* Left Column */}
              <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px', marginLeft: "100px" }}>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Site Phone</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>{siteInfo?.account_site_phone || "N/A"}</div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Address</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {`${siteInfo?.building_name ?? ""} ${siteInfo?.account_site_address1 ?? ""}, `}
                    {siteInfo?.account_site_address2 ? `${siteInfo?.account_site_address2}, ` : ""}
                    {siteInfo?.account_site_city ? `${siteInfo?.account_site_city}, ` : ""}
                    {siteInfo?.account_site_country ? `${siteInfo?.account_site_country}, ` : ""}
                    {`${siteInfo?.account_site_state ?? ""} ${siteInfo?.account_site_zipcode ?? ""}`}
                  </div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Invoice ASAP</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.invoice_asap) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Call Ahead</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.account_site_call_ahead) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
              </div>

              {/* Right Column */}
              <div className="col my-4 form-column" style={{ width: '45%', padding: '0 0px' }}>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Security Clearance</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.security_clearance) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Requires Escort</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.requires_escort) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Out of Area</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.out_of_area) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Site Status</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>{siteInfo?.account_site_status_name || "Active"}</div>
                </div>
                <div style={{ marginBottom: 10, display: "flex", alignItems: "center", flexDirection: "row", gap: "10px" }}>
                  <strong style={{ width: "200px", textAlign: "right" }}>Main Site</strong>
                  <div style={{ width: "200px", textAlign: "left" }}>
                    {Number(siteInfo?.account_site_main_site) === 0 ? (
                      <img src={Cancel} alt="No" width="16" />
                    ) : (
                      <img src={Check} alt="Yes" width="16" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {!siteInfo?.site_hours_toggle === 1 && (
          <>
            <SiteHours siteId={siteId} setEditSiteAdmin={setEditSiteAdmin} />
          </>
        )}

        {/* billing */}
        <BillingShipping
          siteId={siteId}
          accountId={siteData?.account_id}
          setEditSiteAdmin={setEditSiteAdmin}
        />

        <div
          className="container-fluid bottom-border-blue mt-4 pt-2 pb-2"
          style={{
            background: "white",
            border: "2px solid #00578E",
            borderRadius: "5px"
          }}
          id="main_account_section"
        >
          {/* Program Details */}
          <SiteProgramDetails siteId={siteId} />


          {/* AED information */}
          <AedSiteUnits siteId={siteId} />
        </div>

        {/* Training information */}
        <SiteTranningAddress siteId={siteId} siteData={siteInfo} />

        {/* Coordinator Information */}
        <SiteCoordinatorInformation
          accountId={siteData?.siteInfo?.account_id}
          siteId={siteId}
          setEditSiteAdmin={setEditSiteAdmin}
        />
      </div>

      <SiteBottom
        created_date={siteInfo?.created_date}
        created_by={siteInfo?.created_by}
        modified_date={siteInfo?.modified_date}
        modified_by={siteInfo?.modified_by}
      />
    </div>
  );
}