import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";

export default function PermissionManagerMenu({ setShow }) {
  let navigate = useNavigate();
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const listItems = [
    // {
    //     title: 'Create Role',
    //     link: '/permission-manager/create-position'
    // },
    // {
    //     title: 'Create Tab',
    //     link: '/permission-manager/create-tab'
    // },
    // {
    //     title: 'Create Permission',
    //     link: '/permission-manager/create-permission'
    // },
    // {
    //     title: 'Assign Permission',
    //     link: '/permission-manager/assign-permission'
    // },
    {
      title: "Assign Admin",
      link: "/account/assign-admin",
    },
    {
      title: "Multiple Account",
      link: "/account/multiple-account-permission",
    },
    {
      title: "Create Role",
      link: "/account/assign-create-permission",
    },
    {
      title: "Role to User",
      link: "/account/assign-permission-user",
    },
    {
      title: "Role to Position",
      link: "/account/assign-permission-position",
    },
    {
      title: "Import CSV File",
      link: "/import-csv/Accounts",
    }
  ];

  const handleItemClick = (item) => {
    navigate(item.link);
    setShow(false);
  };

  const displayListItems = () => {
    return listItems.map((item, index) => {
      const isActive = location.pathname === item.link;
      return (
        <ListItemButton
          key={index}
          onClick={() => handleItemClick(item)}
          className={isActive ? "active-tab" : ""}
          style={{
            background: "#0C71C3",
            fontSize: "16px",
          }}
          sx={{
            "&:hover": {
              backgroundColor: "rgba(211, 211, 211, 0.3)", // Gray hover effect
            }
          }}
        >
          <ListItemText primary={item.title} sx={{ color: "white", fontSize:"16px" }} />
        </ListItemButton>
      );
    });
  };

  // console.log("permissionMenu");

  return (
    <Box sx={{ position: "relative" }}>
      <List
        sx={{
          width: "100%",
          color: "white",
        }}
        component="nav"
      >
        <ListItemButton
          onClick={handleClick}
          sx={{
            padding: "0 10px",
            marginTop: "-5px",
            marginLeft: "-2px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <div style={{ fontSize: "16px" }}> Permission Manager
          </div>
          {menuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </List>

      {menuOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            zIndex: 1000,
            backgroundColor: "#000",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
          }}
        >
          <List component="div" disablePadding
            sx={{
              "&:hover": {
                backgroundColor: "#F8F9FA", // Gray hover effect
                color: "#0C71C3"
              }
            }}
          >
            {displayListItems()}
          </List>
        </Box>
      )}
    </Box>
  );
}