import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "lucide-react";
import {
  updateBreadcrumbs,
  addNewBreadcrumbs,
  clearBreadcrumbs,
} from "../../../store/slices/breadcrumbsSlice";
import { useNavigate, useLocation } from "react-router-dom";

const navLinks = [
  { to: "/dashboard", label: "Dashboard" },
  { to: "/accounts-listing", label: "Accounts" },
  { to: "/Admin/Sites-listing", label: "Site Listing" },
  { to: "/Admin/Contact-listing", label: "Contact Listing" },
  { to: "/Admin/accessory-listing", label: "Accessory Listing" },
  { to: "/Admin/Aed-listing", label: "AED Listing" },
  { to: "/account/support-listing", label: "Support Listing" },
  { to: "/Admin/Note-listing", label: "Notes Listing" },
];

const navPaths = navLinks.map((link) => link.to);
const FinalNewBreadcrumbs = () => {
  const dispatch = useDispatch();
  // const breadcrumbsFromStore = useSelector(
  //   (state) => state.BreadCrumbs.breadcrumbs
  // );
  const removedBreadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.removedBreadcrumbs
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState(location.pathname);
  const [navigationDirection, setNavigationDirection] = useState(null);
  // Filter breadcrumbs: Always include "Details" tab and the last tab of the same type

  const getBreadcrumbs = () => {
    // Check if 'is_user' is present in sessionStorage and equals '1'
    const isUser = sessionStorage.getItem("is_user");

    // If 'is_user' is 1, get breadcrumbs from sessionStorage
    if (Number(isUser) === 1) {
      const breadcrumbs = sessionStorage.getItem("breadcrumbs");
      return breadcrumbs ? JSON.parse(breadcrumbs) : [];
    }

    // If 'is_user' is not 1, get breadcrumbs from localStorage
    const breadcrumbsFromLocal = localStorage.getItem("breadcrumbs");
    return breadcrumbsFromLocal ? JSON.parse(breadcrumbsFromLocal) : [];
  };

  // Use it to get breadcrumbs in your component
  const breadcrumbsFromStore = getBreadcrumbs();

  const filteredBreadcrumbs = React.useMemo(() => {
    const result = [];
    const seenTypes = new Set();

    // Check if previous breadcrumb's type is 'Dashboard'
    const hasDashboardTypeBefore = breadcrumbsFromStore.some(
      (breadcrumb, index) =>
        index < breadcrumbsFromStore.length - 1 &&
        breadcrumb.type === "dashboard"
    );

    // Check if next breadcrumb has 'Details' tab
    const hasDetailsTabAfter = breadcrumbsFromStore.some(
      (breadcrumb, index) => index > 0 && breadcrumb.tab === "Details"
    );

    // Traverse breadcrumbs in reverse
    for (let i = breadcrumbsFromStore.length - 1; i >= 0; i--) {
      const breadcrumb = breadcrumbsFromStore[i];

      // Condition to show or hide navLinks paths
      const shouldShowNavPath =
        hasDashboardTypeBefore ||
        hasDetailsTabAfter ||
        !navPaths.includes(breadcrumb.url);

      // Skip if the first breadcrumb matches navPaths and condition is not met
      if (navPaths.includes(breadcrumb.url) && !shouldShowNavPath) {
        continue;
      }

      // Always include the "Details" tab, but only once
      if (
        breadcrumb.tab === "Details" &&
        !result.some((b) => b.type === breadcrumb.type && b.tab === "Details")
      ) {
        result.push(breadcrumb);
      }

      // Include only the last tab of the same type, but skip if it's "Details" already added
      if (!seenTypes.has(breadcrumb.type) && breadcrumb.tab !== "Details") {
        seenTypes.add(breadcrumb.type);
        result.push(breadcrumb);
      }
    }

    // Reverse to maintain original breadcrumb order
    const limitedResult = result.reverse().slice(-3); // Get only last 3 items
    return limitedResult;
  }, [breadcrumbsFromStore]);

  // Listen to location changes and update breadcrumbs
  useEffect(() => {
    const matchingBreadcrumb = breadcrumbsFromStore.find(
      (breadcrumb) => breadcrumb.url === location.pathname
    );

    // If breadcrumb doesn't exist, add it
    if (!matchingBreadcrumb) {
      // dispatch(
      //   addNewBreadcrumbs({
      //     url: location.pathname,
      //     title: location.pathname.split("/").pop() || "Home",
      //   })
      // );
    } else if (
      breadcrumbsFromStore.at(-1)?.url !== location.pathname &&
      !breadcrumbsFromStore.some(
        (breadcrumb) => breadcrumb.url === location.pathname
      )
    ) {
      // Update breadcrumbs only if the location has changed to an existing breadcrumb
      const updatedBreadcrumbs = breadcrumbsFromStore.filter(
        (breadcrumb) => breadcrumb.url !== location.pathname
      );

      // Avoid updating breadcrumbs during addBreadcrumbs
      if (updatedBreadcrumbs.length !== breadcrumbsFromStore.length) {
        dispatch(updateBreadcrumbs(updatedBreadcrumbs));
      }
    }

    if (currentUrl !== location.pathname) {
      setCurrentUrl(location.pathname);
    }
  }, [location.pathname, currentUrl, breadcrumbsFromStore, dispatch]);

  useEffect(() => {
    if (currentUrl != location.pathname) {
      setCurrentUrl(location.pathname);
    }
  }, [location.pathname, currentUrl, breadcrumbsFromStore, dispatch]);

  // Handle backward navigation by removing the last breadcrumb

  useEffect(() => {
    // Check if we need to remove the last breadcrumb on location change
    if (breadcrumbsFromStore.length > 0) {
      const currentLastBreadcrumb =
        breadcrumbsFromStore[breadcrumbsFromStore.length - 1];

      if (
        currentLastBreadcrumb.url !== location.pathname &&
        breadcrumbsFromStore.some(
          (breadcrumb) => breadcrumb.url === location.pathname
        )
      ) {
        const updatedBreadcrumbs = breadcrumbsFromStore.slice(0, -1);
        dispatch(updateBreadcrumbs(updatedBreadcrumbs));
      }
    }
  }, [location.pathname, breadcrumbsFromStore, dispatch]);

  const handleBreadcrumbClick = (breadcrumb, index) => {
    // Check if the clicked breadcrumb is "Dashboard"
    if (breadcrumb.title === "Dashboard") {
      // If it's the Dashboard breadcrumb, clear the breadcrumbs array
      const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
      if (isUserContext) {
        sessionStorage.removeItem("breadcrumbs");
      } else {
        localStorage.removeItem("breadcrumbs");
      }
      dispatch(clearBreadcrumbs());
      navigate(breadcrumb.url); // Navigate to Dashboard
      return;
    }

    if (index !== filteredBreadcrumbs.length - 1) {
      const originalBreadcrumbIndex = breadcrumbsFromStore.findIndex(
        (bc) => bc.url === breadcrumb.url
      );

      if (originalBreadcrumbIndex !== -1) {
        // उन breadcrumbs को slice करें जो वर्तमान breadcrumb तक हैं
        const updatedBreadcrumbs = breadcrumbsFromStore.slice(
          0,
          originalBreadcrumbIndex + 1
        );

        // निकाले गए breadcrumbs को ट्रैक करें
        const removedBreadcrumbs = breadcrumbsFromStore.slice(
          originalBreadcrumbIndex + 1
        );

        // removedBreadcrumbs का अंतिम इंडेक्स
        const lastRemovedIndex = removedBreadcrumbs.length - 1;

        // यदि अंतिम हटाया गया breadcrumb "Details" है
        if (
          lastRemovedIndex >= 0 &&
          removedBreadcrumbs[lastRemovedIndex].tab === "Details"
        ) {
          // Details वाला breadcrumb और उससे पहले का breadcrumb
          const detailsBreadcrumb = removedBreadcrumbs[lastRemovedIndex];
          const previousBreadcrumb = removedBreadcrumbs[lastRemovedIndex - 1];

          // इन्हें breadcrumbs में जोड़ें
          if (previousBreadcrumb) {
            // पहले पिछला breadcrumb जोड़ें
            dispatch(addNewBreadcrumbs(previousBreadcrumb));
          }

          // फिर Details वाला breadcrumb जोड़ें
          dispatch(addNewBreadcrumbs(detailsBreadcrumb));
        } else if (originalBreadcrumbIndex !== -1) {
          breadcrumbsFromStore.splice(originalBreadcrumbIndex + 1);
          dispatch(updateBreadcrumbs(breadcrumbsFromStore));
        }
        // यदि कोई अन्य हटाए गए breadcrumbs हैं
        // else if (removedBreadcrumbs.length > 0) {
        //   // सभी हटाए गए breadcrumbs को जोड़ें
        //   console.log("first4", removedBreadcrumbs);
        //   removedBreadcrumbs.forEach((removedBreadcrumb) => {
        //     dispatch(addNewBreadcrumbs(removedBreadcrumb));
        //   });
        // }

        // console.log("first5");
        // नेविगेट करें
        navigate(breadcrumb.url);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <nav className="flex items-center">
        {filteredBreadcrumbs.map((breadcrumb, index) => {
          const isLastBreadcrumb = index === filteredBreadcrumbs.length - 1;

          return (
            <React.Fragment key={breadcrumb.url || index}>
              {index > 0 && (
                <ChevronRight className="w-4 h-4 mx-2 text-gray-400" />
              )}
              <span
                onClick={() =>
                  !isLastBreadcrumb && handleBreadcrumbClick(breadcrumb, index)
                }
                className={`text-sm ${
                  isLastBreadcrumb
                    ? "text-gray-600 cursor-not-allowed"
                    : "text-[#0c71c3] hover:underline cursor-pointer"
                }`}
                style={{
                  color: isLastBreadcrumb ? "#6b7280" : "#0c71c3",
                  cursor: isLastBreadcrumb ? "not-allowed" : "pointer",
                }}
              >
                {breadcrumb.title}
              </span>
            </React.Fragment>
          );
        })}
      </nav>
    </div>
  );
};

export default FinalNewBreadcrumbs;
