// import React, { useState, useEffect } from "react";
// import { Modal, Row } from "react-bootstrap";
// import { Form, Button } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { MultiSelect } from "react-multi-select-component";
// import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
// import { CallPOSTAPI } from "../../../common/services";

// const EditAssignAdminModal = ({
//   show,
//   setShow,
//   newAdminRoleDataArr,
//   newUserRoleDataArr,
//   newGraphArr,
//   contactList,
//   roleData,
//   rowData,
//   fetchUserTblData,
// }) => {
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({
//     contact_id: "",
//     admin_role_id: [],
//     user_role_id: [],
//     graph_id: [],
//   });
//   const [isInvalid, setIsInvalid] = useState({
//     admin_role_id: false,
//     user_role_id: false,
//   });

//   const handleChange = (e, key) => {
//     const { value } = e.target;
//     setFormData((prevState) => ({
//       ...prevState,
//       [key]: value,
//     }));
//   };

//   const handleRoleChange = (roleType, e) => {
//     setFormData({
//       ...formData,
//       [roleType]: e,
//     });
//     setIsInvalid({
//       ...isInvalid,
//       [roleType]: false,
//     });
//   };

//   const handleGraphChange = (e) => {
//     if (formData.admin_role_id.length > 0) {
//       setFormData({
//         ...formData,
//         graph_id: e,
//       });
//     }
//   };

//   useEffect(() => {
//     if (formData.admin_role_id.length === 0) {
//       setFormData((prev) => ({ ...prev, graph_id: [] }));
//     }
//   }, [formData.admin_role_id.length]);

//   useEffect(() => {
//     if (rowData) {
//       const adminRoleArr = newAdminRoleDataArr.filter((item) => {
//         // return rowData.admin_role_id.split(",").map(Number).includes(item.value);
//         return rowData.admin_role_id
//         ? rowData.admin_role_id.split(",").map(Number).includes(item.value)
//         : "";
//       });

//       const userRoleArr = newUserRoleDataArr.filter((item) => {
//         // return rowData.user_role_id.split(",").map(Number).includes(item.value);
//         return rowData.user_role_id
//         ? rowData.user_role_id.split(",").map(Number).includes(item.value)
//         : "";
//       });

//       const graphArr = newGraphArr.filter((item) => {
//         // return rowData.assign_grabh.includes(item.value);
//         return rowData.assign_grabh
//         ? rowData.assign_grabh.includes(item.value)
//         : "";
//       });

//       setFormData({
//         ...formData,
//         contact_id: rowData?.account_main_contact_id,
//         admin_role_id: adminRoleArr,
//         user_role_id: userRoleArr,
//         graph_id: graphArr,
//       });
//     }
//   }, [rowData]);

//   const renderSelectedGraphNames = () => {
//     return [formData.graph_id.map((item) => item.label).join(", ")];
//   };

//   const renderSelectGraphTitle = () => {
//     return (
//       <div>
//         {formData.graph_id.length === 0
//           ? "-- Select --"
//           : formData.graph_id.length >= 2
//           ? `${formData.graph_id.length} Selected`
//           : renderSelectedGraphNames()}
//       </div>
//     );
//   };

//   const renderSelectedTitleNames = (roleType) => {
//     return formData[roleType].map((item) => item.label).join(", ");
//   };

//   const renderSelectTitle = (roleType) => {
//     return (
//       <div>
//         {formData[roleType]?.length === 0
//           ? "-- Select --"
//           : formData[roleType]?.length >= 3
//           ? `${formData[roleType]?.length} Selected`
//           : renderSelectedTitleNames(roleType)}
//       </div>
//     );
//   };

//   const handleModalSubmit = async (e) => {
//     e.preventDefault();

//     let is_false = 0;
//     let obj = {
//       contact_id: false,
//       admin_role_id: false,
//       user_role_id: false,
//       graph_id: false,
//     };

//     if (formData.contact_id === "") {
//       obj.contact_id = true;
//       is_false = 1;
//     }

//     if (
//       formData.admin_role_id?.length === 0 &&
//       formData.user_role_id?.length === 0
//     ) {
//       obj.admin_role_id = true;
//       obj.user_role_id = true;
//       is_false = 1;
//     }
//     // if (formData.graph_id === "") {
//     //   obj.graph_id = true;
//     //   is_false = 1;
//     // }

//     if (is_false) {
//       setIsInvalid(obj);
//       // toast.error("Please fill in all required fields.");
//       return "";
//     }

//     setLoading(true);
//     let adminRolesArr = formData.admin_role_id;
//     let finalAdminRolesArr = adminRolesArr.map((role) => role.value);

//     let userRolesArr = formData.user_role_id;
//     let finalUserRolesArr = userRolesArr.map((role) => role.value);

//     let body = {
//       contact_id: formData?.contact_id,
//       admin_role_id: finalAdminRolesArr + "",
//       user_role_id: finalUserRolesArr + "",
//       graph_id: formData.graph_id,
//     };

//     const res = await CallPOSTAPI(
//       `permission/update-role-admin-user/${formData?.contact_id}`,
//       body
//     );
//     if (res?.data?.status) {
//       setLoading(false);
//       toast.success("Assigned Admin updated successfully");
//       setShow(false);
//       fetchUserTblData();
//       setFormData({
//         contact_id: "",
//         admin_role_id: [],
//         user_role_id: [],
//         graph_id: [],
//       });
//     } else {
//       setLoading(false);
//       setShow(false);
//       toast.error("Error!");
//       setFormData({
//         contact_id: "",
//         admin_role_id: [],
//         user_role_id: [],
//         graph_id: [],
//       });
//     }
//   };

//   return (
//     <>
//       <Modal show={show} onHide={() => setShow(false)} size="lg" backdrop="static" >
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Assign Admin</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form onSubmit={handleModalSubmit} noValidate>
//             <div
//               className="my-4"
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 gap: "3%",
//                 margin: "auto",
//               }}
//             >
//               <Form.Group style={{ width: "180px" }}>
//                 <Form.Label>Select Contact*</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="contact_id"
//                   value={rowData?.contactName}
//                   onChange={(e) => handleChange(e, "contact_id")}
//                   disabled={true}
//                 />
//                 {/* <PaginateAutoComplete
//                   dropDownName="contact_id"
//                   apiEndpoint={"permission/account-admin-contacts-list"}
//                   idKey={"contact_id"}
//                   valueKey={"contact_name"}
//                   parentKey={"finalData"}
//                   onSelect={(e,val) => {
//                     setSelectedContact(val);
//                     handleChange(e, "contact_id");
//                   }}
//                   shouldFetchOnOpen={true}
//                   isCache={true}
//                   selectDisabled={false}
//                   isSearchable={true}
//                   isAsync={true}
//                   pageSize={20}
//                   defaultValue={{
//                     contact_id: selectedContact?.contact_id,
//                     contact_name: selectedContact?.contact_name
//                   }}
//                 /> */}
//               </Form.Group>

//               <Form.Group style={{ width: "180px" }}>
//                 <Form.Label>Admin Role</Form.Label>
//                 <MultiSelect
//                   valueRenderer={() => renderSelectTitle("admin_role_id")}
//                   options={newAdminRoleDataArr}
//                   value={formData.admin_role_id}
//                   onChange={(e) => handleRoleChange("admin_role_id", e)}
//                   labelledBy="Select"
//                   hasSelectAll={false}
//                 />

//                 {isInvalid?.admin_role_id && (
//                   <Form.Control.Feedback type="" className="text-danger mt-1">
//                     Please Select Admin Role.
//                   </Form.Control.Feedback>
//                 )}
//               </Form.Group>

//               <Form.Group style={{ width: "180px" }}>
//                 <Form.Label>User Role</Form.Label>
//                 <MultiSelect
//                   valueRenderer={() => renderSelectTitle("user_role_id")}
//                   options={newUserRoleDataArr}
//                   value={formData.user_role_id}
//                   onChange={(e) => handleRoleChange("user_role_id", e)}
//                   labelledBy="Select"
//                   hasSelectAll={false}
//                 />

//                 {isInvalid?.user_role_id && (
//                   <Form.Control.Feedback type="" className="text-danger mt-1">
//                     Please Select User Role.
//                   </Form.Control.Feedback>
//                 )}
//               </Form.Group>

//               {formData.admin_role_id.length > 0 && (
//                 <Form.Group className={"col"} style={{ width: "180px" }}>
//                   <Form.Label>Graph</Form.Label>
//                   <MultiSelect
//                     valueRenderer={renderSelectGraphTitle}
//                     options={newGraphArr}
//                     value={formData.graph_id}
//                     onChange={(e) => handleGraphChange(e)}
//                     labelledBy="Select"
//                     hasSelectAll={false}
//                   />
//                 </Form.Group>
//               )}
//             </div>
//           </Form>
//         </Modal.Body>

//         <Modal.Footer>
//           <div className=" d-flex justify-content-end">
//             <button
//               className="btn btn-danger mt-0"
//               type="button"
//               onClick={() => setShow(false)}
//             >
//               Cancel
//             </button>
//             &nbsp;
//             <Button
//               variant="success"
//               // type="submit"
//               onClick={(e) => handleModalSubmit(e)}
//               className="mt-0"
//               disabled={loading}
//             >
//               {loading ? "Loading..." : "Submit"}
//             </Button>
//           </div>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default EditAssignAdminModal;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import { CallPOSTAPI } from "../../../common/services";

const EditAssignAdminModal = ({
  show,
  setShow,
  newAdminRoleDataArr,
  newUserRoleDataArr,
  newGraphArr,
  contactList,
  roleData,
  rowData,
  fetchUserTblData,
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    contact_id: "",
    admin_role_id: [],
    user_role_id: [],
    graph_id: [],
  });
  const [isInvalid, setIsInvalid] = useState({
    admin_role_id: false,
    user_role_id: false,
  });

  useEffect(() => {
    if (rowData) {
      const adminRoleIds = rowData.admin_role_id
        ? rowData.admin_role_id.split(",").map(Number)
        : [];
      const preSelectedAdminRoles = adminRoleIds
        .map((id) => {
          const role = newAdminRoleDataArr.find((item) => item.value === id);
          return role ? { label: role.label, value: role.value } : null;
        })
        .filter((role) => role !== null);

      const userRoleArr = newUserRoleDataArr.filter((item) =>
        rowData.user_role_id
          ? rowData.user_role_id.split(",").map(Number).includes(item.value)
          : false
      );

      const graphArr = newGraphArr.filter((item) =>
        rowData.assign_grabh ? rowData.assign_grabh.includes(item.value) : false
      );

      console.log("Initial pre-selected admin roles:", preSelectedAdminRoles);
      setFormData({
        contact_id: rowData?.account_main_contact_id,
        admin_role_id: preSelectedAdminRoles,
        user_role_id: userRoleArr,
        graph_id: graphArr,
      });
    }
  }, [rowData, newAdminRoleDataArr, newUserRoleDataArr, newGraphArr]);

  const handleChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleAdminRoleChange = (e) => {
    console.log("Admin Role onSelect triggered! Raw data:", e.target.formatted);
    const selectedAdminRoles = e.target.formatted.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    console.log("Setting new admin_role_id:", selectedAdminRoles);
    setFormData((prev) => ({
      ...prev,
      admin_role_id: selectedAdminRoles,
    }));
    setIsInvalid({ ...isInvalid, admin_role_id: false });
  };

  const handleUserRoleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      user_role_id: e,
    }));
    setIsInvalid({ ...isInvalid, user_role_id: false });
  };

  const handleGraphChange = (e) => {
    if (formData.admin_role_id.length > 0) {
      setFormData((prev) => ({
        ...prev,
        graph_id: e,
      }));
    }
  };

  useEffect(() => {
    if (formData.admin_role_id.length === 0) {
      setFormData((prev) => ({ ...prev, graph_id: [] }));
    }
  }, [formData.admin_role_id.length]);

  const handleModalSubmit = async (e) => {
    e.preventDefault();

    let is_false = 0;
    let obj = {
      contact_id: false,
      admin_role_id: false,
      user_role_id: false,
      graph_id: false,
    };

    if (formData.contact_id === "") {
      obj.contact_id = true;
      is_false = 1;
    }

    if (
      formData.admin_role_id.length === 0 &&
      formData.user_role_id.length === 0
    ) {
      obj.admin_role_id = true;
      obj.user_role_id = true;
      is_false = 1;
    }

    if (is_false) {
      setIsInvalid(obj);
      return "";
    }

    setLoading(true);
    const finalAdminRoles = formData.admin_role_id
      .map((role) => role.value)
      .join(",");
    const finalUserRoles = formData.user_role_id
      .map((role) => role.value)
      .join(",");
    const finalGraphs = formData.graph_id.map((graph) => graph.value).join(",");
    const body = {
      contact_id: formData.contact_id,
      admin_role_id: finalAdminRoles,
      user_role_id: finalUserRoles,
      graph_id: finalGraphs,
    };

    const res = await CallPOSTAPI(
      `permission/update-role-admin-user/${formData.contact_id}`,
      body
    );
    if (res?.data?.status) {
      setLoading(false);
      toast.success("Assigned Admin updated successfully");
      setShow(false);
      fetchUserTblData();
      setFormData({
        contact_id: "",
        admin_role_id: [],
        user_role_id: [],
        graph_id: [],
      });
    } else {
      setLoading(false);
      setShow(false);
      toast.error("Error!");
      setFormData({
        contact_id: "",
        admin_role_id: [],
        user_role_id: [],
        graph_id: [],
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Assign Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleModalSubmit} noValidate>
          <div className="my-4" style={{ display: "flex", gap: "3%" }}>
            <Form.Group style={{ width: "180px" }}>
              <Form.Label>Select Contact*</Form.Label>
              <Form.Control
                type="text"
                name="contact_id"
                value={rowData?.contactName}
                onChange={(e) => handleChange(e, "contact_id")}
                disabled={true}
              />
            </Form.Group>

            <Form.Group style={{ width: "180px" }}>
              <Form.Label>Admin Role</Form.Label>
              <PaginateAutoComplete
                dropDownName="admin_role_id"
                apiEndpoint={"permission/get-role"}
                idKey={"role_id"}
                valueKey={"role_name"}
                parentKey={"role"}
                onSelect={handleAdminRoleChange}
                placeholder="-- Select --"
                showCountsOnly={1}
                multiple={true}
                showCheckBox={true}
                shouldFetchOnOpen={true}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
                getResults={(res) => {
                  const newData = res?.data?.data?.role || [];
                  const result = newData.filter(
                    (role) => role.is_admin_role === 1
                  ); // Assuming admin roles have is_admin_role === 1
                  console.log("Fetched admin roles:", result);
                  return [result, result.length];
                }}
                defaultValue={
                  formData.admin_role_id.length > 0
                    ? formData.admin_role_id.map((role) => ({
                        role_id: role.value,
                        role_name: role.label,
                      }))
                    : []
                }
              />
              {isInvalid.admin_role_id && (
                <Form.Control.Feedback
                  type="invalid"
                  className="text-danger mt-1"
                >
                  Please Select Admin Role.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group style={{ width: "180px" }}>
              <Form.Label>User Role</Form.Label>
              <MultiSelect
                valueRenderer={() =>
                  formData.user_role_id.length >= 3
                    ? `${formData.user_role_id.length} Selected`
                    : formData.user_role_id
                        .map((item) => item.label)
                        .join(", ") || "-- Select --"
                }
                options={newUserRoleDataArr}
                value={formData.user_role_id}
                onChange={handleUserRoleChange}
                labelledBy="Select"
                hasSelectAll={false}
              />
              {isInvalid.user_role_id && (
                <Form.Control.Feedback
                  type="invalid"
                  className="text-danger mt-1"
                >
                  Please Select User Role.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {formData.admin_role_id.length > 0 && (
              <Form.Group style={{ width: "180px" }}>
                <Form.Label>Graph</Form.Label>
                <MultiSelect
                  valueRenderer={() =>
                    formData.graph_id.length >= 2
                      ? `${formData.graph_id.length} Selected`
                      : formData.graph_id
                          .map((item) => item.label)
                          .join(", ") || "-- Select --"
                  }
                  options={newGraphArr}
                  value={formData.graph_id}
                  onChange={handleGraphChange}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
              </Form.Group>
            )}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleModalSubmit}
          disabled={loading}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAssignAdminModal;
