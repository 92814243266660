import { createSlice } from "@reduxjs/toolkit";
import { printConsole } from "../../common/helper/Common";

const initialState = {
  breadcrumbs: [], // Active breadcrumbs
  removedBreadcrumbs: [], // Breadcrumbs removed during navigation
  stack: [], // Additional history stack
  storageType: "localStorage", // Default
};

const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setStorageType: (state, action) => {
      state.storageType = action.payload;
    },
    addNewBreadcrumbs: (state, action) => {
      const { url } = action.payload;

      // Check if breadcrumb exists in removedBreadcrumbs
      const removedIndex = state.removedBreadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      );

      if (removedIndex !== -1) {
        // Remove from removedBreadcrumbs and add to breadcrumbs
        const [restoredBreadcrumb] = state.removedBreadcrumbs.splice(
          removedIndex,
          1
        );
        state.breadcrumbs.push(restoredBreadcrumb);
      } else {
        // Add new breadcrumb if not existing
        const exists = state.breadcrumbs.some(
          (breadcrumb) => breadcrumb.url === url
        );
        if (!exists) {
          state.breadcrumbs.push(action.payload);
        }
      }
    },

    updateBreadcrumbs: (state, action) => {
      // Find breadcrumbs being removed
      const removedBreadcrumbs = state.breadcrumbs.filter(
        (oldBreadcrumb) =>
          !action.payload.some(
            (newBreadcrumb) => newBreadcrumb.url === oldBreadcrumb.url
          )
      );

      // Add removed breadcrumbs to removedBreadcrumbs
      removedBreadcrumbs.forEach((removedBreadcrumb) => {
        if (
          !state.removedBreadcrumbs.some(
            (existing) => existing.url === removedBreadcrumb.url
          )
        ) {
          state.removedBreadcrumbs.push(removedBreadcrumb);
        }
      });

      // Update breadcrumbs
      state.breadcrumbs = action.payload;
    },

    handleBreadcrumbsClick: (state, action) => {
      const { clickedUrl } = action.payload;

      // Find the index of the clicked breadcrumb
      const clickedIndex = state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === clickedUrl
      );

      if (clickedIndex !== -1) {
        // Remove breadcrumbs after the clicked breadcrumb and add to removedBreadcrumbs
        const removedBreadcrumbs = state.breadcrumbs.slice(clickedIndex + 1);

        // Add removed breadcrumbs to removedBreadcrumbs array if not already present
        removedBreadcrumbs.forEach((breadcrumb) => {
          if (
            !state.removedBreadcrumbs.some((bc) => bc.url === breadcrumb.url)
          ) {
            state.removedBreadcrumbs.push(breadcrumb);
          }
        });

        // Keep only breadcrumbs up to and including the clicked breadcrumb
        state.breadcrumbs = state.breadcrumbs.slice(0, clickedIndex + 1);
      }
    },

    // clearBreadcrumbs: (state) => {
    //   state.breadcrumbs = [];
    //   state.removedBreadcrumbs = [];
    // },

    clearBreadcrumbs: (state) => {
      // Only clear breadcrumbs if storage type is not localStorage
      const isUser = Number(sessionStorage.getItem("is_user")) === 1;
      if (isUser === 1) {
        state.breadcrumbs = [];
        state.removedBreadcrumbs = [];
      } else {
        state.breadcrumbs = [];
        state.removedBreadcrumbs = [];
      }
    },

    addToHistoryStack: (state, action) => {
      if (state?.stack) {
        state.stack = [
          ...state.stack,
          {
            ...action.payload,
            tab: action.payload.tab || "",
            type: action.payload.type || "",
            accountName: action.payload.accountName || "",
            accountId: action.payload.accountId || "",
          },
        ];
      }
    },

    clearHistoryStack: (state) => {
      state.stack = [];
    },

    // ----- extra code -----

    addNewBreadcrumbsMethod: (state, action) => {
      const { title, url, tab, type, accountName, accountId, storageType } =
        action.payload;
      // Check if breadcrumb with the same URL already exists
      const existingBreadcrumbIndex = state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      );

      if (existingBreadcrumbIndex === -1) {
        // If breadcrumb doesn't exist, add a new breadcrumb
        state.breadcrumbs.push({
          title,
          url,
          tab,
          type,
          accountName,
          accountId,
          storageType,
        });
      } else {
        state.breadcrumbs[existingBreadcrumbIndex] = {
          title,
          url,
          tab,
          type,
          accountName,
          accountId,
          storageType,
        };
      }
    },

    removeBreadcrumb: (state, action) => {
      const urlToRemove = action.payload;
      const breadcrumbsList = state.breadcrumbs;
      const breadcrumbToRemove = breadcrumbsList.find(
        (breadcrumb) => breadcrumb.url === urlToRemove
      );
      if (breadcrumbToRemove) {
        if (
          !state.removedBreadcrumbs.some(
            (bc) => bc.url === breadcrumbToRemove.url
          )
        ) {
          state.removedBreadcrumbs.push(breadcrumbToRemove);
        }
      } else {
        printConsole(`Breadcrumb with URL ${urlToRemove} not found.`); // print console.log
      }

      state.breadcrumbs = state.breadcrumbs.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );
    },
    removeBreadcrumbByUrl: (state, action) => {
      const urlToRemove = action.payload;

      state.stack = state.stack.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );
    },
    restoreBreadcrumb: (state, action) => {
      const breadcrumbToRestore = action.payload;
      // const removeList1 = state.removedBreadcrumbs;
      state.breadcrumbs?.pop();
      if (breadcrumbToRestore) {
        state.breadcrumbs.push(breadcrumbToRestore);
        state.removedBreadcrumbs = state.removedBreadcrumbs.filter(
          (bc) => bc.url !== action.payload
        );
      }
      // const removeList = state.removedBreadcrumbs;
    },
    // Clear all breadcrumbs
    // clearBreadcrumbs: (state) => {
    //   state.breadcrumbs = [];
    //   state.removedBreadcrumbs = [];
    // },
  },
});

export const {
  addNewBreadcrumbs,
  updateBreadcrumbs,
  clearBreadcrumbs,
  restoreLastRemovedBreadcrumb,
  addToHistoryStack,
  clearHistoryStack,
  removeBreadcrumb,
  handleBreadcrumbsClick,
  addNewBreadcrumbsMethod,
  setStorageType,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;

// ---------------------------------------------------------------------------------------------------------------

// import { createSlice } from "@reduxjs/toolkit";
// import { printConsole } from "../../common/helper/Common";

// const initialState = {
//   breadcrumbs: [],
//   removedBreadcrumbs: [],
//   stack: [],
//   storageType: "localStorage", // Default
// };

// const breadcrumbSlice = createSlice({
//   name: "breadcrumbs",
//   initialState,
//   reducers: {
//     setStorageType: (state, action) => {
//       state.storageType = action.payload;
//     },

//     addNewBreadcrumbs: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       console.log({ breadcrumb: isUserContext });
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       // Only add breadcrumbs matching current context
//       if (currentStorageType === state.storageType) {
//         const { url } = action.payload;
//         const exists = state.breadcrumbs.some(
//           (breadcrumb) => breadcrumb.url === url
//         );

//         if (!exists) {
//           state.breadcrumbs.push(action.payload);
//         }
//       }
//     },

//     updateBreadcrumbs: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       // Only update if storage type matches
//       if (currentStorageType === state.storageType) {
//         // Find breadcrumbs being removed
//         const removedBreadcrumbs = state.breadcrumbs.filter(
//           (oldBreadcrumb) =>
//             !action.payload.some(
//               (newBreadcrumb) => newBreadcrumb.url === oldBreadcrumb.url
//             )
//         );

//         // Add removed breadcrumbs to removedBreadcrumbs
//         removedBreadcrumbs.forEach((removedBreadcrumb) => {
//           if (
//             !state.removedBreadcrumbs.some(
//               (existing) => existing.url === removedBreadcrumb.url
//             )
//           ) {
//             state.removedBreadcrumbs.push(removedBreadcrumb);
//           }
//         });

//         // Update breadcrumbs
//         state.breadcrumbs = action.payload;
//       }
//     },

//     handleBreadcrumbsClick: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         const { clickedUrl } = action.payload;

//         // Find the index of the clicked breadcrumb
//         const clickedIndex = state.breadcrumbs.findIndex(
//           (breadcrumb) => breadcrumb.url === clickedUrl
//         );

//         if (clickedIndex !== -1) {
//           // Remove breadcrumbs after the clicked breadcrumb and add to removedBreadcrumbs
//           const removedBreadcrumbs = state.breadcrumbs.slice(clickedIndex + 1);

//           // Add removed breadcrumbs to removedBreadcrumbs array if not already present
//           removedBreadcrumbs.forEach((breadcrumb) => {
//             if (
//               !state.removedBreadcrumbs.some((bc) => bc.url === breadcrumb.url)
//             ) {
//               state.removedBreadcrumbs.push(breadcrumb);
//             }
//           });

//           // Keep only breadcrumbs up to and including the clicked breadcrumb
//           state.breadcrumbs = state.breadcrumbs.slice(0, clickedIndex + 1);
//         }
//       }
//     },

//     clearBreadcrumbs: (state) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         state.breadcrumbs = [];
//         state.removedBreadcrumbs = [];
//       }
//     },

//     addToHistoryStack: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType && state?.stack) {
//         state.stack = [
//           ...state.stack,
//           {
//             ...action.payload,
//             tab: action.payload.tab || "",
//             type: action.payload.type || "",
//             accountName: action.payload.accountName || "",
//             accountId: action.payload.accountId || "",
//           },
//         ];
//       }
//     },

//     clearHistoryStack: (state) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         state.stack = [];
//       }
//     },

//     addNewBreadcrumbsMethod: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         const { title, url, tab, type, accountName, accountId } =
//           action.payload;

//         // Check if breadcrumb with the same URL already exists
//         const existingBreadcrumbIndex = state.breadcrumbs.findIndex(
//           (breadcrumb) => breadcrumb.url === url
//         );

//         if (existingBreadcrumbIndex === -1) {
//           // If breadcrumb doesn't exist, add a new breadcrumb
//           state.breadcrumbs.push({
//             title,
//             url,
//             tab,
//             type,
//             accountName,
//             accountId,
//           });
//         } else {
//           state.breadcrumbs[existingBreadcrumbIndex] = {
//             title,
//             url,
//             tab,
//             type,
//             accountName,
//             accountId,
//           };
//         }
//       }
//     },

//     removeBreadcrumb: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         const urlToRemove = action.payload;
//         const breadcrumbsList = state.breadcrumbs;
//         const breadcrumbToRemove = breadcrumbsList.find(
//           (breadcrumb) => breadcrumb.url === urlToRemove
//         );
//         if (breadcrumbToRemove) {
//           if (
//             !state.removedBreadcrumbs.some(
//               (bc) => bc.url === breadcrumbToRemove.url
//             )
//           ) {
//             state.removedBreadcrumbs.push(breadcrumbToRemove);
//           }
//         } else {
//           printConsole(`Breadcrumb with URL ${urlToRemove} not found.`);
//         }

//         state.breadcrumbs = state.breadcrumbs.filter(
//           (breadcrumb) => breadcrumb.url !== urlToRemove
//         );
//       }
//     },

//     removeBreadcrumbByUrl: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         const urlToRemove = action.payload;
//         state.stack = state.stack.filter(
//           (breadcrumb) => breadcrumb.url !== urlToRemove
//         );
//       }
//     },

//     restoreBreadcrumb: (state, action) => {
//       const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
//       const currentStorageType = isUserContext
//         ? "sessionStorage"
//         : "localStorage";

//       if (currentStorageType === state.storageType) {
//         const breadcrumbToRestore = action.payload;
//         state.breadcrumbs?.pop();
//         if (breadcrumbToRestore) {
//           state.breadcrumbs.push(breadcrumbToRestore);
//           state.removedBreadcrumbs = state.removedBreadcrumbs.filter(
//             (bc) => bc.url !== action.payload
//           );
//         }
//       }
//     },
//   },
// });

// export const {
//   addNewBreadcrumbs,
//   updateBreadcrumbs,
//   clearBreadcrumbs,
//   addToHistoryStack,
//   clearHistoryStack,
//   removeBreadcrumb,
//   handleBreadcrumbsClick,
//   addNewBreadcrumbsMethod,
//   setStorageType,
// } = breadcrumbSlice.actions;

// export default breadcrumbSlice.reducer;
