import React, { useEffect, useState } from "react";
import { FormControlLabel, Radio } from "@mui/material";
import { Form } from "react-bootstrap";
import "../../../../global.css";
import { toast } from "react-toastify";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";
import { useLocation } from "react-router-dom";

export default function EditContactMultiEmailForm({
  altTrainerForm,
  setSubFormData,
  increaseAlternative,
  decreaseAlternative,
  handleInputChange,
  allDropDowns,
  noBtns = true,
  disabled = true,
  updateEmail = false,
  sectionId = "",
  validated,
}) {
  const location = useLocation();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const updateFieldChanged = (index, name, value) => {
    const newArr = JSON.parse(JSON.stringify(altTrainerForm));

    if (name === "account_main_contact_email") {
      newArr[index][name] = value;
    } else if (name === "account_main_contact_email_main") {
      newArr.forEach((form, i) => {
        newArr[i].account_main_contact_email_main = "0";
      });
      newArr[index][name] = "1";
    } else if (name === "email_type_id") {
      newArr[index][name] = value; // Directly set the selected ID
    }

    setSubFormData(newArr);
  };

  const handleDecreaseAlternative = (index) => {
    if (altTrainerForm[index].account_main_contact_email_main === "1") {
      toast.error("Main contact cannot be removed");
      return;
    }
    decreaseAlternative(index);
  };

  return (
    <>
      {altTrainerForm.map((singleForm, index) => {
        const emailOptions =
          location?.state?.contactInfoDetails?.emails?.map((phone) => ({
            id: phone.email_type_id,
            value: phone.email_type_name,
          })) || [];

        const selectedEmailTypeId = singleForm.email_type_id || "";
        const selectedEmailTypeValue =
          emailOptions.find((opt) => opt.id === selectedEmailTypeId)?.value || "";
        const finalDefaultValue = selectedEmailTypeId
          ? { id: selectedEmailTypeId, value: selectedEmailTypeValue }
          : { id: "", value: "" };

        return (
          <div
            className="row mb-4"
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
            key={index}
            id={`${sectionId}_${index}`}
          >
            <div
              className="form-group-responsive"
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ width: "120px", margin: 0 }}></div>
              {noBtns && (
                <div style={{ display: "flex", gap: "10px" }}>
                  <button
                    onClick={increaseAlternative}
                    type="button"
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "#007bff",
                      color: "white",
                    }}
                  >
                    +
                  </button>
                  <button
                    onClick={() => handleDecreaseAlternative(index)}
                    type="button"
                    style={{
                      padding: "5px 10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "#dc3545",
                      color: "white",
                    }}
                  >
                    −
                  </button>
                </div>
              )}
            </div>

            <div
              className="form-group-responsive"
              style={{
                marginBottom: "10px",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <label
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#333",
                    width: "120px",
                    margin: 0,
                    textAlign: "right",
                  }}
                >
                  Email*
                </label>
                <input
                  type="email"
                  required
                  name="account_main_contact_email"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "6px 10px",
                    fontSize: "14px",
                    color: "#333",
                    height: "34px",
                    width: "400px",
                  }}
                  value={singleForm.account_main_contact_email || ""}
                  onChange={(e) =>
                    updateFieldChanged(index, "account_main_contact_email", e.target.value)
                  }
                  isInvalid={
                    validated &&
                    (!singleForm.account_main_contact_email ||
                      !validateEmail(singleForm.account_main_contact_email))
                  }
                />
              </div>
              <Form.Control.Feedback
                type="invalid"
                style={{
                  marginLeft: "130px",
                  display:
                    validated &&
                    (!singleForm.account_main_contact_email ||
                      !validateEmail(singleForm.account_main_contact_email))
                      ? "block"
                      : "none",
                }}
              >
                Please enter a valid email address.
              </Form.Control.Feedback>
            </div>

            <div
              className="form-group-responsive"
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#333",
                  width: "120px",
                  margin: 0,
                  textAlign: "right",
                }}
              >
                Email Type
              </label>
              <div style={{ width: "400px" }}>
                <GlobalDropdown
                  dropDownName={`email_type_id_${index}`} // Unique name for each dropdown
                  apiEndpoint={"contact/contact-dropdowns"}
                  idKey={"dropdown_email_type_id"}
                  valueKey={"dropdown_email_type_name"}
                  parentKey={"emailType"}
                  onSelect={(event, value) => {
                    updateFieldChanged(index, "email_type_id", value);
                  }}
                  shouldFetchOnOpen={true}
                  isCache={true}
                  initialSelectedValue={selectedEmailTypeId}
                  defaultValue={finalDefaultValue}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    padding: "6px 10px",
                    fontSize: "14px",
                    color: "#333",
                    height: "34px",
                    width: "100%",
                  }}
                />
              </div>
            </div>

            <div
              className="form-group-responsive"
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
              }}
            >
              <label
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "#333",
                  width: "120px",
                  margin: 0,
                  textAlign: "right",
                }}
              >
                Main
              </label>
              <div>
                <FormControlLabel
                  label=""
                  control={
                    <Radio
                      color="primary"
                      size="medium"
                      value="1"
                      name={`main-${index}`}
                      onChange={() => {
                        const email = altTrainerForm[index].account_main_contact_email;
                        if (!email || email.trim() === "") {
                          toast.error("Please first fill Email");
                        } else {
                          updateFieldChanged(index, "account_main_contact_email_main", "1");
                        }
                      }}
                      checked={singleForm.account_main_contact_email_main === "1"}
                    />
                  }
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}