import { CheckSpareBatteryTblCol } from "../../../../common/helper/BasicFn";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";
import moment from "moment";
import { useEffect, useState } from "react";

const BatteryInformationTrEdit = ({
  is_spare = false,
  batteryInfo,
  print_battery_part,
  RenderDate,
  key,
  DataValue,
  batteryIndex,
  batteryTypeList,
  battery_information,
  formData,
  setFormData,
  DataName,
  readOnly = 0,
  is_disabled = false,
  warentyYear = 0,
  show = 0,
}) => {

  console.log({ DataName })

  const is_has_man = formData?.battery_info?.[0]?.has_man?.length > 0 ? true : false;
  const is_has_9v = formData?.battery_info?.[0]?.has_9v?.length > 0 ? true : false;
  const is_has_battery = formData?.battery_info?.[0]?.has_battery?.length > 0 ? true : false;
  const is_has_installBy = formData?.battery_info?.[0]?.has_installBy?.length > 0 ? true : false;
  const is_has_10pk = formData?.battery_info?.[0]?.has_10pk?.length > 0 ? true : false;
  const is_chargePak = formData?.battery_info?.[0]?.charge_pack?.length > 0 ? true : false;

  const handleDateChange = (name, val) => {
    const fd = { ...formData };
    if (name === "purchase_date") {
      let warenty_date = moment(val).add(warentyYear, "years").calendar();
      fd.warenty_date = warenty_date;
    }
    fd.battery_info[0][DataName][batteryIndex][name] = val;
    setFormData(fd);
  };


  const handleInput = (e) => {
    const fd = { ...formData };

    // Update the value based on the input
    fd.battery_info[0][DataName][batteryIndex][e.target.name] = e.target.value;
    // Check if the name is battery_type_id and value is unknown
    if (e.target.name === "battery_type_id" && e.target.value === "unknown") {
      fd.battery_info[0][DataName][batteryIndex]["battery_part_number"] = "unknown";
      fd.battery_info[0][DataName][batteryIndex]["battery_type_id"] = "unknown";
      if (is_has_man === true || DataName === "has_man") {
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["manufactured_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["install_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_lot"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_udi"] = "unknown";
      }
      if (is_has_9v === true || DataName === "has_9v") {
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["install_9v_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_lot"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_udi"] = "unknown";
      }
      if (is_has_battery === true || DataName === "has_battery") {
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_lot"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_udi"] = "unknown";
      }
      if (is_has_installBy === true || DataName === "has_installby") {
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["install_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["install_before_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_lot"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_udi"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_serial"] = "unknown";
      }
      if (is_has_10pk === true || DataName === "has_10pk") {
        fd.battery_info[0][DataName][batteryIndex]["install_date"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
      }
      if (is_chargePak === true || DataName === "charge_pack") {
        fd.battery_info[0][DataName][batteryIndex]["battery_lot"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["battery_expiration"] = "unknown";
        fd.battery_info[0][DataName][batteryIndex]["charge_pak_uid"] = "unknown";
      }
    }

    // Update the state
    setFormData(fd);
  };

  console.log({ formData })


  return (
    <>
      <tr className="" key={batteryIndex}>
        <td>{is_spare ? "Spare" : "Main"}</td>
        <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
          {battery_information ? (
            print_battery_part(
              show
                ? formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                  ?.battery_part_number
                : formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                  ?.battery_type_id
            )
          ) : (
            <select
              className="form-control"
              name="battery_type_id"
              defaultValue={parseInt(
                formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                  ?.battery_type_id
              )}
              onChange={handleInput}
            // disabled={readOnly}
            >
              <>
                <option value={"unknown"}>
                  --Select-one--
                </option>
                {batteryTypeList?.map((it) => (
                  <option value={it?.battery_type_id}>
                    {it?.battery_part_number}
                  </option>
                ))}
              </>
            </select>
          )}
        </td>
        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "battery_expiration"
        ) === 1 || DataName === "has_man" || DataName === "has_9v" || DataName === "has_battery" || DataName === "has_installby" || DataName === "has_10pk" || DataName === "charge_pack") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                RenderDate(
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.battery_expiration,
                  true
                )
              ) : (
                <CommonDatePicker
                  calName={"battery_expiration"}
                  CalVal={
                    formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                      ?.battery_expiration
                  }
                  HandleChange={handleDateChange}
                // disabled={is_disabled}
                />
              )}
            </td>
          )}

        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "battery_serial"
        ) === 1 || DataName === "has_installby") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                  ?.battery_serial
              ) : (
                <>
                  <input
                    type="text"
                    className="form-control"
                    name="battery_serial"
                    defaultValue={
                      formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                        ?.battery_serial
                    }
                    onChange={handleInput}
                  // readOnly={readOnly}
                  />
                </>
              )}
            </td>
          )}

        {/* battery_expiration */}
        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "manufactured_date"
        ) === 1 || DataName === "has_man") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {/* {RenderDate(formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]?.manufactured_date)} */}

              {battery_information ? (
                RenderDate(
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.manufactured_date,
                  false
                )
              ) : (
                <CommonDatePicker
                  calName={"manufactured_date"}
                  CalVal={
                    formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                      ?.manufactured_date
                  }
                  HandleChange={handleDateChange}
                // disabled={readOnly}
                />
              )}
            </td>
          )}

        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "install_date"
        ) === 1 || DataName === "has_man" || DataName === "has_installby" || DataName === "has_10pk") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                RenderDate(
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.install_date,
                  true
                )
              ) : (
                <CommonDatePicker
                  calName={"install_date"}
                  CalVal={
                    formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                      ?.install_date
                  }
                  HandleChange={handleDateChange}
                // disabled={is_disabled}
                />
              )}
            </td>
          )}

        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "v9_install"
        ) === 1 || DataName === "has_9v") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {/* {RenderDate(formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]?.v9_install)} */}
              {battery_information ? (
                RenderDate(
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.v9_install,
                  false
                )
              ) : (
                <CommonDatePicker
                  calName={"v9_install"}
                  CalVal={
                    formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                      ?.v9_install
                  }
                  HandleChange={handleDateChange}
                // disabled={readOnly}
                />
              )}
            </td>
          )}

        {/* {CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "battery_lot"
        ) === 1 && ( */}
        {DataValue?.section_name === "has_10pk" ? (
          ""
        ) : (
          <>
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                  ?.battery_lot
              ) : (
                <>
                  <input
                    type="text"
                    className="form-control"
                    name="battery_lot"
                    value={
                      formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                        ?.battery_lot
                    }
                    onChange={handleInput}
                  // readOnly={readOnly}
                  />
                </>
              )}
            </td>
          </>
        )}

        {/* {CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "battery_udi"
        ) === 1 && ( */}
        {DataValue?.section_name === "has_10pk" ? (
          ""
        ) : (
          <>
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                DataName == "charge_pack" ? (
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.charge_pak_uid
                ) : (
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.battery_udi
                )
              ) : (
                <input
                  type="text"
                  className="form-control"
                  name={
                    DataName == "charge_pack" ? "charge_pak_uid" : "battery_udi"
                  }
                  value={
                    DataName == "charge_pack"
                      ? formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                        ?.charge_pak_uid
                      : formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                        ?.battery_udi
                  }
                  onChange={(e) => handleInput(e)}
                // readOnly={readOnly}
                />
              )}
            </td>
          </>
        )}

        {(CheckSpareBatteryTblCol(
          [...formData?.allBatterySpares, ...formData?.ALLBatteris],
          "install_before_date"
        ) === 1 || DataName === "has_installby") && (
            <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
              {battery_information ? (
                RenderDate(
                  formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                    ?.install_before_date,
                  true
                ) || "N/A"
              ) : (
                <CommonDatePicker
                  calName={"install_before_date"}
                  CalVal={
                    formData?.battery_info?.[0]?.[DataName]?.[batteryIndex]
                      ?.install_before_date
                  }
                  HandleChange={handleDateChange}
                  disabled={is_disabled}
                />
              )}
            </td>
          )}
      </tr>
    </>
  );
};

export default BatteryInformationTrEdit;
