import React, { useState } from "react";
import CustomTabBar from "../../../common/components/tabs";
import { DecryptToken, getPermission } from "../../../common/helper";
import { isContactPermission } from "../../../common/helper/permission";
import ContactDetails from "./tabComponents/ContactDetails";
import { AccountContactsTab } from "../../../common/hooks";
import Support from "../../Support/Component/SupportTab/Support";
import { useDispatch, useSelector } from "react-redux";
import { handleTabfunction } from "../../../store/slices/TabSlice";
import {
  addNewBreadcrumbs,
  handleBreadcrumbsClick,
} from "../../../store/slices/breadcrumbsSlice";
import { useNavigate, useParams } from "react-router-dom";
import Emails from "../../accounts/components/tabs/Emails";
import Notes from "../../Notes/Component/NoteTab/Notes";
import Documents from "../../Documnents/Pages/Documents";

const ContactsTab = ({
  name,
  account_id,
  setTabTbldata,
  stateData = {},
  support_api,
  newSupportUrl,
}) => {
  const { accountId, contactId, tab } = useParams();
  const activeTab = tab || "Details";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = DecryptToken();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  // const list = [
  //   { title: "Details", id: "CONTACT_DETAILS" },
  //   // { title: "Contacts", id: "CONTACT_LISTING" },
  //   // { title: "Equipment", id: "EQUIPMENT_LISTING" },
  // ];

  const componentsList = {
    Details: <ContactDetails />,
    Emails: (
      <Emails
        emailApi={`email/emails-by-contact/${contactId}`}
        accountId={account_id}
      />
    ),
    Support: (
      <Support
        newSupportUrl={newSupportUrl}
        support_api={support_api}
        account_id={account_id}
        setTabTbldata={setTabTbldata}
        stateData={stateData}
      />
    ),
    Notes: (
      <Notes
        note_api={`notes/contact-notes?account_id=${account_id}&contact_id=${contactId}`}
        accountId={account_id}
        type="CONTACT"
        contact_id={contactId}
        setTabTbldata={setTabTbldata}
        fetch_key={"contactNotes"}
      />
    ),

    Documents: (
      <Documents
        document_api={`document/get-all-contact-documents/${contactId}`}
        url={`/account/${account_id}/contact-details/${contactId}/Documents`}
        accountId={account_id}
        stateData={stateData}
      />
    ),
    // CONTACT_LISTING: <ContactListing accountId={accountId} siteId={siteId} />,
  };
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const handleDispatch = (contactId, BreadCrumbsObject, val) => {
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${account_id}/contact-details/${contactId}/${val}`,
      })
    );
  };

  const handleTabChange = (val, name) => {
    const contact_Name = name;
    const TabTitle = AccountContactsTab[val];
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: "",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };

    if (val === AccountContactsTab.Details) {
      BreadCrumbsObject.tab = val;

      dispatch(
        handleBreadcrumbsClick({
          accountName: contact_Name,
          breadcrumbsFromStore,
          tab: val,
          type: "contact",
        })
      );
      navigate(
        `/account/${stateData.accountId}/contact-details/${stateData.contactId}/${val}`
      );
    } else {
      BreadCrumbsObject.title = TabTitle;
      // BreadCrumbsObject.tab = val;
      // BreadCrumbsObject.type = "contact";
      handleDispatch(stateData.contactId, BreadCrumbsObject, val);
      navigate(`/account/${account_id}/contact-details/${contactId}/${val}`);
    }
    // dispatch(handleTabfunction({ value: val, type: "contact" }));
  };
  const arr = [];
  Object.keys(AccountContactsTab).forEach((key) => {
    arr.push({ id: key, title: AccountContactsTab[key] });
  });
  return (
    <>
      {/* {isContactPermission(activeTab) ? ( */}
      <div className="w-full">
        <CustomTabBar
          tabs={arr}
          onTabChange={(val) => {
            handleTabChange(val, name);
          }}
          from={"contact"}
          activeTab={activeTab}
        />

        {componentsList?.[activeTab]}
      </div>
      {/* ) : null} */}
    </>
  );
};

export default ContactsTab;
