import React from "react";
import {
  isPermission,
  linkTabsPermission,
} from "../../../../../common/helper/permission";
import { useNavigate } from "react-router-dom";
import { addNewBreadcrumbs } from "../../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { truncateText } from "../../../../../common/helper/BasicFn";

const ContactName = ({ url, locationState, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;

  const userPermission = ["contact-details", "notes-tab"];
  const handleClick = () => {
    if (linkTabsPermission(userPermission)) {
      navigate(url, locationState);
    }
  };
  const handleContactDispatch = () => {
    const BreadCrumbsObject = {
      title: "",
      type: "aed",
      tab: "Details",
      storageType: isUserContext ? "sessionStorage" : "localStorage",
    };
    BreadCrumbsObject.title = name + " Details";
    dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: url }));
  };
  return (
    <>
      <span
        className={
          Number(
            isPermission({
              type: "link",
              name: "cordiIPrimary",
            })
          ) === 1
            ? "link"
            : ""
        }
        style={{
          cursor:
            Number(
              isPermission({
                type: "link",
                name: "cordiIPrimary",
              })
            ) === 1
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (
            Number(
              isPermission({
                type: "link",
                name: "cordiIPrimary",
              })
            ) === 1
          ) {
            handleContactDispatch();
            handleClick();
          }
        }}
        title={
          name.length > 15 ? name : undefined // Tooltip only if the text is truncated
        }
      >
        {" "}
        {truncateText(name, 15)}{" "}
      </span>
    </>
  );
};

export default ContactName;
