import React, { useEffect, useState } from "react";
import check from "../../../../common/img/Check.svg";
import cancel from "../../../../common/img/Cancel.svg";
import TableSkeleton from "../../../../domains/accounts/components/skeleton/table/TableSkeleton";
import { FormatDate } from "../../../helper/Common";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "../../../../global.css";
import { isPermission } from "../../../helper/PermissionManager";

export default function AEDStandAlone({
  accountId,
  siteId,
  standaloneData,
  setShowAccTbl,
  tabTbldata,
  setTabTbldata,
  accountName,
  siteName,
}) {
  let navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [modifiedStandaloneData, setModifiedStandaloneData] = useState([]);

  // Table styling from AEDTable
  const thStyle = {
    backgroundColor: "#ffffff",
    padding: "8px",
    textAlign: "left",
    fontWeight: "500",
    color: "#b6b6b6",
    borderBottom: "1px solid #ddd",
    borderTop: "1.4px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    textAlign: "left",
    color: "#333",
    fontWeight: 600,
    borderBottom: "1.2px solid #ddd",
    backgroundColor: "#ffffff"
  };

  useEffect(() => {
    let filteredData = standaloneData?.filter(
      (obj) =>
        !(obj.hasOwnProperty("pad_part") && obj.section_name === "charge_pack")
    );
    setModifiedStandaloneData(filteredData);
  }, [standaloneData]);

  useEffect(() => {
    if (typeof setShowAccTbl === "function") {
      setShowAccTbl(false);
    }
  }, []);

  useEffect(() => {
    if (modifiedStandaloneData?.length > 0) {
      setTabTbldata({
        ...tabTbldata,
        equipment: {
          ...tabTbldata.equipment,
          accessory: true,
        },
      });
    }
  }, [modifiedStandaloneData]);

  function getFormattedDate(item) {
    const dateKeys = [
      "manufactured_date",
      "install_date",
      "battery_expiration",
      "pad_expiration",
    ];

    for (let key of dateKeys) {
      if (item?.[key]) {
        return FormatDate(item[key]);
      }
    }

    return "N/A"; // Default if no date is found
  }

  const fillTable = () => {
    return modifiedStandaloneData?.map((item, i) => {
      return (
        <tr
          key={i}
          style={{ backgroundColor: i % 2 === 0 ? "#ffffff" : "#f9f9f9" }}
        >
          <td style={tdStyle}>
            {item.hasOwnProperty("manufactured_date")
              ? item?.battery_part
                ? item?.battery_part
                : "N/A"
              : item?.pad_part
              ? item?.pad_part
              : "N/A"}
          </td>
          <td style={tdStyle}>{item?.accessory_type}</td>
          <td style={tdStyle}>
            <img
              className="image"
              src={`/assets/images/${
                item.hasOwnProperty("manufactured_date")
                  ? item?.manufactured_date
                    ? "/BatteryMfgr.svg"
                    : item?.battery_expiration
                    ? "/Battery.png"
                    : "installby.svg"
                  : item?.pad_expiration && item?.is_pediatric === 1
                  ? "/child-Vector.png"
                  : "/people-Group.svg"
              }`}
              style={{
                width: item?.battery_expiration ? 15 : 25,
                height: item?.battery_expiration ? 30 : 30,
                marginRight: "2%",
              }}
            />
            {getFormattedDate(item)}
            
            <div style={{ margin: "5% 0" }} />
            {item?.section_name === "charge_pack" && item?.pad_expiration_1 ? (
              <img
                className="image"
                src="/assets/images/people-Group.svg"
                style={{ marginRight: "2%" }}
              />
            ) : (
              <></>
            )}
            {item.section_name === "charge_pack" && item?.pad_expiration_1
              ? moment(item.pad_expiration_1).format("MM/DD/YYYY")
              : null}

            <div style={{ margin: "5% 0" }} />
            {item?.section_name === "charge_pack" && item?.pad_expiration_2 ? (
              <img
                className="image"
                src="/assets/images/people-Group.svg"
                style={{ marginRight: "2%" }}
              />
            ) : (
              <></>
            )}
            {item.section_name === "charge_pack" && item?.pad_expiration_2
              ? moment(item.pad_expiration_2).format("MM/DD/YYYY")
              : null}
          </td>
          <td
            style={{...tdStyle, maxWidth: "100px", wordBreak: "break-word"}}
          >
            {item.hasOwnProperty("manufactured_date")
              ? item?.battery_lot
                ? item?.battery_lot
                : "N/A"
              : item?.pad_lot
              ? item?.pad_lot
              : "N/A"}
          </td>
          <td style={tdStyle}>
            {item.hasOwnProperty("manufactured_date")
              ? item?.section_name === "charge_pack"
                ? item?.charge_pak_uid
                  ? item?.charge_pak_uid
                  : "N/A"
                : item?.battery_udi
                ? item?.battery_udi
                : "N/A"
              : item?.pad_udi
              ? item?.pad_udi
              : "N/A"}
          </td>
          <td style={tdStyle}>
            {<img src={item?.dni ? check : cancel} alt="Status" height={12} />}
          </td>
          <td
            style={{...tdStyle, fontWeight: 600}}
          >
            {item?.quantity}
          </td>
        </tr>
      );
    });
  };

  return (
    <div style={{ marginBottom: "3%" }}>
      {showLoading ? (
        <>
          <div className="showloading-table">
            <TableSkeleton />
          </div>
        </>
      ) : (
        <>
          <div className="" style={{ display: "flex" }}>
            {Number(isPermission({ type: "btn", name: "newAccessory" })) === 1 ? (
              <>
                <button
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    navigate(
                      `/account/aed/NewStandloneAcce/${accountId}/${siteId}`,
                      {
                        state: {
                          selectedAccount: {
                            accountId: accountId,
                            accountName: accountName,
                          },
                          selectedSite: {
                            siteId: siteId,
                            siteName: siteName,
                          },
                        },
                      }
                    );
                  }}
                  className="btn text-primary"
                  type="button"
                  id={`accessory_new_btn_${siteId}`}
                >
                  <img
                    src="/assets/images/add.svg"
                    alt="svg"
                    style={{ marginRight: "5px" }}
                  />
                  <span className="ms-1">New </span>
                </button>
              </>
            ) : (
              ""
            )}
          </div>
          <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "16px", marginBottom: "3%" }}>
            <thead>
              <tr>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Part #</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Accessory Type</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Date</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Lot</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>UDI</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>DNI</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
                <th style={thStyle}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span>Quantity</span>
                    <span style={{ fontSize: "12px", color: "#b6b6b6" }}>▼</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {modifiedStandaloneData?.length === 0 ? (
                <tr key="noData">
                  <td colSpan={7} style={{ ...tdStyle, textAlign: "center", padding: "20px" }}>
                    No accessory data found.
                  </td>
                </tr>
              ) : (
                fillTable()
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
}