import React from "react";
import { calculate9vExpiryDate, CheckADTable, conditonalCheckingTypeOfBattery, RenderDate } from "../../../../common/helper/BasicFn";
import { FormatDate } from "../../../../common/helper/Common";
import moment from "moment";

const BatteryInformationTr = ({
  batteryInfo,
  print_battery_part,
  // RenderDate,
  BatteryExpTbl,
  key,
  DataValue,
  batteryIndex,
}) => {

  console.log({DataValue})

  const typeofBattery = conditonalCheckingTypeOfBattery(DataValue)

  const displayColumns = (typeOfBat) => {
    let cols = [];
    if (typeOfBat == "has_man") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "manufactured_date", title: "Manufactured Date" },
        { key: "install_date", title: "Install Date" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_9v") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "install_9v_date", title: "9v Install Date" },
        { key: "9v_expiry_date", title: "9v Expiry Date" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_battery") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_installby") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_serial", title: "Serial" },
        { key: "install_date", title: "Install Date" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
        { key: "install_before_date", title: "Install Before Date" },
      );
    }
    else if (typeOfBat == "has_10pk") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "install_date", title: "Install Date" },
        // { key: "install_before_date", title: "Install Before Date" },
      );
    }
    else if (typeOfBat == "charge_pack") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "charge_pak_uid", title: "Battery UDI" },
      );
    }
    return cols;
  }


  const renderCellValue = (key, value) => {

    // Special handling for calculated expiry date
    if (key === "9v_expiry_date") {
      const expiryDate = calculate9vExpiryDate(DataValue?.install_9v_date);
      return expiryDate === "unknown" ? "unknown" : RenderDate(expiryDate, 0);
    }

    if (value === "unknown") return "unknown";
    if (!value) return "";

    switch (key) {
      case "battery_type_id":
        return print_battery_part(value);
      case "battery_expiration":
        return RenderDate(FormatDate(value), 1);
      case "install_9v_date":
        return RenderDate(FormatDate(value), 0);
      // case "9v_expiry_date":
      //   return RenderDate(calculate9vExpiryDate(DataValue?.install_9v_date), 0);
      case "manufactured_date":
      case "install_date":
      case "install_before_date":
        return RenderDate(value, 0);
      default:
        return value;
    }
  };

  const columns = displayColumns(typeofBattery);

  if (!DataValue?.battery_type_id) {
    return "";
  }


  return (
    <tr className="" key={batteryIndex}>
      {columns.map((column) => (
        <td key={column.key} className="border bg-tbl-border py-1 px-2 border-r-blue">
          {renderCellValue(column.key, DataValue[column.key])}
        </td>
      ))}
    </tr>
  );

  // return (
  //   <>
  //     <tr className="" key={batteryIndex}>
  //       {/* <td>Main</td> */}
  //       {CheckADTable(batteryInfo, "battery_type_id") === 1  ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {print_battery_part(DataValue?.battery_type_id)}
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "battery_expiration") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(FormatDate(DataValue?.battery_expiration), 1)} */}
  //           {
  //             DataValue?.battery_expiration === 'unknown' ?
  //               'unknown' :
  //               RenderDate(FormatDate(DataValue?.battery_expiration), 1)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "battery_serial") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {DataValue?.battery_serial}
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "install_9v_date") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(FormatDate(DataValue?.install_9v_date), 0)} */}
  //           {
  //             DataValue?.install_9v_date === 'unknown' ?
  //               'unknown' :
  //               RenderDate(FormatDate(DataValue?.install_9v_date), 0)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "install_9v_date") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(moment(FormatDate(DataValue?.install_9v_date)).add(1, 'year'), 0)} */}
  //           {
  //             DataValue?.install_9v_date === 'unknown' ?
  //               'unknown' :
  //               RenderDate(moment(FormatDate(DataValue?.install_9v_date)).add(1, 'year'), 0)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "manufactured_date") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(DataValue?.manufactured_date, 0)} */}
  //           {
  //             DataValue?.manufactured_date === 'unknown' ?
  //               'unknown' :
  //               RenderDate(DataValue?.manufactured_date, 0)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //       {CheckADTable(batteryInfo, "install_date") === 1  ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(DataValue?.install_date, 0)} */}
  //           {
  //             DataValue?.install_date === 'unknown' ?
  //               'unknown' :
  //               RenderDate(DataValue?.install_date, 0)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}

  //       {CheckADTable(batteryInfo, "battery_lot") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {DataValue?.battery_lot}
  //         </td>
  //       ) : (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"></td>
  //       )}

  //       {CheckADTable(batteryInfo, "battery_udi") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {DataValue?.battery_udi}
  //         </td>
  //       ) : (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue"></td>
  //       )}
  //       {CheckADTable(batteryInfo, "install_before_date") === 1 ? (
  //         <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
  //           {/* {RenderDate(DataValue?.install_before_date, 0)} */}
  //           {
  //             DataValue?.install_before_date === 'unknown' ?
  //               'unknown' :
  //               RenderDate(DataValue?.install_before_date, 0)
  //           }
  //         </td>
  //       ) : (
  //         ""
  //       )}
  //     </tr>
  //   </>
  // );

  /** {CheckADTable(batteryInfo,'serial')===1 &&  
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {DataValue?.battery_serial}
                </td>}
                {CheckADTable(batteryInfo,'v9_install')===1 && 
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {RenderDate(DataValue?.v9_install)}
                </td>}
                {CheckADTable(batteryInfo,'install_before_date')===1 &&
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {RenderDate(DataValue?.install_before_date)}
                </td>
                }

                {CheckADTable(batteryInfo,'date_installed')===1 &&
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                        {RenderDate(DataValue?.date_installed)}
                </td>
                }
                {CheckADTable(batteryInfo,'manufactured_date')===1  &&
                <td className="border border-2 py-1 px-2 bg-tbl-border border-r-blue">
                    {RenderDate(DataValue?.manufactured_date)}
                </td>} */

};

export default BatteryInformationTr;
