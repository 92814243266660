import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Person2Icon from "@mui/icons-material/Person2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";

import {
  removeFilterData as removeListingFilter,
  removePayloadData as removeListingFilterPayload,
} from "../../../store/slices/AccountListFilter";
import {
  removeFilterData as removeEquipmentFilter,
  removePayloadData as removeEquipmentFilterPayload,
} from "../../../store/slices/AccountDetailsEquipmentFilter";
import {
  removeFilterData as removeEquipmentListingFilter,
  removePayloadData as removeEquipmentListingFilterPayload,
} from "../../../store/slices/EquipmentListingFilterSlice";
import {
  removeFilterData as removeAccessoryListingFilter,
  removePayloadData as removeAccessoryListingFilterPayload,
} from "../../../store/slices/AccessoryListingFilterSlice";

import { useDispatch } from "react-redux";

// import ReportsModel from "../modals/ReportsModel";
// import SwitchUserModel from "../modals/SwitchUserModel";
// import AdminReportsModel from "../modals/AdminReportModel";

import { CallGETAPI, CallPOSTAPI } from "../../services";
import {
  DecryptToken,
  GetProfile,
  getPermission,
  setPermission,
} from "../../helper";
import UserTopRightMenuBar from "./subComponent/userTopRightMenuBar";
import UserMenuBar from "./subComponent/userTopRightMenuBar";
import SubAdminMenuBar from "./subComponent/SubAdminMenuBar";
import MainAdminMenuBar from "./subComponent/MainAdminMenuBar";
import Swal from "sweetalert2";
import { truncateText } from "../../helper/BasicFn";
import { PiUserSwitch } from "react-icons/pi";
import { Avatar, Tooltip } from "@mui/material";
import SwitchUserModel from "../modals/switchUser/switchUser";
// import LogoutHooks from "../../helper/LogoutHooks";
// import { HandleLogout } from "../../helper/BasicFn";

// import { removeFilterData } from "../../../store/slices/AccountDetailsEquipmentFilter";

const SearchForm = () => {
  const urlObject = new URL(window.location.href);
  const isUser = urlObject.searchParams.get("is_user");

  // const navigat = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const [rossProfile, setRossProfile] = useState([]);
  const [openReports, setOpenReports] = useState(false);
  const [adminOpenReports, setAdminOpenReports] = useState(false);
  const [openSwitchUser, setOpenSwitchUser] = useState(false);
  const dispatch = useDispatch();
  const privileges = getPermission();
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const [profileImage, setProfileImage] = useState("")

  const user = DecryptToken();

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  function filterBreadcrumbs(breadcrumbs) {
    // Filter the breadcrumbs array to remove items where storageType is 'sessionStorage'
    const filteredBreadcrumbs = breadcrumbs.filter(
      (item) => item.storageType !== "sessionStorage"
    );

    // Return the filtered array
    return filteredBreadcrumbs;
  }

  const handleLogout = () => {
    const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
    if (isUserContext) {
      const breadf = localStorage.getItem("breadcrumbs");
      const updateLocalBreadcrumbs = filterBreadcrumbs(JSON.parse(breadf));
      localStorage.setItem(
        "breadcrumbs",
        JSON.stringify(updateLocalBreadcrumbs)
      );

      sessionStorage.removeItem("ross_token");
      sessionStorage.removeItem("ross_rtoken");
      sessionStorage.removeItem("Permission");
      sessionStorage.removeItem("breadcrumbs");
      sessionStorage.removeItem("is_user");

      navigate("/");
      window.close();
    } else {
      localStorage.removeItem("ross_token");
      localStorage.removeItem("ross_rtoken");
      localStorage.removeItem("ross-profile");
      localStorage.removeItem("Permission");
      localStorage.removeItem("breadcrumbs");

      dispatch(removeListingFilter());
      dispatch(removeListingFilterPayload());
      dispatch(removeEquipmentFilter());
      dispatch(removeEquipmentFilterPayload());
      dispatch(removeEquipmentListingFilter());
      dispatch(removeEquipmentListingFilterPayload());
      dispatch(removeAccessoryListingFilter());
      dispatch(removeAccessoryListingFilterPayload());

      navigate("/");
    }
  };

  useEffect(() => {
    let profiles = GetProfile();
    setRossProfile(profiles);
    if (profiles) {
      setContactId(profiles?.contact_id);
    }
  }, []);

  const handleSwitchSubAdmin = async (e) => {
    e.preventDefault();
    setShowLoading(true);

    const res = await CallGETAPI("auth/switch-admin");
    let token = res?.data?.token;
    let refresh_token = res?.data?.refreshtoken;
    if (!token) {
      return;
    }

    localStorage.setItem("ross_token", token);
    localStorage.setItem("ross_rtoken", refresh_token);

    const permission = res?.data?.permission;
    setPermission(permission);
    const redirectSubAdmin = permission?.includes("dashboard")
      ? "/dashboard"
      : "";
    navigate(redirectSubAdmin);
    setShowLoading(false);
  };

  const moveSubAdminToUser = async (e) => {
    e.preventDefault();
    let userData = GetProfile();
    setShowLoading(true);

    let payload = {
      account_id: userData?.account_id,
      contact_id: userData?.contact_id,
    };
    const response = await CallPOSTAPI("account/switch-user", payload);
    let token = response?.data?.token;
    let refresh_token = response?.data?.refreshtoken;
    if (!token) {
      return;
    }

    localStorage.setItem("ross_token", token);
    localStorage.setItem("ross_rtoken", refresh_token);

    let permission = response?.data?.permission;
    if (permission?.length > 0) {
      permission.includes("dashboard")
        ? navigate("/user-dashboard1")
        : permission.includes("site-tab")
          ? navigate("/user-listing/sites")
          : permission.includes("contact-tab")
            ? navigate("/user-listing/contacts")
            : permission.includes("equipment-tab")
              ? navigate("/user-listing/equipment")
              : permission.includes("notes-tab")
                ? navigate("/user-listing/notes")
                : permission.includes("support-tab")
                  ? navigate("/user-listing/support")
                  : // permission.includes("account-details") ?
                  navigate("/user/Details");
      // ? navigate("/user/account-details/" + user.account_id)
      // : "";
    } else {
      navigate("/dashboard");
    }
    setShowLoading(false);
  };

  // For automatic logout
  useEffect(() => {
    // let autoLogout;
    let popUpTimer;

    const getLogoutTime = () => {
      if (user.user_type === 0) return 6 * 60 * 60 * 1000; // admin = 6 hour
      if (user.user_type === 1) return 6 * 60 * 60 * 1000;
      if (user.user_type === 2) return 6 * 60 * 60 * 1000; // sub-admin = 6 hour
      return 30 * 60 * 1000; // user =  2 hours
    };

    const resetTimer = () => {
      // clearTimeout(autoLogout);
      clearTimeout(popUpTimer);

      popUpTimer = setTimeout(showPopUp, getLogoutTime());
      // autoLogout = setTimeout(logout, getLogoutTime());
    };

    const refreshToken = async () => {
      let payload = {
        email: user.email,
      };

      const response = await CallPOSTAPI("auth/refresh-token", payload);
      if (response?.data) {
        let refresh_token = response?.data?.data?.refreshtoken;
        localStorage.setItem("ross_token", refresh_token);
      }
      resetTimer();
    };

    const showPopUp = () => {
      Swal.fire({
        text: "Are you still available on site?",
        showCancelButton: true,
        confirmButtonText: "Stay logged in",
        cancelButtonText: "Logout",
        cancelButtonColor: "#d9534f",
        timer: 30000, // 30 seconds for auto-close
        timerProgressBar: true,
      }).then((result) => {
        if (result.isConfirmed) {
          refreshToken();
        } else {
          handleLogout();
        }
      });
    };

    // const logout = () => {
    //   Swal.close();
    //   handleLogout();
    // };

    const activityListener = () => {
      resetTimer();
    };

    resetTimer();

    // event listeners for user activity
    window.addEventListener("mousemove", activityListener);
    window.addEventListener("click", activityListener);
    window.addEventListener("keypress", activityListener);

    return () => {
      // clearTimeout(autoLogout);
      clearTimeout(popUpTimer);
      window.removeEventListener("mousemove", activityListener);
      window.removeEventListener("click", activityListener);
      window.removeEventListener("keypress", activityListener);
    };
  }, [user.user_type]);

  return (
    <>
      <div
        className="Navbar-Search"
        style={{ position: "relative", display: "block" }}
      >
        {/* {Number(user?.user?.user_type) == 0 ? (<>  
                <div className='d-flex mx-auto Search-button'>
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        // className="me-2"
                        aria-label="Search"
                    />
                    <button className="btn btn-search text-white p-0" variant="outline-success">
                   <SearchIcon /> 
                    </button>
                </div>
                </>) : ""
                 }  */}

        {/* <div
          className="d-flex align-items NavbarUserIconDiv"
          // onClick={(e) => handleClick(e)}
          onClick={handleClick}
        // style={{ position: "absolute", cursor: "pointer" }}
        >
          <Person2Icon sx={{ color: "white" }} fontSize="large" />

          <div style={{ color: "white" }} className="NavbarUserEmailDiv">
            <div> {user?.name}</div>
            <div className="Email"> {truncateText(user?.email, 20)}</div>
          </div>

          <ArrowDropDownIcon
            className="ArrowIcon"
            sx={{ color: "white" }}
            fontSize="large"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
        </div> */}

        <div className="profile-head">
          {(parseInt(user?.user_type) === 0 || parseInt(user?.user_type) === 2) && (
            <Tooltip title="Switch User">

              <div
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpenSwitchUser(true);
                  handleClose();
                }}>
                <PiUserSwitch size={40}
                />
              </div>
            </Tooltip>
          )}
          <div onClick={handleClick}
            style={{ color: "white", cursor: "pointer" }}
          >
            {/* <Tooltip title="profile"> */}

            {profileImage ?
              <img
                src={profileImage}
                alt="profile"
                width={40}
                style={{ color: "white" }}
              />
              : <Person2Icon sx={{ color: "white" }} fontSize="large" />
            }
            {/* </Tooltip> */}
          </div>
        </div>
      </div>

      {parseInt(user?.user_type) === 0 ? (
        <>
          <MainAdminMenuBar
            user={user}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            moveSubAdminToUser={moveSubAdminToUser}
            handleSwitchSubAdmin={handleSwitchSubAdmin}
            handleLogout={handleLogout}
          />
        </>
      ) : (
        ""
      )}

      {parseInt(user?.user_type) === 2 ? (
        <>
          <SubAdminMenuBar
            user={user}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            moveSubAdminToUser={moveSubAdminToUser}
            handleSwitchSubAdmin={handleSwitchSubAdmin}
            handleLogout={handleLogout}
          />
        </>
      ) : (
        ""
      )}

      {parseInt(user?.user_type) === 3 ? (
        <>
          <UserMenuBar
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            moveSubAdminToUser={moveSubAdminToUser}
            handleSwitchSubAdmin={handleSwitchSubAdmin}
            handleLogout={handleLogout}
          />
        </>
      ) : (
        ""
      )}

      {/* <MenuItem onClick={handleLogout}>Logout</MenuItem> */}
      {/* </Menu> */}

      {/* {openReports && (
        <>
          <ReportsModel
            openReports={openReports}
            setOpenReports={setOpenReports}
            privileges={privileges}
          />
        </>
      )}

      {adminOpenReports && (
        <>
          <AdminReportsModel
            openReports={adminOpenReports}
            setOpenReports={setAdminOpenReports}
            // privileges={privileges}
          />
        </>
      )}

      */}
      {openSwitchUser && (
        <SwitchUserModel
          openSwitchUser={openSwitchUser}
          setOpenSwitchUser={setOpenSwitchUser}
        />
      )}
    </>
  );
};

export default SearchForm;
