import React from "react";
import CustomToggleButton2 from "../../../../common/components/toggleSwitch/CustomToggleButton2";
import BatteryInformationTrEdit from "./BatteryInformationTrEdit";
import SpareBatteryInfoEdit from "./SpareBatteryInfoEdit";
import BatteryInfoTableSkeleton from "./AedSkeletons/BatteryInfoTableSkeleton";
import { CheckSpareBatteryTblCol, conditonalCheckingTypeOfBattery } from "../../../../common/helper/BasicFn";



const BatteryInfoTables = ({
  showLoading,
  formData,
  setFormData,
  handleToggleChange,
  BatteryExpTbl,
  batteryTypeList,
  print_battery_part,
  RenderDate,
  CheckAEDBatteryTblCol,
}) => {

  const is_has_man = formData?.battery_Info?.[0]?.has_man?.length > 0 ? true : false;
  const is_has_9v = formData?.battery_Info?.[0]?.has_9v?.length > 0 ? true : false;
  const is_has_battery = formData?.battery_Info?.[0]?.has_battery?.length > 0 ? true : false;
  const is_has_installBy = formData?.battery_Info?.[0]?.has_installBy?.length > 0 ? true : false;
  const is_has_10pk = formData?.battery_Info?.[0]?.has_10pk?.length > 0 ? true : false;
  const is_chargePak = formData?.charge_pak_info?.length > 0 ? true : false;



  if (showLoading) {
    return <BatteryInfoTableSkeleton BatteryExpTbl={BatteryExpTbl} />;
  }

  return (
    <>
      <table className="theme-table">
        <tr>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                {" "}
                {!formData?.battery_information_toggle
                  ? "Please Correct Below Information"
                  : "Is below information correct?"}{" "}
              </div>
              <div style={{ display: "flex", marginRight: "10px" }}>
                <CustomToggleButton2
                  ToggleName="battery_information_toggle"
                  ToggleValue={formData?.battery_information_toggle}
                  changeHandler={handleToggleChange}
                  is_read_only={false}
                />
              </div>
            </div>
          </td>
        </tr>
      </table>
      <table className="theme-table">
        <thead>
          <tr className="">
            <th
              scope="col"
              className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
            >
              Main #
            </th>
            {Array.isArray(formData?.battery_info) &&
              BatteryExpTbl?.map((item) =>
                item?.is_default ||
                  (item?.conditionalShow && item?.conditionalShow(formData?.battery_info)) ||
                  CheckSpareBatteryTblCol(
                    [...formData?.allBatterySpares, ...formData?.ALLBatteris] ||
                    [],
                    item?.key
                  ) === 1 || conditonalCheckingTypeOfBattery(formData?.batteryInfo?.[0]) ? (
                  <>
                    {(item.title === "Battery Lot" ||
                      item.title === "Battery UDI") &&
                      formData?.battery_info[0].has_10pk.length > 0 ? (
                      ""
                    ) : (
                      <th
                        scope="col"
                        className="border border-2 py-1 px-2 bg-tbl-border border-t-blue border-r-blue"
                      >
                        {item?.title} #
                      </th>
                    )}
                  </>
                ) : (
                  ""
                )
              )}
          </tr>
        </thead>

        {Array.isArray(formData?.battery_info) &&
          formData?.battery_info?.map((data, index) => (
            <tbody className="odd-even-row border-b-blue" key={index}>
              {/* has_battery loop */}
              {data?.has_battery?.length > 0 && (
                <>
                  {data?.has_battery?.map(
                    (hasBattery, batteryIndex) =>
                      hasBattery?.battery_type_id &&
                      hasBattery?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={hasBattery}
                          DataName={"has_battery"}
                          batteryIndex={batteryIndex}
                          parentName={"battery_info"}
                        />
                      )
                  )}
                </>
              )}

              {/* has_9v loop */}
              {data?.has_9v?.length > 0 && (
                <>
                  {data?.has_9v?.map(
                    (has9v, has9vIndex) =>
                      has9v?.battery_type_id &&
                      has9v?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={has9v}
                          DataName={"has_9v"}
                          batteryIndex={has9vIndex}
                          parentName={"battery_info"}
                        />
                      )
                  )}
                </>
              )}

              {/* has_installby loop */}
              {data?.has_installby?.length > 0 && (
                <>
                  {data?.has_installby?.map(
                    (hasInstallby, hasInstallbyIndex) =>
                      hasInstallby?.battery_type_id &&
                      hasInstallby?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={hasInstallby}
                          DataName={"has_installby"}
                          batteryIndex={hasInstallbyIndex}
                          parentName={"battery_info"}
                        />
                      )
                  )}
                </>
              )}

              {/* has_man loop */}
              {data?.has_man?.length > 0 && (
                <>
                  {data?.has_man.map(
                    (hasMan, hasManIndex) =>
                      hasMan?.battery_type_id &&
                      hasMan?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={hasMan}
                          DataName={"has_man"}
                          batteryIndex={hasManIndex}
                          parentName={"battery_info"}
                        />
                      )
                  )}
                </>
              )}

              {/* has_10pk loop */}
              {data?.has_10pk?.length > 0 && (
                <>
                  {data?.has_10pk.map(
                    (has10pk, has10pkIndex) =>
                      has10pk?.battery_type_id &&
                      has10pk?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={has10pk}
                          DataName={"has_10pk"}
                          is_disabled={true}
                          batteryIndex={has10pkIndex}
                          parentName={"battery_info"}
                        />
                      )
                  )}
                </>
              )}
              {/* {JSON.stringify(data)} */}
              {data?.charge_pack?.length > 0 && (
                <>
                  {data?.charge_pack.map(
                    (chargePak, chargePakIndex) =>
                      chargePak?.battery_type_id &&
                    chargePak?.battery_type_id != "" && (
                        <BatteryInformationTrEdit
                          setFormData={setFormData}
                          formData={formData}
                          battery_information={
                            formData?.battery_information_toggle
                          }
                          batteryTypeList={batteryTypeList}
                          batteryInfo={formData?.battery_info}
                          print_battery_part={print_battery_part}
                          RenderDate={RenderDate}
                          DataValue={chargePak}
                          DataName={"charge_pack"}
                          batteryIndex={chargePakIndex}
                        />
                      )
                  )}
                </>
              )}
            </tbody>
          ))}

        <SpareBatteryInfoEdit
          formData={formData}
          setFormData={setFormData}
          print_battery_part={print_battery_part}
          RenderDate={RenderDate}
          batteryTypeList={batteryTypeList}
        />
      </table>
    </>
  );
};

export default BatteryInfoTables;
