import React, { useEffect } from "react";
import CustomToggleButton2 from "../../../../../../common/components/toggleSwitch/CustomToggleButton2";
import { calculate9vExpiryDate, conditonalCheckingTypeOfBattery } from "../../../../../../common/helper/BasicFn";

const DynamicTable = ({  
  // columns,
  data, battery_information_toggle }) => {
  // console.log({ columns })
  console.log({ data });
  
  // Function to check if a column has any non-null values
  const hasNonNullValues = (key) => {
    return data.some((row) => row[key] != null && row[key] !== "");
  };


  const typeOfBat = conditonalCheckingTypeOfBattery(data[0]);

  console.log({ typeOfBat })

  // Filter columns to display
  // const displayColumns = columns.filter(
  //   (col) =>
  //     (col.is_default === 1 && hasNonNullValues(col.key)) ||
  //     (col.is_default === 0 && hasNonNullValues(col.key))
  // );

  const displayColumns = (typeOfBat) => {
    let cols = [];
    if (typeOfBat == "has_man") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "manufactured_date", title: "Manufactured Date" },
        { key: "install_date", title: "Install Date" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_9v") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "9v_expiry_date", title: "9v Expiry Date" },
        { key: "battery_udi", title: "Battery UDI" },
        { key: "install_9v_date", title: "9v Install Date" },
      );
    }
    else if (typeOfBat == "has_battery") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        // { key: "install_date", title: "Install Date" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_installby") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "install_before_date", title: "Install Before Date" },
        { key: "battery_serial", title: "Serial" },
        { key: "install_date", title: "Install Date" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "battery_udi", title: "Battery UDI" },
      );
    }
    else if (typeOfBat == "has_10pk") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "install_date", title: "Install Date" },
        { key: "install_before_date", title: "Install Before Date" },
      );
    }
    else if (typeOfBat == "charge_pack") {
      cols.push(
        { key: "battery_type_id", title: "Battery Part" },
        { key: "battery_expiration", title: "Battery Expiration" },
        { key: "battery_lot", title: "Battery Lot" },
        { key: "charge_pak_uid", title: "Battery UDI" },
      );
    }
    return cols;
  }

  // find the columns on the basis of type of the battery
  const columns  = displayColumns(typeOfBat);

  // Sort data: is_spare = 0 first, then is_spare = 1
  const sortedData = [...data].sort((a, b) => a.is_spare - b.is_spare);

  return (
    <div className="table-responsive">
      <table className="theme-table">
        <tbody>
          <tr>
            <td>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {!battery_information_toggle
                    ? "Please Correct Below Information"
                    : "Is below information correct?"}
                </div>
                <div
                  style={{
                    display: "flex",
                    // marginLeft: "74.1%"
                  }}
                >
                  <CustomToggleButton2
                    ToggleName="battery_information_toggle"
                    ToggleValue={battery_information_toggle}
                    changeHandler={() => { }}
                    is_read_only={true}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-striped table-bordered">
        <thead>
          <tr className="table-light">
            <th scope="col">Battery Type</th>
            {columns.map((col) => (
              <th key={col.key} scope="col">
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr key={index}>
              <td>{row.is_spare === 1 ? "Spare" : "Main"}</td>
              {columns.map((col) => (
                <RenderTd row={row} col={col} key={col.key} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const RenderTd = ({ row, col }) => {
  if (col.key === "is_spare") {
    return <td>{row[col.key] === 1 ? "Spare" : "Main"}</td>;
  }

  // Handle calculated 9v expiry date
  if (col.key === "9v_expiry_date") {
    const expiryDate = calculate9vExpiryDate(row.install_9v_date);
    return <td>{expiryDate}</td>;
  }


  if (col.renderRow) {
    return (
      <td>
        {" "}
        {row[col.key] != null && row[col.key] !== ""
          ? col.renderRow(row[col.key])
          : ""}
      </td>
    );
  }
  return (
    <td>
      {row[col.key] != null && row[col.key] !== ""
        ? String(row[col.key === "battery_type_id" ? "battery_part_number" : col.key])
        : ""}
    </td>
  );
};

export default DynamicTable;
