import { useState, useCallback } from "react";
import Minus from "../../../../common/img/Minus.svg";
import Activitycheck from "../../../../common/img/Activity Symbols.png";
import KingTaz from "../../../../common/img/KingTaz.png";
import { Form } from "react-bootstrap";
import CommonDatePicker from "../../../../common/components/DatePickers/CommonDatePicker";
import { FormatDate, printConsole } from "../../../../common/helper/Common";
import { toast } from "react-toastify";
import AEDInventoryModal from "./AEDInventoryModal";
import { NewModiFicationGroupIcon } from "../../../../common/helper/icons";

const NewAedPadRow = ({
  index,
  Pindex,
  padInfo,
  is_readonly,
  handleMoveSpareToMainPadRow,
  handlePadSpareCrown,
  setNewFormData,
  handleCalendarChange,
  handleChange,
  handleRemovePadsRow,
  onInputChangePads,
  aedPadTypeList,
  AedFormData,
  contact_id,
  handleResetBtn,
  inspection_by,
  AedItem,
  handleDNDButton,
  isInventory,
}) => {
  const [inventoryModal, setInventoryModal] = useState(false);
  let isPediatricTable = 0;

  // Get filtered pad types based on selection
  const getPadTypeOptions = (selectedType) => {
    if (selectedType === "pediatric" || selectedType === "spare_pediatric") {
      return aedPadTypeList.filter(obj => obj.pediatric === 1);
    } else {
      return aedPadTypeList.filter(obj => obj.pediatric === 0);
    }
  };

  const print_pad_part = (pid) => {
    let findName = aedPadTypeList.find(
      (item) => parseInt(item?.pad_type_id) === parseInt(pid)
    );
    return findName?.pad_part_number || pid;
  };

  const handleChange2 = useCallback(
    (event, index, Pindex) => {
      printConsole({ event, index, Pindex });

      const { name, value } = event.target;

      if (
        name === "type" &&
        (value === "main" || value === "pediatric")
      ) {
        let Fd = [...AedFormData];
        const allPadsArr = Fd[index].all_pads;
        let existingPadIndex = -1;

        // Check for existing pad of the same type, excluding the current pad being edited
        if (value === "main") {
          existingPadIndex = allPadsArr.findIndex(
            (item, idx) => idx !== Pindex && item.is_spare === 0 && item.is_pediatric === 0
          );
        } else if (value === "pediatric") {
          existingPadIndex = allPadsArr.findIndex(
            (item, idx) => idx !== Pindex && item.is_spare === 0 && item.is_pediatric === 1
          );
        }

        if (existingPadIndex !== -1) {
          if (value === "main") {
            toast.error("Please remove existing main pad first.");
          } else {
            toast.error("Please remove existing pediatric pad first.");
          }
          return false;
        }
      }

      // Update the pad type
      onInputChangePads(
        value,
        name,
        index,
        Pindex,
        "all_pads"
      );

      // Reset pad_type_id when changing pad type
      if (name === "type") {
        onInputChangePads(
          "",
          "pad_type_id",
          index,
          Pindex,
          "all_pads"
        );
      }
    },
    [onInputChangePads, AedFormData]
);

  const handleDateChange = useCallback(
    (name, val, Pindex) => {
      onInputChangePads(val, name, index, Pindex, "all_pads");
    },
    [onInputChangePads]
  );

  const handleDND = () => {
    setInventoryModal(true);
  };

  const handlePadSpareCrownFunc = useCallback(
    (index, Pindex, type) => {
      let Fd = [...AedFormData];
      const padSecName = Fd[index].all_pads[Pindex].section_name;
      handlePadSpareCrown(index, Pindex, type, padSecName);
    },
    [handlePadSpareCrown]
  );

  const renderPadInfo = () => {
    const isSpare = padInfo.is_spare === 1;
    const isPediatric = padInfo.is_pediatric === 1;

    if (isSpare && !isPediatric) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          Spare
        </div>
      );
    } else if (!isSpare && isPediatric) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          Pediatric
        </div>
      );
    } else if (isSpare && isPediatric) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          Spare Pediatric
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          Main
        </div>
      );
    }
  };

  const handleDefaultPadExpiration = (AedPadInfo) => {
    let current_date = new Date();
    let expiration_date = new Date(AedPadInfo?.pad_expiration);
    let days_difference = expiration_date
      ? Math.floor((expiration_date - current_date) / (1000 * 60 * 60 * 24))
      : null;

    return (
      <>
        {days_difference <= 30 ? (
          <font style={{ color: "red" }}>
            {FormatDate(AedPadInfo?.pad_expiration)}
          </font>
        ) : (
          <font>{FormatDate(AedPadInfo?.pad_expiration)}</font>
        )}
      </>
    );
  };

  const handleNewPadDD = (AedItem) => {
    let section_permissions = AedItem?.section_permissions;
    if (
      section_permissions?.has_pad === 1 &&
      section_permissions?.has_ped_pad === 1 &&
      section_permissions?.has_pedpak === 0 &&
      section_permissions?.has_padpak === 0
    ) {
      isPediatricTable = 1;
      return (
        <>
          <option value="main">Main</option>
          <option value="spare">Spare</option>
          <option value="pediatric">Main Pediatric</option>
          <option value="spare_pediatric">Spare Pediatric</option>
        </>
      );
    } else if (
      section_permissions?.has_pad === 0 &&
      section_permissions?.has_ped_pad === 0 &&
      section_permissions?.has_pedpak === 0 &&
      section_permissions?.has_padpak === 0
    ) {
      isPediatricTable = 1;
      return (
        <>
          <option value="pediatric">Main Pediatric</option>
          <option value="spare_pediatric">Spare Pediatric</option>
        </>
      );
    } else {
      return (
        <>
          <option value="main">Main</option>
          <option value="spare">Spare</option>
        </>
      );
    }
  };

  return (
    <>
      <tr key={Pindex}>
        <td>
          {is_readonly === 0 ? (
            renderPadInfo()
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
            >
              <select
                className={"form-control"}
                name="type"
                onChange={(e) => handleChange2(e, index, Pindex)}
                value={padInfo?.type || 0}
              >
                <option value="0">--Select One--</option>
                {handleNewPadDD(AedItem)}
              </select>
            </div>
          )}
        </td>

        <td>
          {is_readonly === 0 ? (
            print_pad_part(padInfo.pad_type_id) || "N/A"
          ) : (
            <div className="col form-group" style={{ maxWidth: "300px" }}>
              <select
                className={"form-control"}
                value={padInfo?.pad_type_id}
                name={"pad_type_id"}
                onChange={(e) => handleChange2(e, index, Pindex)}
              >
                <option value="">--Select One--</option>
                {getPadTypeOptions(padInfo?.type).map((item) => (
                  <option value={item.pad_type_id} key={item.pad_type_id}>
                    {item.pad_part_number}
                  </option>
                ))}
              </select>
            </div>
          )}
        </td>

        <td>
          {is_readonly === 0 ? (
            <>
              {FormatDate(padInfo?.pad_expiration) ? (
                <>{handleDefaultPadExpiration(padInfo)}</>
              ) : (
                "N/A"
              )}
            </>
          ) : (
            <div className={"d-flex align-items-center calendar-input-btn"}>
              <CommonDatePicker
                calName={"pad_expiration"}
                CalVal={padInfo?.pad_expiration}
                HandleChange={(name, val) => handleDateChange(name, val, Pindex)}
                disabled={false}
              />
            </div>
          )}
        </td>

        <td>
          {is_readonly === 0 ? (
            padInfo.pad_lot || "N/A"
          ) : (
            <Form.Group className={"col"}>
              <Form.Control
                type="text"
                name="pad_lot"
                value={padInfo?.pad_lot}
                onChange={(e) => handleChange2(e, index, Pindex)}
              />
            </Form.Group>
          )}
        </td>

        <td>
          {is_readonly === 0 ? (
            padInfo.pad_udi || "N/A"
          ) : (
            <Form.Group className={"col"}>
              <Form.Control
                type="text"
                name="pad_udi"
                value={padInfo?.pad_udi}
                onChange={(e) => handleChange2(e, index, Pindex)}
              />
            </Form.Group>
          )}
        </td>

        <td>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "5%",
              alignItems: "center",
            }}
          >
            {padInfo?.is_pediatric !== 1 && padInfo?.is_spare === 1 && padInfo?.is_new !== 1 && (
              <img
                src={KingTaz}
                alt="KingTaz"
                height={30}
                style={{ cursor: "pointer" }}
                onClick={() => handlePadSpareCrownFunc(index, Pindex, "main")}
              />
            )}
            {padInfo?.is_pediatric === 1 && padInfo?.is_spare === 1 && is_readonly === 0 && (
              <img
                src={KingTaz}
                alt="KingTaz"
                height={30}
                style={{ cursor: "pointer" }}
                onClick={() => handlePadSpareCrownFunc(index, Pindex, "pediatric")}
              />
            )}
            {padInfo?.is_new && isInventory ? (
              <span onClick={handleDND} style={{ cursor: "pointer" }}>
                <NewModiFicationGroupIcon />
              </span>
            ) : isInventory != 0 ? (
              <img
                src={Activitycheck}
                alt="Activitycheck"
                height={30}
                width={30}
                style={{
                  cursor:
                    AedFormData[index].dni_array_list.findIndex(
                      (it) => it.pid === padInfo.pid
                    ) !== -1
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() => handleDNDButton(index, Pindex, padInfo, "pads")}
              />
            ) : (
              ""
            )}
            <img
              src={Minus}
              alt="Minus"
              height={20}
              width={30}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleRemovePadsRow(index, Pindex, false, false, padInfo?.aed_id)
              }
            />
          </div>
        </td>
      </tr>
      {inventoryModal && (
        <AEDInventoryModal
          setNewFormData={setNewFormData}
          Pindex={Pindex}
          index={index}
          AedFormData={AedFormData}
          inventoryModal={inventoryModal}
          setInventoryModal={setInventoryModal}
          contact_id={contact_id}
          inspection_by={inspection_by}
          api="acc/get-pad-inventory"
        />
      )}
    </>
  );
};

export default NewAedPadRow;