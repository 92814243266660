import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import { CallPOSTAPI } from "../../../common/services";
import { ContactList } from "../../../common/helper/BasicFn";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";

const EditMultipleAccountModal = ({
  show,
  setShow,
  newRoleDataArr,
  rowData,
  fetchCreateTblData,
}) => {
  const initialRolesValuesArr = rowData.assign_account_ids
    .split(",")
    .map((id, index) => ({
      value: Number(id),
      label: rowData.assign_accounts.split(",")[index]?.trim(),
    }));

  // const initialRolesValuesArr = rowData.assign_account_ids
  //   .split(",")
  //   .map(Number);
  // const filteredRolesArray = newRoleDataArr.filter((item) =>
  //   initialRolesValuesArr.includes(item.value)
  // );

  console.log({ rowData });

  const [loading, setLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [selectRoleValidations, setSelectRoleValidations] = useState(false);
  const [accounctListData, setAccounctListData] = useState([]);
  const [formData, setFormData] = useState({
    account_id: rowData?.account_name,
    contact_id: rowData?.contactName,
    // role_id: initialRolesValuesArr,
    role_id: rowData.assign_account_ids.split(",").map((id, index) => ({
      value: Number(id),
      label: rowData.assign_accounts.split(",")[index]?.trim(),
    })),
  });

  useEffect(() => {
    const removeAccount = newRoleDataArr.filter((item) => {
      return item?.label != rowData?.account_name;
    });
    setAccounctListData(removeAccount);
  }, []);

  const handleChange = (e, key) => {
    const { value } = e.target;
    if (key === "account_id") {
      setSelectedAccountId(value);
    }
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const fetchContactData = async (selectedAccountId) => {
    const res = await ContactList(selectedAccountId);
    setContactList(res);
  };

  useEffect(() => {
    if (selectedAccountId !== null) {
      fetchContactData(selectedAccountId);
    }
  }, [selectedAccountId]);

  const handleModalSubmit = async (e) => {
    e.preventDefault();

    if (formData.role_id.length === 0) {
      setSelectRoleValidations(true);
      return;
    }

    setLoading(true);
    let rolesArr = formData.role_id;
    let finalRolesArr = rolesArr.map((role) => role.value);
    let body = { accounts_id: finalRolesArr + "" };

    const res = await CallPOSTAPI(
      "permission/update-accounts/" + rowData.contact_id,
      body
    );
    // const res = await CallPOSTAPI(
    //   "admin/update-accounts/" + rowData.contact_id,
    //   body
    // )

    if (res?.data?.status) {
      setLoading(false);
      toast.success(res?.data?.msg);
      fetchCreateTblData();
      setShow(false);
    } else {
      setLoading(false);
      toast.error(res?.data?.msg);
      setShow(false);
    }
  };

  // const handleRoleChange = (e) => {
  //   const value = e.target.formatted;
  //   setFormData({
  //     ...formData,
  //     role_id: value,
  //   });
  //   setSelectRoleValidations(false);
  // };

  const handleRoleChange = (selectedOptions) => {
    console.log({ selectedOptions });
    const updatedRoles = selectedOptions.target.formatted.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    setFormData({
      ...formData,
      role_id: updatedRoles,
    });
    setSelectRoleValidations(false);
  };

  const renderSelectedTitleNames = () => {
    return [formData.role_id.map((item) => item.label).join(", ")];
  };

  const renderSelectTitle = () => {
    return (
      <div>
        {formData.role_id.length >= 3
          ? `${formData.role_id.length} Selected`
          : renderSelectedTitleNames()}
      </div>
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Multiple Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="" onSubmit={handleModalSubmit} noValidate>
            <div
              className="my-4"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5%",
                marginBottom: "50px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  gap: "5%",
                }}
              >
                <Form.Group
                  className={"col"}
                  style={{ minWidth: "200px", maxWidth: "300px" }}
                >
                  <Form.Label>Select Account</Form.Label>
                  <Form.Control
                    type="text"
                    name="account_id"
                    value={formData.account_id}
                    onChange={(e) => handleChange(e, "account_id")}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group
                  className={"col"}
                  style={{ minWidth: "200px", maxWidth: "300px" }}
                >
                  <Form.Label>Select Contact</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_id"
                    value={formData.contact_id}
                    onChange={(e) => handleChange(e, "contact_id")}
                    disabled={true}
                  />
                </Form.Group>

                <Form.Group className={"col"} style={{ minWidth: "200px" }}>
                  <Form.Label> Assign Account*</Form.Label>
                  {/* <MultiSelect
                    hasSelectAll={false}
                    valueRenderer={renderSelectTitle}
                    options={accounctListData}
                    value={formData.role_id}
                    onChange={(e) => handleRoleChange(e)}
                    labelledBy="Select"
                  /> */}
                  {console.log({ roleId: formData?.role_id })}
                  <PaginateAutoComplete
                    dropDownName="assign_account_id"
                    apiEndpoint={"permission/account-list-user"}
                    idKey={"account_id"}
                    valueKey={"account_name"}
                    parentKey={"allAccount"}
                    onSelect={handleRoleChange}
                    placeholder="-- Select --"
                    showCountsOnly={1}
                    multiple={true}
                    showCheckBox={true}
                    shouldFetchOnOpen={true}
                    isCache={true}
                    selectDisabled={false}
                    isSearchable={true}
                    isAsync={true}
                    pageSize={20}
                    excludeRecords={[rowData?.account_id]}
                    // defaultValue={
                    //   formData?.role_id?.length > 0
                    //     ? formData.role_id?.map((it) => ({
                    //         account_id: it.value,
                    //         account_name: it.label,
                    //       }))
                    //     : null
                    // }
                    defaultValue={
                      formData?.role_id?.length > 0
                        ? formData.role_id.map((it) => ({
                            account_id: it.value,
                            account_name: it.label,
                          }))
                        : null
                    }
                  />

                  {selectRoleValidations && (
                    <Form.Control.Feedback type="" className="text-danger mt-1">
                      Please Select Role.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <div className=" d-flex justify-content-end">
            <button
              className="btn btn-danger mt-0"
              type="button"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            &nbsp;
            <Button
              variant="success"
              onClick={(e) => handleModalSubmit(e)}
              className="mt-0"
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMultipleAccountModal;
