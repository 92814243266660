import { getPermission } from "./BasicFn";
import { CallGETAPI } from "../services";
import { DecryptToken } from "./BasicFn";
import { printConsole } from "./Common";
import { getPer } from "./BasicFn";

// const permission = [
//   "dashboard",
//   "accounts-listing",
//   "account-details",
//   "account-edit",
//   "site-tab",
//   "sites-new",
//   "site-details",
//   "site-details-edit",
//   "contact-tab",
//   "contacts-new",
//   "contact-details",
//   "contact-details-edit",
//   "equipment-tab",
//   "new-aed",
//   "aed-details",
//   "aed-edit",
//   "move-aed",
//   "assign-aed",
//   "new-accessories",
//   "move-accessory",
//   "new-aed-checks",
//   "aed-check-details",
//   "aed-service-check",
//   "service-check-details",
//   "training-tab",
//   "training-new",
//   "training-details",
//   "edit-training",
//   "inperson-tab",
//   "new-inperson",
//   "inperson-details",
//   "edit-inperson",
//   "pops-tab",
//   "new-pop",
//   "pop-details",
//   "pop-edit",
//   "notes-tab",
//   "new-note",
//   "note-details",
//   "edit-note",
//   "email-tab",
//   "support-tab",
//   "new-support",
//   "support-details",
//   "edit-support",
//   "documents-tab",
//   "new-document",
//   "document-details",
//   "edit-document",
//   "rfi-tab",
//   "new-rfi",
//   "rfi-details",
//   "edit-rfi",
//   "out-of-service",
//   "support-respond",
//   "support-reassign",
//   "support-close",
//   "pop-clone",
//   "inperson-clone",
//   "inperson-student-tab",
//   "inperson-certification-tab",
//   "inperson-instructor",
//   "inperson-broadcast",
//   "switch-user",
// ];

// const permission = [];

const account = {
  Details: "account-details",
  Sites: "site-tab",
  Contacts: "contact-tab",
  Equipment: "equipment-tab",
  POPS: "pops-tab",
  Notes: "notes-tab",
  Support: "support-tab",
  Emails: "email-tab",
  Notes: "notes-tab",
  Documents: "documents-tab",
};

const site = {
  Details: "site-details",
  Contacts: "contact-tab",
  Equipment: "equipment-tab",
  Emails: "email-tab",
  Notes: "notes-tab",
  Documents: "documents-tab",
};

const contact = {
  CONTACT_DETAILS: "contact-details",
  Details: "contact-details",
  Notes: "notes-tab",
  Support: "support-tab",
  Emails: "email-tab",
  Notes: "notes-tab",
  Documents: "documents-tab",
};

const equipment = {
  Details: "aed-details",
  AEDChecks: "aed-check-listing",
  AEDServicing: "service-check-listing",
  Notes: "notes-tab",
  Documents: "documents-tab",
};

const pop = {
  Details: "pop-details",
  Notes: "notes-tab",
};

const support = {
  newSupport: "new-support",
};

const btn = {
  accountName: "account-details",
  accountNew: "new-account",
  accountEdit: "account-edit",
  assignedAed: "assign-aed",
  newSite: "sites-new",
  newContact: "contacts-new",
  newAed: "new-aed",
  newAccessory: "new-accessories",
  moveAed: "move-aed",
  moveAccessory: "move-accessory",
  accountDelete: "account-delete",
  // serviceCheck: permission key nhi pta hai,
  outOfService: "out-of-service",
  editAed: "aed-edit",
  newAedCheck: "new-aed-checks",
  newServiceCheck: "aed-service-check",
  newPop: "new-pop",
  newSupport: "new-support",
  editSite: "site-details-edit",
  editContact: "contact-details-edit",
  editPop: "pop-edit",
  accountListing: "accounts-listing",
  siteListing: "site-tab",
  contactListing: "contact-tab",
  equipmentListing: "equipment-tab",
  accessoryListing: "accessories-listing",
  newNote: "new-note",
  supportListing: "support-tab",
  notesListing: "notes-tab",
  supportRespond: "support-respond",
  supportReassign: "support-reassign",
  supportClose: "support-close",
  NewDocument: "new-document",
  NoteEdit: "edit-note",
  emailResend: "email-resend",
  emailForward: "email-forward",
  heldPosition: "held-position-dropdown",
  editBrandModel: "edit-brand",
  rescueUser: "switch-rescue-user",
  switchUser: "switch-user",
  trainingNew: "training-new",
};
const link = {
  accountName: "account-details",
  parentAccount: "account-details",
  distributorAccount: "account-details",
  parentAccountListing: "",
  distributorAccountListing: "",
  contactName: "contact-details",
  popType: "pop-details",
  cordiIPrimary: "contact-details",
  cordiIBackup: "contact-details",
  mainSite: "site-details",
  siteName: "site-details", 
  aedBrand: "aed-details",
  serialNumber: "aed-details",
  contactPopTbl: "contact-details",
  aedCheckDate: "aed-check-details",
  aedServiceDate: "service-check-details",
  supportDetails: "support-details",
  sitePoc: "contact-details",
  popContactContracts: "contact-details",
  documentDetails: "document-details",
  notesDetails: "note-details",
  emailDetails: "email-details",
};

const card = {
  "Checks Needed": "equipment-tab",
  Equipment: "equipment-tab",
  "Equipment Alerts": "equipment-tab",
  AEDs: "equipment-tab",
  Support: "support-tab",
  "AEDs Distribution": "equipment-tab",
  "Training Distribution": "training-tab",
  Training: "training - tab",
  "Classes Scheduled": "inperson-tab",
  "Seats Remain": "inperson-tab",
};

const dashboard = {
  AccountListing: "accounts-listing",
  AccountDetails: "account-details",
  SiteListing: "site-tab",
  SiteDetails: "site-details",
  ContactListing: "contact-tab",
  ContactDetails: "contact-details",
  EquipmentListing: "equipment-tab",
  AedDetails: "aed-details",
  SupportListing: "support-tab",
};

const dashboardStates = {
  accounts: "account-details",
  accounts: "accounts-listing",
  sites: "site-details",
  sites: "site-tab",
  contacts: "contact-details",
  contacts: "contact-tab",
  aeds: "aed-details",
  aeds: "equipment-tab",
  accessories: "accessories-listing",
  delinquentChecks: "delinquentChecks",
  aedsOwned: "aedsOwned",
  aedDistribution: "aedDistribution",
  aedsAssigned: "aedsAssigned",
  aedChecks: "aedChecks",
  supportTickets: "support-tab",
  AedTechServicing: "AedTechServicing",
  aedTechAssignments: "aedTechAssignments",
};
// const dashboardStatesNotAssignedAed = {
//   accounts: ["accounts-listing", "account-details"],
//   sites: ["site-details", "site-tab"],
//   contacts: ["contact-details", "contact-tab"],
//   aeds: ["aed-details", "equipment-tab"],
//   accessories: "",
//   supportTickets: "support-tab",
// };

const data = {
  gerneralInfo: "",
  siteDetailsToggle: "",
};

const getTypePermissionName = ({ type, name }) => {
  if (type === "account") {
    return account[name];
  }
  if (type === "site") {
    return site[name];
  }
  if (type === "contact") {
    return contact[name];
  }
  if (type === "equipment") {
    return equipment[name];
  }
  if (type === "pop") {
    return pop[name];
  }
  if (type === "btn") {
    return btn[name];
  }
  if (type === "link") {
    return link[name];
  }
  if (type === "card") {
    return card[name];
  }
  if (type === "support") {
    return support[name];
  }
  if (type === "dashboard") {
    return dashboard[name];
  }
  // if (type === "dashboardStates") {
  //   return dashboardStates[name];
  // }
  // if (type === "dashboardStatesNotAssignedAed") {
  //   return dashboardStatesNotAssignedAed[name];
  // }
  if (type === "data") {
    return data[name];
  }
};

const hasPermission = ({ permissionName, permission }) => {
  if (
    permissionName === "aedsAssigned" ||
    permissionName === "aedChecks" ||
    permissionName === "delinquentChecks" ||
    permissionName === "aedsOwned" ||
    permissionName === "aedDistribution" ||
    permissionName === "aedTechAssignments" ||
    permissionName === "AedTechServicing"
  )
    return 1;
  if (permission && permissionName) {
    for (let i = 0; i < permission?.length; i++) {
      if (permissionName === permission[i].trim()) {
        return 1; // Return 1 if a match is found
      }
    }
  }
  return 0; // Return 0 if no match is found
};
const hasPermissionDashboard = ({ type, permission, name }) => {
  if (
    name === "delinquentChecks" ||
    name === "aedsOwned" ||
    name === "aedDistribution"
  )
    return 1;

  if (
    Number(getPer()) === 1 &&
    (name === "aedTechAssignments" || name === "AedTechServicing")
  )
    return 1;

  if (type === "dashboardStates") {
    if (name === "aedsAssigned" || name === "aedChecks") return 1;
  }

  const dashboardStates = {
    accounts: ["accounts-listing", "account-details"],
    sites: ["site-details", "site-tab"],
    contacts: ["contact-details", "contact-tab"],
    aeds: ["aed-details", "equipment-tab"],
    accessories: ["accessories-listing"],
    supportTickets: ["support-tab"],
  };
  const dashboardStatesNotAssignedAed = {
    accounts: ["accounts-listing", "account-details"],
    sites: ["site-details", "site-tab"],
    contacts: ["contact-details", "contact-tab"],
    aeds: ["aed-details", "equipment-tab"],
    accessories: ["accessories-listing"],
    supportTickets: ["support-tab"],
  };

  // Get array of permissions for the given name
  const checkPermissions =
    type === "dashboardStates"
      ? dashboardStates[name]
      : dashboardStatesNotAssignedAed[name];
  // If checkPermissions is not an array or empty string, return 0
  if (!Array.isArray(checkPermissions)) {
    return 0;
  }

  let hasPermission = false;
  // Check if any permission from checkPermissions array exists in permission array
  // for (let i = 0; i < checkPermissions?.length; i++) {
  //   const check = checkPermissions[i]?.trim();
  //   printConsole({ checkPermissions });
  //   printConsole({ check });
  //   if (permission.includes(check)) {
  //     hasPermission = true;
  //   }
  // }

  const permissionSet = new Set(permission); // permission ko Set mein convert karen

  for (let i = 0; i < checkPermissions?.length; i++) {
    const check = checkPermissions[i]?.trim();
    printConsole({ checkPermissions });
    printConsole({ check });

    // Set mein check karna
    if (permissionSet.has(check)) {
      hasPermission = true;
    }
  }

  return hasPermission ? 1 : 0;
};

// Function to check if tabName exists and whether it has permission
export function isPermission({
  type = "defaultType",
  name = "defaultTabName",
} = {}) {
  const user = DecryptToken() || {};
  if (Number(user?.user_type) === 0) {
    return 1;
  }
  if (Number(user?.user_type) === 2) {
    return 1;
  }
  if (Number(user?.user_type) === 3) {
    const permission = getPermission();
    const permissionName = getTypePermissionName({ type, name });

    if (
      type === "dashboardStatesNotAssignedAed" ||
      type === "dashboardStates"
    ) {
      return hasPermissionDashboard({ type, permission, name });
    } else if (permissionName) {
      return hasPermission({ permissionName, permission });
    }
  }
  return 0;
}
