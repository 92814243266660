import { Skeleton } from '@mui/material';
import React from 'react';

const AEDUnitsSkeleton = () => {
    const skeletonArray = Array(6).fill(null); // Change 6 to the number of skeleton items you want
  
    return (
      <div style={{ display: "flex", flexWrap: "wrap", gap: 0 }}>
        {skeletonArray.map((_, i) => (
          <div
            key={i}
            style={{
              flex: "0 0 16.666%",
              maxWidth: "17.666%",
              marginBottom: 0,
              padding: 0,
            }}
            // className={`border-l-blue border-t-blue border-r-blue`}
          >
            <div
              style={{
                // background: "#D9D9D9",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: 55,
              }}
            >
              <div style={{ backgroundColor: "#ccc", width: "60%", height: "16px", marginBottom: "4px" }}></div>
              <div style={{ backgroundColor: "#ccc", width: "60%", height: "16px" }}></div>
            </div>
            <div
              style={{
                padding: "8% 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: 130,
                  height: 130,
                  backgroundColor: "#ccc",
                }}
              ></div>
            </div>
            <div
              style={{
                padding: "1% 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ backgroundColor: "#ccc", width: "50%", height: "18px" }}></div>
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default AEDUnitsSkeleton;
