import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

const SitesCard = ({ sections }) => {
  const [sites, setSites] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id = "sites";
  const account = findSectionById("accounts", sections);
  const isUserContext = Number(sessionStorage.getItem("is_user")) === 1;
  console.log({ sites: isUserContext });

  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
    storageType: isUserContext ? "sessionStorage" : "localStorage",
  };

  const handleDetailsDispatch = (accountId = "", item = "") => {
    BreadCrumbsObject.title = "Dashboard";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/dashboard",
        type: "dashboard",
      })
    );

    if (!account?.account_id) {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1
      ) {
        BreadCrumbsObject.title = `Site Listing`;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/Admin/Sites-listing`,
          })
        );
      }
    } else if (Number(sites?.totalcount) > 1) {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1
      ) {
        BreadCrumbsObject.title = item;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account-details/${accountId}/${item}`,
          })
        );
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteDetails" })) === 1
      ) {
        BreadCrumbsObject.title = `Site Name ${item}`;
        dispatch(
          addNewBreadcrumbs({
            ...BreadCrumbsObject,
            url: `/account/site-details/${sites?.site_id}/Details`,
          })
        );
      }
    }
  };

  const navigateSite = () => {
    if (!account?.account_id) {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1
      ) {
        handleDetailsDispatch();
        navigate("/Admin/Sites-listing");
      }
    } else if (Number(sites?.totalcount) > 1) {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1
      ) {
        handleDetailsDispatch(account?.account_id, "Sites");
        navigate(`/account-details/${account?.account_id}/Sites`);
      }
    } else {
      if (
        Number(isPermission({ type: "dashboard", name: "SiteDetails" })) === 1
      ) {
        handleDetailsDispatch(account?.account_id, "Details");
        navigate(`/account/site-details/${sites?.site_id}/Details`);
      }
    }
  };

  // const navigateSite = () => {
  //   if (!account?.account_id) {
  //     Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1 &&
  //       navigate("/Admin/Sites-listing");
  //   } else if (Number(sites?.totalcount) > 1) {
  //     Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1 &&
  //       navigate(`/account-details/${account?.account_id}/Sites`);
  //   } else {
  //     Number(isPermission({ type: "dashboard", name: "SiteDetails" })) === 1 &&
  //       navigate(`/account/site-details/${sites?.site_id}/Details`);
  //   }
  // };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setSites(matched);
  }, [sections]);

  return (
    <div
      className="p-4 rounded-lg"
      style={{ cursor: Number(sites?.totalcount) > 0 ? "pointer" : "" }}
      onClick={() => Number(sites?.totalcount) > 0 && navigateSite()}
    >
      <h3 id="sites-card" className="text-center text-lg font-bold mb-2">
        {sites?.totalcount}
      </h3>
      <p className="text-center">Sites</p>
    </div>
  );
};

export default SitesCard;
